<template>
  <div class="vab-nav">
    <el-row :gutter="15">
      <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="4">
        <div class="left-panel">
          <vab-fold v-if="layout !== 'float'" />
          <el-tabs
            v-if="layout === 'comprehensive'"
            v-model="extra.first"
            tab-position="top"
            @tab-click="handleTabClick"
          >
            <template
              v-for="(route, index) in handleRoutes"
              :key="index + route.name"
            >
              <el-tab-pane :name="route.name">
                <template #label>
                  <vab-icon
                    v-if="route.meta.icon"
                    :icon="route.meta.icon"
                    :is-custom-svg="route.meta.isCustomSvg"
                    style="min-width: 16px"
                  />
                  <!-- {{ route.meta.title }} -->
                  <!-- {{ translateTitle(route.meta.title) }} -->
                </template>
              </el-tab-pane>
            </template>
          </el-tabs>
          <vab-breadcrumb v-else class="hidden-xs-only" />
        </div>
      </el-col>
      <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="20">
        <div class="right-panel">
          <!-- <vab-error-log /> -->
          <!-- <vab-search /> -->
          <!-- <vab-notice /> -->
          <!-- 上传文件 -->
          <VabUploadloadFile />
          <!-- 下载文件 -->
          <vab-download-file />
          <!-- 金豆 -->
          <vab-golden />
          <!-- 店铺 -->
          <vab-shop-site />
          <!-- 版权 -->
          <vab-copyright />
          <!-- 全屏 -->
          <vab-full-screen />
          <vab-language />
          <!-- <vab-theme /> -->
          <vab-refresh />
          <vab-customer-service />
          <!-- <VabUseDark /> -->
          <vab-avatar />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { computed, defineComponent, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { translateTitle } from '@/utils/i18n'
import { openFirstMenu } from '@/config'
import { fillUrl } from 'common/js/utils'

export default defineComponent({
  name: 'VabNav',
  props: {
    layout: {
      type: String,
      default: '',
    },
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const extra = computed(() => store.getters['settings/extra'])
    const routes = computed(() => store.getters['routes/routes'])
    const user = computed(() => store.getters['user/userInfo'])

    const handleRoutes = computed(() =>
      routes.value.filter(
        (_route) => _route.meta && _route.meta.hidden !== true
      )
    )
    const handleActiveMenu = () =>
      routes.value.find((_route) => _route.name === extra.value.first)

    const handleTabClick = (handler) => {
      if (handler !== true && openFirstMenu) router.push(handleActiveMenu())
    }

    watch(
      () => route.matched[0].name,
      (name) => {
        extra.value.first = name
        handleTabClick(true)
      },
      {
        immediate: true,
      }
    )

    return {
      fillUrl,
      extra,
      routes,
      handleRoutes,
      handleTabClick,
      translateTitle,
      user,
    }
  },
})
</script>

<style lang="scss" scoped>
.vab-nav {
  position: relative;
  height: $base-nav-height;
  padding-right: $base-padding;
  padding-left: $base-padding;
  overflow: hidden;
  user-select: none;
  background: #1746a2;
  box-shadow: $base-box-shadow;

  .left-panel {
    display: flex;
    align-items: center;
    justify-items: center;
    height: $base-nav-height;

    :deep() {
      .fold-unfold {
        margin-right: $base-margin;
      }

      .el-tabs {
        width: 100%;
        margin-left: $base-margin;

        .el-tabs__header {
          margin: 0;

          > .el-tabs__nav-wrap {
            display: flex;
            align-items: center;

            .el-icon-arrow-left,
            .el-icon-arrow-right {
              font-weight: 600;
              color: var(--el-color-grey);
            }
          }
        }

        .el-tabs__item {
          > div {
            display: flex;
            align-items: center;

            i {
              margin-right: 3px;
            }
          }
        }
      }

      .el-tabs__nav-wrap::after {
        display: none;
      }
    }
  }

  .right-panel {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-end;
    height: $base-nav-height;

    :deep() {
      [class*='ri-'] {
        margin-left: $base-margin;
        // color: var(--el-color-grey);
        color: #fff7e9;
        cursor: pointer;
      }

      button {
        [class*='ri-'] {
          margin-left: 0;
          color: var(--el-color-white);
          cursor: pointer;
        }
      }
    }
  }
}
</style>
