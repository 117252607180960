/*
 * @Author: WGL 958897276@qq.com
 * @Date: 2024-12-25 15:30:02
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-02-24 16:22:59
 * @FilePath: /erp-admin/src/vab/plugins/directive.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { hasPermission } from '@/utils/permission'

export function setup(app) {
  /**
   * @description 自定义指令v-permissions
   */
  app.directive('permissions', {
    mounted(el, binding) {
      const { value } = binding
      if (value)
        if (!hasPermission(value))
          el.parentNode && el.parentNode.removeChild(el)
    },
  })
}
