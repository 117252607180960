<!--
 * @Author: WGL
 * @Date: 2023-06-21 09:23:33
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-03-06 09:31:12
 * @Description: 
-->
<template>
  <div
    v-if="list?.length"
    class="tl omit3 w"
    :class="[list.length <= showNum ? '' : 'cp']"
  >
    <div v-if="list.length <= showNum" class="w omit3">
      <div v-for="(item, index) in showListData" :key="index" class="fxmj">
        <div class="ex tr g6">{{ item?.field_info?.attr_title || '' }}：</div>
        <div class="ex omit">{{ item?.attr_value || '' }}</div>
      </div>
    </div>
    <el-popover
      v-else
      :persistent="false"
      placement="right"
      trigger="click"
      :width="300"
    >
      <template #reference>
        <div class="w omit3">
          <div v-for="(item, index) in showListData" :key="index" class="fxmj">
            <div class="ex tr g6">
              {{ item?.field_info?.attr_title || '' }}：
            </div>
            <div class="ex omit">{{ item?.attr_value || '' }}</div>
          </div>
        </div>
      </template>
      <el-table border :data="list" stripe>
        <el-table-column :label="t('字段')" prop="title" width="100">
          <template #default="{ row }">
            {{ row?.field_info?.attr_title || '' }}
          </template>
        </el-table-column>
        <el-table-column :label="t('值')" prop="attr_value" width="200">
          <template #default="{ row }">
            <BaseImages
              v-if="row?.field_info?.attr_type === 'avatar'"
              :src="row?.attr_value"
            />
            <div v-else>{{ row?.attr_value || '' }}</div>
          </template>
        </el-table-column>
      </el-table>
    </el-popover>
  </div>
  <span v-else>-</span>
</template>

<script>
// 基础产品供应商组件
import { defineComponent, computed } from 'vue'
import { t } from '@/utils/i18n'

export default defineComponent({
  name: 'BaseExtendFields',
  components: {},
  props: {
    list: { type: Array, default: () => [] },
    showNum: { type: Number, default: 3 }, // 显示数量
  },
  setup(props) {
    // 显示列表数据
    const showListData = computed(() => {
      if (props.list?.length) {
        return props.list.slice(0, props.showNum)
      }
      return []
    })

    return {
      showListData,
      t,
    }
  },
})
</script>

<style lang="scss" infod></style>
