const MODULE_CUSTOMER = 'customer' // 客户模块
const MODULE_PURCHASE_ORDER = 'purchase-order' // 采购单模块
const MODULE_BUSINESS = 'business-opportunity' // 商机模块
import { ElMessage, ElMessageBox } from 'element-plus'
import { uploadFileUrl, baseURL } from '@/config/index'
import { gp } from '@vab'
import {
  getCustomerCode,
  checkCustomerInfo,
  checkCustomerPhone,
} from '@/api/customer'
import { checkSuppilerInfo } from '@/api/supplier'
import { checkProductInfo } from '@/api/product'
import { getOrderTitle } from '@/api/order'
import { getRequest } from '@/api/api'
import { isKey } from '@/utils/validate'
import { isObject } from 'lodash-es'
import {
  numberToFixed,
  deleteKey,
  objectToUrlParams,
  convertVolume,
  handleVolume,
} from '@/utils/index'
const cosSdkConfig = baseURL + '/company/config' // 获取cos配置信息
console.log('cosSdkConfig :>> ', cosSdkConfig)
import store from '@/store'
import { useRouter } from 'vue-router'
import { t } from '@/utils/i18n'
import request from '@/utils/request'
import { getImageOrg } from '@/api/tool'
const mattingUrl = '/common/matting'
import { authentication } from '@/config'
import { commonFiledObj } from '@/utils/headerSearchConfig'
import { getParseAddress } from '@/api/expressDelivery'
import { handleClipboard } from '@/utils/clipboard'
import { SALEORDER_PATH } from '@/config/business.config'

// import { download } from '@/common/js/utils'
// const MODULE_PERFORMANCE_RULE = 'performance-rule' // 绩效规则模块
import {
  BUSINESS_OPPORTUNITY_STAGE,
  PERFORMANCE_TYPE,
  PRODUCT_UNIT,
  PRODUCT_SERVICE_CYCLE,
  ORDER_RENEW,
  PRODUCT_TYPE,
  PRODUCT_SOURCE,
  PAY_TYPE,
  CYCLE,
  PRICE_POLICY_TYPE,
  PRICE_POLICY_MODE,
  INVOICE_TYPE,
  BANNER_TYPE,
  // ARTICLE_TYPE,
  CONTAIN_TAX,
  SHIP_MODE,
  FEEDBACK_TYPE,
  EMAIL_LIST,
  INCREASE_DECREASE,
  FILED_ORDER,
  PRODUCT_SALE_CHANNEL_SUB_TYPE,
  PRODUCT_PROMOTION_CHANNEL_SUB_TYPE,
  THIRD_SHOP_TYPE,
  EXPRESS_PAY_TYPE,
  // EXPRESS_DATA,
  YES_NO,
  LANGUAGE_LIST,
  PRODUCT_CARTON_TYPE,
  EXTRA_FIELD_TYPE,
  EXTEND_ATTR_TYPE,
  SUPPLIER_ONLINE,
  SUPPLIER_TYPE,
  DELIVERY_PLAN_DISTRIBUTION_MODE,
  PRINTER_TYPE,
  EXPRESS_INSURANCE_PROVIDER,
  EXPRESS_SIGNATURE,
  SUPPLIER_BALANCE_TYPE,
  SALE_RETURN_HANDLE,
  SUPPLIER_CLAIM_STATUS,
  CUSTOMER_PUBLIC,
  PRODUCT_NATURE,
  MHL_HONGKONG_DISTRICT,
  CUSTOMER_TYPE,
  CURRENCY_UNIT,
  LENGTH_UNIT,
  WEIGHT_UNIT,
  PRINT_LABEL_TYPE,
  NORMAL_ONLINE_STATUS,
  richTextExcludeKeys,
} from '@/config/variable.config'
import { objToArr, arrToArr, objToArrRadio } from '@/utils/index'
import dayjs from 'dayjs'
import { getAllProductList } from '@/api/repository'
import {
  handleLanguageLayout,
  handleSupportCurrency,
  getFormListIndex,
} from '@/utils/business'
import { deepClone, fillUrl } from '@/common/js/utils'
// const localAreaTree = SS.get('areaTree')
function handleDetailList(needSupplier = false, needCarton = false) {
  return store.getters['sys/addProductDrawerSelect'].map((item) => {
    console.log('item :>> beforeReq', item)
    const repository_id = F.isArray(item?.repository_id)
      ? item?.repository_id[item?.repository_id.length - 1]
      : item?.repository_id || ''
    const result = {
      product_id: item.product_id || item.id || '',
      num: item.nums || item.num,
      price: item.priceD,
      repository_id: repository_id,
      admin_remark: item.admin_remark || '',
    }
    if (needSupplier) {
      result.supplier_id = item.supplier_id || ''
    }
    if (needCarton) {
      result.packing_info = item.packing_info
    }
    return result
  })
}

/**
 * 设置客户编码
 * @param {*} val
 * @param {*} model
 * @param {*} params
 * @returns
 */
function handleSetCustomerCode(val, model, params = {}) {
  console.log('model :>> ', model)
  // 只处理添加情况
  if (model.id) return
  getCustomerCode(params).then((res) => {
    console.log('res :>> ', res)
    model.code = res.code || ''
  })
}

/**
 * 设置订单标题
 * @param {*} val
 * @param {*} model
 * @param {*} params
 * @returns
 */
function handleSetOrderTitle(val, model, params = {}, type = '') {
  // console.log('model :>> ', model);
  model.channel_id = val.channel_id || ''
  // 只处理添加情况
  if (model.id) return
  getOrderTitle(params).then((res) => {
    const defaultVal = `${val.title}-${t('订单')}${dayjs().format('YYYYMMDD')}`
    console.log('res :>> ', res)
    model.title = res.title || defaultVal
  })
  // 家具 || 常规订单
  if (
    type === 'editFurniture' ||
    type === 'furniture' ||
    type === 'common' ||
    type === 'edit'
  ) {
    model.channel_id = val.channel_id
  }
}

// 处理form表单label
function handleFormlabelPosition() {
  const { labelPosition } = handleLanguageLayout()
  const result = {
    labelPosition: labelPosition,
  }
  console.log('handleFormlabelPosition :>> ', result)
  return result
}

const dynamicsFormAttrs = handleFormlabelPosition()

function openReconfirm(text) {
  return new Promise((resolve) => {
    ElMessageBox.confirm(t(text), 'Warning', {
      confirmButtonText: t('确认'),
      cancelButtonText: t('取消'),
      type: 'warning',
    })
      .then(() => {
        resolve(true)
      })
      .catch(() => {
        resolve(false)
      })
  })
}

/**
 * 公用审核弹窗
 * @param {*} module
 * @param {*} type
 * @returns
 */
export function commomAuditDialog(module, type = '', needTips = '') {
  console.log('type :>> ', type)

  const defaultConfig = {
    title: '审核',
    dialog: {
      width: type === 'billDetail' ? '600px' : '500px',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        update: `/${module}/audit`,
        refreshTable: true,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '80px',
      },
      beforeReq: async (data, model) => {
        data.audit_status = data.audit_statuss
        delete data.audit_statuss
        if (needTips && model.is_warning && data.audit_status == 10) {
          let result = await openReconfirm(needTips)
          return result ? data : true
        }
        return data
      },
      formList: [
        {
          type: 'radio',
          key: 'audit_statuss',
          value: 10,
          labelAttrs: {
            label: '审核状态',
            rules: [
              {
                required: true,
                message: '请选择类型',
                trigger: 'blur',
              },
            ],
          },
          options: [
            { title: t('通过'), attrs: { label: 10 }, events: {} },
            { title: t('不通过'), attrs: { label: 5 }, events: {} },
          ],
          layoutAttrs: {
            span: type === 'billDetail' ? 12 : 24,
          },
        },
        {
          type: 'input',
          key: 'audit_remark',
          value: '',
          labelAttrs: {
            label: '备注',
          },
          formAttrs: {
            type: 'textarea',
            clearable: true,
            disabled: false,
            placeholder: '',
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  // 绩效
  if (type === 'performance') {
    defaultConfig.form.formList.splice(1, 0, {
      type: 'inputNumber',
      key: 'real_amount',
      refValKey: 'amount',
      labelAttrs: {
        label: '提成金额',
        value: '',
        rules: [
          {
            required: true,
            message: '请输入提成金额',
            trigger: 'blur',
          },
        ],
      },
      formAttrs: {
        class: 'w220',
        placeholder: '请输入提成金额',
        clearable: true,
      },
    })
  } else if (type === 'billDetail') {
    // 回款明细
    defaultConfig.form.formList.splice(
      1,
      0,
      {
        type: 'selectServer',
        key: 'bill_channel_id',
        labelAttrs: {
          label: '渠道',
        },
        formAttrs: {
          listApi: `/bill-channel/index`,
          searchKey: 'title',
          labelKey: 'title',
          placeholder: '请选择渠道',
          addApi: 'bill-channel/add',
          class: 'w',
          autoAdd: true,
        },
        layoutAttrs: { span: 12 },
      },
      {
        type: 'select',
        key: 'pay_type',
        value: '',
        labelAttrs: {
          label: '回款方式',
        },
        formAttrs: {
          placeholder: '请选择回款方式',
          clearable: true,
          class: 'w300',
        },
        options: objToArr(PAY_TYPE),
        layoutAttrs: {
          span: 12,
        },
      },
      {
        type: 'datePicker',
        key: 'date',
        value: dayjs().format('YYYY-MM-DD'),
        labelAttrs: {
          label: '回款日期',
        },
        formAttrs: {
          class: 'w',
          placeholder: '请输入回款日期',
          clearable: true,
          valueFormat: 'YYYY-MM-DD',
        },
        layoutAttrs: {
          span: 12,
        },
      },
      {
        type: 'input',
        key: 'trade_no',
        labelAttrs: {
          label: '流水单号',
        },
        formAttrs: {
          class: 'w',
          placeholder: '请输入支付流水单号',
          clearable: true,
        },
      },
      {
        type: 'slot',
        key: 'proof',
        name: 'fileUpload',
        labelAttrs: {
          label: '支付凭证',
        },
        formAttrs: {
          uploadUrl: uploadFileUrl,
          copyImgUploade: true,
          uploadParam: {},
        },
      }
    )
  } else if (type === 'paymentDetail') {
    // 付款明细
    defaultConfig.form.formList.splice(
      1,
      0,
      {
        type: 'select',
        key: 'pay_type',
        value: '',
        labelAttrs: {
          label: '付款方式',
        },
        formAttrs: {
          placeholder: '请选择付款方式',
          clearable: true,
          class: 'w300',
        },
        options: objToArr(PAY_TYPE),
        layoutAttrs: {
          span: 12,
        },
      },
      {
        type: 'datePicker',
        key: 'date',
        value: dayjs().format('YYYY-MM-DD'),
        labelAttrs: {
          label: '付款日期',
        },
        formAttrs: {
          class: 'w',
          placeholder: '请输入付款日期',
          clearable: true,
          valueFormat: 'YYYY-MM-DD',
        },
        layoutAttrs: {
          span: 12,
        },
      },
      {
        type: 'input',
        key: 'trade_no',
        labelAttrs: {
          label: '流水单号',
        },
        formAttrs: {
          class: 'w',
          placeholder: '请输入支付流水单号',
          clearable: true,
        },
      },
      {
        type: 'slot',
        key: 'file',
        name: 'fileUpload',
        labelAttrs: {
          label: '支付凭证',
        },
        formAttrs: {
          multiple: true,
          uploadUrl: uploadFileUrl,
          copyImgUploade: true,
          uploadParam: {},
        },
      }
    )
  } else if (type === 'batchAudit') {
    // 批量审核
    defaultConfig.title = '批量审核'
    defaultConfig.customTitle = true
    defaultConfig.form.api.add = `/${module}/audits`
    defaultConfig.form.beforeReq = async (data, model, selectionRowIds) => {
      data.audit_status = data.audit_statuss
      delete data.audit_statuss
      if (needTips) {
        let result = await openReconfirm(needTips)
        return result ? data : true
      }
      const ids = selectionRowIds.split(',')
      return { ...data, ids }
    }
  }
  return defaultConfig
}

/**
 * 公用弹窗
 * @param {*} module
 * @param {*} type
 * @returns
 */
export function commomAddDialog({
  MODULE,
  type = '',
  url = '',
  title = '',
  customTitle = false,
  width = '500px',
  filedList = [],
  info = {},
  addCallback,
  updateCallback,
  beforeReqCallback,
  params = {},
}) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('inparamsfo :>> ', params)
  const defaultConfig = {
    title: title || '添加',
    customTitle: customTitle,
    refreshTable: true,
    dialog: {
      width: width || '500px',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        update: `/${MODULE}/edit`,
        addCallback: () => {
          addCallback?.()
        },
        updateCallback: () => {
          updateCallback?.()
        },
      },
      beforeReq: (data, model) => {
        if (beforeReqCallback) {
          return beforeReqCallback?.(data, model)
        }
        return data
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '80px',
      },
      formList: [],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  if (filedList.length) {
    const formList = F.copy(defaultConfig.form.formList)
    for (let i = 0; i < filedList.length; i++) {
      const filed = filedList[i]
      // 对象配置
      if (isObject(filed)) {
        const { key, rules, label, name, defaultVal, api } = filed
        const filedInfo = deepClone(commonFiledObj[key])
        if (rules) {
          filedInfo.labelAttrs.rules = rules
        }
        if (label) {
          filedInfo.labelAttrs.label = label
        }
        if (name) {
          filedInfo.key = name
        }
        if (defaultVal) {
          filedInfo.value = defaultVal
        }
        if (filedInfo) {
          formList.splice(i + 1, 0, filedInfo)
        }
        if (api) {
          filedInfo.formAttrs.listApi = api
        }
      } else if (commonFiledObj[filed]) {
        formList.splice(i + 1, 0, commonFiledObj[filed])
      }
    }
    defaultConfig.form.formList = formList
  }
  if (url) {
    defaultConfig.form.api.add = `/${MODULE}/${url}`
    defaultConfig.form.api.update = `/${MODULE}/${url}`
  }
  return defaultConfig
}

/**
 * 公用成员添加弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function withMemberAddDialog(
  MODULE = '',
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  let defaultConfig = {
    title: '成员管理',
    dialog: {
      width: '550px',
      top: '20vh',
      appendToBody: true,
      destroyOnClose: true,
    },
    form: {
      api: {
        detail: `/${MODULE}/detail`,
        add: `/${MODULE}/add`,
        update: `/${MODULE}/edit`,
      },
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '80px',
        ...dynamicsFormAttrs,
      },
      formList: [
        {
          type: 'selectServer',
          key: 'admin_ids',
          labelAttrs: {
            label: '成员',
          },
          formAttrs: {
            listApi: `/admin/index`,
            class: 'w',
            labelKey: 'realname',
            searchKey: 'realname',
            valueType: 'number',
            multiple: true,
            placeholder: '请选择成员',
          },
        },
        {
          type: 'radio',
          key: 'auth',
          value: 1,
          labelAttrs: {
            label: '权限',
          },
          options: [
            { title: t('只读'), attrs: { label: 1 }, events: {} },
            { title: t('读写'), attrs: { label: 2 }, events: {} },
          ],
        },
        {
          type: 'radio',
          key: 'is_owner',
          value: 0,
          labelAttrs: {
            label: '负责人',
          },
          options: [
            { title: t('是'), attrs: { label: 1 }, events: {} },
            { title: t('否'), attrs: { label: 0 }, events: {} },
          ],
        },
        {
          type: 'slot',
          key: 'ref_id',
          value: info?.id,
        },
        {
          type: 'slot',
          key: 'module',
          value: params?.module,
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '确定',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  // 订单
  // if (params?.type === 'orderlist') {
  //   defaultConfig.form.formList.splice(1, 0, {
  //     type: 'inputNumber',
  //     key: 'performance_rate',
  //     value: 0,
  //     labelAttrs: {
  //       label: '提成比例',
  //     },
  //     formAttrs: {
  //       class: 'w',
  //       clearable: true,
  //       min: 0,
  //       max: 100,
  //       step: 1,
  //     },
  //   })
  // }
  return defaultConfig
}

/**
 * 公用成员添加弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function teamMemberAddDialog(MODULE = '', type = '', info = {}) {
  console.log('type :>> ', type)
  let defaultConfig = {
    title: '成员管理',
    customTitle: true,
    dialog: {
      width: '550px',
      top: '20vh',
      appendToBody: true,
      destroyOnClose: true,
    },
    form: {
      api: {
        detail: `/${MODULE}/detail`,
        add: `/${MODULE}/addMember`,
        update: `/${MODULE}/edit`,
      },
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '80px',
        ...dynamicsFormAttrs,
      },
      beforeReq: (data, model, selectionRowIds) => {
        data.ref_ids = selectionRowIds.split(',')
        data.member_ids = data.member_ids.split(',') || []
        return data
      },
      formList: [
        {
          type: 'selectServer',
          key: 'member_ids',
          labelAttrs: {
            label: '成员',
            rules: [
              {
                required: true,
                message: '请选择成员',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            listApi: `/admin/index`,
            class: 'w',
            labelKey: 'realname',
            searchKey: 'realname',
            valueType: 'number',
            multiple: true,
            placeholder: '请选择成员',
          },
        },
        // {
        //   type: 'radio',
        //   key: 'auth',
        //   value: 1,
        //   labelAttrs: {
        //     label: '权限',
        //   },
        //   options: [
        //     { title: t('只读'), attrs: { label: 1 }, events: {} },
        //     { title: t('读写'), attrs: { label: 2 }, events: {} },
        //   ],
        // },
        {
          type: 'radio',
          key: 'is_owner',
          value: 0,
          labelAttrs: {
            label: '负责人',
          },
          options: [
            { title: t('是'), attrs: { label: 1 }, events: {} },
            { title: t('否'), attrs: { label: 0 }, events: {} },
          ],
        },
        {
          type: 'slot',
          key: 'ref_ids',
          value: info?.id,
        },
        {
          type: 'slot',
          key: 'module',
          value: MODULE,
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '确定',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  // 订单
  // if (params?.type === 'orderlist') {
  //   defaultConfig.form.formList.splice(1, 0, {
  //     type: 'inputNumber',
  //     key: 'performance_rate',
  //     value: 0,
  //     labelAttrs: {
  //       label: '提成比例',
  //     },
  //     formAttrs: {
  //       class: 'w',
  //       clearable: true,
  //       min: 0,
  //       max: 100,
  //       step: 1,
  //     },
  //   })
  // }
  return defaultConfig
}
/**
 * 商品添加弹窗
 * @param {*} MODULE // 模块
 * @param {*} type // 类型判断
 * @param {*} tableRef // 表格
 * @param {*} ExtraFormRef // 附加表单
 * @param {*} PosterDesignRef // 图片设计
 * @returns
 */

export function productAddDialog2(MODULE = '', type = '', refObj = {}) {
  console.log('type :>> ', type)
  const { tableRef, ExtraFormRef, PosterDesignRef, state, isMateriel } = refObj
  const { supportCurrency, defaultCurrency } = handleSupportCurrency()

  /**
   * 处理抠图
   * @param {*} action
   * @param {*} setModel
   * @param {*} model
   */
  const handlePictureActions = (action, setModel, model, itemConfig) => {
    const { item, file } = action
    if (item.code === 'setMain') {
      setModel('image', file.org_url)
    } else if (item.code === 'mattingImg' || item.code === 'mattingAll') {
      let mattingImage = F.copy(model.matting_image)
      const result = file.map((item) => item.org_url)
      if (mattingImage && F.isArray(mattingImage)) {
        mattingImage.unshift(...result)
      } else {
        mattingImage = mattingImage
          ? result.join(',') + `${mattingImage ? ',' : ''}${mattingImage}`
          : result.join(',')
      }
      const matting_images = F.isArray(mattingImage)
        ? mattingImage.join(',')
        : mattingImage
      setModel('matting_image', matting_images)
    } else if (item.code === 'design' && PosterDesignRef) {
      if (itemConfig.key === 'image') {
        const params = {
          urls: [model.image],
          product_id: model.id,
        }
        getImageOrg(params).then((res) => {
          PosterDesignRef.value.showPosterDesign({
            ...model,
            slider_image: res[0],
          })
          state.imageType = 'single'
        })
      } else if (itemConfig.key === 'slider_image') {
        let urls = F.isArray(model.slider_image)
          ? model.slider_image
          : model.slider_image.split(',')
        const params = {
          urls,
          product_id: model.id,
        }
        getImageOrg(params).then((res) => {
          PosterDesignRef.value.showPosterDesign({
            ...model,
            slider_image: res.join(','),
          })
          state.imageType = 'mutiple'
        })
      }
      state.openData = model
    }
  }

  let defaultConfig = {
    title: '产品',
    dialog: {
      width: '1200px',
      top: '8vh',
      appendToBody: true,
      destroyOnClose: true,
      closeOnClickModal: false,
      lockScroll: true,
      'lock-scroll': true,
      // fullscreen: true
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
        updateCallback: (res) => {
          ExtraFormRef?.value?.handleSubmit(res)
          console.log('ExtraFormRef :>> ', ExtraFormRef, res)
        },
        addCallback: (res) => {
          ExtraFormRef?.value?.handleSubmit(res)
          console.log('ExtraFormRef :>> ', ExtraFormRef, res)
        },
      },
      formListKey: true, // 参数是formList的key
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '103px',
        ...dynamicsFormAttrs,
      },
      beforeReq: (data) => {
        if (data?.vehicles?.length === 0) {
          data.vehicles = ''
        }
        if (data.oem_code) {
          data.oem_code = data.oem_code.replace(/[；;]/g, ';')
        }
        return data
      },
      // addSuccess: (searchModel, setSearch) => {
      //   setSearch('sort', 'id')
      //   setSearch('order', 'desc')
      // },
      beforeCreate: {
        url: `${MODULE}/code`,
        callback: (res, model) => {
          model.code = res.code
        },
      },
      formList: [
        {
          type: 'input',
          key: 'title',
          value: '',
          labelAttrs: {
            label: '产品名称',
            rules: [
              {
                required: true,
                message: '请输入产品名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入产品名称',
            clearable: true,
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'cascaderServer',
          key: 'category_ids',
          value: '',
          labelAttrs: {
            label: '产品分类',
          },
          formAttrs: {
            placeholder: '请选择产品分类',
            clearable: true,
            filterable: true,
            multiple: true,
            class: 'w',
            'show-all-levels': true,
            listApi: '/category/tree',
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: false,
              multiple: true,
            },
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 12,
            lg: 12,
            xl: 12,
          },
        },
        {
          type: 'tag',
          key: 'oem_code',
          value: '',
          labelAttrs: {
            label: 'OEM编码',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入OEM码多个;分割',
            clearable: true,
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 12,
            lg: 12,
            xl: 12,
          },
        },
        {
          type: 'input',
          key: 'code',
          value: '',
          labelAttrs: {
            label: '产品编码',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入产品编码',
            clearable: true,
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 6,
            lg: 6,
            xl: 6,
          },
        },
        {
          type: 'select',
          key: 'nature',
          value: isMateriel ? 1 : 0,
          labelAttrs: {
            label: '产品类别',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请选择产品类别',
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 6,
            lg: 6,
            xl: 6,
          },
          condition: () => {
            return isMateriel
          },
          options: objToArr(PRODUCT_NATURE).slice(1),
        },
        {
          type: 'cascaderServer',
          key: 'default_repository_id',
          value: '',
          labelAttrs: {
            label: '默认仓库',
          },
          formAttrs: {
            placeholder: '请选择仓库',
            clearable: true,
            filterable: true,
            multiple: false,
            showAllLevels: true,
            listApi: '/repository/tree',
            exParams: {
              sort: 'title',
              order: 'asc',
            },
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: true,
              multiple: false,
            },
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 6,
            lg: 6,
            xl: 6,
          },
        },
        {
          type: 'inputNumber',
          key: 'alarm_stock',
          value: 0,
          labelAttrs: {
            label: '预警库存',
          },
          formAttrs: {
            clearable: true,
            min: 0,
            max: 999999,
            step: 1,
            style: 'width:220px',
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 6,
            lg: 6,
            xl: 6,
          },
        },
        {
          type: 'selectServer',
          key: 'unit',
          cptKey: 'unit',
          value: '',
          labelAttrs: {
            label: '单位',
          },
          formAttrs: {
            class: 'w',
            listApi: 'config/get',
            labelKey: 'title',
            filterable: true,
            allowCreate: true,
            placeholder: '请选择单位',
            exParams: {
              key: 'PRODUCT_UNIT',
            },
            defaultSelect: true,
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 6,
            lg: 6,
            xl: 6,
          },
        },
        {
          type: 'select',
          key: 'type',
          value: 0,
          labelAttrs: {
            label: '产品类型',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请选择产品类型',
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 6,
            lg: 6,
            xl: 6,
          },
          options: objToArr(PRODUCT_TYPE),
        },
        {
          type: 'inputNumber',
          key: 'moq',
          value: 1,
          labelAttrs: {
            label: '起订量',
          },
          formAttrs: {
            clearable: true,
            min: 1,
            max: 999999,
            step: 1,
            class: 'w',
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 6,
            lg: 6,
            xl: 6,
          },
        },
        {
          type: 'radio',
          key: 'is_pivotal',
          value: 0,
          labelAttrs: {
            label: '是否KP',
            tips: '是否关键产品',
          },
          formAttrs: {
            class: 'w',
          },
          options: objToArrRadio(YES_NO),
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 6,
            lg: 6,
            xl: 6,
          },
        },
        {
          type: 'cascaderServer',
          key: 'target_area',
          value: '',
          labelAttrs: {
            label: '目标市场',
          },
          formAttrs: {
            placeholder: '请选择目标市场',
            clearable: true,
            filterable: true,
            searchKey: 'title',
            'show-all-levels': true,
            listApi: '/area/tree',
            class: 'w',
            props: {
              multiple: true,
              label: 'title',
              value: 'id',
              checkStrictly: true,
            },
            // options: localAreaTree
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'cascaderServer',
          key: 'source_area_id',
          value: '',
          labelAttrs: {
            label: '原产地',
          },
          formAttrs: {
            placeholder: '请选择原产地',
            clearable: true,
            filterable: true,
            searchKey: 'title',
            'show-all-levels': true,
            listApi: '/area/tree',
            class: 'w',
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: true,
            },
            // options: localAreaTree
          },
          layoutAttrs: {
            span: 6,
          },
        },
        {
          type: 'cascaderServer',
          key: 'vehicle_parts_id',
          value: '',
          labelAttrs: {
            label: '车身部件',
          },
          formAttrs: {
            placeholder: '请选择车身部件',
            clearable: true,
            filterable: true,
            multiple: false,
            showAllLevels: true,
            listApi: '/vehicle-part/tree',
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: true,
              multiple: false,
            },
            showAdd: true,
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 6,
            lg: 6,
            xl: 6,
          },
          action: {
            type: 'editBox',
            editBoxName: 'addVehiclePartBox',
          },
        },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '部件备注',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入部件备注',
            clearable: true,
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 12,
            lg: 12,
            xl: 12,
          },
        },
        {
          type: 'select',
          key: 'source',
          value: 2,
          labelAttrs: {
            label: '产品货源',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请选择产品货源',
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 6,
            lg: 6,
            xl: 6,
          },
          options: objToArr(PRODUCT_SOURCE),
        },
        {
          type: 'slot',
          key: 'urgency_star',
          name: 'urgencyStar',
          value: 0,
          labelAttrs: {
            label: '紧急程度',
          },
          formAttrs: {
            type: 'text',
            icon: 'fire-fill',
            voidIcon: 'fire-line',
            color: '#ff4d4f',
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 6,
            lg: 6,
            xl: 6,
          },
        },
        {
          type: 'slot',
          // type: 'cascaderServer',
          name: 'extraFormData',
          value: '',
          titleKey: '',
          labelAttrs: {
            label: '',
          },
          formAttrs: {},
          layoutAttrs: {
            span: 24,
          },
        },
        {
          type: 'slot',
          // type: 'cascaderServer',
          key: 'vehicles',
          name: 'vehicle_list_info',
          value: [],
          titleKey: '',
          labelAttrs: {
            label: '适用车型',
          },
          formAttrs: {
            clearable: false,
            filterable: true,
            listApi: `/vehicle/index`,
            addApi: `/vehicle/add`,
            searchKey: 'title',
            placeholder: '请选择适用车型',
            autoAdd: true,
          },
          layoutAttrs: {
            span: 24,
          },
        },
        // {
        //   type: 'divider',
        //   value: "价格",
        //   labelAttrs: {
        //     labelWidth: '0px',
        //   },
        //   formAttrs: {
        //     class: "cd",
        //     contentPosition: "center"
        //   }
        // },
        // {
        //   type: 'tips',
        //   value: "价格",
        //   labelAttrs: {
        //     label: "文字显示"
        //   },
        //   formAttrs: {
        //     class: "cd"
        //   }
        // },
        {
          type: 'select',
          key: 'currency',
          value: defaultCurrency,
          labelAttrs: {
            label: '产品货币',
          },
          formAttrs: {
            class: 'w120',
            placeholder: '请选择产品货币单位',
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 4,
            lg: 4,
            xl: 4,
          },
          options: objToArr(supportCurrency, false),
        },
        {
          type: 'inputNumber',
          key: 'ft_price',
          privacyId: 'retailPrice',
          value: 0,
          labelAttrs: {
            label: '零售价格',
          },
          formAttrs: {
            clearable: true,
            min: 0,
            max: 999999,
            step: 0.01,
            class: 'wi',
          },
          layoutAttrs: {
            span: 5,
          },
        },
        {
          type: 'inputNumber',
          key: 'price',
          privacyId: 'advisePrice',
          value: 0,
          labelAttrs: {
            label: '建议售价',
            tips: '不含税',
          },
          formAttrs: {
            clearable: true,
            min: 0,
            max: 999999,
            step: 0.01,
            class: 'wi',
          },
          layoutAttrs: {
            span: 5,
          },
        },
        {
          type: 'inputNumber',
          key: 'rt_price',
          privacyId: 'rt_price',
          value: 0,
          labelAttrs: {
            label: '最低售价',
            tips: '不含税',
          },
          formAttrs: {
            clearable: true,
            min: 0,
            max: 999999,
            step: 0.01,
            class: 'wi',
          },
          layoutAttrs: {
            span: 5,
          },
        },
        {
          type: 'inputNumber',
          key: 'cost',
          value: 0,
          privacyId: 'cost',
          labelAttrs: {
            label: '成本价',
            tips: '不含税',
          },
          formAttrs: {
            clearable: true,
            min: 0,
            max: 999999,
            step: 0.01,
            class: 'wi',
          },
          layoutAttrs: {
            span: 5,
          },
        },
        {
          type: 'slot',
          value: '',
          labelAttrs: {
            labelWidth: '0px',
          },
          formAttrs: {
            class: 'cd',
            contentPosition: 'center',
          },
          layoutAttrs: {
            span: 24,
          },
        },
        {
          type: 'slot',
          key: 'cartons',
          name: 'cartonList',
          value: [],
          labelAttrs: {
            label: '箱规',
          },
          formAttrs: {
            class: 'cd',
            tips: '箱规信息已移至详情页面添加编辑',
            tipsCls: 'cd',
            contentPosition: 'center',
          },
          layoutAttrs: {
            span: 24,
          },
        },
        {
          type: 'input',
          key: 'description',
          value: '',
          labelAttrs: {
            label: '描述',
          },
          formAttrs: {
            type: 'textarea',
            placeholder: '请输入描述',
            clearable: true,
          },
        },
        {
          type: 'richText',
          key: 'content',
          value: '',
          labelAttrs: {
            label: '内容',
          },
          formAttrs: {
            class: 'w',
            style: {
              height: '300px',
            },
            excludeKeys: richTextExcludeKeys,
          },
        },
        {
          type: 'uploadImg',
          key: 'image',
          labelAttrs: {
            label: '封面图',
          },
          formAttrs: {
            uploadParam: { module: 'product' },
            copyImgUploade: true,
            tips: '建议尺寸比例',
            tipsCls: 'cd fs12 lh1 mt5',
            limitSize: 1024 * 10,
            afterBtnText: '从素材库选择',
            mattingUrl: mattingUrl,
            requestFun: request,
            actionList: ['mattingImg', PosterDesignRef ? 'design' : null],
          },
          handleFun: handlePictureActions,
        },
        {
          type: 'uploadImg',
          key: 'slider_image',
          labelAttrs: {
            label: '图集',
          },
          formAttrs: {
            multiple: true,
            uploadParam: { module: 'product' },
            copyImgUploade: true,
            limitSize: 1024 * 10,
            afterBtnText: '从素材库选择',
            mattingUrl: mattingUrl,
            mattingAll: true,
            requestFun: request,
            actionList: [
              'mattingImg',
              'setMain',
              PosterDesignRef ? 'design' : null,
            ],
          },
          handleFun: handlePictureActions,
        },
        {
          type: 'uploadImg',
          key: 'matting_image',
          labelAttrs: {
            label: '抠图图集',
          },
          formAttrs: {
            multiple: true,
            uploadParam: { module: 'product' },
            copyImgUploade: true,
            limitSize: 1024 * 10,
            afterBtnText: '从素材库选择',
            mattingUrl: mattingUrl,
            requestFun: request,
            actionList: ['setMain'],
          },
          handleFun: handlePictureActions,
        },
        {
          type: 'uploadVideo',
          key: 'video',
          labelAttrs: {
            label: '视频',
          },
          formAttrs: {
            multiple: true,
            uploadParam: { module: 'product' },
            afterBtnText: '从素材库选择',
            isCosSdk: true,
            uploadUrl: cosSdkConfig,
            // uploadUrl: uploadFileUrl,
            module: 'product',
          },
        },
        {
          type: 'uploadFile',
          key: 'certificate',
          labelAttrs: {
            label: '证书',
          },
          formAttrs: {
            multiple: false,
            module: 'product',
          },
        },
        // {
        //   type: 'richText',
        //   key: 'content',
        //   value: '',
        //   labelAttrs: {
        //     label: '内容',
        //   },
        //   formAttrs: {
        //     type: 'textarea',
        //     placeholder: '请输入内容',
        //     clearable: true,
        //   },
        // },
        {
          type: 'switch',
          key: 'status',
          value: 1,
          labelAttrs: {
            label: '使用状态',
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  if (type === 'edit' || type === 'editStashWms') {
    // 切换语言
    defaultConfig.form.formList.unshift({
      type: 'tabs',
      key: '__lang',
      value: LS.get('language') || 'zh_CN',
      labelAttrs: {
        label: '',
        labelWidth: '0px',
      },
      formAttrs: {
        class: 'w',
        refKey: 'unit', // 关联数据key
        refSearchKey: '__lang', // 关联数据搜索key
      },
      layoutAttrs: {
        span: 24,
      },
      options: objToArr(LANGUAGE_LIST, false),
      onChange: (val, model) => {
        const params = {
          id: model.id,
          __lang: val,
        }
        if (params.id) {
          setTimeout(() => {
            // 重新请求数据
            tableRef.value.jwtTableRef.handleGetDetail(
              params,
              'editBox',
              params,
              (data) => {
                return {
                  ...data,
                  __lang: model.__lang,
                  content: data.content || '<p><br> </p>',
                }
              }
            )
          }, 200)
        }
      },
    })
  }
  // 普通商品
  if (type === 'common' || type === 'furniture') {
    defaultConfig.dialog.width = '1000px'
    defaultConfig.form.formList = [
      {
        type: 'input',
        key: 'title',
        value: '',
        labelAttrs: {
          label: '产品名称',
          rules: [
            {
              required: true,
              message: '请输入产品名称',
              trigger: 'blur',
            },
          ],
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入产品名称',
          clearable: true,
        },
        layoutAttrs: {
          xs: 24,
          sm: 24,
          md: 12,
          lg: 12,
          xl: 12,
        },
      },
      {
        type: 'cascaderServer',
        key: 'category_ids',
        value: 0,
        labelAttrs: {
          label: '产品分类',
        },
        formAttrs: {
          placeholder: '请选择产品分类',
          clearable: true,
          filterable: true,
          multiple: true,
          class: 'w',
          'show-all-levels': true,
          listApi: '/category/tree',
          props: {
            label: 'title',
            value: 'id',
            checkStrictly: false,
            multiple: true,
          },
        },
        layoutAttrs: {
          xs: 24,
          sm: 24,
          md: 12,
          lg: 12,
          xl: 12,
        },
      },
      {
        type: 'select',
        key: 'type',
        value: type === 'furniture' ? 0 : 1,
        labelAttrs: {
          label: '产品类型',
        },
        formAttrs: {
          class: 'w',
          placeholder: '请选择产品类型',
        },
        layoutAttrs: {
          xs: 24,
          sm: 24,
          md: 12,
          lg: 12,
          xl: 12,
        },
        options: objToArr(PRODUCT_TYPE),
      },
      {
        type: 'input',
        key: 'code',
        value: '',
        labelAttrs: {
          label: '产品编码',
          // rules: [
          //   {
          //     required: true,
          //     message: '请输入产品编码',
          //     trigger: 'blur',
          //   },
          // ],
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入产品编码',
          clearable: true,
        },
        layoutAttrs: {
          xs: 24,
          sm: 24,
          md: 12,
          lg: 12,
          xl: 12,
        },
      },
      {
        type: 'select',
        key: 'unit',
        value: arrToArr(PRODUCT_UNIT)[0].value,
        labelAttrs: {
          label: '单位',
        },
        formAttrs: {
          class: 'w',
          placeholder: '请选择单位',
          clearable: true,
          filterable: true,
          allowCreate: true,
        },
        layoutAttrs: {
          xs: 24,
          sm: 24,
          md: 12,
          lg: 12,
          xl: 12,
        },
        options: arrToArr(PRODUCT_UNIT),
      },
      {
        type: 'select',
        key: 'service_cycle',
        value: objToArr(PRODUCT_SERVICE_CYCLE, false)[0].value,
        labelAttrs: {
          label: '实施服务',
          // rules: [
          //   {
          //     required: true,
          //     message: '请选择实施服务',
          //     trigger: 'blur',
          //   },
          // ],
        },
        formAttrs: {
          class: 'w',
          placeholder: '请选择实施服务',
          clearable: true,
        },
        layoutAttrs: {
          xs: 24,
          sm: 24,
          md: 12,
          lg: 12,
          xl: 12,
        },
        options: objToArr(PRODUCT_SERVICE_CYCLE, false),
      },
      {
        type: 'inputNumber',
        key: 'price',
        value: 0,
        labelAttrs: {
          label: '价格',
          rules: [
            {
              required: true,
              message: '请输入',
              trigger: 'blur',
            },
          ],
        },
        formAttrs: {
          class: 'w',
          min: 0,
          max: 999999,
          step: 0.01,
          style: 'width:100%',
        },
        layoutAttrs: {
          xs: 24,
          sm: 24,
          md: 12,
          lg: 12,
          xl: 12,
        },
      },
      {
        type: 'cascaderServer',
        key: 'target_area',
        value: '',
        labelAttrs: {
          label: '目标市场',
        },
        formAttrs: {
          placeholder: '请选择目标市场',
          clearable: true,
          filterable: true,
          searchKey: 'title',
          'show-all-levels': true,
          listApi: '/area/tree',
          class: 'w',
          props: {
            multiple: true,
            label: 'title',
            value: 'id',
            checkStrictly: true,
          },
          // options: localAreaTree
        },
        layoutAttrs: {
          span: 12,
        },
      },
      // {
      //   type: 'inputNumber',
      //   key: 'price',
      //   value: 0,
      //   labelAttrs: {
      //     label: '~',
      //     labelWidth: '15px'
      //   },
      //   formAttrs: {
      //     clearable: true,
      //     min: 0,
      //     max: 999999,
      //     step: 0.01,
      //     style: 'width:150px',
      //   },
      //   layoutAttrs: {
      //     xs: 24,
      //     sm: 24,
      //     md: 6,
      //     lg: 6,
      //     xl: 6,
      //   },
      // },

      {
        type: 'input',
        key: 'description',
        value: '',
        labelAttrs: {
          label: '描述',
        },
        formAttrs: {
          type: 'textarea',
          placeholder: '请输入描述',
          clearable: true,
        },
      },
      {
        type: 'uploadImg',
        key: 'image',
        labelAttrs: {
          label: '封面图',
        },
        formAttrs: {
          uploadParam: { module: 'product' },
          copyImgUploade: true,
          afterBtnText: '从素材库选择',
        },
      },
      {
        type: 'uploadImg',
        key: 'slider_image',
        labelAttrs: {
          label: '图集',
        },
        formAttrs: {
          multiple: true,
          uploadParam: { module: 'product' },
          copyImgUploade: true,
          afterBtnText: '从素材库选择',
        },
      },
      {
        type: 'uploadVideo',
        key: 'video',
        labelAttrs: {
          label: '视频',
        },
        formAttrs: {
          multiple: true,
          uploadParam: { module: 'product' },
          afterBtnText: '从素材库选择',
          isCosSdk: true,
          uploadUrl: cosSdkConfig,
          // uploadUrl: uploadFileUrl,
          module: 'product',
        },
      },
      // {
      //   type: 'richText',
      //   key: 'content',
      //   value: '',
      //   labelAttrs: {
      //     label: '内容',
      //   },
      //   formAttrs: {
      //     type: 'textarea',
      //     placeholder: '请输入内容',
      //     clearable: true,
      //   },
      // },
      {
        type: 'switch',
        key: 'status',
        value: 1,
        labelAttrs: {
          label: '使用状态',
        },
      },
    ]
    if (type === 'furniture') {
      defaultConfig.form.formList.splice(2, 1, {
        type: 'tag',
        key: 'sku',
        value: '',
        labelAttrs: {
          label: '产品规格',
        },
        formAttrs: {
          type: 'text',
          clearable: true,
        },
        layoutAttrs: {
          xs: 24,
          sm: 24,
          md: 12,
          lg: 12,
          xl: 12,
        },
      })
      defaultConfig.form.formList.splice(8, 0, {
        type: 'selectServer',
        key: 'supplier_ids',
        labelAttrs: {
          label: '供应商名称',
        },
        formAttrs: {
          clearable: false,
          filterable: true,
          listApi: `/supplier/index`,
          searchKey: 'title',
          class: 'w',
          multiple: true,
          valueType: 'number',
          placeholder: '请选择供应商',
          modelValueKey: 'supplier_list',
        },
      })
    }
  }
  // wms 仓库端编辑产品
  if (type === 'editStashWms') {
    defaultConfig.form.formList.splice(1, 100, {
      type: 'inputNumber',
      key: 'price',
      // privacyId: 'advisePrice',
      value: 0,
      labelAttrs: {
        label: '建议售价',
        tips: '不含税',
      },
      formAttrs: {
        clearable: true,
        min: 0,
        max: 999999,
        step: 0.01,
        class: 'wi',
      },
      layoutAttrs: {
        // span: 5,
      },
    })
    defaultConfig.dialog.width = '700px'
  }
  return defaultConfig
}

export function productAddDialog(MODULE = '', type = '', refObj = {}) {
  console.log('type :>> ', type)
  const {
    tableRef,
    ExtraFormRef,
    PosterDesignRef,
    state,
    isMateriel,
    isShopMenu,
  } = refObj
  const { supportCurrency, defaultCurrency } = handleSupportCurrency()

  /**
   * 处理抠图
   * @param {*} action
   * @param {*} setModel
   * @param {*} model
   */
  const handlePictureActions = (action, setModel, model, itemConfig) => {
    const { item, file } = action
    if (item.code === 'setMain') {
      setModel('image', file.org_url)
    } else if (item.code === 'mattingImg' || item.code === 'mattingAll') {
      let mattingImage = F.copy(model.matting_image)
      const result = file.map((item) => item.org_url)
      if (mattingImage && F.isArray(mattingImage)) {
        mattingImage.unshift(...result)
      } else {
        mattingImage = mattingImage
          ? result.join(',') + `${mattingImage ? ',' : ''}${mattingImage}`
          : result.join(',')
      }
      const matting_images = F.isArray(mattingImage)
        ? mattingImage.join(',')
        : mattingImage
      setModel('matting_image', matting_images)
    } else if (item.code === 'design' && PosterDesignRef) {
      if (itemConfig.key === 'image') {
        const params = {
          urls: [model.image],
          product_id: model.id,
        }
        getImageOrg(params).then((res) => {
          PosterDesignRef.value.showPosterDesign({
            ...model,
            slider_image: res[0],
          })
          state.imageType = 'single'
        })
      } else if (itemConfig.key === 'slider_image') {
        let urls = F.isArray(model.slider_image)
          ? model.slider_image
          : model.slider_image.split(',')
        const params = {
          urls,
          product_id: model.id,
        }
        getImageOrg(params).then((res) => {
          PosterDesignRef.value.showPosterDesign({
            ...model,
            slider_image: res.join(','),
          })
          state.imageType = 'mutiple'
        })
      }
      state.openData = model
    }
  }

  let defaultConfig = {
    title: '产品',
    dialog: {
      width: '1200px',
      top: '8vh',
      appendToBody: true,
      destroyOnClose: true,
      closeOnClickModal: false,
      lockScroll: true,
      'lock-scroll': true,
      // fullscreen: true
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
        updateCallback: (res) => {
          ExtraFormRef?.value?.handleSubmit(res)
          console.log('ExtraFormRef :>> ', ExtraFormRef, res)
        },
        addCallback: (res) => {
          ExtraFormRef?.value?.handleSubmit(res)
          console.log('ExtraFormRef :>> ', ExtraFormRef, res)
        },
        detailCallBack: (result) => {
          if (result?.cartons) {
            result.cartons = result.cartons.map((v) => {
              v.volume = handleVolume(v, v.display_length_unit)
              return v
            })
          }
          return result
        },
      },
      showNavDivider: true,
      formListKey: true, // 参数是formList的key
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '90px',
        ...dynamicsFormAttrs,
      },
      beforeReq: (data) => {
        if (data?.vehicles?.length === 0) {
          data.vehicles = ''
        }
        if (data.oem_code) {
          data.oem_code = data.oem_code.replace(/[；;]/g, ';')
        }
        return data
      },
      // addSuccess: (searchModel, setSearch) => {
      //   setSearch('sort', 'id')
      //   setSearch('order', 'desc')
      // },
      beforeCreate: {
        url: `${MODULE}/code`,
        callback: (res, model) => {
          model.code = res.code
        },
      },
      formList: [
        {
          type: 'divider',
          value: '基础信息',
          labelAttrs: {
            labelWidth: '0px',
          },
          formAttrs: {
            class: 'cb b fs15',
            contentPosition: 'center',
          },
        },
        {
          type: 'input',
          key: 'title',
          value: '',
          labelAttrs: {
            label: '产品名称',
            rules: [
              {
                required: true,
                message: '请输入产品名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入产品名称',
            clearable: true,
          },
          onChange: (val, model) => {
            if (!model.id) {
              checkProductInfo({ title: val }, MODULE)
            }
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'tag',
          key: 'oem_code',
          value: '',
          labelAttrs: {
            label: 'OEM编码',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入OEM码多个;分割',
            clearable: true,
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 12,
            lg: 12,
            xl: 12,
          },
        },
        {
          type: 'selectServer',
          key: 'label_ids',
          labelAttrs: {
            label: '产品标签',
          },
          formAttrs: {
            class: 'mw150 w',
            clearable: true,
            filterable: true,
            multiple: true,
            valueType: 'number',
            listApi: `/label/index`,
            searchKey: 'title',
            placeholder: '请选择标签',
            exParams: {
              type: ['common', 'product'],
            },
            autoAdd: true,
            addApi: '/label/add',
            addParams: {
              type: 'product',
            },
          },
          layoutAttrs: {},
        },
        {
          type: 'input',
          key: 'code',
          value: '',
          labelAttrs: {
            label: '产品编码',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入产品编码',
            clearable: true,
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 6,
            lg: 6,
            xl: 6,
          },
        },

        {
          type: 'select',
          key: 'type',
          value: 0,
          labelAttrs: {
            label: '产品类型',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请选择产品类型',
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 6,
            lg: 6,
            xl: 6,
          },
          options: objToArr(PRODUCT_TYPE),
        },
        {
          type: 'radio',
          key: 'is_pivotal',
          value: 0,
          labelAttrs: {
            label: '是否KP',
            tips: '是否关键产品',
          },
          formAttrs: {
            class: 'w',
          },
          options: objToArrRadio(YES_NO),
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 6,
            lg: 6,
            xl: 6,
          },
        },
        {
          type: 'select',
          key: 'source',
          value: 2,
          labelAttrs: {
            label: '产品货源',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请选择产品货源',
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 6,
            lg: 6,
            xl: 6,
          },
          options: objToArr(PRODUCT_SOURCE),
        },
        {
          type: 'slot',
          key: 'urgency_star',
          name: 'urgencyStar',
          value: 0,
          labelAttrs: {
            label: '紧急程度',
          },
          formAttrs: {
            type: 'text',
            icon: 'fire-fill',
            voidIcon: 'fire-line',
            color: '#ff4d4f',
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 6,
            lg: 6,
            xl: 6,
          },
        },

        {
          type: 'divider',
          value: '销售信息',
          labelAttrs: {
            labelWidth: '0px',
          },
          formAttrs: {
            class: 'cb b fs15',
            contentPosition: 'center',
          },
        },
        {
          type: 'select',
          key: 'currency',
          value: defaultCurrency,
          labelAttrs: {
            label: '产品货币',
          },
          formAttrs: {
            class: 'w120',
            placeholder: '请选择产品货币单位',
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 4,
            lg: 4,
            xl: 4,
          },
          options: objToArr(supportCurrency, false),
        },
        {
          type: 'inputNumber',
          key: 'ft_price',
          privacyId: 'retailPrice',
          value: 0,
          labelAttrs: {
            label: '零售价格',
          },
          formAttrs: {
            clearable: true,
            min: 0,
            max: 999999,
            step: 0.01,
            class: 'wi',
          },
          layoutAttrs: {
            span: 5,
          },
        },
        {
          type: 'inputNumber',
          key: 'price',
          privacyId: 'advisePrice',
          value: 0,
          labelAttrs: {
            label: '建议售价',
            tips: '不含税',
          },
          formAttrs: {
            clearable: true,
            min: 0,
            max: 999999,
            step: 0.01,
            class: 'wi',
          },
          layoutAttrs: {
            span: 5,
          },
        },
        {
          type: 'inputNumber',
          key: 'rt_price',
          privacyId: 'rt_price',
          value: 0,
          labelAttrs: {
            label: '最低售价',
            tips: '不含税',
          },
          formAttrs: {
            clearable: true,
            min: 0,
            max: 999999,
            step: 0.01,
            class: 'wi',
          },
          layoutAttrs: {
            span: 5,
          },
        },
        {
          type: 'inputNumber',
          key: 'cost',
          value: 0,
          privacyId: 'cost',
          labelAttrs: {
            label: '成本价',
            tips: '不含税',
          },
          formAttrs: {
            clearable: true,
            min: 0,
            max: 999999,
            step: 0.01,
            class: 'wi',
          },
          layoutAttrs: {
            span: 5,
          },
        },
        {
          type: 'inputNumber',
          key: 'moq',
          value: 1,
          labelAttrs: {
            label: '起订量',
          },
          formAttrs: {
            clearable: true,
            min: 1,
            max: 999999,
            step: 1,
            class: 'w',
          },
          layoutAttrs: {
            span: 4,
          },
        },
        {
          type: 'baseRate',
          key: 'retail_suitable',
          value: 0,
          labelAttrs: {
            label: '零售适配',
          },
          formAttrs: {
            type: 'text',
            max: 3,
          },
          layoutAttrs: {
            span: 4,
          },
        },
        {
          type: 'cascaderServer',
          key: 'source_area_id',
          value: '',
          labelAttrs: {
            label: '原产地',
          },
          formAttrs: {
            placeholder: '请选择原产地',
            clearable: true,
            filterable: true,
            searchKey: 'title',
            'show-all-levels': true,
            listApi: '/area/tree',
            class: 'w',
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: true,
            },
            // options: localAreaTree
          },
          layoutAttrs: {
            span: 6,
          },
        },
        {
          type: 'cascaderServer',
          key: 'target_area',
          value: '',
          labelAttrs: {
            label: '目标市场',
          },
          formAttrs: {
            placeholder: '请选择目标市场',
            clearable: true,
            filterable: true,
            searchKey: 'title',
            'show-all-levels': true,
            listApi: '/area/tree',
            class: 'w',
            props: {
              multiple: true,
              label: 'title',
              value: 'id',
              checkStrictly: true,
            },
            // options: localAreaTree
          },
          layoutAttrs: {
            span: 10,
          },
        },
        {
          type: 'uploadFile',
          key: 'certificate',
          labelAttrs: {
            label: '证书',
          },
          formAttrs: {
            multiple: false,
            module: 'product',
          },
        },
        {
          type: 'divider',
          value: '仓储信息',
          labelAttrs: {
            labelWidth: '0px',
          },
          formAttrs: {
            class: 'cb b fs15',
            contentPosition: 'center',
          },
        },
        {
          type: 'select',
          key: 'nature',
          value: isMateriel ? 1 : 0,
          labelAttrs: {
            label: '产品类别',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请选择产品类别',
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 6,
            lg: 6,
            xl: 6,
          },
          condition: () => {
            return isMateriel
          },
          options: objToArr(PRODUCT_NATURE).slice(1),
        },
        {
          type: 'cascaderServer',
          key: 'default_repository_id',
          value: '',
          labelAttrs: {
            label: '默认仓库',
          },
          formAttrs: {
            placeholder: '请选择仓库',
            clearable: true,
            filterable: true,
            multiple: false,
            showAllLevels: true,
            listApi: '/repository/tree',
            exParams: {
              sort: 'title',
              order: 'asc',
            },
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: true,
              multiple: false,
            },
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 6,
            lg: 6,
            xl: 6,
          },
        },
        {
          type: 'inputNumber',
          key: 'alarm_stock',
          value: 0,
          labelAttrs: {
            label: '预警库存',
          },
          formAttrs: {
            clearable: true,
            min: 0,
            max: 999999,
            step: 1,
            style: 'width:220px',
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 6,
            lg: 6,
            xl: 6,
          },
        },
        {
          type: 'selectServer',
          key: 'unit',
          cptKey: 'unit',
          value: '',
          labelAttrs: {
            label: '单位',
          },
          formAttrs: {
            class: 'w',
            listApi: 'config/get',
            labelKey: 'title',
            filterable: true,
            allowCreate: true,
            placeholder: '请选择单位',
            exParams: {
              key: 'PRODUCT_UNIT',
            },
            defaultSelect: true,
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 6,
            lg: 6,
            xl: 6,
          },
        },
        {
          type: 'divider',
          value: '分类信息',
          labelAttrs: {
            labelWidth: '0px',
          },
          formAttrs: {
            class: 'cb b fs15',
            contentPosition: 'center',
          },
        },
        {
          type: 'cascaderServer',
          key: 'category_ids',
          value: '',
          labelAttrs: {
            label: '产品分类',
          },
          formAttrs: {
            placeholder: '请选择产品分类',
            clearable: true,
            filterable: true,
            multiple: true,
            class: 'w',
            'show-all-levels': true,
            listApi: '/category/tree',
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: false,
              multiple: true,
            },
          },
          layoutAttrs: {
            span: 24,
          },
        },
        {
          type: 'cascaderServer',
          key: 'vehicle_parts_id',
          value: '',
          labelAttrs: {
            label: '车身部件',
          },
          formAttrs: {
            placeholder: '请选择车身部件',
            clearable: true,
            filterable: true,
            multiple: false,
            showAllLevels: true,
            listApi: '/vehicle-part/tree',
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: true,
              multiple: false,
            },
            showAdd: true,
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 6,
            lg: 6,
            xl: 6,
          },
          action: {
            type: 'editBox',
            editBoxName: 'addVehiclePartBox',
          },
        },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '部件备注',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入部件备注',
            clearable: true,
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 18,
            lg: 18,
            xl: 18,
          },
        },
        {
          type: 'slot',
          // type: 'cascaderServer',
          key: 'vehicles',
          name: 'vehicle_list_info',
          value: [],
          titleKey: '',
          labelAttrs: {
            label: '适用车型',
          },
          formAttrs: {
            clearable: false,
            filterable: true,
            listApi: `/vehicle/index`,
            addApi: `/vehicle/add`,
            searchKey: 'title',
            placeholder: '请选择适用车型',
            autoAdd: true,
          },
          layoutAttrs: {
            span: 24,
          },
        },
        {
          type: 'divider',
          value: '图片与视频',
          labelAttrs: {
            labelWidth: '0px',
          },
          formAttrs: {
            class: 'cb b fs15',
            contentPosition: 'center',
          },
        },
        {
          type: 'uploadImg',
          key: 'image',
          labelAttrs: {
            label: '封面图',
          },
          formAttrs: {
            uploadParam: { module: 'product' },
            copyImgUploade: true,
            tips: '建议尺寸比例',
            tipsCls: 'cd fs12 lh1 mt5',
            limitSize: 1024 * 10,
            afterBtnText: '从素材库选择',
            mattingUrl: mattingUrl,
            requestFun: request,
            actionList: ['mattingImg', PosterDesignRef ? 'design' : null],
          },
          handleFun: handlePictureActions,
        },
        {
          type: 'uploadImg',
          key: 'slider_image',
          labelAttrs: {
            label: '图集',
          },
          formAttrs: {
            multiple: true,
            uploadParam: { module: 'product' },
            copyImgUploade: true,
            limitSize: 1024 * 10,
            afterBtnText: '从素材库选择',
            mattingUrl: mattingUrl,
            mattingAll: true,
            requestFun: request,
            actionList: [
              'mattingImg',
              'setMain',
              PosterDesignRef ? 'design' : null,
            ],
          },
          handleFun: handlePictureActions,
        },
        {
          type: 'uploadImg',
          key: 'matting_image',
          labelAttrs: {
            label: '抠图图集',
          },
          formAttrs: {
            multiple: true,
            uploadParam: { module: 'product' },
            copyImgUploade: true,
            limitSize: 1024 * 10,
            afterBtnText: '从素材库选择',
            mattingUrl: mattingUrl,
            requestFun: request,
            actionList: ['setMain'],
          },
          handleFun: handlePictureActions,
        },
        {
          type: 'uploadVideo',
          key: 'video',
          labelAttrs: {
            label: '视频',
          },
          formAttrs: {
            multiple: true,
            uploadParam: { module: 'product' },
            afterBtnText: '从素材库选择',
            isCosSdk: true,
            uploadUrl: cosSdkConfig,
            // uploadUrl: uploadFileUrl,
            module: 'product',
          },
        },
        {
          type: 'divider',
          value: '包装与箱规',
          labelAttrs: {
            labelWidth: '0px',
          },
          formAttrs: {
            class: 'cb b fs15',
            contentPosition: 'center',
          },
        },
        {
          type: 'slot',
          key: 'cartons',
          name: 'cartonList',
          value: [],
          labelAttrs: {
            label: '箱规',
          },
          formAttrs: {
            class: 'cd',
            tips: '箱规信息已移至详情页面添加编辑',
            tipsCls: 'cd',
            contentPosition: 'center',
          },
          layoutAttrs: {
            span: 24,
          },
        },

        {
          type: 'divider',
          value: '描述与详情',
          labelAttrs: {
            labelWidth: '0px',
          },
          formAttrs: {
            class: 'cb b fs15',
            contentPosition: 'center',
          },
        },
        {
          type: 'input',
          key: 'description',
          value: '',
          labelAttrs: {
            label: '描述',
          },
          formAttrs: {
            type: 'textarea',
            placeholder: '请输入描述',
            clearable: true,
          },
        },
        {
          type: 'richText',
          key: 'content',
          value: '',
          labelAttrs: {
            label: '内容',
          },
          formAttrs: {
            class: 'w',
            style: {
              height: '300px',
            },
            excludeKeys: richTextExcludeKeys,
          },
        },
        {
          type: 'divider',
          value: '拓展信息',
          labelAttrs: {
            labelWidth: '0px',
          },
          formAttrs: {
            class: 'cb b fs15',
            contentPosition: 'center',
          },
        },
        {
          type: 'slot',
          // type: 'cascaderServer',
          name: 'extraFormData',
          value: '',
          titleKey: '',
          labelAttrs: {
            label: '',
          },
          formAttrs: {},
          layoutAttrs: {
            span: 24,
          },
        },

        {
          type: 'switch',
          key: 'status',
          value: 1,
          labelAttrs: {
            label: '使用状态',
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  if (type === 'edit' || type === 'editStashWms') {
    let defaultLang = LS.get('language') || isShopMenu ? 'en_US' : 'zh_CN'
    if (isShopMenu) {
      // defaultConfig.form.api.detailParamsKey = '__lang'
      defaultConfig.form.api.detailExParams = {
        __lang: defaultLang,
        __from: 'shop',
      }
    }
    // 切换语言
    defaultConfig.form.formList.unshift({
      type: 'tabs',
      key: '__lang',
      value: defaultLang,
      labelAttrs: {
        label: '',
        labelWidth: '0px',
      },
      hideEmit: true,
      formAttrs: {
        class: 'w',
        refKey: 'unit', // 关联数据key
        refSearchKey: '__lang', // 关联数据搜索key
        beforeLeave: (activeName, oldActiveName) => {
          console.log('activeName :>> ', activeName)
          console.log('oldActiveName :>> ', oldActiveName)
          if (state.formItemChange) {
            return new Promise((resolve, reject) => {
              ElMessageBox.confirm('数据未保存是否保存数据？', '温馨提示', {
                confirmButtonText: '保存',
                cancelButtonText: '不保存',
                type: 'warning',
              })
                .then(() => {
                  state.oldLang = oldActiveName
                  resolve(true)
                })
                .catch(() => {
                  resolve(true)
                  state.formItemChange = false
                })
            })
          }
          return true
        },
      },
      layoutAttrs: {
        span: 24,
      },
      options: objToArr(LANGUAGE_LIST, false),
      onChange: (val, model) => {
        const params = {
          id: model.id,
          __lang: val,
        }
        if (state.formItemChange && state.oldLang) {
          tableRef.value.jwtTableRef.editBoxRef.handleBox('continue', {
            id: model.id,
            __lang: state.oldLang,
          })
          state.formItemChange = false
          state.oldLang = ''
        }
        if (params.id) {
          setTimeout(() => {
            // 重新请求数据
            tableRef.value.jwtTableRef.handleGetDetail(
              params,
              'editBox',
              params,
              (data) => {
                return {
                  ...data,
                  __lang: model.__lang,
                  content: data.content || '<p><br> </p>',
                }
              }
            )
            // ExtraFormRef.value?.handleGetDetailInfo()
          }, 200)
        }
      },
    })
    // 供应商不显示零售价格
    if (store.getters['user/isSupplier']) {
      // 零售价
      const retailPriceIndex = getFormListIndex(
        'ft_price',
        defaultConfig.form.formList
      )
      defaultConfig.form.formList.splice(retailPriceIndex, 1)
      // 描述信息不可见
      const descIndex = getFormListIndex(
        'description',
        defaultConfig.form.formList
      )
      defaultConfig.form.formList.splice(descIndex - 1, 3)
    }
  }
  // 普通商品
  if (type === 'common' || type === 'furniture') {
    defaultConfig.dialog.width = '1000px'
    defaultConfig.form.formList = [
      {
        type: 'input',
        key: 'title',
        value: '',
        labelAttrs: {
          label: '产品名称',
          rules: [
            {
              required: true,
              message: '请输入产品名称',
              trigger: 'blur',
            },
          ],
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入产品名称',
          clearable: true,
        },
        layoutAttrs: {
          xs: 24,
          sm: 24,
          md: 12,
          lg: 12,
          xl: 12,
        },
      },
      {
        type: 'cascaderServer',
        key: 'category_ids',
        value: 0,
        labelAttrs: {
          label: '产品分类',
        },
        formAttrs: {
          placeholder: '请选择产品分类',
          clearable: true,
          filterable: true,
          multiple: true,
          class: 'w',
          'show-all-levels': true,
          listApi: '/category/tree',
          props: {
            label: 'title',
            value: 'id',
            checkStrictly: false,
            multiple: true,
          },
        },
        layoutAttrs: {
          xs: 24,
          sm: 24,
          md: 12,
          lg: 12,
          xl: 12,
        },
      },
      {
        type: 'select',
        key: 'type',
        value: type === 'furniture' ? 0 : 1,
        labelAttrs: {
          label: '产品类型',
        },
        formAttrs: {
          class: 'w',
          placeholder: '请选择产品类型',
        },
        layoutAttrs: {
          xs: 24,
          sm: 24,
          md: 12,
          lg: 12,
          xl: 12,
        },
        options: objToArr(PRODUCT_TYPE),
      },
      {
        type: 'input',
        key: 'code',
        value: '',
        labelAttrs: {
          label: '产品编码',
          // rules: [
          //   {
          //     required: true,
          //     message: '请输入产品编码',
          //     trigger: 'blur',
          //   },
          // ],
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入产品编码',
          clearable: true,
        },
        layoutAttrs: {
          xs: 24,
          sm: 24,
          md: 12,
          lg: 12,
          xl: 12,
        },
      },
      {
        type: 'select',
        key: 'unit',
        value: arrToArr(PRODUCT_UNIT)[0].value,
        labelAttrs: {
          label: '单位',
        },
        formAttrs: {
          class: 'w',
          placeholder: '请选择单位',
          clearable: true,
          filterable: true,
          allowCreate: true,
        },
        layoutAttrs: {
          xs: 24,
          sm: 24,
          md: 12,
          lg: 12,
          xl: 12,
        },
        options: arrToArr(PRODUCT_UNIT),
      },
      {
        type: 'select',
        key: 'service_cycle',
        value: objToArr(PRODUCT_SERVICE_CYCLE, false)[0].value,
        labelAttrs: {
          label: '实施服务',
          // rules: [
          //   {
          //     required: true,
          //     message: '请选择实施服务',
          //     trigger: 'blur',
          //   },
          // ],
        },
        formAttrs: {
          class: 'w',
          placeholder: '请选择实施服务',
          clearable: true,
        },
        layoutAttrs: {
          xs: 24,
          sm: 24,
          md: 12,
          lg: 12,
          xl: 12,
        },
        options: objToArr(PRODUCT_SERVICE_CYCLE, false),
      },
      {
        type: 'inputNumber',
        key: 'price',
        value: 0,
        labelAttrs: {
          label: '价格',
          rules: [
            {
              required: true,
              message: '请输入',
              trigger: 'blur',
            },
          ],
        },
        formAttrs: {
          class: 'w',
          min: 0,
          max: 999999,
          step: 0.01,
          style: 'width:100%',
        },
        layoutAttrs: {
          xs: 24,
          sm: 24,
          md: 12,
          lg: 12,
          xl: 12,
        },
      },
      {
        type: 'cascaderServer',
        key: 'target_area',
        value: '',
        labelAttrs: {
          label: '目标市场',
        },
        formAttrs: {
          placeholder: '请选择目标市场',
          clearable: true,
          filterable: true,
          searchKey: 'title',
          'show-all-levels': true,
          listApi: '/area/tree',
          class: 'w',
          props: {
            multiple: true,
            label: 'title',
            value: 'id',
            checkStrictly: true,
          },
          // options: localAreaTree
        },
        layoutAttrs: {
          span: 12,
        },
      },
      // {
      //   type: 'inputNumber',
      //   key: 'price',
      //   value: 0,
      //   labelAttrs: {
      //     label: '~',
      //     labelWidth: '15px'
      //   },
      //   formAttrs: {
      //     clearable: true,
      //     min: 0,
      //     max: 999999,
      //     step: 0.01,
      //     style: 'width:150px',
      //   },
      //   layoutAttrs: {
      //     xs: 24,
      //     sm: 24,
      //     md: 6,
      //     lg: 6,
      //     xl: 6,
      //   },
      // },

      {
        type: 'input',
        key: 'description',
        value: '',
        labelAttrs: {
          label: '描述',
        },
        formAttrs: {
          type: 'textarea',
          placeholder: '请输入描述',
          clearable: true,
        },
      },
      {
        type: 'uploadImg',
        key: 'image',
        labelAttrs: {
          label: '封面图',
        },
        formAttrs: {
          uploadParam: { module: 'product' },
          copyImgUploade: true,
          afterBtnText: '从素材库选择',
        },
      },
      {
        type: 'uploadImg',
        key: 'slider_image',
        labelAttrs: {
          label: '图集',
        },
        formAttrs: {
          multiple: true,
          uploadParam: { module: 'product' },
          copyImgUploade: true,
          afterBtnText: '从素材库选择',
        },
      },
      {
        type: 'uploadVideo',
        key: 'video',
        labelAttrs: {
          label: '视频',
        },
        formAttrs: {
          multiple: true,
          uploadParam: { module: 'product' },
          afterBtnText: '从素材库选择',
          isCosSdk: true,
          uploadUrl: cosSdkConfig,
          // uploadUrl: uploadFileUrl,
          module: 'product',
        },
      },
      // {
      //   type: 'richText',
      //   key: 'content',
      //   value: '',
      //   labelAttrs: {
      //     label: '内容',
      //   },
      //   formAttrs: {
      //     type: 'textarea',
      //     placeholder: '请输入内容',
      //     clearable: true,
      //   },
      // },
      {
        type: 'switch',
        key: 'status',
        value: 1,
        labelAttrs: {
          label: '使用状态',
        },
      },
    ]
    if (type === 'furniture') {
      defaultConfig.form.formList.splice(2, 1, {
        type: 'tag',
        key: 'sku',
        value: '',
        labelAttrs: {
          label: '产品规格',
        },
        formAttrs: {
          type: 'text',
          clearable: true,
        },
        layoutAttrs: {
          xs: 24,
          sm: 24,
          md: 12,
          lg: 12,
          xl: 12,
        },
      })
      defaultConfig.form.formList.splice(8, 0, {
        type: 'selectServer',
        key: 'supplier_ids',
        labelAttrs: {
          label: '供应商名称',
        },
        formAttrs: {
          clearable: false,
          filterable: true,
          listApi: `/supplier/index`,
          searchKey: 'title',
          class: 'w',
          multiple: true,
          valueType: 'number',
          placeholder: '请选择供应商',
          modelValueKey: 'supplier_list',
        },
      })
    }
  }
  // wms 仓库端编辑产品
  if (type === 'editStashWms') {
    const editWmsInfo = [
      {
        type: 'divider',
        value: '基础信息',
        labelAttrs: {
          labelWidth: '0px',
        },
        formAttrs: {
          class: 'cb b fs15',
          contentPosition: 'center',
        },
      },
      {
        type: 'input',
        key: 'title',
        value: '',
        labelAttrs: {
          label: '产品名称',
          rules: [
            {
              required: true,
              message: '请输入产品名称',
              trigger: 'blur',
            },
          ],
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入产品名称',
          clearable: true,
        },
        layoutAttrs: {
          xs: 24,
          sm: 24,
          md: 6,
          lg: 6,
          xl: 6,
        },
      },
      {
        type: 'input',
        key: 'code',
        value: '',
        labelAttrs: {
          label: '产品编码',
        },
        formAttrs: {
          type: 'text',
          disabled: true,
          placeholder: '请输入产品编码',
          clearable: true,
        },
        layoutAttrs: {
          xs: 24,
          sm: 24,
          md: 6,
          lg: 6,
          xl: 6,
        },
      },
      {
        type: 'tag',
        key: 'oem_code',
        value: '',
        labelAttrs: {
          label: 'OEM编码',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入OEM码多个;分割',
          clearable: true,
        },
        layoutAttrs: {
          xs: 24,
          sm: 24,
          md: 12,
          lg: 12,
          xl: 12,
        },
      },

      // {
      //   type: 'select',
      //   key: 'type',
      //   value: 0,
      //   labelAttrs: {
      //     label: '产品类型',
      //   },
      //   formAttrs: {
      //     class: 'w',
      //     placeholder: '请选择产品类型',
      //   },
      //   layoutAttrs: {
      //     xs: 24,
      //     sm: 24,
      //     md: 6,
      //     lg: 6,
      //     xl: 6,
      //   },
      //   options: objToArr(PRODUCT_TYPE),
      // },
      // {
      //   type: 'radio',
      //   key: 'is_pivotal',
      //   value: 0,
      //   labelAttrs: {
      //     label: '是否KP',
      //     tips: "是否关键产品",
      //   },
      //   formAttrs: {
      //     class: 'w',
      //   },
      //   options: objToArrRadio(YES_NO),
      //   layoutAttrs: {
      //     xs: 24,
      //     sm: 24,
      //     md: 6,
      //     lg: 6,
      //     xl: 6,
      //   },
      // },
      // {
      //   type: 'select',
      //   key: 'source',
      //   value: 2,
      //   labelAttrs: {
      //     label: '产品货源',
      //   },
      //   formAttrs: {
      //     class: 'w',
      //     placeholder: '请选择产品货源',
      //   },
      //   layoutAttrs: {
      //     xs: 24,
      //     sm: 24,
      //     md: 6,
      //     lg: 6,
      //     xl: 6,
      //   },
      //   options: objToArr(PRODUCT_SOURCE),
      // },
      // {
      //   type: 'slot',
      //   key: 'urgency_star',
      //   name: 'urgencyStar',
      //   value: 0,
      //   labelAttrs: {
      //     label: '紧急程度',
      //   },
      //   formAttrs: {
      //     type: 'text',
      //     icon: 'fire-fill',
      //     voidIcon: 'fire-line',
      //     color: '#ff4d4f',
      //   },
      //   layoutAttrs: {
      //     xs: 24,
      //     sm: 24,
      //     md: 6,
      //     lg: 6,
      //     xl: 6,
      //   },
      // },
      {
        type: 'divider',
        value: '销售信息',
        labelAttrs: {
          labelWidth: '0px',
        },
        formAttrs: {
          class: 'cb b fs15',
          contentPosition: 'center',
        },
      },
      {
        type: 'select',
        key: 'currency',
        value: defaultCurrency,
        labelAttrs: {
          label: '产品货币',
        },
        formAttrs: {
          class: 'w120',
          placeholder: '请选择产品货币单位',
        },
        layoutAttrs: {
          xs: 24,
          sm: 24,
          md: 4,
          lg: 4,
          xl: 4,
        },
        options: objToArr(supportCurrency, false),
      },
      {
        type: 'inputNumber',
        key: 'ft_price',
        privacyId: 'retailPrice',
        value: 0,
        labelAttrs: {
          label: '零售价格',
        },
        formAttrs: {
          clearable: true,
          min: 0,
          max: 999999,
          step: 0.01,
          class: 'wi',
        },
        layoutAttrs: {
          span: 5,
        },
      },
      // {
      //   type: 'inputNumber',
      //   key: 'price',
      //   privacyId: 'advisePrice',
      //   value: 0,
      //   labelAttrs: {
      //     label: '建议售价',
      //     tips: '不含税',
      //   },
      //   formAttrs: {
      //     clearable: true,
      //     min: 0,
      //     max: 999999,
      //     step: 0.01,
      //     class: 'wi',
      //   },
      //   layoutAttrs: {
      //     span: 5,
      //   },
      // },
      // {
      //   type: 'inputNumber',
      //   key: 'rt_price',
      //   privacyId: 'rt_price',
      //   value: 0,
      //   labelAttrs: {
      //     label: '最低售价',
      //     tips: '不含税',
      //   },
      //   formAttrs: {
      //     clearable: true,
      //     min: 0,
      //     max: 999999,
      //     step: 0.01,
      //     class: 'wi',
      //   },
      //   layoutAttrs: {
      //     span: 5,
      //   },
      // },
      // {
      //   type: 'inputNumber',
      //   key: 'cost',
      //   value: 0,
      //   privacyId: 'cost',
      //   labelAttrs: {
      //     label: '成本价',
      //     tips: '不含税',
      //   },
      //   formAttrs: {
      //     clearable: true,
      //     min: 0,
      //     max: 999999,
      //     step: 0.01,
      //     class: 'wi',
      //   },
      //   layoutAttrs: {
      //     span: 5,
      //   },
      // },
      // {
      //   type: 'inputNumber',
      //   key: 'moq',
      //   value: 1,
      //   labelAttrs: {
      //     label: '起订量',
      //   },
      //   formAttrs: {
      //     clearable: true,
      //     min: 1,
      //     max: 999999,
      //     step: 1,
      //     class: 'w',
      //   },
      //   layoutAttrs: {
      //     span: 4
      //   },
      // },
      // {
      //   type: 'baseRate',
      //   key: 'retail_suitable',
      //   value: 0,
      //   labelAttrs: {
      //     label: '零售适配',
      //   },
      //   formAttrs: {
      //     type: 'text',
      //     max: 3,
      //   },
      //   layoutAttrs: {
      //     span: 4
      //   },
      // },
      // {
      //   type: 'cascaderServer',
      //   key: 'source_area_id',
      //   value: '',
      //   labelAttrs: {
      //     label: '原产地',
      //   },
      //   formAttrs: {
      //     placeholder: '请选择原产地',
      //     clearable: true,
      //     filterable: true,
      //     searchKey: 'title',
      //     'show-all-levels': true,
      //     listApi: '/area/tree',
      //     class: 'w',
      //     props: {
      //       label: 'title',
      //       value: 'id',
      //       checkStrictly: true,
      //     },
      //     // options: localAreaTree
      //   },
      //   layoutAttrs: {
      //     span: 6,
      //   },
      // },
      // {
      //   type: 'cascaderServer',
      //   key: 'target_area',
      //   value: '',
      //   labelAttrs: {
      //     label: '目标市场',
      //   },
      //   formAttrs: {
      //     placeholder: '请选择目标市场',
      //     clearable: true,
      //     filterable: true,
      //     searchKey: 'title',
      //     'show-all-levels': true,
      //     listApi: '/area/tree',
      //     class: 'w',
      //     props: {
      //       multiple: true,
      //       label: 'title',
      //       value: 'id',
      //       checkStrictly: true,
      //     },
      //     // options: localAreaTree
      //   },
      //   layoutAttrs: {
      //     span: 10,
      //   },
      // },
      // {
      //   type: 'uploadFile',
      //   key: 'certificate',
      //   labelAttrs: {
      //     label: '证书',
      //   },
      //   formAttrs: {
      //     multiple: false,
      //     module: 'product',
      //   },
      // },
      // 仓储信息
      // {
      //   type: 'divider',
      //   value: "仓储信息",
      //   labelAttrs: {
      //     labelWidth: '0px',
      //   },
      //   formAttrs: {
      //     class: "cb b fs15",
      //     contentPosition: "center"
      //   }
      // },
      // {
      //   type: 'select',
      //   key: 'nature',
      //   value: isMateriel ? 1 : 0,
      //   labelAttrs: {
      //     label: '产品类别',
      //   },
      //   formAttrs: {
      //     class: 'w',
      //     placeholder: '请选择产品类别',
      //   },
      //   layoutAttrs: {
      //     xs: 24,
      //     sm: 24,
      //     md: 6,
      //     lg: 6,
      //     xl: 6,
      //   },
      //   condition: () => {
      //     return isMateriel
      //   },
      //   options: objToArr(PRODUCT_NATURE).slice(1),
      // },
      // {
      //   type: 'cascaderServer',
      //   key: 'default_repository_id',
      //   value: '',
      //   labelAttrs: {
      //     label: '默认仓库',
      //   },
      //   formAttrs: {
      //     placeholder: '请选择仓库',
      //     clearable: true,
      //     filterable: true,
      //     multiple: false,
      //     showAllLevels: true,
      //     listApi: '/repository/tree',
      //     exParams: {
      //       sort: 'title',
      //       order: 'asc',
      //     },
      //     props: {
      //       label: 'title',
      //       value: 'id',
      //       checkStrictly: true,
      //       multiple: false,
      //     },
      //   },
      //   layoutAttrs: {
      //     xs: 24,
      //     sm: 24,
      //     md: 6,
      //     lg: 6,
      //     xl: 6,
      //   },
      // },
      // {
      //   type: 'inputNumber',
      //   key: 'alarm_stock',
      //   value: 0,
      //   labelAttrs: {
      //     label: '预警库存',
      //   },
      //   formAttrs: {
      //     clearable: true,
      //     min: 0,
      //     max: 999999,
      //     step: 1,
      //     style: 'width:220px',
      //   },
      //   layoutAttrs: {
      //     xs: 24,
      //     sm: 24,
      //     md: 6,
      //     lg: 6,
      //     xl: 6,
      //   },
      // },
      // {
      //   type: 'selectServer',
      //   key: 'unit',
      //   cptKey: 'unit',
      //   value: '',
      //   labelAttrs: {
      //     label: '单位',
      //   },
      //   formAttrs: {
      //     class: 'w',
      //     listApi: 'config/get',
      //     labelKey: 'title',
      //     filterable: true,
      //     allowCreate: true,
      //     placeholder: '请选择单位',
      //     exParams: {
      //       key: 'PRODUCT_UNIT',
      //     },
      //     defaultSelect: true
      //   },
      //   layoutAttrs: {
      //     xs: 24,
      //     sm: 24,
      //     md: 6,
      //     lg: 6,
      //     xl: 6,
      //   },
      // },
      {
        type: 'divider',
        value: '分类信息',
        labelAttrs: {
          labelWidth: '0px',
        },
        formAttrs: {
          class: 'cb b fs15',
          contentPosition: 'center',
        },
      },
      {
        type: 'cascaderServer',
        key: 'category_ids',
        value: '',
        labelAttrs: {
          label: '产品分类',
        },
        formAttrs: {
          placeholder: '请选择产品分类',
          clearable: true,
          filterable: true,
          multiple: true,
          class: 'w',
          'show-all-levels': true,
          listApi: '/category/tree',
          props: {
            label: 'title',
            value: 'id',
            checkStrictly: false,
            multiple: true,
          },
        },
        layoutAttrs: {
          span: 24,
        },
      },
      {
        type: 'cascaderServer',
        key: 'vehicle_parts_id',
        value: '',
        labelAttrs: {
          label: '车身部件',
        },
        formAttrs: {
          placeholder: '请选择车身部件',
          clearable: true,
          filterable: true,
          multiple: false,
          showAllLevels: true,
          listApi: '/vehicle-part/tree',
          props: {
            label: 'title',
            value: 'id',
            checkStrictly: true,
            multiple: false,
          },
          showAdd: true,
        },
        layoutAttrs: {
          xs: 24,
          sm: 24,
          md: 6,
          lg: 6,
          xl: 6,
        },
        action: {
          type: 'editBox',
          editBoxName: 'addVehiclePartBox',
        },
      },
      {
        type: 'input',
        key: 'admin_remark',
        value: '',
        labelAttrs: {
          label: '部件备注',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入部件备注',
          clearable: true,
        },
        layoutAttrs: {
          xs: 24,
          sm: 24,
          md: 18,
          lg: 18,
          xl: 18,
        },
      },
      {
        type: 'slot',
        // type: 'cascaderServer',
        key: 'vehicles',
        name: 'vehicle_list_info',
        value: [],
        titleKey: '',
        labelAttrs: {
          label: '适用车型',
        },
        formAttrs: {
          clearable: false,
          filterable: true,
          listApi: `/vehicle/index`,
          addApi: `/vehicle/add`,
          searchKey: 'title',
          placeholder: '请选择适用车型',
          autoAdd: true,
        },
        layoutAttrs: {
          span: 24,
        },
      },
      {
        type: 'divider',
        value: '图片与视频',
        labelAttrs: {
          labelWidth: '0px',
        },
        formAttrs: {
          class: 'cb b fs15',
          contentPosition: 'center',
        },
      },
      {
        type: 'uploadImg',
        key: 'image',
        labelAttrs: {
          label: '封面图',
        },
        formAttrs: {
          uploadParam: { module: 'product' },
          copyImgUploade: true,
          tips: '建议尺寸比例',
          tipsCls: 'cd fs12 lh1 mt5',
          limitSize: 1024 * 10,
          afterBtnText: '从素材库选择',
          mattingUrl: mattingUrl,
          requestFun: request,
          actionList: ['mattingImg', PosterDesignRef ? 'design' : null],
        },
        handleFun: handlePictureActions,
      },
      {
        type: 'uploadImg',
        key: 'slider_image',
        labelAttrs: {
          label: '图集',
        },
        formAttrs: {
          multiple: true,
          uploadParam: { module: 'product' },
          copyImgUploade: true,
          limitSize: 1024 * 10,
          afterBtnText: '从素材库选择',
          mattingUrl: mattingUrl,
          mattingAll: true,
          requestFun: request,
          actionList: [
            'mattingImg',
            'setMain',
            PosterDesignRef ? 'design' : null,
          ],
        },
        handleFun: handlePictureActions,
      },
      {
        type: 'uploadImg',
        key: 'matting_image',
        labelAttrs: {
          label: '抠图图集',
        },
        formAttrs: {
          multiple: true,
          uploadParam: { module: 'product' },
          copyImgUploade: true,
          limitSize: 1024 * 10,
          afterBtnText: '从素材库选择',
          mattingUrl: mattingUrl,
          requestFun: request,
          actionList: ['setMain'],
        },
        handleFun: handlePictureActions,
      },
      {
        type: 'uploadVideo',
        key: 'video',
        labelAttrs: {
          label: '视频',
        },
        formAttrs: {
          multiple: true,
          uploadParam: { module: 'product' },
          afterBtnText: '从素材库选择',
          isCosSdk: true,
          uploadUrl: cosSdkConfig,
          // uploadUrl: uploadFileUrl,
          module: 'product',
        },
      },
      {
        type: 'divider',
        value: '包装与箱规',
        labelAttrs: {
          labelWidth: '0px',
        },
        formAttrs: {
          class: 'cb b fs15',
          contentPosition: 'center',
        },
      },
      {
        type: 'slot',
        key: 'cartons',
        name: 'cartonList',
        value: [],
        labelAttrs: {
          label: '箱规',
        },
        formAttrs: {
          class: 'cd',
          tips: '箱规信息已移至详情页面添加编辑',
          tipsCls: 'cd',
          contentPosition: 'center',
        },
        layoutAttrs: {
          span: 24,
        },
      },

      {
        type: 'divider',
        value: '描述与详情',
        labelAttrs: {
          labelWidth: '0px',
        },
        formAttrs: {
          class: 'cb b fs15',
          contentPosition: 'center',
        },
      },
      {
        type: 'input',
        key: 'description',
        value: '',
        labelAttrs: {
          label: '描述',
        },
        formAttrs: {
          type: 'textarea',
          placeholder: '请输入描述',
          clearable: true,
        },
      },
      {
        type: 'richText',
        key: 'content',
        value: '',
        labelAttrs: {
          label: '内容',
        },
        formAttrs: {
          class: 'w',
          style: {
            height: '300px',
          },
          excludeKeys: richTextExcludeKeys,
        },
      },
      {
        type: 'divider',
        value: '拓展信息',
        labelAttrs: {
          labelWidth: '0px',
        },
        formAttrs: {
          class: 'cb b fs15',
          contentPosition: 'center',
        },
      },
      {
        type: 'slot',
        // type: 'cascaderServer',
        name: 'extraFormData',
        value: '',
        titleKey: '',
        labelAttrs: {
          label: '',
        },
        formAttrs: {},
        layoutAttrs: {
          span: 24,
        },
      },

      {
        type: 'switch',
        key: 'status',
        value: 1,
        labelAttrs: {
          label: '使用状态',
        },
      },
    ]
    defaultConfig.form.formList.splice(1, 100, ...editWmsInfo)
    // defaultConfig.dialog.width = '700px'
  }
  return defaultConfig
}

/**
 * 商品复制弹窗（家具）
 * @param {*} MODULE // 模块
 * @param {*} type // 类型判断
 * @returns
 */

export function productFurnitureCopyAddDialog(MODULE = '', type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    title: '复制产品',
    customTitle: true,
    dialog: {
      width: '700px',
      appendToBody: true,
      destroyOnClose: true,
      closeOnClickModal: false,
      lockScroll: true,
      'lock-scroll': true,
      // fullscreen: true
    },
    form: {
      api: {
        add: `/${MODULE}/mhlAddSku`,
        update: `/${MODULE}/mhlAddSku`,
        detailCallBack: (result, data) => {
          result.product_id = data.id
          delete result.id
          return result
        },
      },
      formListKey: true, // 参数是formList的key
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '103px',
        ...dynamicsFormAttrs,
      },
      // addSuccess: (searchModel, setSearch) => {
      //   setSearch('sort', 'id')
      //   setSearch('order', 'desc')
      // },
      formList: [
        {
          type: 'selectServer',
          key: 'product_id',
          value: '',
          labelAttrs: {
            label: '产品',
            rules: [{ required: true, message: '请选择产品', trigger: 'blur' }],
            // required: true,
          },
          formAttrs: {
            class: 'w',
            disabled: true,
            clearable: false,
            filterable: true,
            modelValueKey: 'title',
            listApi: `/product/index`,
            searchKey: 'title',
            placeholder: '支持产品名称/OEM码/编码搜索',
            showAdd: true,
          },
        },
        {
          type: 'tag',
          key: 'sku',
          value: '',
          labelAttrs: {
            label: '产品规格',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  return defaultConfig
}
/**
 * 产品图片设计
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function productImgDesign(MODULE = '', type, refObj = {}) {
  console.log('type :>> ', type)
  const { PosterDesignRef, state } = refObj

  /**
   * 处理抠图
   * @param {*} action
   * @param {*} setModel
   * @param {*} model
   */
  const handlePictureActions = (action, setModel, model, itemConfig) => {
    const { item, file } = action
    console.log('file :>> ', file)
    if (item.code === 'setMain') {
      setModel('image', file.org_url)
    } else if (item.code === 'mattingImg' || item.code === 'mattingAll') {
      let mattingImage = F.copy(model.matting_image)
      const result = file.map((item) => item.org_url)
      if (mattingImage && F.isArray(mattingImage)) {
        mattingImage.push(...result)
      } else {
        mattingImage = mattingImage
          ? result.join(',') + `${mattingImage ? ',' : ''}${mattingImage}`
          : result.join(',')
      }
      setModel(
        'matting_image',
        F.isArray(mattingImage) ? mattingImage.join('') : mattingImage
      )
    } else if (item.code === 'design' && PosterDesignRef) {
      if (itemConfig.key === 'image') {
        PosterDesignRef.value.showPosterDesign({
          ...model.product_info,
          slider_image: model.image,
        })
        state.imageType = 'single'
      } else if (itemConfig.key === 'slider_image') {
        const selectImgIndex = model.slider_image?.findIndex(
          (item) => item === file.org_url
        )
        if (selectImgIndex > -1) {
          state.selectImgIndex = selectImgIndex
          PosterDesignRef.value.showPosterDesign({
            ...model.product_info,
            slider_image: file.url || '',
          })
        }
        state.imageType = 'mutiple'
      }
      state.openData = model
      state.setModel = setModel
    }
  }

  let defaultConfig = {
    title: '图片设计',
    customTitle: true,
    dialog: {
      width: '1200px',
      top: '8vh',
      appendToBody: true,
      destroyOnClose: true,
      closeOnClickModal: false,
      lockScroll: true,
      'lock-scroll': true,
      // fullscreen: true
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true, // 参数是formList的key
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '103px',
        ...dynamicsFormAttrs,
      },
      // beforeReq: (data) => {
      //   if (data?.vehicles?.length === 0) {
      //     data.vehicles = ''
      //   }
      //   if (data.oem_code) {
      //     data.oem_code = data.oem_code.replace(/[；;]/g, ';');
      //   }
      //   return data
      // },
      formList: [
        // {
        //   type: 'input',
        //   key: 'description',
        //   value: '',
        //   labelAttrs: {
        //     label: '描述',
        //   },
        //   formAttrs: {
        //     type: 'textarea',
        //     placeholder: '请输入描述',
        //     clearable: true,
        //   },
        // },
        // {
        //   type: 'richText',
        //   key: 'content',
        //   value: '',
        //   labelAttrs: {
        //     label: '内容',
        //   },
        //   formAttrs: {
        //     class: 'w',
        //     style: {
        //       height: '300px',
        //     },
        //     excludeKeys: [
        //       'blockquote',
        //       'group-more-style',
        //       'fontFamily',
        //       'lineHeight',
        //       'todo',
        //       'emotion',
        //       'uploadAttachment',
        //       'group-video',
        //       'insertTable',
        //       'codeBlock',
        //       'divider',
        //       'undo',
        //       'redo',
        //     ],
        //   },
        // },
        {
          type: 'uploadImg',
          key: 'image',
          labelAttrs: {
            label: '封面图',
          },
          formAttrs: {
            uploadParam: { module: 'product' },
            copyImgUploade: true,
            tips: '建议尺寸比例',
            tipsCls: 'cd fs12 lh1 mt5',
            limitSize: 1024 * 10,
            afterBtnText: '从素材库选择',
            mattingUrl: mattingUrl,
            requestFun: request,
            actionList: [PosterDesignRef ? 'design' : null],
          },
          handleFun: handlePictureActions,
        },
        {
          type: 'uploadImg',
          key: 'slider_image',
          labelAttrs: {
            label: '图集',
          },
          formAttrs: {
            multiple: true,
            uploadParam: { module: 'product' },
            copyImgUploade: true,
            limitSize: 1024 * 10,
            afterBtnText: '从素材库选择',
            mattingUrl: mattingUrl,
            mattingAll: true,
            requestFun: request,
            actionList: ['setMain', PosterDesignRef ? 'design' : null],
          },
          handleFun: handlePictureActions,
        },
        // {
        //   type: 'uploadVideo',
        //   key: 'video',
        //   labelAttrs: {
        //     label: '视频',
        //   },
        //   formAttrs: {
        //     multiple: false,
        //     uploadParam: { module: 'product' },
        //     afterBtnText: '从素材库选择',
        //     isCosSdk: true,
        //     uploadUrl: cosSdkConfig,
        //     // uploadUrl: uploadFileUrl,
        //     module: 'product',
        //   },
        // },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  return defaultConfig
}

/**
 * 配套产品新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function productPackageAddDialog(
  MODULE = '',
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  let defaultConfig = {
    title: '配套产品',
    dialog: {
      width: '500px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '80px',
      },
      formList: [
        {
          type: 'input',
          key: 'title',
          value: '',
          labelAttrs: {
            label: '名称',
            rules: [
              {
                required: true,
                message: '请输入名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入名称',
            clearable: true,
          },
        },
        {
          // type: 'selectServer',
          // key: 'customer_id',
          type: 'selectServer',
          key: 'product_id',
          titleKey: 'customer_info.title',
          value: info?.[params?.valKey] || '',
          labelAttrs: {
            label: '产品',
            rules: [{ required: true, message: '请选择产品', trigger: 'blur' }],
            // required: true,
          },
          formAttrs: {
            disabled: !!info?.[params?.valKey],
            class: 'w',
            clearable: false,
            filterable: true,
            listApi: `/product/index`,
            searchKey: 'title',
            placeholder: '支持产品名称/OEM码/编码搜索',
            showAdd: true,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 产品价格策略新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function productPricePolicyAddDialog(
  MODULE = '',
  type = '',
  info = {},
  params = {},
  from = ''
) {
  console.log('type :>> ', type)
  console.log('params :>> ', params)
  console.log('from :>> ', from)
  console.log('PRICE_POLICY_MODE :>> ', PRICE_POLICY_MODE)
  const { supportCurrency, defaultCurrency } = handleSupportCurrency()

  let defaultConfig = {
    title: '产品价格策略',
    customTitle: true,
    dialog: {
      width: '750px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/set`,
        // detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
        addCallback: () => {
          setTimeout(() => {
            defaultConfig.form.formList[5].value = [
              {
                price: 1,
                over_num: 1,
              },
            ]
          }, 1000)
        },
        updateCallback: () => {
          setTimeout(() => {
            defaultConfig.form.formList[5].value = [
              {
                price: 1,
                over_num: 1,
              },
            ]
          }, 1000)
        },
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '110px',
      },
      formList: [
        {
          type: 'slot',
          key: 'product_id',
          value: info.id,
          labelAttrs: {},
          formAttrs: {},
        },
        // {
        //   type: 'select',
        //   key: 'type',
        //   value: +info.type || 1,
        //   labelAttrs: {
        //     label: '策略类型',
        //   },
        //   formAttrs: {
        //     placeholder: '请选择策略类型',
        //   },
        //   options: objToArr(PRICE_POLICY_TYPE),
        //   layoutAttrs: {
        //     span: 10
        //   }
        // },
        {
          type: from === 'supplier' ? 'slot' : 'radio',
          key: 'type',
          value: from === 'supplier' ? 2 : +info.mode || 1,
          labelAttrs: {
            label: '策略类型：',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请选择类型',
          },
          formEvents: {
            change: (e) => {
              console.log('cahnge :>> ', e)
            },
          },
          options: objToArrRadio(PRICE_POLICY_TYPE),
          layoutAttrs: {
            span: from === 'supplier' ? 0 : 9,
          },
        },
        // {
        //   type: 'radio',
        //   key: 'mode',
        //   value: +info.mode || 1,
        //   labelAttrs: {
        //     label: '策略模式：',
        //   },
        //   formAttrs: {
        //     class: 'w',
        //     placeholder: '请选择类型',
        //   },
        //   options: objToArrRadio(PRICE_POLICY_MODE),
        //   layoutAttrs: {
        //     span: 15
        //   }
        // },
        {
          type: 'slot',
          key: 'mode',
          value: 1,
          labelAttrs: {},
          formAttrs: {},
          layoutAttrs: { span: 0 },
        },
        {
          type: 'select',
          key: 'currency',
          value: defaultCurrency,
          labelAttrs: {
            label: '货币币种',
          },
          formAttrs: {
            disabled: type === 'edit',
            placeholder: '请选择币种',
            class: 'w',
          },
          options: objToArr(supportCurrency, false),
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'input',
          key: 'price',
          value: info.price,
          labelAttrs: {
            label: '起始价格',
            customTitle: true,
          },
          formAttrs: {
            disabled: true,
            placeholder: '',
            clearable: true,
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'slot',
          key: 'list',
          name: 'detailList',
          value: [
            {
              price: 1,
              over_num: 1,
            },
          ],
          labelAttrs: {},
          formAttrs: {},
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  if (from === 'supplier') {
    defaultConfig.form.formList.push({
      type: 'slot',
      key: 'supplier_id',
      value: params.supplier_id,
      labelAttrs: {},
      formAttrs: {},
      layoutAttrs: { span: 0 },
    })
  }

  return defaultConfig
}

/**
 * 产品箱规新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function productCartonAddDialog(
  MODULE = '',
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  console.log('params', params)
  // 改变箱规单位和尺寸
  function changeUnitAndDimension(value, model) {
    model.volume = handleVolume(model, model.display_length_unit)
  }
  let defaultConfig = {
    title: '产品箱规',
    dialog: {
      width: '900px',
      top: '8vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
        detailCallBack: (result, data) => {
          result.volume = handleVolume(data, data.display_length_unit)
          return result
        },
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '80px',
      },
      formList: [
        {
          type: info.id ? 'slot' : 'selectServer',
          key: 'product_id',
          value: info.id,
          labelAttrs: {
            label: '产品',
          },
          formAttrs: {
            class: 'w',
            clearable: false,
            filterable: true,
            listApi: `/product/index`,
            searchKey: 'title',
            placeholder: '支持产品名称/OEM码/编码搜索',
            showAdd: true,
          },
        },
        {
          type: 'input',
          key: 'title',
          value: '',
          labelAttrs: {
            label: '名称',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入名称',
            clearable: true,
          },
        },
        {
          type: 'selectServer',
          key: 'supplier_ids',
          labelAttrs: {
            label: '供应商名称',
          },
          formAttrs: {
            clearable: false,
            filterable: true,
            listApi: `/supplier/index`,
            searchKey: 'title',
            class: 'w',
            multiple: true,
            valueType: 'number',
            placeholder: '请选择供应商',
            modelValueKey: 'supplier_list',
          },
        },
        {
          type: 'select',
          key: 'type',
          value: objToArr(PRODUCT_CARTON_TYPE)[0].value,
          labelAttrs: {
            label: '箱规类型',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请选择箱规类型',
          },
          layoutAttrs: {
            span: 6,
          },
          options: objToArr(PRODUCT_CARTON_TYPE),
        },
        {
          type: 'inputNumber',
          key: 'num',
          value: 1,
          labelAttrs: {
            label: '产品数量',
            rules: [
              {
                required: true,
                message: '请输入产品数量',
                trigger: 'blur',
              },
            ],
            tips: '可以容纳的产品个数',
          },
          formAttrs: {
            type: 'text',
            min: 1,
            placeholder: '请输入产品数量',
            clearable: true,
          },
          layoutAttrs: {
            span: 6,
          },
        },
        {
          type: 'switch',
          key: 'is_default',
          value: 0,
          labelAttrs: {
            label: '默认箱规',
          },
          formAttrs: {
            'inline-prompt': true,
            'active-text': '是',
            'inactive-text': '否',
          },
          layoutAttrs: {
            span: 4,
          },
        },
        {
          type: 'switch',
          key: 'is_storage_volume',
          value: 0,
          labelAttrs: {
            label: '费用箱规',
            tips: '计算货品体积费用时的箱规',
          },
          formAttrs: {
            'inline-prompt': true,
            'active-text': '是',
            'inactive-text': '否',
          },
          layoutAttrs: {
            span: 4,
          },
        },
        {
          type: 'select',
          key: 'display_length_unit',
          value: objToArr(LENGTH_UNIT, false)[0].value,
          labelAttrs: {
            label: '尺寸单位',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请选择尺寸单位',
          },
          layoutAttrs: {
            span: 6,
          },
          onChange: changeUnitAndDimension,
          options: objToArr(LENGTH_UNIT, false),
        },
        {
          type: 'inputNumber',
          key: 'l',
          value: '',
          labelAttrs: {
            label: (model) => {
              return `长(${model.display_length_unit || 'cm'})`
            },
            rules: [
              {
                required: true,
                message: '请输入箱规长',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            min: 1,
            class: 'fx',
            placeholder: '请输入箱规长',
            clearable: true,
          },
          onChange: changeUnitAndDimension,
          layoutAttrs: {
            span: 6,
          },
        },
        {
          type: 'inputNumber',
          key: 'w',
          value: '',
          labelAttrs: {
            label: (model) => {
              return `宽(${model.display_length_unit || 'cm'})`
            },
            rules: [
              {
                required: true,
                message: '请输入箱规宽',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            min: 1,
            placeholder: '请输入箱规宽',
            clearable: true,
          },
          onChange: changeUnitAndDimension,
          layoutAttrs: {
            span: 6,
          },
        },
        {
          type: 'inputNumber',
          key: 'h',
          value: '',
          labelAttrs: {
            label: (model) => {
              return `高(${model.display_length_unit || 'cm'})`
            },
            rules: [
              {
                required: true,
                message: '请输入箱规高',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            min: 1,
            placeholder: '请输入箱规高',
            clearable: true,
          },
          onChange: changeUnitAndDimension,
          layoutAttrs: {
            span: 6,
          },
        },
        {
          type: 'select',
          key: 'display_weight_unit',
          value: objToArr(WEIGHT_UNIT, false)[0].value,
          labelAttrs: {
            label: '重量单位',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请选择重量单位',
          },
          layoutAttrs: {
            span: 6,
          },
          options: objToArr(WEIGHT_UNIT, false),
        },
        {
          type: 'inputNumber',
          key: 'weight',
          value: '',
          labelAttrs: {
            label: (model) => {
              return `重量(${model.display_weight_unit || 'kg'})`
            },
            // rules: [
            //   {
            //     required: true,
            //     message: '请输入箱规重量',
            //     trigger: 'blur',
            //   },
            // ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入箱规重量',
            clearable: true,
          },
          layoutAttrs: {
            span: 6,
          },
        },
        // {
        //   type: 'inputNumber',
        //   key: 'rough_weight',
        //   value: '',
        //   labelAttrs: {
        //     label: '毛重(kg)',
        //     rules: [
        //       {
        //         required: true,
        //         message: '请输入箱规毛重',
        //         trigger: 'blur',
        //       },
        //     ],
        //   },
        //   formAttrs: {
        //     type: 'text',
        //     placeholder: '请输入箱规毛重',
        //     clearable: true,
        //   },
        //   layoutAttrs: {
        //     span: 8,
        //   },
        // },
        {
          type: 'inputNumber',
          key: 'volume',
          value: '',
          labelAttrs: {
            label: (model) => {
              return `体积(${model.display_length_unit === 'cm' ? 'm' : 'ft'}³)`
            },
            rules: [
              {
                required: true,
                message: '请输入箱规体积',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入箱规体积',
            clearable: true,
          },
          layoutAttrs: {
            span: 6,
          },
        },
        {
          type: 'text',
          // type: 'slot',
          // name: 'volume2',
          key: 'volume2',
          value: '',
          valFun: (model) => {
            const volume = handleVolume(model, model.display_length_unit)
            return convertVolume(volume, model.display_length_unit, true)
          },
          labelAttrs: {
            label: (model) => {
              return `体积(${model.display_length_unit != 'cm' ? 'm' : 'ft'}³)`
            },
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入箱规体积',
            clearable: true,
          },
          layoutAttrs: {
            span: 6,
          },
        },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '备注',
            customTitle: true,
          },
          formAttrs: {
            type: 'textarea',
            placeholder: '请输入备注',
            clearable: true,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 产品分类新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function productCategoryAddDialog(MODULE = '', type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    title: '分类',
    dialog: {
      width: '800px',
      appendToBody: false,
      destroyOnClose: true,
      'destroy-on-close': true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        update: `/${MODULE}/edit`,
        detail: `/${MODULE}/detail`,
      },
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '90px',
        ...dynamicsFormAttrs,
      },
      formList: [
        {
          type: 'input',
          key: 'title',
          value: '',
          labelAttrs: {
            label: '分类名称',
            rules: [
              {
                required: true,
                message: '请输入分类名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入分类名称',
            clearable: true,
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'cascaderServer',
          key: 'pid',
          value: 0,
          labelAttrs: {
            label: '所属分类',
          },
          formAttrs: {
            placeholder: '请选择所属分类',
            clearable: true,
            filterable: true,
            'show-all-levels': true,
            listApi: '/category/tree',
            class: 'wi',
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: true,
            },
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'uploadImg',
          key: 'image',
          labelAttrs: {
            label: '分类logo',
          },
          formAttrs: {
            useMaterial: true,
            uploadParam: { module: 'category' },
            tips: '建议尺寸200*200',
            tipsCls: 'lh1 pt5 fs12 cd',
          },
        },
        {
          type: 'uploadImg',
          key: 'banner',
          value: '',
          labelAttrs: {
            label: '分类banner',
          },
          formAttrs: {
            useMaterial: true,
            multiple: true,
            uploadParam: { module: 'category' },
          },
        },
        {
          type: 'inputNumber',
          key: 'rank',
          value: 0,
          labelAttrs: {
            label: '排序',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入排序',
            clearable: true,
            style: 'width:220px',
          },
        },
        {
          type: 'switch',
          key: 'status',
          value: 1,
          labelAttrs: {
            label: '使用状态',
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '确定',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  if (type === 'shop' || store.getters['routes/hasShopAuth']) {
    const validateUrl = (rule, value, callback) => {
      if (value && !isKey(value)) callback(new Error(t('需要以字母开头')))
      else callback()
    }
    defaultConfig.form.formList.splice(
      2,
      0,
      {
        type: 'input',
        key: 'alias_title',
        value: '',
        labelAttrs: {
          label: '显示名称',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入显示名称',
          tips: '用于生成电商网站时显示的分类标题',
          tipsCls: 'lh1 g6 pt5 fs12',
          clearable: true,
        },
        layoutAttrs: {
          span: 12,
        },
      },
      {
        type: 'input',
        key: 'code',
        value: '',
        labelAttrs: {
          label: '分类url',
          rules: [
            {
              validator: validateUrl,
              message: '请输入字母或数字',
              trigger: 'blur',
            },
          ],
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入分类url,如lights',
          tips: '用于生成电商网站的链接,可不填写',
          tipsCls: 'lh1 g6 pt5 fs12',
          clearable: true,
        },
        layoutAttrs: {
          span: 12,
        },
      },
      {
        type: 'input',
        key: 'description',
        value: '',
        labelAttrs: {
          label: '分类描述',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入分类描述',
          tips: '用于生成电商网站时SEO',
          tipsCls: 'lh1 g6 pt5 fs12',
          clearable: true,
        },
        layoutAttrs: {
          span: 24,
        },
      },
      {
        type: 'input',
        key: 'keywords',
        value: '',
        labelAttrs: {
          label: '分类关键词',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入分类',
          tips: '用于生成电商网站时SEO',
          tipsCls: 'lh1 g6 pt5 fs12',
          clearable: true,
        },
        layoutAttrs: {
          span: 24,
        },
      }
    )
  }
  return defaultConfig
}

/**
 * 产品图册新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function productAtlaAddDialog(
  MODULE = '',
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('params :>> ', params)
  let defaultConfig = {
    title: '图册',
    dialog: {
      width: '500px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '80px',
      },
      formList: [
        {
          type: 'input',
          key: 'title',
          value: '',
          labelAttrs: {
            label: '名称',
            rules: [
              {
                required: true,
                message: '请输入名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入名称',
            clearable: true,
            tips: '文件请到附件中上传',
          },
        },
        // {
        //   type: 'uploadFile',
        //   key: 'certificate',
        //   labelAttrs: {
        //     label: '图册文件',
        //   },
        //   formAttrs: {
        //     multiple: false,
        //     uploadUrl: uploadFileUrl,
        //     uploadParam: {},
        //   },
        // },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 产品渠道新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function productChannelAddDialog(
  MODULE = '',
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  console.log('params :>> ', params)
  const { supportCurrency, defaultCurrency } = handleSupportCurrency()
  let defaultConfig = {
    title: '我方渠道',
    dialog: {
      width: '700px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '80px',
      },
      formList: [
        {
          type: 'slot',
          key: 'product_id',
          value: info.id,
          labelAttrs: {
            label: '产品id',
          },
        },
        {
          type: 'cascaderServer',
          key: 'channel_id',
          value: 0,
          labelAttrs: {
            label: '渠道名称',
            rules: [
              {
                required: true,
                message: '请选择渠道名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            placeholder: '请选择渠道名称',
            clearable: true,
            filterable: true,
            multiple: false,
            'show-all-levels': true,
            listApi: '/channel/tree',
            class: 'w',
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: false,
              multiple: false,
            },
          },
        },
        // {
        //   type: 'selectServer',
        //   key: 'shop_channel_id',
        //   value: '',
        //   labelAttrs: {
        //     label: '关联店铺',
        //   },
        //   formAttrs: {
        //     listApi: `/shop-channel/index`,
        //     class: 'w',
        //     valueType: 'number',
        //     labelKey: "title",
        //     multiple: false,
        //     placeholder: '请选择关联店铺',
        //     exParams: {},
        //     // exLabelKeyFun: (row) => {
        //     //   const options = row.type === 1 ? PRODUCT_SALE_CHANNEL_SUB_TYPE : PRODUCT_PROMOTION_CHANNEL_SUB_TYPE
        //     //   return options[row.sub_type]
        //     // }
        //   },
        //   // layoutAttrs: {
        //   //   span: layoutSpan,
        //   // },
        // },
        {
          type: 'input',
          key: 'url',
          name: 'productUrl',
          value: '',
          labelAttrs: {
            label: '链接地址',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入链接地址',
            clearable: true,
          },
          // layoutAttrs: {
          //   span: layoutSpan,
          // },
        },
        {
          type: 'select',
          key: 'currency',
          value: defaultCurrency,
          labelAttrs: {
            label: '货币币种',
          },
          formAttrs: {
            placeholder: '请选择币种',
            class: 'w',
          },
          options: objToArr(supportCurrency, false),
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'inputNumber',
          key: 'price',
          value: '',
          labelAttrs: {
            label: '销售价格',
          },
          formAttrs: {
            type: 'text',
            class: 'w',
            placeholder: '请输入销售价格',
            clearable: true,
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '备注',
            customTitle: true,
          },
          formAttrs: {
            type: 'textarea',
            placeholder: '请输入备注',
            clearable: true,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  // if (type === 'bind') {
  //   defaultConfig.title = '绑定店铺'
  //   defaultConfig.customTitle = true
  //   defaultConfig.form.formList = [
  //     {
  //       type: 'selectServer',
  //       key: 'third_shop_id',
  //       value: '',
  //       labelAttrs: {
  //         label: '店铺',
  //         rules: [
  //           {
  //             required: true,
  //             message: '请选择店铺',
  //             trigger: 'blur',
  //           },
  //         ],
  //       },
  //       formAttrs: {
  //         listApi: `/albb/shopList`,
  //         class: 'w',
  //         valueType: 'number',
  //         labelKey: "company_name",
  //         multiple: false,
  //         placeholder: '请选择店铺',
  //       },
  //       layoutAttrs: {
  //         span: layoutSpan,
  //       },
  //     },
  //     {
  //       type: 'input',
  //       key: 'third_product_id',
  //       value: '',
  //       labelAttrs: {
  //         label: '商品ID',
  //       },
  //       formAttrs: {
  //         type: 'text',
  //         placeholder: '请输入第三方商品ID',
  //         clearable: true,
  //       },
  //       layoutAttrs: {
  //         span: layoutSpan,
  //       },
  //     },
  //   ]

  // }

  // if (channelType === 1) {
  //   defaultConfig.form.formList.splice(5, 0, ,)

  // }

  return defaultConfig
}

/**
 * 产品渠道新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function productRivalChannelAddDialog(
  MODULE = '',
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  console.log('params :>> ', params)
  const { supportCurrency, defaultCurrency } = handleSupportCurrency()

  let defaultConfig = {
    title: '竞对渠道',
    dialog: {
      width: '700px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '80px',
      },
      formList: [
        {
          type: 'slot',
          key: 'product_id',
          value: info.id,
          labelAttrs: {
            label: '产品id',
          },
        },
        {
          type: 'cascaderServer',
          key: 'enemy_channel_id',
          value: 0,
          labelAttrs: {
            label: '渠道名称',
            rules: [
              {
                required: true,
                message: '请选择渠道名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            placeholder: '请选择渠道名称',
            clearable: true,
            filterable: true,
            multiple: false,
            'show-all-levels': true,
            listApi: '/enemy-channel/tree',
            class: 'w',
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: false,
              multiple: false,
            },
          },
        },
        {
          type: 'input',
          key: 'url',
          name: 'productUrl',
          value: '',
          labelAttrs: {
            label: '链接地址',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入链接地址',
            clearable: true,
          },
          // layoutAttrs: {
          //   span: layoutSpan,
          // },
        },
        {
          type: 'select',
          key: 'currency',
          value: defaultCurrency,
          labelAttrs: {
            label: '货币币种',
          },
          formAttrs: {
            placeholder: '请选择币种',
            class: 'w',
          },
          options: objToArr(supportCurrency, false),
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'inputNumber',
          key: 'price',
          value: '',
          labelAttrs: {
            label: '销售价格',
          },
          formAttrs: {
            type: 'text',
            class: 'w',
            placeholder: '请输入销售价格',
            clearable: true,
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'input',
          key: 'number',
          value: '',
          labelAttrs: {
            label: '售出产品数',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入售出产品数量',
            clearable: true,
          },
        },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '备注',
            customTitle: true,
          },
          formAttrs: {
            type: 'textarea',
            placeholder: '请输入备注',
            clearable: true,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 选择拉取的第三方店铺商品
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function pullThirdProductAndOrderAddDialog(type = 'product') {
  console.log('type :>> ', type)
  const moduleObj = {
    product: '/third-goods/pullGoodsList',
    order: '/third-order/pullOrderList',
    dyorder: '/third-order/pullOrderList',
    stockoutOrder: '/delivery-plan/pullShipList',
  }
  const url = moduleObj[type]
  let defaultConfig = {
    title: type === 'product' ? '拉取三方店铺商品' : '拉取三方店铺订单',
    customTitle: true,
    dialog: {
      width: '500px',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: url,
        update: url,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '100px',
      },
      formList: [
        {
          type: 'selectServer',
          key: 'channel_id',
          labelAttrs: {
            label: '三方店铺',
            rules: [
              {
                required: true,
                message: '请选择三方店铺',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            listApi: `/channel/mayPullList`,
            class: 'w',
            labelKey: 'title',
            searchKey: 'title',
            placeholder: '请选择三方店铺',
            defaultSelect: true,
            exParams: {
              type: type === 'dyorder' ? 4 : '',
            },
            // exLabelKeyFun: (item) => {
            //   return `${item.title}（${THIRD_SHOP_TYPE
            //   [item.type]}）`
            // }
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '拉取',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  // TODO: 暂时不需要订单拉取时间范围
  // if (type === 'order') {
  //   defaultConfig.form.beforeReq = (data) => {
  //     console.log('beforeReq :>> ', data)
  //     if (!data.start_time) {
  //       data.start_time = dayjs().format('YYYY-MM-DD')
  //     }
  //     data.end_time = dayjs(data.start_time).add(1, 'day').format('YYYY-MM-DD')
  //     return data
  //   }
  //   defaultConfig.form.formList.push({
  //     type: 'datePicker',
  //     key: 'start_time',
  //     value: dayjs().format('YYYY-MM-DD'),
  //     labelAttrs: {
  //       label: '开始日期',
  //       rules: [
  //         {
  //           required: true,
  //           message: '请选择开始日期',
  //           trigger: 'blur',
  //         }
  //       ]
  //     },
  //     formAttrs: {
  //       class: 'w',
  //       clearable: false,
  //       placeholder: '请选择开始日期',
  //       valueFormat: 'YYYY-MM-DD',
  //       tips: '只能拉取一天的订单信息',
  //       tipsCls: 'cd'
  //     },
  //   },)

  // }
  return defaultConfig
}
/**
 * 订单新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function saleOrderAddDialog(MODULE = '', type = '') {
  console.log('type :>> ', type)
  const { supportCurrency, defaultCurrency } = handleSupportCurrency()

  let layoutSpan = 24
  const layoutSpan6 = ['common', 'furniture', 'retaiOrder', 'retaiOrderPrint']
  // 是否是零售订单
  const retaiOrderArr = ['retaiOrder', 'retaiOrderPrint']
  const isRetailOrder = retaiOrderArr.includes(type)
  if (layoutSpan6.includes(type)) {
    layoutSpan = 6
  } else {
    layoutSpan = 12
  }
  // const layoutSpan = 24
  let defaultConfig = {
    title: '订单',
    dialog: {
      width: '800px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
      // fullscreen: type === 'common'
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
        addCallback: (res) => {
          if (type === 'retaiOrderPrint') {
            ElMessageBox.confirm(
              `当前快递单号为：${res.express_task_code}`,
              '下单成功',
              {
                confirmButtonText: '复制',
                cancelButtonText: '取消',
                type: 'success',
              }
            ).then(() => {
              handleClipboard(res.express_task_code, null, false)
            })
          }
        },
      },
      desHeader: {
        title: isRetailOrder ? '' : '基本信息',
        // border: true,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '80px',
        ...dynamicsFormAttrs,
      },
      formList: [
        {
          type: 'selectServer',
          key: 'customer_id',
          labelAttrs: {
            label: '客户',
            rules: [
              {
                required: true,
                message: '请选择客户',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            listApi: `/customer/index`,
            class: 'w',
            valueType: 'number',
            multiple: false,
            placeholder: '请选择客户',
            emitSelect: true,
            modelValueKey: 'customer_info.title',
            // showAdd: true,
            // autoAdd: true,
            alwaysAdd: true,
          },
          action: {
            type: 'editBox',
            editBoxName: 'addCoustomBox',
          },
          needParams: true,
          onChange: (val, model, params) => {
            handleSetOrderTitle(val, model, params, type)
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'input',
          key: 'title',
          value: '',
          labelAttrs: {
            label: '名称',
            rules: [
              {
                required: true,
                message: '请输入名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入名称',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'radio',
          key: 'is_renew',
          value: 0,
          labelAttrs: {
            label: '签单类型',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请选择类型',
            clearable: true,
          },
          options: objToArrRadio(ORDER_RENEW),
          layoutAttrs: {
            span: layoutSpan,
          },
          condition: () => {
            return !isRetailOrder
          },
        },
        {
          type: 'select',
          key: 'currency',
          value: defaultCurrency,
          labelAttrs: {
            label: '货币币种',
          },
          formAttrs: {
            placeholder: '请选择币种',
            class: 'w',
          },
          options: objToArr(supportCurrency, false),
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'select',
          key: 'pay_type',
          value: 5,
          labelAttrs: {
            label: '付款方式',
          },
          formAttrs: {
            placeholder: '请选择付款方式',
            clearable: true,
            class: 'w',
          },
          options: objToArr(PAY_TYPE),
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'datePicker',
          key: 'start_date',
          value: dayjs().format('YYYY-MM-DD'),
          labelAttrs: {
            label: '开始日期',
            tips: '生成任务时的开始日期',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请选择开始日期',
            valueFormat: 'YYYY-MM-DD',
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'datePicker',
          key: 'order_time',
          value: dayjs().format('YYYY-MM-DD'),
          labelAttrs: {
            label: '单据日期',
            tips: '订单成交时日期',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请选择开始日期',
            valueFormat: 'YYYY-MM-DD',
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'datePicker',
          key: 'delivery_date',
          value: dayjs().add(3, 'day').format('YYYY-MM-DD'),
          labelAttrs: {
            label: '交付日期',
            tips: '订单交付/发货日期',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请选择交付日期',
            valueFormat: 'YYYY-MM-DD',
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: type === 'retaiOrderPrint' ? '保存并打印' : '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  // 家具
  if (type === 'editFurniture' || type === 'furniture') {
    defaultConfig.form.formList[0].action.editBoxName = 'addFurnitureCoustomBox'
    defaultConfig.form.formList.splice(2, 0, {
      type: 'input',
      key: 'code',
      value: '',
      labelAttrs: {
        label: '订单号',
        rules: [
          {
            required: true,
            message: '请输入订单号',
            trigger: 'blur',
          },
        ],
      },
      formAttrs: {
        type: 'text',
        placeholder: '请输入订单号',
        clearable: true,
      },
      layoutAttrs: {
        span: layoutSpan,
      },
    })
  }
  // 从报价单创建
  if (type === 'fromQuoteList') {
    defaultConfig.form.formList.splice(2, 1, {
      type: 'selectServer',
      key: 'quote_id',
      labelAttrs: {
        label: '报价单',
        rules: [
          {
            required: true,
            message: '请选择报价单',
            trigger: 'blur',
          },
        ],
      },
      formAttrs: {
        listApi: `/quote/index`,
        class: 'w',
        valueType: 'number',
        multiple: false,
        placeholder: '请选择报价单',
        searchKey: 'search',
      },
    })
  }
  // 零售添加
  if (isRetailOrder) {
    const customerList = [
      {
        type: 'desHeader',
        value: '客户信息',
        labelAttrs: {
          labelWidth: '0px',
        },
        formAttrs: {
          title: '客户信息',
          style: {
            marginBottom: '0px',
          },
        },
        layoutAttrs: {
          span: 24,
          // class: 'mt10'
        },
      },
      {
        type: 'input',
        key: 'customer_parse_addresss',
        value: '',
        labelAttrs: {
          label: '智能填写',
        },
        formAttrs: {
          type: 'textarea',
          rows: 3,
          placeholder: '粘贴收货信息到此处，将自动识别姓名/电话/地址',
          clearable: true,
        },
        layoutAttrs: {
          span: 12,
          // class: 'mt10'
        },
        onChange: (val, model) => {
          console.log('val onChange', val)
          getParseAddress(val, model)
        },
      },
      {
        type: 'slot',
        key: 'customer_parse_address',
        value: '',
        name: 'parseAddressBtn',
        layoutAttrs: {
          span: 12,
          // class: 'mt10'
        },
      },
      {
        type: 'input',
        key: 'customer_telephone',
        value: '',
        labelAttrs: {
          label: '客户电话',
          rules: [
            { required: true, message: '请输入客户电话', trigger: 'blur' },
          ],
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入客户电话',
          clearable: true,
        },
        layoutAttrs: {
          span: layoutSpan,
        },
        onChange: (val, model) => {
          if (!model.id) {
            checkCustomerPhone(val).then((res) => {
              const result = res.data
              if (result.length) {
                ElMessageBox.confirm(
                  `已存在客户：${result[0].title} 电话：${result[0].telephone}，是否使用当前客户信息？`,
                  '温馨提示',
                  {
                    confirmButtonText: '是',
                    cancelButtonText: '否',
                    type: 'warning',
                  }
                )
                  .then(() => {
                    model.customer_id = result[0].id
                    model.customer_title = result[0].title
                    model.customer_address = result[0].address
                  })
                  .catch(() => {
                    model.customer_id = ''
                  })
              }
            })
          }
        },
      },
      {
        type: 'input',
        key: 'customer_title',
        value: '',
        labelAttrs: {
          label: '客户姓名',
          rules: [
            { required: true, message: '请输入客户姓名', trigger: 'blur' },
          ],
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入客户姓名',
          clearable: true,
        },
        layoutAttrs: {
          span: layoutSpan,
        },
      },
      {
        type: 'input',
        key: 'customer_address',
        value: '',
        labelAttrs: {
          label: '收货地址',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入收货地址',
          clearable: true,
        },
        layoutAttrs: {
          span: 12,
        },
      },
    ]
    defaultConfig.form.formList.splice(0, 1, ...customerList)
    defaultConfig.form.formList.splice(10, 3)
  }
  // 常规添加
  if (type === 'common' || type === 'furniture' || isRetailOrder) {
    // 添加产品
    defaultConfig.form.formList.push({
      type: 'cascaderServer',
      key: 'channel_id',
      value: 0,
      labelAttrs: {
        label: type === 'furniture' ? '销售点' : '订单来源',
      },
      formAttrs: {
        placeholder: type === 'furniture' ? '请选择销售点' : '请选择订单来源',
        clearable: true,
        filterable: true,
        multiple: false,
        'show-all-levels': true,
        defaultSelect: true,
        listApi: '/channel/tree',
        class: 'w',
        props: {
          label: 'title',
          value: 'id',
          checkStrictly: false,
          multiple: false,
        },
      },
      layoutAttrs: {
        span: layoutSpan,
      },
    })
    // 零售订单
    if (isRetailOrder) {
      const payList = [
        {
          type: 'desHeader',
          value: '付款信息',
          labelAttrs: {
            labelWidth: '0px',
          },
          formAttrs: {
            title: '付款信息',
            style: {
              marginBottom: '0px',
            },
          },
          layoutAttrs: {
            span: 24,
            // class: 'mt10'
          },
        },
        {
          type: 'radio',
          key: 'is_pay',
          value: 0,
          labelAttrs: {
            label: '是否已付款',
          },
          formAttrs: {
            class: 'w',
          },
          options: objToArrRadio(YES_NO),
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'select',
          key: 'currency',
          value: defaultCurrency,
          labelAttrs: {
            label: '货币币种',
          },
          formAttrs: {
            placeholder: '请选择币种',
            class: 'w',
          },
          options: objToArr(supportCurrency, false),
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'select',
          key: 'pay_type',
          value: 5,
          labelAttrs: {
            label: '付款方式',
          },
          formAttrs: {
            placeholder: '请选择付款方式',
            clearable: true,
            class: 'w',
          },
          options: objToArr(PAY_TYPE),
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'cascaderServer',
          key: 'channel_id',
          value: '',
          labelAttrs: {
            label: '客户来源',
          },
          formAttrs: {
            placeholder: '请选择客户来源',
            clearable: true,
            filterable: true,
            multiple: false,
            'show-all-levels': true,
            listApi: '/channel/tree',
            class: 'w',
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: false,
              multiple: false,
            },
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'inputNumber',
          key: 'bill_amount',
          value: null,
          labelAttrs: {
            label: '付款金额',
            rules: [
              {
                required: true,
                message: '付款金额',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            placeholder: '请输入付款金额',
            clearable: true,
            class: 'wi',
          },
          layoutAttrs: {
            span: layoutSpan,
          },
          condition: (model) => {
            return model.is_pay === 1
          },
        },
        {
          type: 'datePicker',
          key: 'bill_date',
          value: dayjs().format('YYYY-MM-DD'),
          labelAttrs: {
            label: '付款日期',
            rules: [
              {
                required: true,
                message: '请选择付款日期',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'date',
            'value-format': 'YYYY-MM-DD',
            placeholder: '请选择付款日期',
            class: 'w',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
          condition: (model) => {
            return model.is_pay === 1
          },
        },
        {
          type: 'input',
          key: 'bill_trade_no',
          labelAttrs: {
            label: '流水单号',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请输入支付流水单号',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
          condition: (model) => {
            return model.is_pay === 1
          },
        },
        {
          type: 'uploadImg',
          key: 'bill_proof',
          labelAttrs: {
            label: '凭证',
          },
          formAttrs: {
            // copyImgUploade: true,
            uploadParam: { module: 'bill' },
            limitSize: 1024 * 10,
            cardSize: '100px',
          },
          layoutAttrs: {
            span: layoutSpan,
          },
          condition: (model) => {
            return model.is_pay === 1
          },
        },
      ]
      // defaultConfig.form.formList.push(...payList)
      defaultConfig.form.formList.splice(6, 5, ...payList)
    }
    defaultConfig.form.formList.push({
      type: 'slot',
      key: 'detail_list',
      name: 'productList',
      value: '',
      labelAttrs: {
        label: '产品列表',
      },
      formAttrs: {
        type: 'text',
        placeholder: '请输入名称',
        clearable: true,
      },
    })
    defaultConfig.form.beforeReq = (data) => {
      console.log('beforeReq :>> ', data)
      console.log('beforeReq :>> store', store)
      data.detail_list = handleDetailList()
      const hasDangerPrice = store.getters['sys/addProductDrawerSelect'].some(
        (item) => item.priceDanger || item.numDanger
      )
      if (hasDangerPrice) {
        ElMessage({
          showClose: true,
          message: '您的报价产品中有低于最低售价或最小起订量的产品，请检查！',
          type: 'error',
        })

        return true
      }
      // 零售订单
      if (isRetailOrder) {
        if (!data.is_pay) {
          deleteKey(data, [
            'bill_amount',
            'bill_date',
            'bill_trade_no',
            'bill_proof',
          ])
        }
        // 直接打印快递单
        if (type === 'retaiOrderPrint') {
          data.__express = 'kuaibao'
        }
        data.__retail = 1
        delete data.is_pay
      }
      if (type === 'furniture') {
        // 家具订单
        data.__retail = 1
      }
      return data
    }
    defaultConfig.footer.splice(1, 0, {
      action: 'confirmAdd',
      title: '保存并新增',
      attrs: {
        type: 'primary',
      },
    })
  }

  // 编辑
  if (type === 'edit' || type === 'editFurniture') {
    defaultConfig.form.formList.push({
      type: 'cascaderServer',
      key: 'channel_id',
      value: 0,
      labelAttrs: {
        label: type === 'furniture' ? '销售点' : '客户来源',
      },
      formAttrs: {
        placeholder: '请选择客户来源',
        clearable: true,
        filterable: true,
        multiple: false,
        'show-all-levels': true,
        listApi: '/channel/tree',
        class: 'w',
        props: {
          label: 'title',
          value: 'id',
          checkStrictly: false,
          multiple: false,
        },
      },
    })
  }
  defaultConfig.form.formList.push({
    type: 'input',
    key: 'admin_remark',
    value: '',
    labelAttrs: {
      label: '备注',
    },
    formAttrs: {
      type: 'textarea',
      clearable: true,
      disabled: false,
      placeholder: '',
    },
  })
  // 零售订单增加运费信息填写
  if (isRetailOrder) {
    const taxFreight = [
      {
        type: 'slot',
        key: 'is_contain_tax',
        value: 0,
      },
      {
        type: 'slot',
        key: 'tax_rate',
        value: 0,
      },
      {
        type: 'slot',
        key: 'is_freight',
        value: 0,
      },
      {
        type: 'slot',
        key: 'freight_amount',
        value: 0,
      },
      {
        type: 'slot',
        key: 'showTaxFreight',
        value: 1,
      },
    ]
    defaultConfig.form.formList.push(...taxFreight)
  }
  return defaultConfig
}

/**
 * 订单退货弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function saleOrderRefundDialog(MODULE = '', type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    title: '订单退/换货',
    customTitle: true,
    dialog: {
      width: '800px',
      top: '8vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        // add: `/${MODULE}/add`,
        update: `/${MODULE}/refund`,
        refreshDraw: true, // 刷新弹窗
      },
      formListKey: true, // 参数是formList的key
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '100px',
        ...dynamicsFormAttrs,
      },
      formList: [
        {
          type: 'inputNumber',
          key: 'refund_amount',
          labelAttrs: {
            label: '退款金额',
            rules: [
              {
                required: true,
                message: '请输入退款金额',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            class: 'w',
            tips: '退款金额为0表示只退货',
            tipsCls: 'cy fs12 lh1 ml10 mt4',
            placeholder: '请输入退款金额',
            clearable: true,
            style: {
              width: '300px',
            },
          },
        },
        {
          type: 'select',
          key: 'refund_pay_type',
          value: objToArr(PAY_TYPE).pop().value,
          labelAttrs: {
            label: '退款方式',
          },
          formAttrs: {
            placeholder: '请选择退款方式',
            class: 'w300',
          },
          options: objToArr(PAY_TYPE),
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'datePicker',
          key: 'refund_plan_date',
          value: dayjs().format('YYYY-MM-DD'),
          labelAttrs: {
            label: '退款日期',
            rules: [
              {
                required: true,
                message: '请选择退款日期',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            'value-format': 'YYYY-MM-DD',
            placeholder: '请选择退款日期',
            clearable: true,
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'slot',
          key: 'refund_list',
          name: 'refundProductlist',
          value: [],
          labelAttrs: {},
          formAttrs: {
            tips: '退货商品为空表示只退款',
            tipsCls: 'cd fs12 lh1 ml10',
          },
        },
        {
          type: 'inputNumber',
          key: 'supplement_amount',
          labelAttrs: {
            label: '补差金额',
            rules: [
              {
                required: true,
                message: '请输入补差金额',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            class: 'w',
            tips: '补差金额为0表示只换货',
            tipsCls: 'cy fs12 lh1 ml10 mt4',
            placeholder: '请输入补差金额',
            clearable: true,
            style: {
              width: '300px',
            },
          },
        },
        {
          type: 'select',
          key: 'supplement_pay_type',
          value: objToArr(PAY_TYPE).pop().value,
          labelAttrs: {
            label: '补差方式',
          },
          formAttrs: {
            placeholder: '请选择补差方式',
            class: 'w300',
          },
          options: objToArr(PAY_TYPE),
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'datePicker',
          key: 'supplement_plan_date',
          value: dayjs().format('YYYY-MM-DD'),
          labelAttrs: {
            label: '补差日期',
            rules: [
              {
                required: true,
                message: '请选择补差计划日期',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            'value-format': 'YYYY-MM-DD',
            placeholder: '请选择补差计划日期',
            clearable: true,
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'slot',
          key: 'barter_list',
          name: 'barterProductlist',
          value: [],
          labelAttrs: {},
          formAttrs: {
            tips: '换货商品为空表示只补差金额',
            tipsCls: 'cd fs12 lh1 ml10',
          },
        },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '退/换货备注',
            customTitle: true,
          },
          formAttrs: {
            type: 'textarea',
            placeholder: '请输入退货备注',
            clearable: true,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 客户新增弹窗
 * @param {*} type  // 类型判断
 * @returns
 */
export function customerAddDialog(
  type = '',
  MODULE = MODULE_CUSTOMER,
  isPublic = ''
) {
  console.log('type :>> ', type)

  const isClue = type === 'clue' || type === 'clueEdit' // 线索
  let layoutSpan = 12
  const defaultConfig = {
    title: '客户',
    dialog: {
      width: '800px',
      top: isClue ? '15vh' : '4vh',
      appendToBody: true,
      destroyOnClose: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        update: `/${MODULE}/edit`,
        detail: `/${MODULE}/detail`,
        bindModule: MODULE,
      },
      formListKey: true, // 参数是formList的key
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '100px',
        ...dynamicsFormAttrs,
      },
      beforeCreate: {
        url: `${MODULE}/code`,
        callback: (res, model) => {
          model.code = res.code
        },
      },
      formList: [
        {
          type: 'input',
          key: 'title',
          value: '',
          labelAttrs: {
            label: isClue ? '线索名称' : '客户名称',
            rules: [
              {
                required: true,
                message: `请输入${isClue ? '线索' : '客户'}名称`,
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: `请输入${isClue ? '线索' : '客户'}名称`,
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
          onChange: (val, model) => {
            if (!model.id) {
              checkCustomerInfo({ title: val }, MODULE)
            }
          },
        },
        {
          type: 'input',
          key: 'contact',
          labelAttrs: {
            label: '联系人',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入联系人',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'input',
          key: 'telephone',
          labelAttrs: {
            label: '手机号',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入客户手机号',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
          onChange: (val, model) => {
            if (!model.id) {
              checkCustomerInfo({ telephone: val }, MODULE)
            }
          },
        },
        {
          type: 'cascaderServer',
          key: 'area_id',
          value: 0,
          labelAttrs: {
            label: '国家地区',
          },
          formAttrs: {
            placeholder: '请选择地区',
            clearable: true,
            filterable: true,
            searchKey: 'title',
            'show-all-levels': true,
            listApi: '/area/tree',
            class: 'w',
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: true,
            },
            // options: localAreaTree
          },
          layoutAttrs: {
            span: layoutSpan,
          },
          onChange: handleSetCustomerCode,
        },
        {
          type: 'cascaderServer',
          key: 'channel_id',
          value: 0,
          labelAttrs: {
            label: isClue ? '线索来源' : '客户来源',
          },
          formAttrs: {
            placeholder: '请选择来源',
            clearable: true,
            filterable: true,
            multiple: false,
            'show-all-levels': true,
            listApi: '/channel/tree',
            class: 'w',
            showAdd: true,
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: false,
              multiple: false,
            },
          },
          action: {
            type: 'editBox',
            editBoxName: 'addChannelBox',
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'input',
          key: 'code',
          value: '',
          labelAttrs: {
            label: '客户编码',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入客户编码',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
          condition: () => {
            return !isClue
          },
        },
        {
          type: 'datePicker',
          key: 'birthday',
          value: '',
          labelAttrs: {
            label: '生日',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请选择日期',
            clearable: true,
            valueFormat: 'YYYY-MM-DD',
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'input',
          key: 'religion',
          value: '',
          labelAttrs: {
            label: '宗教',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入宗教',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'input',
          key: 'interest',
          value: '',
          labelAttrs: {
            label: '感兴趣产品',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入客户感兴趣产品',
            clearable: true,
          },
          layoutAttrs: {
            span: 24,
          },
          condition: () => {
            return !isClue
          },
        },
        {
          type: 'selectServer',
          key: 'label_ids',
          labelAttrs: {
            label: '客户标签',
          },
          formAttrs: {
            class: 'mw150 w',
            clearable: true,
            filterable: true,
            multiple: true,
            valueType: 'number',
            listApi: `/label/index`,
            searchKey: 'title',
            placeholder: '请选择标签',
            exParams: {
              type: ['common', 'customer'],
            },
            autoAdd: true,
            addApi: '/label/add',
            addParams: {
              type: 'customer',
            },
          },
          layoutAttrs: {},
        },
        {
          type: 'selectServer',
          key: 'customer_trade_id',
          titleKey: 'customer_trade.title',
          labelAttrs: {
            label: '客户行业',
            // required: true,
          },
          formAttrs: {
            clearable: false,
            filterable: true,
            class: 'w',
            listApi: `/customer-trade/index`,
            searchKey: 'title',
            placeholder: '请选择客户行业',
            exParams: { status: 1 },
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'selectServer',
          key: 'customer_level_id',
          titleKey: 'customer_level.title',
          labelAttrs: {
            label: '客户等级',
            // required: true,
          },
          formAttrs: {
            clearable: false,
            filterable: true,
            class: 'w',
            listApi: `/customer-level/index`,
            searchKey: 'title',
            placeholder: '请选择客户等级',
            exParams: { status: 1 },
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'radio',
          key: 'is_payment_period',
          value: 0,
          labelAttrs: {
            label: '账期客户',
          },
          formAttrs: {
            class: 'w',
          },
          options: objToArrRadio(YES_NO),
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'radio',
          key: 'is_pivotal',
          value: 0,
          labelAttrs: {
            label: '是否KA',
          },
          formAttrs: {
            class: 'w',
          },
          options: objToArrRadio(YES_NO),
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: isClue ? 'slot' : 'radio',
          key: 'is_public',
          value: isPublic === 'public' ? 1 : 0,
          labelAttrs: {
            label: '客户类型',
          },
          formAttrs: {
            class: 'w',
          },
          options: objToArrRadio(CUSTOMER_PUBLIC),
          layoutAttrs: {
            span: isClue ? 0 : layoutSpan,
          },
        },
        {
          type: 'input',
          key: 'website',
          labelAttrs: {
            label: '网址',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入客户网址',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'input',
          key: 'whats_app',
          labelAttrs: {
            label: 'Whats App',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入客户WhatsApp',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'input',
          key: 'email',
          labelAttrs: {
            label: '邮箱',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入邮箱地址',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'input',
          key: 'wechat',
          labelAttrs: {
            label: '微信',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入微信',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'uploadImg',
          key: 'avatar',
          labelAttrs: {
            label: '头像',
          },
          formAttrs: {
            uploadParam: { module: 'customer' },
            copyImgUploade: true,
            tips: '建议尺寸比例',
            tipsCls: 'cd fs12 lh1 mt5',
            limitSize: 1024 * 10,
            afterBtnText: '从素材库选择',
          },
        },
        {
          type: 'uploadImg',
          key: 'images',
          labelAttrs: {
            label: '相册',
          },
          formAttrs: {
            multiple: true,
            uploadParam: { module: 'customer' },
            copyImgUploade: true,
            limitSize: 1024 * 10,
            afterBtnText: '从素材库选择',
          },
        },
        {
          type: 'uploadVideo',
          key: 'video',
          labelAttrs: {
            label: '视频',
          },
          formAttrs: {
            multiple: true,
            uploadParam: { module: 'product' },
            afterBtnText: '从素材库选择',
            isCosSdk: true,
            uploadUrl: cosSdkConfig,
            // uploadUrl: uploadFileUrl,
            module: 'customer',
          },
        },
        {
          type: 'input',
          key: 'address',
          labelAttrs: {
            label: '地址',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入客户地址',
            clearable: true,
          },
        },
        {
          type: 'input',
          key: 'remark',
          labelAttrs: {
            label: '备注',
          },
          formAttrs: {
            type: 'textarea',
            placeholder: '请输入备注',
            clearable: true,
          },
        },
        // {
        //   type: 'switch',
        //   key: 'is_deal',
        //   labelAttrs: {
        //     label: '是否成交',
        //   },
        // },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '确定',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  // 家具
  if (type === 'furniture') {
    defaultConfig.form.formList = [
      {
        type: 'input',
        key: 'title',
        value: '',
        labelAttrs: {
          label: '客户名称',
          rules: [
            {
              required: true,
              message: '请输入客户名称',
              trigger: 'blur',
            },
          ],
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入客户名称',
          clearable: true,
        },
        layoutAttrs: {
          span: layoutSpan,
        },
      },
      {
        type: 'cascaderServer',
        key: 'channel_id',
        value: 0,
        labelAttrs: {
          label: '客户来源',
        },
        formAttrs: {
          placeholder: '请选择客户来源',
          clearable: true,
          filterable: true,
          multiple: false,
          'show-all-levels': true,
          listApi: '/channel/tree',
          class: 'w',
          showAdd: true,
          props: {
            label: 'title',
            value: 'id',
            checkStrictly: false,
            multiple: false,
          },
        },
        action: {
          type: 'editBox',
          editBoxName: 'addChannelBox',
        },
        layoutAttrs: {
          span: layoutSpan,
        },
      },
      {
        type: 'input',
        key: 'telephone',
        labelAttrs: {
          label: '手机号',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入客户手机号',
          clearable: true,
        },
        layoutAttrs: {
          span: layoutSpan,
        },
      },
      {
        type: 'input',
        key: 'mobile',
        labelAttrs: {
          label: '电话',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入客户电话',
          clearable: true,
        },
        layoutAttrs: {
          span: layoutSpan,
        },
      },
      {
        type: 'select',
        key: 'district',
        labelAttrs: {
          label: '地区',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入客户地区',
          clearable: true,
        },
        options: objToArr(MHL_HONGKONG_DISTRICT, false),
        layoutAttrs: {
          span: layoutSpan,
        },
      },
      {
        type: 'input',
        key: 'address',
        labelAttrs: {
          label: '地址',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入客户地址',
          clearable: true,
        },
      },
      {
        type: 'input',
        key: 'remark',
        labelAttrs: {
          label: '备注',
        },
        formAttrs: {
          type: 'textarea',
          placeholder: '请输入备注',
          clearable: true,
        },
      },
    ]
  }
  // WMS
  if (type === 'wms') {
    const insertIndex = getFormListIndex(
      'customer_trade_id',
      defaultConfig.form.formList
    )
    defaultConfig.form.formList.splice(
      insertIndex + 1,
      0,
      {
        type: 'radio',
        key: 'type',
        value: 1,
        labelAttrs: {
          label: '采购类型',
        },
        formAttrs: {
          class: 'w',
        },
        options: objToArrRadio(CUSTOMER_TYPE),
        layoutAttrs: {
          span: layoutSpan,
        },
      },
      {
        type: 'inputNumber',
        key: 'discount_rate',
        value: 0,
        labelAttrs: {
          label: '客户折扣',
        },
        formAttrs: {
          class: 'w',
          placeholder: '请输入客户折扣百分比',
          clearable: true,
          max: 100,
        },
        sufUnit: '%',
        layoutAttrs: {
          span: 11,
        },
      },
      {
        type: 'tips',
        key: '',
        value: '',
        labelAttrs: {
          label: '%',
          labelWidth: '15px',
        },
        formAttrs: {
          placeholder: '请输入比分比',
        },
        layoutAttrs: {
          span: 1,
        },
      }
    )
    defaultConfig.form.formList.push({
      type: 'switch',
      key: 'status',
      value: 1,
      labelAttrs: {
        label: '使用状态',
      },
    })
  }
  // 线索
  if (isClue) {
    defaultConfig.title = '线索'
    defaultConfig.form.beforeCreate = null
    const common = defaultConfig.form.formList.slice(0, 6)
    defaultConfig.form.formList = [
      ...common,
      {
        type: 'radio',
        key: 'stage',
        value: 0,
        labelAttrs: {
          label: '线索阶段',
        },
        formAttrs: {
          clearable: false,
          filterable: true,
          searchKey: 'title',
          placeholder: '请选择线索阶段',
        },
        options: objToArrRadio(BUSINESS_OPPORTUNITY_STAGE),
      },
      {
        type: 'input',
        key: 'remark',
        labelAttrs: {
          label: '备注',
        },
        formAttrs: {
          type: 'textarea',
          placeholder: '请输入备注',
          clearable: true,
        },
      },
    ]
  }
  return defaultConfig
}

/**
 * 客户发票抬头新增弹窗
 * @param {*} type  // 类型判断
 * @param {*} params  // 参数
 * @param {*} info  // 信息
 * @returns
 */
export function customerInvoiceAddDialog(
  MODULE = '',
  type = '',
  params = {},
  info = {}
) {
  console.log('type :>> ', type)
  const { supportCurrency, defaultCurrency } = handleSupportCurrency()
  const defaultConfig = {
    title: params?.type === 'supplierList' ? '账户信息' : '发票抬头',
    dialog: {
      width: '700px',
      top: '10vh',
      appendToBody: true,
      destroyOnClose: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        update: `/${MODULE}/edit`,
        bindModule: MODULE,
      },
      formListKey: true, // 参数是formList的key
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '100px',
        ...dynamicsFormAttrs,
      },
      formList: [
        {
          // type: 'selectServer',
          // key: 'customer_id',
          type: params?.type ? 'slot' : 'selectServer',
          key: params?.type === 'supplierList' ? 'supplier_id' : 'customer_id',
          value: info?.[params?.valKey] || '',
          labelAttrs: {
            label: '关联客户',
            // required: true,
            rules: [
              {
                required: true,
                message: '请选择客户',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            class: 'w',
            clearable: false,
            filterable: true,
            listApi: `/customer/index`,
            searchKey: 'title',
            placeholder: '请选择客户',
          },
        },
        {
          type: 'input',
          key: 'company',
          value: '',
          labelAttrs: {
            label: '公司名称',
            rules: [
              {
                required: true,
                message: '请输入公司名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入公司名称',
            clearable: true,
          },
          layoutAttrs: {
            span: 24,
          },
        },
        {
          type: 'input',
          key: 'tax_no',
          value: '',
          labelAttrs: {
            label: '税号',
            rules: [
              {
                required: true,
                message: '请输入公司税号',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入公司税号',
            clearable: true,
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'select',
          key: 'currency',
          value: defaultCurrency,
          labelAttrs: {
            label: '货币币种',
          },
          formAttrs: {
            class: 'w220',
            searchKey: 'title',
            placeholder: '请选择货币',
          },
          options: objToArr(supportCurrency, false),
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'input',
          key: 'bank',
          labelAttrs: {
            label: '开户银行',
            required: true,
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入开户银行全称',
            clearable: true,
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'input',
          key: 'bank_account_no',
          value: null,
          labelAttrs: {
            label: '银行卡号',
            rules: [
              {
                required: true,
                message: '请输入银行卡号',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            class: 'wi',
            placeholder: '请输入银行卡号',
            clearable: true,
            controls: false,
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'input',
          key: 'telephone',
          labelAttrs: {
            label: '电话',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入电话',
            clearable: true,
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'input',
          key: 'email',
          labelAttrs: {
            label: '邮箱',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入邮箱地址',
            clearable: true,
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'input',
          key: 'address',
          labelAttrs: {
            label: '地址',
            rules: [
              {
                required: true,
                message: '请输入地址',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'textarea',
            placeholder: '请输入地址',
            clearable: true,
          },
        },
        {
          type: 'input',
          key: 'admin_remark',
          labelAttrs: {
            label: '备注',
          },
          formAttrs: {
            type: 'textarea',
            placeholder: '请输入备注',
            clearable: true,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '确定',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  return defaultConfig
}

/**
 * 客户收货地址新增弹窗
 * @param {*} type  // 类型判断
 * @param {*} params  // 参数
 * @param {*} info  // 信息
 * @returns
 */
export function customerAddressAddDialog(
  MODULE = 'customer-address',
  type = '',
  params = {},
  info = {}
) {
  console.log('type :>> ', type)
  console.log('params :>> ', params)
  const defaultConfig = {
    title: '收货地址',
    dialog: {
      width: '700px',
      top: '10vh',
      appendToBody: true,
      destroyOnClose: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        update: `/${MODULE}/edit`,
        bindModule: MODULE,
      },
      formListKey: true, // 参数是formList的key
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '100px',
        ...dynamicsFormAttrs,
      },
      formList: [
        {
          type: info.id ? 'slot' : 'selectServer',
          key: 'customer_id',
          value: info.id || '',
          labelAttrs: {
            label: '关联客户',
            // required: true,
            rules: [
              {
                required: true,
                message: '请选择客户',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            class: 'w',
            clearable: false,
            filterable: true,
            listApi: `/customer/index`,
            searchKey: 'title',
            placeholder: '请选择客户',
            showAdd: true,
          },
        },
        {
          type: 'input',
          key: 'recipients',
          value: '',
          labelAttrs: {
            label: '收件人',
            rules: [
              {
                required: true,
                message: '请输入收件人',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入收件人',
            clearable: true,
          },
          layoutAttrs: {
            span: 24,
          },
        },
        {
          type: 'input',
          key: 'phone',
          value: '',
          labelAttrs: {
            label: '收件电话',
            rules: [
              {
                required: true,
                message: '请输入收件电话',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入收件电话',
            clearable: true,
          },
          layoutAttrs: {},
        },
        {
          type: 'input',
          key: 'address',
          labelAttrs: {
            label: '收件地址',
            rules: [
              {
                required: true,
                message: '请输入收件地址',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'textarea',
            placeholder: '请输入收件地址',
            clearable: true,
          },
        },
        {
          type: 'input',
          key: 'admin_remark',
          labelAttrs: {
            label: '备注',
          },
          formAttrs: {
            type: 'textarea',
            placeholder: '请输入备注',
            clearable: true,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '确定',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  return defaultConfig
}
/**
 * 商机新增弹窗
 * @param {*} type  // 类型判断
 * @returns
 */
export function businessAddDialog(params = {}, info = {}, type = '') {
  console.log('params :>> ', params)
  console.log('info :>> ', info)
  const { supportCurrency, defaultCurrency } = handleSupportCurrency()

  let defaultConfig = {
    title: '商机',
    dialog: {
      width: '700px',
      top: '15vh',
      appendToBody: true,
      destroyOnClose: true,
    },
    form: {
      api: {
        add: `/${MODULE_BUSINESS}/add`,
        update: `/${MODULE_BUSINESS}/edit`,
        bindModule: MODULE_BUSINESS,
      },
      formListKey: true, // 参数是formList的key
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '100px',
        ...dynamicsFormAttrs,
      },
      formList: [
        {
          // type: 'selectServer',
          // key: 'customer_id',
          type: params?.type ? 'slot' : 'selectServer',
          key: 'customer_id',
          titleKey: 'customer_info.title',
          value: info?.[params?.valKey] || '',
          labelAttrs: {
            label: '关联客户',
            // required: true,
            rules: [
              {
                required: true,
                message: '请选择客户',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            class: 'w',
            clearable: false,
            filterable: true,
            listApi: `/customer/index`,
            searchKey: 'title',
            placeholder: '请选择客户',
            emitSelect: true,
            showAdd: true,
          },
          action: {
            type: 'editBox',
            editBoxName: 'addCoustomBox',
          },
          onChange: (item, model) => {
            model.title = `${item.title}-${t('商机')}${dayjs().format(
              'YYYYMMDD'
            )}`
          },
        },
        {
          type: 'input',
          key: 'title',
          value: info?.title || '',
          labelAttrs: {
            label: '商机名称',
            rules: [
              {
                required: true,
                message: '请输入商机名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入商机名称',
            clearable: true,
          },
        },
        {
          type: 'radio',
          key: 'stage',
          value: 0,
          labelAttrs: {
            label: '商机阶段',
          },
          formAttrs: {
            clearable: false,
            filterable: true,
            searchKey: 'title',
            placeholder: '请选择商机阶段',
          },
          options: objToArrRadio(BUSINESS_OPPORTUNITY_STAGE),
        },
        {
          type: 'inputNumber',
          key: 'amount',
          labelAttrs: {
            label: '预计金额',
          },
          formAttrs: {
            class: 'w220',
            placeholder: '请输入预计成交金额',
            clearable: true,
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'select',
          key: 'currency',
          value: defaultCurrency,
          labelAttrs: {
            label: '货币币种',
          },
          formAttrs: {
            class: 'w220',
            searchKey: 'title',
            placeholder: '请选择货币',
          },
          options: objToArr(supportCurrency, false),
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'datePicker',
          key: 'expected_completion_time',
          value: dayjs().format('YYYY-MM-DD'),
          labelAttrs: {
            label: '预计成交',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入预计成交日期',
            clearable: true,
            valueFormat: 'YYYY-MM-DD',
          },
        },
        {
          type: 'input',
          key: 'remark',
          labelAttrs: {
            label: '备注',
          },
          formAttrs: {
            type: 'textarea',
            placeholder: '请输入备注',
            clearable: true,
          },
        },
        // {
        //   type: 'switch',
        //   key: 'is_deal',
        //   labelAttrs: {
        //     label: '是否成交',
        //   },
        // },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '确定',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  // 编辑
  if (type === 'edit') {
    defaultConfig.form.formList.splice(2, 1)
  }
  return defaultConfig
}

/**
 * 绩效规则新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function performanceRuleAddDialog(MODULE = '', type = '') {
  console.log('type :>> ', type)
  const defaultConfig = {
    api: {
      // add: `/${MODULE}/add`,
      // update: `/${MODULE}/edit`,
      bindModule: MODULE,
    },
    formListKey: true, // 参数是formList的key
    formAttrs: {
      labelPosition: 'right',
      labelWidth: '100px',
      ...dynamicsFormAttrs,
    },
    formList: [
      {
        type: 'input',
        key: 'title',
        value: '',
        labelAttrs: {
          label: '规则名称',
          rules: [
            {
              required: true,
              message: '请输入规则名称',
              trigger: 'blur',
            },
          ],
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入规则名称',
          clearable: true,
        },
      },
      {
        type: 'radio',
        key: 'type',
        value: 1,
        labelAttrs: {
          label: '绩效类型',
          rules: [
            {
              required: true,
              message: '请选择绩效类型',
              trigger: 'change',
            },
          ],
        },
        formAttrs: {
          class: 'w',
          clearable: false,
          filterable: true,
          placeholder: '请选择绩效类型',
        },
        options: objToArrRadio(PERFORMANCE_TYPE),
      },
      {
        type: 'slot',
        key: 'commission_mode',
        name: 'commissionMode',
        value: 1,
        labelAttrs: {},
        formAttrs: {},
      },
      {
        type: 'slot',
        key: 'formula_list',
        value: [],
        name: 'rulesList',
        labelAttrs: {},
        formAttrs: {},
        condition: (model) => {
          return model.type != 3
        },
      },
      {
        type: 'inputNumber',
        key: 'rank',
        labelAttrs: {
          label: '优先级',
        },
        formAttrs: {
          placeholder: '请输入优先级',
          class: 'wi',
          clearable: true,
        },
      },
    ],
  }
  return defaultConfig
}

/**
 * 供应商新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function supplierAddDialog(MODULE = '', type = '') {
  console.log('type :>> ', type)
  const defaultConfig = {
    title: '供应商',
    dialog: {
      width: '800px',
      top: '8vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      // beforeReq: (data) => {
      //   console.log('beforeReq :>> ', data)
      //   if (data?.tax_rate) {
      //     data.tax_rate = data.tax_rate / 100
      //   }
      //   return data
      // },
      formListKey: true,
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '80px',
        ...dynamicsFormAttrs,
      },
      formList: [
        {
          type: 'input',
          key: 'title',
          value: '',
          labelAttrs: {
            label: '名称',
            rules: [
              {
                required: true,
                message: '请输入名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入名称',
            clearable: true,
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 12,
            lg: 12,
            xl: 12,
          },
          onChange: (val, model) => {
            if (!model.id) {
              checkSuppilerInfo({ title: val })
            }
          },
        },
        {
          type: 'input',
          key: 'code',
          value: '',
          labelAttrs: {
            label: '编码',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            placeholder: '请输入编码',
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 12,
            lg: 12,
            xl: 12,
          },
        },
        {
          type: 'selectServer',
          key: 'category_id',
          labelAttrs: {
            label: '分类',
          },
          formAttrs: {
            listApi: `/supplier-category/index`,
            class: 'w',
            valueType: 'number',
            multiple: false,
            placeholder: '请选择分类',
            searchKey: 'search',
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 12,
            lg: 12,
            xl: 12,
          },
        },
        {
          type: 'cascaderServer',
          key: 'area_id',
          value: 0,
          labelAttrs: {
            label: '地区',
          },
          formAttrs: {
            placeholder: '请选择地区',
            clearable: true,
            filterable: true,
            multiple: false,
            'show-all-levels': true,
            listApi: '/area/tree',
            class: 'w',
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: false,
              multiple: false,
            },
            // options: localAreaTree
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 12,
            lg: 12,
            xl: 12,
          },
        },
        {
          type: 'select',
          key: 'online',
          value: 0,
          labelAttrs: {
            label: '渠道类型',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请选择渠道类型',
          },
          options: objToArr(SUPPLIER_ONLINE),
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 12,
            lg: 12,
            xl: 12,
          },
        },
        {
          type: 'select',
          key: 'type',
          value: '',
          labelAttrs: {
            label: '渠道分类',
          },
          formAttrs: {
            class: 'w',
            clearable: true,
            placeholder: '请选择渠道分类',
          },
          options: objToArr(SUPPLIER_TYPE),
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 12,
            lg: 12,
            xl: 12,
          },
        },
        {
          type: 'selectServer',
          key: 'label_ids',
          labelAttrs: {
            label: '供应商标签',
          },
          formAttrs: {
            class: 'mw150 w',
            clearable: true,
            filterable: true,
            multiple: true,
            valueType: 'number',
            listApi: `/label/index`,
            searchKey: 'title',
            placeholder: '请选择标签',
            exParams: {
              type: ['common', 'supplier'],
            },
            autoAdd: true,
            addApi: '/label/add',
            addParams: {
              type: 'supplier',
            },
          },
          layoutAttrs: {},
        },
        {
          type: 'baseRate',
          key: 'quality_rating',
          value: 0,
          labelAttrs: {
            label: '产品质量',
          },
          formAttrs: {},
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 8,
            lg: 8,
            xl: 8,
          },
        },
        {
          type: 'baseRate',
          key: 'delivery_rating',
          value: 0,
          labelAttrs: {
            label: '发货速度',
          },
          formAttrs: {},
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 8,
            lg: 8,
            xl: 8,
          },
        },
        {
          type: 'baseRate',
          key: 'service_rating',
          value: 0,
          labelAttrs: {
            label: '售后服务',
          },
          formAttrs: {},
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 8,
            lg: 8,
            xl: 8,
          },
        },
        {
          type: 'inputNumber',
          key: 'tax_rate',
          value: '',
          labelAttrs: {
            label: '税率',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            class: 'wi',
            placeholder: '请输入采购税率',
            precision: '2',
            max: 100,
            step: '0.1',
          },
          sufUnit: '%',
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 12,
            lg: 12,
            xl: 12,
          },
        },
        {
          type: 'radio',
          key: 'is_payment_period',
          value: 0,
          labelAttrs: {
            label: '是否账期',
          },
          formAttrs: {
            class: 'w',
          },
          options: objToArrRadio(YES_NO),
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 12,
            lg: 12,
            xl: 12,
          },
        },
        {
          type: 'input',
          key: 'address',
          value: '',
          labelAttrs: {
            label: '详细地址',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            placeholder: '请输入详细地址',
          },
        },
        {
          type: 'input',
          key: 'contact',
          value: '',
          labelAttrs: {
            label: '联系人',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            placeholder: '请输入联系人',
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 12,
            lg: 12,
            xl: 12,
          },
        },
        {
          type: 'input',
          key: 'telephone',
          value: '',
          labelAttrs: {
            label: '手机号',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            placeholder: '请输入手机号',
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 12,
            lg: 12,
            xl: 12,
          },
        },
        {
          type: 'input',
          key: 'mob',
          value: '',
          labelAttrs: {
            label: '固定电话',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            placeholder: '请输入固定电话',
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 12,
            lg: 12,
            xl: 12,
          },
        },
        {
          type: 'input',
          key: 'email',
          value: '',
          labelAttrs: {
            label: '邮箱',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            placeholder: '请输入邮箱',
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 12,
            lg: 12,
            xl: 12,
          },
        },
        {
          type: 'input',
          key: 'wechat',
          value: '',
          labelAttrs: {
            label: '微信',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            placeholder: '请输入微信',
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 12,
            lg: 12,
            xl: 12,
          },
        },
        {
          type: 'input',
          key: 'qq',
          value: '',
          labelAttrs: {
            label: 'QQ',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            placeholder: '请输入QQ',
          },
          layoutAttrs: {
            xs: 24,
            sm: 24,
            md: 12,
            lg: 12,
            xl: 12,
          },
        },
        {
          type: 'input',
          key: 'website',
          value: '',
          labelAttrs: {
            label: '网址',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            placeholder: '请输入网址',
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  // 仓库端
  if (type === 'stash') {
    const stashAddList = [
      {
        type: 'selectServer',
        key: 'express_account_ids',
        labelAttrs: {
          label: '快递账号',
        },
        formAttrs: {
          listApi: `/express-account/index`,
          class: 'w',
          valueType: 'number',
          multiple: true,
          placeholder: '请选择快递账号',
          modelValueKey: 'express_account_list',
          searchKey: 'search',
          tips: '配置>系统配置>快递账号配置 中添加',
          tipsCls: 'g9 fs12 lh1 mt5',
          defaultSelect: true,
          exParams: {},
        },
        layoutAttrs: {
          span: 12,
        },
      },
      {
        type: 'inputNumber',
        key: 'express_increment_rate',
        value: '',
        labelAttrs: {
          label: '征收比例',
          tips: '快递费用征收比例',
        },
        formAttrs: {
          min: 0,
          max: 100,
          placeholder: '请输入比例',
          class: 'wi',
          precision: 2,
        },
        sufUnit: '%',
        layoutAttrs: {
          span: 12,
        },
      },
      {
        type: 'radio',
        key: 'auto_express',
        value: 0,
        labelAttrs: {
          label: '自动下运单',
        },
        formAttrs: {
          class: 'w',
        },
        options: objToArrRadio(YES_NO),
        layoutAttrs: {
          xs: 24,
          sm: 24,
          md: 12,
          lg: 12,
          xl: 12,
        },
      },
    ]
    const insertIndex = getFormListIndex(
      'is_payment_period',
      defaultConfig.form.formList
    )
    defaultConfig.form.formList.splice(insertIndex + 1, 0, ...stashAddList)
  }
  return defaultConfig
}

/**
 * 报价订单新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function quoteAddDialog(MODULE = '', type = '', info = {}, form = '') {
  console.log('type :>> ', type)
  let layoutSpan = 24
  let hideSelect = false // 隐藏出入库选择
  if (type === 'business-opportunity') {
    hideSelect = true
  }
  if (form === 'draw') {
    layoutSpan = 12
  } else {
    layoutSpan = 24
  }
  const { supportCurrency, defaultCurrency } = handleSupportCurrency()

  let defaultConfig = {
    title: '报价单',
    dialog: {
      width: '500px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
      'lock-scroll': true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      desHeader: {
        title: '基本信息',
        // border: true,
      },
      formListKey: true, // 参数是formList的key
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '80px',
      },
      formList: [
        {
          type: type === 'business-opportunity' ? 'slot' : 'selectServer',
          key: 'customer_id',
          value: type === 'business-opportunity' ? info?.customer_id : '',
          titleKey: 'customer_info.title',
          labelAttrs: {
            label: '客户',
            rules: [{ required: true, message: '请选择客户', trigger: 'blur' }],
          },
          formAttrs: {
            class: 'w',
            listApi: 'customer/index',
            labelKey: 'title',
            searchKey: 'search',
            placeholder: '请选择客户',
            emitSelect: true,
            refKey: 'business_opportunity_id', // 关联数据key
            refSearchKey: 'customer_id', // 关联数据搜索key
            modelValueKey: 'customer_info.title',
          },
          onChange: (item, model) => {
            model.title = `${item.title}-${t('报价单')}${dayjs().format(
              'YYYYMMDD'
            )}`
          },
          layoutAttrs: {
            span: hideSelect ? 0 : layoutSpan,
          },
        },
        {
          type: 'input',
          key: 'title',
          value: '',
          labelAttrs: {
            label: '名称',
            rules: [{ required: true, message: '请输入名称', trigger: 'blur' }],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入名称',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: type === 'business-opportunity' ? 'slot' : 'selectServer',
          key: 'business_opportunity_id',
          value: type === 'business-opportunity' ? info?.id : '',
          labelAttrs: {
            label: '关联商机',
          },
          formAttrs: {
            class: 'w',
            listApi: 'business-opportunity/index',
            labelKey: 'title',
            searchKey: 'search',
            placeholder: '请选择商机',
          },
          layoutAttrs: {
            span: hideSelect ? 0 : layoutSpan,
          },
        },
        {
          type: 'select',
          key: 'currency',
          value: defaultCurrency,
          labelAttrs: {
            label: '币种',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请选择币种',
          },
          options: objToArr(supportCurrency, false),
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '备注',
          },
          formAttrs: {
            type: 'textarea',
            placeholder: '请输入备注',
            clearable: true,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  // 抽屉添加
  if (form === 'draw') {
    // 添加产品
    defaultConfig.form.formList.splice(5, 0, {
      type: 'slot',
      key: 'detail_list',
      name: 'productList',
      value: '',
      labelAttrs: {
        label: '产品列表',
      },
      formAttrs: {
        type: 'text',
        placeholder: '请输入名称',
        clearable: true,
      },
    })
    defaultConfig.form.beforeReq = (data) => {
      console.log('beforeReq :>> ', data)
      console.log('beforeReq :>> store', store)
      data.detail_list = handleDetailList()
      const hasDangerPrice = store.getters['sys/addProductDrawerSelect'].some(
        (item) => item.priceDanger || item.numDanger
      )
      if (hasDangerPrice) {
        ElMessage({
          showClose: true,
          message: '您的报价产品中有低于最低售价或最小起订量的产品，请检查！',
          type: 'error',
        })

        return true
      }
      return data
    }
    defaultConfig.footer.splice(1, 0, {
      action: 'confirmAdd',
      title: '保存并新增',
      attrs: {
        type: 'primary',
      },
    })
  }
  return defaultConfig
}

/**
 * 报价订单生成订单
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function quoteToOrderDialog(MODULE = '', type = '', info = {}) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  const router = useRouter()
  let defaultConfig = {
    title: '生成订单',
    customTitle: true,
    dialog: {
      width: '500px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
      'lock-scroll': true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        update: `/${MODULE}/finalise`,
        updateCallback: () => {
          router.push(SALEORDER_PATH)
          store.dispatch('sys/handleCloseDrawer')
        },
      },
      formListKey: true, // 参数是formList的key
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '90px',
      },
      formList: [
        {
          type: 'datePicker',
          key: 'delivery_date',
          value: dayjs().add(3, 'day').format('YYYY-MM-DD'),
          labelAttrs: {
            label: '交付日期',
            tips: '订单交付/发货日期',
            rules: [
              {
                required: true,
                message: '请选择交付日期',
                trigger: 'change',
              },
            ],
          },
          formAttrs: {
            class: 'w',
            placeholder: '请选择交付日期',
            valueFormat: 'YYYY-MM-DD',
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 客诉新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function customerFeedbackAddDialog(
  MODULE = '',
  type = '',
  info = {},
  form = ''
) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  let layoutSpan = 24
  let hideSelect = false // 隐藏出入库选择
  if (type === 'business-opportunity') {
    hideSelect = true
  }
  if (form === 'draw') {
    layoutSpan = 12
  } else {
    layoutSpan = 24
  }
  let defaultConfig = {
    title: '客诉',
    dialog: {
      width: '900px',
      top: '7vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
      'lock-scroll': true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      beforeReq: (data) => {
        const typeObj = {
          product: 'product_id',
          'sale-order': 'sale_order_id',
          supplier: 'supplier_id',
        }
        const refKey = typeObj[data.type]
        data[refKey] = data.ref_id
        delete data.ref_id
        return data
      },
      desHeader: {
        title: '基本信息',
        // border: true,
      },
      formListKey: true, // 参数是formList的key
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '100px',
        ...dynamicsFormAttrs,
      },
      formList: [
        // {
        //   type: 'input',
        //   key: 'title',
        //   value: '',
        //   labelAttrs: {
        //     label: '客诉标题',
        //     rules: [{ required: true, message: '请输入客诉标题', trigger: 'blur' }],
        //   },
        //   formAttrs: {
        //     type: 'text',
        //     placeholder: '请输入客诉标题',
        //     clearable: true,
        //   },
        //   layoutAttrs: {
        //     span: layoutSpan,
        //   },
        // },
        {
          type: type === 'sale-order' ? 'slot' : 'selectServer',
          key: 'customer_id',
          value: type === 'sale-order' ? info.customer_id : '',
          labelAttrs: {
            label: '客户',
            rules: [{ required: true, message: '请选择客户', trigger: 'blur' }],
          },
          formAttrs: {
            class: 'w',
            listApi: 'customer/index',
            labelKey: 'title',
            searchKey: 'search',
            placeholder: '请选择客户',
          },
          layoutAttrs: {
            span: hideSelect ? 0 : layoutSpan,
          },
        },
        {
          type: type ? 'slot' : 'radio',
          key: 'type',
          value: type || 'product',
          labelAttrs: {
            label: '客诉类型',
          },
          formAttrs: {},
          options: objToArrRadio(FEEDBACK_TYPE, false),
          formatFun: (data) => {
            if (data.product_id) {
              return 'product'
            } else if (data.sale_order_id) {
              return 'sale-order'
            } else if (data.supplier_id) {
              return 'supplier'
            } else if (data.customer_id) {
              return 'customer'
            } else {
              return type || 'product'
            }
          },
        },
        {
          type: 'slot',
          key: 'ref_id',
          name: type ? 'refInfo2' : 'refInfo',
          value: info?.id || '',
          labelAttrs: {
            label: '关联信息',
            rules: [
              { required: true, message: '请选择关联信息', trigger: 'blur' },
            ],
          },
          formAttrs: {},
          layoutAttrs: {
            span: hideSelect ? 0 : layoutSpan,
          },
        },
        {
          type: 'input',
          key: 'code',
          value: '',
          labelAttrs: {
            label: '单号',
          },
          formAttrs: {},
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'inputNumber',
          key: 'num',
          value: '',
          labelAttrs: {
            label: '数量',
          },
          formAttrs: {
            class: 'w',
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          // type: 'richText',
          type: 'input',
          key: 'reason',
          value: '',
          labelAttrs: {
            label: '客诉原因',
            rules: [
              { required: true, message: '请输入客诉原因', trigger: 'blur' },
            ],
          },
          formAttrs: {
            type: 'textarea',
            placeholder: '请输入客诉原因',
            clearable: true,
          },
        },
        {
          // type: 'richText',
          type: 'input',
          key: 'handle',
          value: '',
          labelAttrs: {
            label: '处理方式',
          },
          formAttrs: {
            type: 'textarea',
            placeholder: '请输入处理方式',
            clearable: true,
          },
        },
        {
          type: 'uploadImg',
          key: 'images',
          labelAttrs: {
            label: '图片',
          },
          formAttrs: {
            multiple: true,
            uploadParam: { module: MODULE },
            // copyImgUploade: true,
            limitSize: 1024 * 10,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  // 抽屉添加
  if (form === 'draw') {
    // 添加产品
    defaultConfig.form.formList.splice(5, 0, {
      type: 'slot',
      key: 'detail_list',
      name: 'productList',
      value: '',
      labelAttrs: {
        label: '产品列表',
      },
      formAttrs: {
        type: 'text',
        placeholder: '请输入名称',
        clearable: true,
      },
    })
    defaultConfig.form.beforeReq = (data) => {
      console.log('beforeReq :>> ', data)
      console.log('beforeReq :>> store', store)
      data.detail_list = handleDetailList()
      return data
    }
    defaultConfig.footer.splice(1, 0, {
      action: 'confirmAdd',
      title: '保存并新增',
      attrs: {
        type: 'primary',
      },
    })
  }
  return defaultConfig
}

/**
 * 采购计划新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function purchasePlanAddDialog(MODULE = '', type = '', info = {}) {
  console.log('type :>> ', type)
  console.log('type :>> ', info)
  let defaultConfig = {
    title: '添加到采购计划',
    customTitle: true,
    dialog: {
      width: '450px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        update: `/${MODULE}/add`,
      },
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '80px',
      },
      formList: [
        {
          type: 'slot',
          key: 'product_id',
          value: '',
        },
        {
          type: 'slot',
          key: 'delivery_plan_id',
          value: info?.id || '',
        },
        {
          type: 'inputNumber',
          key: 'num',
          value: '',
          labelAttrs: {
            label: '数量',
            rules: [
              {
                required: true,
              },
            ],
          },
          formAttrs: {
            class: 'w',
            clearable: true,
            min: 0,
            max: 999999,
            placeholder: '请输入数量',
            step: 1,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '确定',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  // 单独添加
  if (type === 'list') {
    defaultConfig = {
      title: '添加采购计划',
      customTitle: true,
      dialog: {
        width: '450px',
        top: '25vh',
        appendToBody: true,
        destroyOnClose: true,
      },
      form: {
        api: {
          add: `/${MODULE}/add`,
          update: `/${MODULE}/add`,
        },
        formAttrs: {
          labelPosition: 'left',
          labelWidth: '80px',
        },
        formList: [
          {
            type: 'selectServer',
            key: 'product_id',
            labelAttrs: {
              label: '产品',
              rules: [
                {
                  required: true,
                  message: '请选择产品',
                  trigger: 'blur',
                },
              ],
            },
            formAttrs: {
              class: 'w',
              avatarKey: 'image',
              avatarCls: 'wh50 r4px',
              listApi: 'product/index',
              labelKey: 'title',
              searchKey: 'search',
              placeholder: '支持产品名称/OEM码/编码搜索',
              exLabelKeyFun: (item) => {
                return `${item.title} | ${item.oem_code || '-'} | ${item.code}`
              },
            },
          },
          {
            type: 'inputNumber',
            key: 'num',
            labelAttrs: {
              label: '数量',
              rules: [
                {
                  required: true,
                  message: '请输入采购数量',
                  trigger: 'blur',
                },
              ],
            },
            formAttrs: {
              class: 'w220',
              placeholder: '请输入采购数量',
              clearable: true,
            },
          },
        ],
      },
      footer: [
        {
          action: 'cancel',
          title: '取消',
          attrs: {},
        },
        {
          action: 'confirm',
          title: '确定',
          attrs: {
            type: 'primary',
          },
        },
      ],
    }
  } else if (type === 'stockBalance') {
    // 库存余额
    defaultConfig.form.formList.splice(0, 2, {
      type: 'slot',
      key: 'product_id',
      value: info?.id || '',
    })
    defaultConfig.form.beforeReq = (data, model) => {
      data.product_id = model.id
      return data
    }
    defaultConfig.form.isAddFun = () => {
      return true
    }
  }
  return defaultConfig
}

/**
 * 采购订单新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function purchaseOrderAddDialog(MODULE = '', type = '', info = {}) {
  console.log('type :>> ', type)
  console.log('type :>> ', info)
  const { supportCurrency, defaultCurrency } = handleSupportCurrency()
  let layoutSpan = 24
  if (type === 'furnitureOrder') {
    layoutSpan = 6
  }
  const isFurniture =
    type === 'furniture' ||
    type === 'furnitureOrder' ||
    type === 'furnitureStock'
  let defaultConfig = {
    title: '采购',
    dialog: {
      width: '850px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: type === 'furniture' ? '' : `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '90px',
        ...dynamicsFormAttrs,
      },
      beforeCreate: {
        url: `${MODULE_PURCHASE_ORDER}/code`,
        callback: (res, model) => {
          model.code = res.code
        },
        Fun: (model) => {
          getRequest(`${MODULE_PURCHASE_ORDER}/title`).then((res) => {
            model.title = res.title
          })
        },
      },
      formList: [
        {
          type: isFurniture ? 'selectServer' : 'slot',
          key: 'supplier_id',
          name: 'supplierId',
          labelAttrs: {
            label: '供应商',
            rules: [
              {
                required: true,
                message: '请选择供应商',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            class: 'w',
            listApi: 'supplier/index',
            labelKey: 'title',
            searchKey: 'search',
            placeholder: '请选择供应商',
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'input',
          key: 'title',
          value: '',
          labelAttrs: {
            label: '名称',
            rules: isFurniture
              ? null
              : [
                  {
                    required: true,
                    message: '请输入名称',
                    trigger: 'blur',
                  },
                ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入名称',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'input',
          key: 'code',
          value: '',
          labelAttrs: {
            label: '单号',
            rules: [
              {
                required: true,
                message: '请输入采购单单号',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入采购单单号',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'radio',
          key: 'is_contain_tax',
          value: 0,
          labelAttrs: {
            label: '是否含税',
            rules: [
              {
                required: true,
                message: '请选择类型',
                trigger: 'blur',
              },
            ],
          },
          options: objToArrRadio(CONTAIN_TAX),
          layoutAttrs: {
            span: type === 'furnitureOrder' ? layoutSpan : 12,
          },
        },
        {
          type: 'radio',
          key: 'price_type',
          value: 'exw',
          labelAttrs: {
            label: '交货方式',
            rules: [
              {
                required: true,
                message: '请选择类型',
                trigger: 'blur',
              },
            ],
          },
          options: objToArrRadio(SHIP_MODE, false),
          layoutAttrs: {
            span: type === 'furnitureOrder' ? layoutSpan : 12,
          },
        },
        {
          type: 'select',
          key: 'currency',
          value: defaultCurrency,
          labelAttrs: {
            label: '币种',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请选择币种',
          },
          options: objToArr(supportCurrency, false),
          layoutAttrs: {
            span: type === 'furnitureOrder' ? layoutSpan : 12,
          },
        },
        {
          type: 'datePicker',
          key: 'order_time',
          value: dayjs().format('YYYY-MM-DD'),
          labelAttrs: {
            label: '单据日期',
            tips: '订单成交时日期',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请选择开始日期',
            valueFormat: 'YYYY-MM-DD',
          },
          layoutAttrs: {
            span: type === 'furnitureOrder' ? layoutSpan : 12,
          },
        },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '备注',
          },
          formAttrs: {
            type: 'textarea',
            clearable: true,
            disabled: false,
            placeholder: '',
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  if (type === 'batch') {
    defaultConfig.title = '添加到采购单'
    defaultConfig.customTitle = true
    defaultConfig.form.api.detail = ''
    defaultConfig.form.api.detailCallBack = (result) => {
      delete result.id
      return result
    }
    defaultConfig.form.formList.unshift({
      type: 'slot',
      key: 'purchase_plan_detail_id',
      refValKey: 'id',
      value: '',
    })
  } else if (type === 'furniture') {
    debugger
    defaultConfig.customTitle = true
    defaultConfig.title = '添加到采购单'
    const codeIndex = getFormListIndex('code', defaultConfig.form.formList)
    defaultConfig.form.formList.splice(codeIndex, 1)
    defaultConfig.form.api.add = `/${MODULE}/generatePurchase`
    defaultConfig.form.api.update = `/${MODULE}/generatePurchase`
  } else if (type === 'furnitureOrder') {
    defaultConfig.form.desHeader = {
      title: '基本信息',
      // border: true,
    }
    const index = getFormListIndex('admin_remark', defaultConfig.form.formList)
    defaultConfig.form.formList.splice(index, 0, {
      type: 'slot',
      key: 'detail_list',
      name: 'productList',
      value: '',
      labelAttrs: {
        label: '产品列表',
      },
      formAttrs: {
        type: 'text',
        placeholder: '请输入名称',
        clearable: true,
      },
    })
    defaultConfig.form.beforeReq = (data) => {
      console.log('beforeReq :>> ', data)
      console.log('beforeReq :>> store', store)
      data.detail_list = handleDetailList()
      return data
    }
    defaultConfig.footer.splice(1, 0, {
      action: 'confirmAdd',
      title: '保存并新增',
      attrs: {
        type: 'primary',
      },
    })
  } else if (type === 'furnitureStock') {
    // 家具库存备货采购
    defaultConfig.customTitle = true
    defaultConfig.title = '添加到采购单'
    const index = getFormListIndex('code', defaultConfig.form.formList)
    defaultConfig.form.formList.splice(index, 1, {
      type: 'inputNumber',
      key: 'nums',
      value: 1,
      labelAttrs: {
        label: '数量',
      },
      formAttrs: {
        class: 'wi',
        placeholder: '请输入数量',
        clearable: true,
      },
      layoutAttrs: {
        span: layoutSpan,
      },
    })
    defaultConfig.form.api.detail = ''
    defaultConfig.form.beforeReq = (data, model) => {
      console.log('beforeReq :>> ', data)
      console.log('beforeReq :>> store', store)
      data.detail_list = [
        {
          product_id: model.product_id,
          num: data.nums,
        },
      ]
      delete data.id
      delete model.id
      return data
    }
  }
  return defaultConfig
}

/**
 * 采购订单新增弹窗(家具)
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function purchaseOrderFurnitureAddDialog(
  MODULE = '',
  type = '',
  info = {}
) {
  console.log('type :>> ', type)
  console.log('type :>> ', info)
  let layoutSpan = 24
  if (type === 'furnitureOrder') {
    layoutSpan = 6
  }

  let defaultConfig = {
    title: '采购',
    dialog: {
      width: '850px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: type === 'furniture' ? '' : `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '90px',
        ...dynamicsFormAttrs,
      },
      beforeCreate: {
        url: `${MODULE_PURCHASE_ORDER}/code`,
        callback: (res, model) => {
          model.code = res.code
        },
        Fun: (model) => {
          getRequest(`${MODULE_PURCHASE_ORDER}/title`).then((res) => {
            model.title = res.title
          })
        },
      },
      formList: [
        {
          type: 'slot',
          key: 'title',
          value: '',
          labelAttrs: {
            label: '名称',
            rules: [
              {
                required: true,
                message: '请输入名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入名称',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'inputNumber',
          key: 'nums',
          value: 1,
          labelAttrs: {
            label: '数量',
          },
          formAttrs: {
            class: 'wi',
            placeholder: '请输入数量',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '备注',
          },
          formAttrs: {
            type: 'textarea',
            clearable: true,
            disabled: false,
            placeholder: '',
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  if (type === 'furniture') {
    defaultConfig.customTitle = true
    defaultConfig.title = '添加到采购单'
    defaultConfig.form.formList.splice(2, 1)
    defaultConfig.form.api.add = `/${MODULE}/generatePurchase`
    defaultConfig.form.api.update = `/${MODULE}/generatePurchase`
  } else if (type === 'furnitureOrder') {
    defaultConfig.form.desHeader = {
      title: '基本信息',
      // border: true,
    }
    defaultConfig.form.formList.splice(6, 0, {
      type: 'slot',
      key: 'detail_list',
      name: 'productList',
      value: '',
      labelAttrs: {
        label: '产品列表',
      },
      formAttrs: {
        type: 'text',
        placeholder: '请输入名称',
        clearable: true,
      },
    })
    defaultConfig.form.beforeReq = (data) => {
      console.log('beforeReq :>> ', data)
      console.log('beforeReq :>> store', store)
      data.detail_list = handleDetailList()
      return data
    }
    defaultConfig.footer.splice(1, 0, {
      action: 'confirmAdd',
      title: '保存并新增',
      attrs: {
        type: 'primary',
      },
    })
  } else if (type === 'furnitureStock') {
    // 家具库存备货采购
    defaultConfig.customTitle = true
    defaultConfig.title = '添加到采购单'
    defaultConfig.form.api.detail = ''
    defaultConfig.form.beforeReq = (data, model) => {
      console.log('beforeReq :>> ', data)
      console.log('beforeReq :>> store', store)
      data.detail_list = [
        {
          product_id: model.product_id,
          num: data.nums,
        },
      ]
      delete data.id
      delete model.id
      return data
    }
  }
  return defaultConfig
}
/**
 * 任务添加弹窗
 * @param {*} MODULE
 * @param {*} type
 * @param {*} info
 * @param {*} from 来源
 * @returns
 */
export function taskAddDialog(MODULE, type = '', info = {}, from = '') {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('from :>> ', from)

  let title = ''
  if (type === 'add') {
    title = '任务'
  } else if (type === 'edit') {
    title = '任务'
  } else {
    title = '更换负责人'
  }
  const defaultConfig = {
    title: title,
    custome: true,
    dialog: {
      width: '800px',
      top: '20vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '80px',
        ...dynamicsFormAttrs,
      },
      beforeReq: (data) => {
        console.log('beforeReq :>> ', data)
        if (data?.owner_list?.length === 0) {
          data.owner_list = ''
        }
        return data
      },
      formList: [
        {
          type: 'selectServer',
          key: 'main_admin_id',
          value: '',
          titleKey: '',
          labelAttrs: {
            label: '负责人',
            rules: [
              {
                required: true,
                message: '选择负责人',
                trigger: 'change',
              },
            ],
          },
          formAttrs: {
            listApi: `/admin/index`,
            class: 'w',
            labelKey: 'realname',
            searchKey: 'realname',
            valueType: 'number',
            // modelValueKey: 'mainer',
            placeholder: '请选择负责人',
            exParams: {
              status: 1,
            },
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'slot',
          key: 'owner_list',
          name: 'ownerAdminList',
          value: type === 'add' ? [] : [],
          labelAttrs: {},
          formAttrs: {},
          layoutAttrs: {
            span: 24,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  // 添加任务
  if (type === 'add') {
    defaultConfig.form.formList.splice(
      0,
      0,
      {
        type: 'input',
        key: 'title',
        labelAttrs: {
          label: '名称',
          rules: [
            {
              required: true,
              message: '请输入名称',
              trigger: 'blur',
            },
          ],
        },
        formAttrs: {
          class: 'w',
          placeholder: '请输入名称',
          clearable: true,
        },
        layoutAttrs: {
          span: 12,
        },
      },
      {
        type: 'input',
        key: 'service_year',
        labelAttrs: {
          label: '服务年限',
        },
        formAttrs: {
          class: 'w',
          placeholder: '请输入服务年限(报告年限)',
          clearable: true,
        },
        layoutAttrs: {
          span: 12,
        },
      },
      {
        type: 'datePickerRange',
        key: 'create_time',
        startKey: 'start_time',
        endKey: 'end_time',
        value: [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
        labelAttrs: {
          label: '任务日期',
        },
        formAttrs: {
          type: 'daterange',
          placeholder: '请选择任务日期',
          format: 'YYYY-MM-DD',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          // clearable: true,
        },
        layoutAttrs: {
          span: 12,
        },
      }
    )
    defaultConfig.form.formList.push({
      type: 'input',
      key: 'admin_remark',
      value: '',
      labelAttrs: {
        label: '备注',
      },
      formAttrs: {
        type: 'textarea',
        clearable: true,
        disabled: false,
        placeholder: '',
      },
      layoutAttrs: {
        span: 24,
      },
    })
    if (from === 'orderlist') {
      // 订单任务
      defaultConfig.form.formEditParams = {
        sale_order_ids: info.id,
      }
    } else {
      defaultConfig.form.formList.splice(0, 0, {
        type: 'selectServer',
        key: 'customer_id',
        labelAttrs: {
          label: '客户',
          rules: [
            {
              required: true,
              message: '请选择客户',
              trigger: 'blur',
            },
          ],
        },
        formAttrs: {
          listApi: `/customer/index`,
          class: 'w',
          valueType: 'number',
          multiple: false,
          placeholder: '请选择客户',
          emitSelect: true,
          // showAdd: true,
          // autoAdd: true,
          // alwaysAdd: true
        },
        // action: {
        //   type: 'editBox',
        //   editBoxName: 'addCoustomBox',
        // },
        onChange: (item, model) => {
          model.title = `${item.title}-任务${dayjs().format('YYYYMMDD')}`
        },
        layoutAttrs: {
          span: 12,
        },
      })
      defaultConfig.form.formList.splice(2, 0, {
        type: 'inputNumber',
        key: 'amount',
        value: '',
        labelAttrs: {
          label: '金额',
          rules: [],
        },
        formAttrs: {
          class: 'wi',
          placeholder: '请输入任务金额',
          clearable: true,
        },
        layoutAttrs: {
          span: 12,
        },
      })
    }
  } else if (type === 'edit') {
    defaultConfig.form.formList = [
      {
        type: 'input',
        key: 'title',
        labelAttrs: {
          label: '名称',
          rules: [
            {
              required: true,
              message: '请输入名称',
              trigger: 'blur',
            },
          ],
        },
        formAttrs: {
          class: 'w',
          placeholder: '请输入名称',
          clearable: true,
        },
        layoutAttrs: {
          span: 12,
        },
      },
      {
        type: 'inputNumber',
        key: 'amount',
        value: '',
        labelAttrs: {
          label: '金额',
          rules: [],
        },
        formAttrs: {
          class: 'wi',
          placeholder: '请输入任务金额',
          clearable: true,
        },
        layoutAttrs: {
          span: 12,
        },
      },
      {
        type: 'input',
        key: 'service_year',
        labelAttrs: {
          label: '服务年限',
        },
        formAttrs: {
          class: 'w',
          placeholder: '请输入服务年限(报告年限)',
          clearable: true,
        },
        layoutAttrs: {
          span: 12,
        },
      },
      {
        type: 'input',
        key: 'admin_remark',
        value: '',
        labelAttrs: {
          label: '备注',
        },
        formAttrs: {
          type: 'textarea',
          clearable: true,
          disabled: false,
          placeholder: '',
        },
        layoutAttrs: {
          span: 24,
        },
      },
    ]
  }

  console.log('defaultConfig :>> ', defaultConfig)
  return defaultConfig
}

/**
 * 回款计划添加弹窗
 * @param {*} MODULE
 * @param {*} type
 * @param {*} info
 * @param {*} from 来源
 * @returns
 */
export function financeReceivablesPlanAddDialog(
  MODULE,
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('params :>> ', params)
  const { supportCurrency, defaultCurrency } = handleSupportCurrency()
  const fromOrderList = params?.type === 'orderlist'
  const defaultConfig = {
    title: '回款计划',
    dialog: {
      width: '750px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '100px',
        ...dynamicsFormAttrs,
      },
      formList: [
        {
          type: fromOrderList ? 'slot' : 'selectServer',
          key: 'customer_id',
          labelAttrs: {
            label: '客户名称',
            rules: [{ required: true, message: '发货时间', trigger: 'blur' }],
          },
          formAttrs: {
            clearable: false,
            filterable: true,
            listApi: `/customer/index`,
            searchKey: 'title',
            class: 'w',
            placeholder: '请选择客户',
            // showAdd: true,
          },
          layoutAttrs: {
            span: fromOrderList ? 0 : 12,
          },
          action: {
            type: 'editBox',
            editBoxName: 'addCoustomBox',
          },
        },
        {
          type: fromOrderList ? 'slot' : 'selectServer',
          key: 'sale_order_id',
          value: fromOrderList ? info?.id : '',
          labelAttrs: {
            label: '销售订单',
            rules: [
              {
                required: true,
                message: '请选择销售订单',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            listApi: `/sale-order/index`,
            searchKey: 'search',
            multiple: false,
            class: 'w',
            placeholder: '请选择关联销售订单',
            exLabelKeyFun: (item) => {
              return `${item.title}-${CURRENCY_UNIT[item.currency]}${
                item.amount
              }-${dayjs(item.order_time)?.format('YYYY-MM-DD') || ''}`
            },
          },
          layoutAttrs: {
            span: fromOrderList ? 0 : 12,
          },
        },
        {
          type: 'inputNumber',
          key: 'amount',
          value: '',
          labelAttrs: {
            label: '回款金额',
            rules: [
              {
                required: true,
                message: '回款金额',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            placeholder: '请输入回款金额',
            clearable: true,
            class: 'wi',
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'datePicker',
          key: 'plan_date',
          value: dayjs().format('YYYY-MM-DD'),
          labelAttrs: {
            label: '回款日期',
            rules: [
              {
                required: true,
                message: '请选择回款日期',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            'value-format': 'YYYY-MM-DD',
            placeholder: '请选择回款日期',
            clearable: true,
            class: 'w',
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'select',
          key: 'pay_type',
          value: 5,
          labelAttrs: {
            label: '回款方式',
          },
          formAttrs: {
            placeholder: '请选择回款方式',
            clearable: true,
            class: 'w',
          },
          options: objToArr(PAY_TYPE),
          layoutAttrs: { span: 12 },
        },

        {
          type: 'selectServer',
          key: 'bill_channel_id',
          labelAttrs: {
            label: '渠道',
          },
          formAttrs: {
            listApi: `/bill-channel/index`,
            searchKey: 'title',
            labelKey: 'title',
            placeholder: '请选择渠道',
            addApi: 'bill-channel/add',
            class: 'w',
            autoAdd: true,
          },
          layoutAttrs: { span: 12 },
        },

        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '备注',
          },
          formAttrs: {
            type: 'textarea',
            clearable: true,
            disabled: false,
            placeholder: '',
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  if (type === 'planDetail') {
    defaultConfig.form.formList.splice(2, 0, {
      type: 'select',
      key: 'currency',
      value:
        info?.sale_order_info?.currency ||
        info?.currency ||
        defaultCurrency ||
        '',
      labelAttrs: {
        label: '货币币种',
      },
      formAttrs: {
        disabled: type === 'edit',
        placeholder: '请选择币种',
        class: 'w',
      },
      options: objToArr(supportCurrency, false),
      layoutAttrs: {
        span: 12,
      },
    })
    const formListLen = defaultConfig.form.formList.length
    defaultConfig.form.formList.splice(
      formListLen - 2,
      0,
      {
        type: 'slot',
        key: 'sync_bill',
        value: 1,
        labelAttrs: {
          label: '添加回款明细',
        },
      },
      {
        type: 'input',
        key: 'trade_no',
        labelAttrs: {
          label: '流水单号',
        },
        formAttrs: {
          class: 'w',
          placeholder: '请输入支付流水单号',
          clearable: true,
        },
      },
      {
        type: 'uploadImg',
        key: 'proof',
        labelAttrs: {
          label: '凭证',
        },
        formAttrs: {
          copyImgUploade: true,
          uploadParam: { module: 'bill' },
          limitSize: 1024 * 10,
        },
      }
    )
  }
  console.log('defaultConfig :>> ', defaultConfig)
  return defaultConfig
}

/**
 * 回款计划批量生成添加弹窗
 * @param {*} MODULE
 * @param {*} type
 * @param {*} info
 * @param {*} params 来源
 * @returns
 */
export function billPlanBatchAddDialog(
  MODULE,
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('params :>> ', params)

  const defaultConfig = {
    title: '批量生成回款计划',
    customTitle: true,
    dialog: {
      width: '550px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/generate`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/generate`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '110px',
      },
      formList: [
        {
          type: params?.type === 'orderlist' ? 'slot' : 'selectServer',
          key: 'sale_order_id',
          value: params?.type === 'orderlist' ? info?.id : '',
          labelAttrs: {
            label: '销售订单',
            rules: [
              {
                required: true,
                message: '请选择销售订单',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            listApi: `/sale-order/index`,
            searchKey: 'search',
            multiple: false,
            class: 'w220',
            placeholder: '请选择关联销售订单',
          },
        },
        {
          type: 'datePicker',
          key: 'date',
          value: dayjs().format('YYYY-MM-DD'),
          labelAttrs: {
            label: '首次回款日期',
            rules: [
              {
                required: true,
                message: '请选择首次回款日期',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            'value-format': 'YYYY-MM-DD',
            placeholder: '请选择首次回款日期',
          },
        },
        {
          type: 'inputNumber',
          key: 'times',
          value: 1,
          labelAttrs: {
            label: '回款间隔时间',
            rules: [
              {
                required: true,
                message: '请输入回款间隔时间',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            min: 1,
            placeholder: '请输入回款间隔时间',
            clearable: true,
            class: 'w220',
          },
          layoutAttrs: {
            span: 16,
          },
        },
        {
          type: 'select',
          key: 'cycle',
          value: objToArr(CYCLE, false)[0].value,
          labelAttrs: {
            label: '',
            labelWidth: '0px',
          },
          formAttrs: {
            placeholder: '请选择回款方式',
          },
          options: objToArr(CYCLE, false),
          layoutAttrs: {
            span: 8,
          },
        },
        {
          type: 'inputNumber',
          key: 'periods',
          value: 1,
          labelAttrs: {
            label: '总回款期数',
            rules: [
              {
                required: true,
                message: '请输入总回款期数',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            min: 1,
            placeholder: '请输入总回款期数',
            clearable: true,
            class: 'w220',
          },
        },
        {
          type: 'select',
          key: 'pay_type',
          value: 5,
          labelAttrs: {
            label: '回款方式',
          },
          formAttrs: {
            placeholder: '请选择回款方式',
          },
          options: objToArr(PAY_TYPE),
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  console.log('defaultConfig :>> ', defaultConfig)
  return defaultConfig
}

/**
 * 回款明细添加弹窗
 * @param {*} MODULE
 * @param {*} type
 * @param {*} info
 * @param {*} from 来源
 * @returns
 */
export function financeReceivablesAddDialog(
  MODULE,
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('params :>> ', params)
  const { supportCurrency, defaultCurrency } = handleSupportCurrency()

  const defaultConfig = {
    title: '回款明细',
    dialog: {
      width: '750px',
      top: '7vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '100px',
        ...dynamicsFormAttrs,
      },
      formList: [
        {
          type: params?.type ? 'slot' : 'selectServer',
          key: 'customer_id',
          value: info?.customer_id || '',
          labelAttrs: {
            label: '客户名称',
            rules: [{ required: true, message: '请选择客户', trigger: 'blur' }],
          },
          formAttrs: {
            clearable: false,
            filterable: true,
            listApi: `/customer/index`,
            searchKey: 'title',
            class: 'w',
            placeholder: '请选择客户',
            // showAdd: true,
          },
          layoutAttrs: {
            span: 12,
          },
          action: {
            type: 'editBox',
            editBoxName: 'addCoustomBox',
          },
        },
        {
          type: params?.type ? 'slot' : 'selectServer',
          key: params?.type === 'orderlist' ? 'sale_order_id' : 'bill_plan_id',
          value: info?.[params?.valKey] || '',
          labelAttrs: {
            label: '回款计划',
          },
          formAttrs: {
            listApi: `/bill-plan/index`,
            searchKey: 'search',
            labelKey: 'code',
            multiple: false,
            class: 'w',
            placeholder: '请选择关联回款计划',
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'inputNumber',
          key: 'amount',
          value: '',
          labelAttrs: {
            label: '回款金额',
            rules: [
              {
                required: true,
                message: '回款金额',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            placeholder: '请输入回款金额',
            clearable: true,
            class: 'wi',
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'select',
          key: 'currency',
          value:
            info?.sale_order_info?.currency ||
            info?.currency ||
            defaultCurrency ||
            '',
          labelAttrs: {
            label: '货币币种',
          },
          formAttrs: {
            disabled: type === 'edit',
            placeholder: '请选择币种',
            class: 'w',
          },
          options: objToArr(supportCurrency, false),
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'datePicker',
          key: 'date',
          value: dayjs().format('YYYY-MM-DD'),
          labelAttrs: {
            label: '回款日期',
            rules: [
              {
                required: true,
                message: '请选择回款日期',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'date',
            class: 'w',
            'value-format': 'YYYY-MM-DD',
            placeholder: '请选择回款日期',
            clearable: true,
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'select',
          key: 'pay_type',
          value: objToArr(PAY_TYPE).pop().value,
          labelAttrs: {
            label: '回款方式',
          },
          formAttrs: {
            placeholder: '请选择回款方式',
            clearable: true,
            class: 'w',
          },
          layoutAttrs: {
            span: 12,
          },
          options: objToArr(PAY_TYPE),
        },
        {
          type: 'selectServer',
          key: 'bill_channel_id',
          value: info.bill_channel_id || '',
          labelAttrs: {
            label: '渠道',
          },
          formAttrs: {
            listApi: `/bill-channel/index`,
            searchKey: 'title',
            labelKey: 'title',
            placeholder: '请选择渠道',
            addApi: 'bill-channel/add',
            modelValueName: info?.bill_channel_info?.title || '',
            class: 'w',
            autoAdd: true,
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'input',
          key: 'trade_no',
          labelAttrs: {
            label: '流水单号',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请输入支付流水单号',
            clearable: true,
          },
        },
        {
          type: 'uploadImg',
          key: 'proof',
          labelAttrs: {
            label: '凭证',
          },
          formAttrs: {
            copyImgUploade: true,
            multiple: true,
            uploadParam: { module: 'bill' },
            limitSize: 1024 * 10,
          },
        },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '备注',
          },
          formAttrs: {
            type: 'textarea',
            clearable: true,
            disabled: false,
            placeholder: '',
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  // 回款计划添加
  if (params.type === 'plan') {
    defaultConfig.form.formList.splice(
      0,
      0,
      {
        type: 'slot',
        key: 'id',
        labelAttrs: {
          label: '',
        },
        formAttrs: {
          class: 'w',
          placeholder: '请输入名称',
          clearable: true,
        },
      },
      {
        type: 'slot',
        key: 'audit_status',
        value: 1,
      }
    )
    defaultConfig.form.api.add = '/bill/add'
    defaultConfig.form.api.update = '/bill/add'
    defaultConfig.form.beforeReq = (data) => {
      const obj = { ...data }
      obj.bill_plan_id = obj.id
      console.log('obj :>> beforeReq', obj)
      return obj
    }
  } else if (type === 'bill_period') {
    // 账期
    defaultConfig.form.api.add = `/${MODULE}/add`
    defaultConfig.form.api.update = `/${MODULE}/add`
    defaultConfig.form.api.detail = ''
    defaultConfig.form.customTitle = true
    defaultConfig.form.formList.splice(
      0,
      2,
      {
        type: 'slot',
        key: 'customer_id',
        value: '',
        labelAttrs: {
          label: '客户名称',
        },
        formAttrs: {
          clearable: false,
        },
      },
      {
        type: 'slot',
        key: 'bill_sheet_id',
        value: '',
        labelAttrs: {
          label: '账期',
        },
        formAttrs: {
          clearable: false,
        },
      },
      {
        type: 'slot',
        key: 'pm',
        value: 1,
        labelAttrs: {
          label: '类型',
        },
        formAttrs: {
          clearable: false,
        },
      }
    )
    defaultConfig.form.beforeReq = (data, model) => {
      const obj = { ...data }
      obj.bill_sheet_id = model.id
      console.log('obj :>> beforeReq', obj)
      return obj
    }
  } else if (type === 'otherExpense') {
    // 其他费用
    defaultConfig.title = '其他费用'
  }
  console.log('defaultConfig :>> ', defaultConfig)
  return defaultConfig
}

/**
 * 付款计划添加弹窗
 * @param {*} MODULE
 * @param {*} type
 * @param {*} info
 * @param {*} from 来源
 * @returns
 */
export function financePaymentPlanAddDialog(
  MODULE,
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('params :>> ', params)

  const defaultConfig = {
    title: '付款计划',
    dialog: {
      width: '750px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '100px',
        ...dynamicsFormAttrs,
      },
      formList: [
        {
          type: 'slot',
          key: 'pm',
          value: 0,
          labelAttrs: {
            label: '类型',
          },
        },
        {
          type: params?.type === 'purchaselist' ? 'slot' : 'selectServer',
          key: 'supplier_id',
          labelAttrs: {
            label: '供应商名称',
            rules: [{ required: true, message: '供应商名称', trigger: 'blur' }],
          },
          formAttrs: {
            clearable: false,
            filterable: true,
            listApi: `/supplier/index`,
            searchKey: 'title',
            class: 'w',
            placeholder: '请选择供应商',
            // showAdd: true,
          },
          layoutAttrs: {
            span: 12,
          },
          action: {
            type: 'editBox',
            editBoxName: 'addCoustomBox',
          },
        },
        {
          type: params?.type === 'purchaselist' ? 'slot' : 'selectServer',
          key: 'purchase_order_id',
          value: params?.type === 'purchaselist' ? info?.id : '',
          labelAttrs: {
            label: '采购订单',
            rules: [
              {
                required: true,
                message: '请选择采购订单',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            listApi: `/purchase-order/index`,
            searchKey: 'search',
            multiple: false,
            class: 'w',
            placeholder: '请选择关联采购订单',
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'inputNumber',
          key: 'amount',
          value: '',
          labelAttrs: {
            label: '付款金额',
            rules: [
              {
                required: true,
                message: '付款金额',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            placeholder: '请输入付款金额',
            clearable: true,
            class: 'wi',
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'datePicker',
          key: 'plan_date',
          value: dayjs().format('YYYY-MM-DD'),
          labelAttrs: {
            label: '付款日期',
            rules: [
              {
                required: true,
                message: '请选择付款日期',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            'value-format': 'YYYY-MM-DD',
            class: 'w',
            placeholder: '请选择付款日期',
            clearable: true,
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'select',
          key: 'pay_type',
          value: 5,
          labelAttrs: {
            label: '付款方式',
          },
          formAttrs: {
            placeholder: '请选择付款方式',
            clearable: true,
            class: 'w',
          },
          options: objToArr(PAY_TYPE),
        },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '备注',
          },
          formAttrs: {
            type: 'textarea',
            clearable: true,
            disabled: false,
            placeholder: '',
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  console.log('defaultConfig :>> ', defaultConfig)
  return defaultConfig
}

/**
 * 付款计划批量生成添加弹窗
 * @param {*} MODULE
 * @param {*} type
 * @param {*} info
 * @param {*} params 来源
 * @returns
 */
export function paymentPlanBatchAddDialog(
  MODULE,
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('params :>> ', params)
  const isFormOrder = params?.type === 'orderlist'
  const defaultConfig = {
    title: '批量生成付款计划',
    customTitle: true,
    dialog: {
      width: '550px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/generate`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/generate`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '110px',
      },
      formList: [
        {
          type: 'slot',
          key: 'pm',
          value: 0,
          labelAttrs: {
            label: '类型',
          },
        },
        {
          type: isFormOrder ? 'slot' : 'selectServer',
          key: 'purchase_order_id',
          value: isFormOrder ? info?.id : '',
          labelAttrs: {
            label: '采购订单',
            rules: [
              {
                required: true,
                message: '请选择采购订单',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            listApi: `/purchase-order/index`,
            searchKey: 'search',
            multiple: false,
            class: isFormOrder ? '' : 'w',
            placeholder: '请选择关联采购订单',
          },
          layoutAttrs: {
            // span: 12,
          },
        },
        {
          type: 'datePicker',
          key: 'date',
          value: dayjs().format('YYYY-MM-DD'),
          labelAttrs: {
            label: '首次付款日期',
            rules: [
              {
                required: true,
                message: '请选择首次付款日期',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            'value-format': 'YYYY-MM-DD',
            placeholder: '请选择首次付款日期',
          },
        },
        {
          type: 'inputNumber',
          key: 'times',
          value: 1,
          labelAttrs: {
            label: '付款间隔时间',
            rules: [
              {
                required: true,
                message: '请输入付款间隔时间',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            min: 1,
            placeholder: '请输入付款间隔时间',
            clearable: true,
            class: 'w220',
          },
          layoutAttrs: {
            span: 16,
          },
        },
        {
          type: 'select',
          key: 'cycle',
          value: objToArr(CYCLE, false)[0].value,
          labelAttrs: {
            label: '',
            labelWidth: '0px',
          },
          formAttrs: {
            placeholder: '请选择付款方式',
          },
          options: objToArr(CYCLE, false),
          layoutAttrs: {
            span: 8,
          },
        },
        {
          type: 'inputNumber',
          key: 'periods',
          value: 1,
          labelAttrs: {
            label: '总付款期数',
            rules: [
              {
                required: true,
                message: '请输入总付款期数',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            min: 1,
            placeholder: '请输入总付款期数',
            clearable: true,
            class: 'w220',
          },
        },
        {
          type: 'select',
          key: 'pay_type',
          value: 5,
          labelAttrs: {
            label: '付款方式',
          },
          formAttrs: {
            placeholder: '请选择付款方式',
          },
          options: objToArr(PAY_TYPE),
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  console.log('defaultConfig :>> ', defaultConfig)
  return defaultConfig
}

/**
 * 付款明细添加弹窗
 * @param {*} MODULE
 * @param {*} type
 * @param {*} info
 * @param {*} from 来源
 * @returns
 */
export function financePaymentDetailAddDialog(
  MODULE,
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('params :>> ', params)

  const refObj = {
    purchaselist: {
      key: 'purchase_order_id',
    },
    orderlist: {
      key: 'sale_order_id',
    },
  }
  const defaultConfig = {
    title: '付款明细',
    dialog: {
      width: '750px',
      top: '8vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '100px',
        ...dynamicsFormAttrs,
      },
      formList: [
        {
          type: 'slot',
          key: 'pm',
          value: 0,
          labelAttrs: {
            label: '类型',
          },
        },
        {
          type: params?.type ? 'slot' : 'selectServer',
          key: 'supplier_id',
          value: info?.supplier_id || '',
          labelAttrs: {
            label: '供应商名称',
            rules: [{ required: true, message: '供应商名称', trigger: 'blur' }],
          },
          formAttrs: {
            clearable: false,
            filterable: true,
            listApi: `/supplier/index`,
            searchKey: 'title',
            class: 'w',
            placeholder: '请选择供应商',
            // showAdd: true,
          },
          layoutAttrs: {
            span: 12,
          },
          action: {
            type: 'editBox',
            editBoxName: 'addCoustomBox',
          },
        },
        {
          type: params?.type ? 'slot' : 'selectServer',
          key: refObj[params?.type]?.key || 'bill_plan_id',
          value: info?.[params?.valKey] || '',
          labelAttrs: {
            label: '付款计划',
          },
          formAttrs: {
            listApi: `/bill-plan/index`,
            searchKey: 'search',
            labelKey: 'code',
            multiple: false,
            class: 'w',
            placeholder: '请选择关联付款计划',
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'inputNumber',
          key: 'amount',
          value: '',
          labelAttrs: {
            label: '付款金额',
            rules: [
              {
                required: true,
                message: '付款金额',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            placeholder: '请输入付款金额',
            clearable: true,
            class: 'wi',
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'datePicker',
          key: 'date',
          value: dayjs().format('YYYY-MM-DD'),
          labelAttrs: {
            label: '付款日期',
            rules: [
              {
                required: true,
                message: '请选择付款日期',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'date',
            'value-format': 'YYYY-MM-DD',
            placeholder: '请选择付款日期',
            class: 'w',
            clearable: true,
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'select',
          key: 'pay_type',
          value: objToArr(PAY_TYPE).pop().value,
          labelAttrs: {
            label: '付款方式',
          },
          formAttrs: {
            placeholder: '请选择付款方式',
            clearable: true,
            class: 'w',
          },
          layoutAttrs: {
            span: 12,
          },
          options: objToArr(PAY_TYPE),
        },
        {
          type: 'selectServer',
          key: 'bill_channel_id',
          labelAttrs: {
            label: '渠道',
          },
          formAttrs: {
            listApi: `/bill-channel/index`,
            searchKey: 'title',
            labelKey: 'title',
            class: 'w',
            placeholder: '请选择渠道',
            addApi: 'bill-channel/add',
            autoAdd: true,
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'input',
          key: 'trade_no',
          labelAttrs: {
            label: '流水单号',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请输入支付流水单号',
            clearable: true,
          },
        },
        {
          type: 'uploadImg',
          key: 'proof',
          labelAttrs: {
            label: '凭证',
          },
          formAttrs: {
            copyImgUploade: true,
            multiple: true,
            uploadParam: { module: 'bill' },
            limitSize: 1024 * 10,
          },
        },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '备注',
          },
          formAttrs: {
            type: 'textarea',
            clearable: true,
            disabled: false,
            placeholder: '',
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  // 回款计划添加
  if (params.type === 'plan' || params.type === 'purchaselist') {
    defaultConfig.form.formList.splice(0, 0, {
      type: 'slot',
      key: 'id',
      labelAttrs: {
        label: '',
      },
      formAttrs: {
        class: 'w',
        placeholder: '请输入名称',
        clearable: true,
      },
    })
    defaultConfig.form.api.add = '/bill/add'
    defaultConfig.form.api.update = '/bill/add'
    defaultConfig.form.beforeReq = (data) => {
      const obj = { ...data }
      obj.bill_plan_id = obj.id
      console.log('obj :>> beforeReq', obj)
      return obj
    }
  }
  if (type === 'furniture') {
    // 家具付款明细添加
    defaultConfig.customTitle = true
    defaultConfig.title = '添加付款明细'
    defaultConfig.form.formList.splice(0, 3)
    defaultConfig.form.api.add = `/${MODULE}/payment`
    defaultConfig.form.api.update = `/${MODULE}/payment`
  } else if (type === 'bill_period') {
    // 账期
    defaultConfig.title = '添加付款明细'
    defaultConfig.form.api.add = `/${MODULE}/add`
    defaultConfig.form.api.update = `/${MODULE}/add`
    defaultConfig.form.api.detail = ''
    defaultConfig.form.customTitle = true
    defaultConfig.form.formList.splice(
      1,
      2,
      {
        type: 'slot',
        key: 'supplier_id',
        value: '',
        labelAttrs: {
          label: '供应商名称',
        },
        formAttrs: {
          clearable: false,
        },
      },
      {
        type: 'slot',
        key: 'bill_sheet_id',
        value: '',
        labelAttrs: {
          label: '账期',
        },
        formAttrs: {
          clearable: false,
        },
      }
    )
    defaultConfig.form.beforeReq = (data, model) => {
      const obj = { ...data }
      obj.bill_sheet_id = model.id
      console.log('obj :>> beforeReq', obj)
      return obj
    }
  }

  console.log('defaultConfig :>> ', defaultConfig)
  return defaultConfig
}

/**
 * 开票计划添加弹窗
 * @param {*} MODULE
 * @param {*} type
 * @param {*} info
 * @param {*} from 来源
 * @returns
 */
export function financeInvoicePlanAddDialog(
  MODULE,
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('params :>> ', params)

  const defaultConfig = {
    title: '开票计划',
    dialog: {
      width: '750px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '80px',
        ...dynamicsFormAttrs,
      },
      formList: [
        {
          type: 'selectServer',
          key: 'invoice_title_id',
          labelAttrs: {
            label: '发票抬头',
            rules: [
              { required: true, message: '请选择发票抬头', trigger: 'blur' },
            ],
          },
          formAttrs: {
            clearable: false,
            filterable: true,
            listApi: `/invoice-title/index`,
            searchKey: 'search',
            labelKey: 'company',
            valueType: 'id',
            class: 'w220',
            placeholder: '请选择发票抬头',
            exParams: {
              customer_id: info.customer_id,
            },
            showAdd: true,
            alwaysAdd: true,
          },
          layoutAttrs: {
            span: 12,
          },
          action: {
            type: 'editBox',
            editBoxName: 'addInvoiceBox',
          },
        },
        {
          type: params?.type === 'orderlist' ? 'slot' : 'selectServer',
          key: 'sale_order_id',
          value: params?.type === 'orderlist' ? info?.id : '',
          labelAttrs: {
            label: '销售订单',
            rules: [
              {
                required: true,
                message: '请选择销售订单',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            listApi: `/sale-order/index`,
            searchKey: 'search',
            multiple: false,
            class: 'w220',
            placeholder: '请选择关联销售订单',
          },
          layoutAttrs: {
            span: params?.type === 'orderlist' ? 0 : 12,
          },
        },
        {
          type: 'inputNumber',
          key: 'amount',
          value: '',
          labelAttrs: {
            label: '开票金额',
            rules: [
              {
                required: true,
                message: '请输入开票金额',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            placeholder: '请输入开票金额',
            clearable: true,
            class: 'w220',
            tips: '含税金额',
            tipsCls: 'lh1 cd pt5 fs12',
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'input',
          key: 'content',
          value: '',
          labelAttrs: {
            label: '商品名称',
          },
          formAttrs: {
            placeholder: '请输入商品名称',
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'input',
          key: 'serial_no',
          value: '',
          labelAttrs: {
            label: '商品编号',
          },
          formAttrs: {
            placeholder: '请输入商品编号',
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'input',
          key: 'specification',
          value: '',
          labelAttrs: {
            label: '规格型号',
          },
          formAttrs: {
            placeholder: '请输入商品规格型号',
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'input',
          key: 'unit',
          value: '',
          labelAttrs: {
            label: '商品单位',
          },
          formAttrs: {
            placeholder: '请输入商品单位',
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'select',
          key: 'invoice_type',
          value: objToArr(INVOICE_TYPE)[0]?.value || '',
          labelAttrs: {
            label: '发票类型',
          },
          formAttrs: {
            placeholder: '请选择发票类型',
            clearable: true,
            class: 'w',
          },
          options: objToArr(INVOICE_TYPE),
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'inputNumber',
          key: 'tax_rate',
          value: '',
          labelAttrs: {
            label: '税率',
          },
          formAttrs: {
            min: 0,
            max: 100,
            placeholder: '请输入税率',
            class: 'wi',
          },
          sufUnit: '%',
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'inputNumber',
          key: 'num',
          value: 1,
          labelAttrs: {
            label: '商品数量',
          },
          formAttrs: {
            min: 1,
            placeholder: '请输入商品数量',
            class: 'wi',
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'datePicker',
          key: 'plan_date',
          value: dayjs().format('YYYY-MM-DD'),
          labelAttrs: {
            label: '开票日期',
            rules: [
              {
                required: true,
                message: '请选择开票日期',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            'value-format': 'YYYY-MM-DD',
            placeholder: '请选择开票日期',
            clearable: true,
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '备注',
          },
          formAttrs: {
            type: 'textarea',
            clearable: true,
            disabled: false,
            placeholder: '',
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  console.log('defaultConfig :>> ', defaultConfig)
  return defaultConfig
}

/**
 * 完成开票添加弹窗
 * @param {*} MODULE
 * @param {*} type
 * @param {*} info
 * @param {*} from 来源
 * @returns
 */
export function financeCompleteInvoiceDialog(
  MODULE,
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('params :>> ', params)

  const defaultConfig = {
    title: '开票明细',
    dialog: {
      width: '750px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/quick`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '100px',
      },
      formList: [
        {
          type: 'inputNumber',
          key: 'amount',
          value: '',
          labelAttrs: {
            label: '开票金额',
            rules: [
              {
                required: true,
                message: '开票金额',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            placeholder: '请输入开票金额',
            clearable: true,
            class: 'w220',
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'uploadImg',
          key: 'proof',
          labelAttrs: {
            label: '凭证',
          },
          formAttrs: {
            copyImgUploade: true,
            uploadParam: { module: 'invoice-plan' },
            limitSize: 1024 * 10,
          },
        },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '备注',
          },
          formAttrs: {
            type: 'textarea',
            clearable: true,
            disabled: false,
            placeholder: '',
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  console.log('defaultConfig :>> ', defaultConfig)
  return defaultConfig
}

/**
 * 其他费用添加弹窗
 * @param {*} MODULE
 * @param {*} type
 * @param {*} info
 * @param {*} from 来源
 * @returns
 */
export function otherExpenseAddDialog(
  MODULE,
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('params :>> ', params)
  const { supportCurrency, defaultCurrency } = handleSupportCurrency()

  const defaultConfig = {
    title: '其他费用',
    dialog: {
      width: '750px',
      top: '7vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '100px',
        ...dynamicsFormAttrs,
      },
      formList: [
        {
          type: params?.type ? 'slot' : 'selectServer',
          key:
            params?.type === 'orderlist'
              ? 'sale_order_id'
              : 'purchase_order_id',
          value: info?.[params?.valKey] || '',
          labelAttrs: {
            label: '销售订单',
          },
          formAttrs: {
            listApi: `/sale-order/index`,
            searchKey: 'search',
            labelKey: 'code',
            multiple: false,
            class: 'w',
            placeholder: '请选择关联销售订单',
          },
          layoutAttrs: {
            span: 24,
          },
        },
        {
          type: 'radio',
          key: 'pm',
          value: 0,
          labelAttrs: {
            label: '费用类型',
          },
          formAttrs: {
            clearable: true,
          },
          options: [
            { title: t('费用减免'), attrs: { label: 0 }, events: {} },
            { title: t('额外费用'), attrs: { label: 1 }, events: {} },
          ],
          formEvents: {},
          layoutAttrs: {
            // span: 12
          },
        },
        {
          type: 'inputNumber',
          key: 'amount',
          value: '',
          labelAttrs: {
            label: '金额',
            rules: [
              {
                required: true,
                message: '金额',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            placeholder: '请输入金额',
            clearable: true,
            class: 'wi',
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'select',
          key: 'currency',
          value:
            info?.sale_order_info?.currency ||
            info?.currency ||
            defaultCurrency ||
            '',
          labelAttrs: {
            label: '货币币种',
          },
          formAttrs: {
            disabled: type === 'edit' || !!params.type,
            placeholder: '请选择币种',
            class: 'w',
          },
          options: objToArr(supportCurrency, false),
          layoutAttrs: {
            span: 12,
          },
        },
        // {
        //   type: 'input',
        //   key: 'trade_no',
        //   labelAttrs: {
        //     label: '流水单号',
        //   },
        //   formAttrs: {
        //     class: 'w',
        //     placeholder: '请输入支付流水单号',
        //     clearable: true,
        //   },
        // },
        // {
        //   type: 'uploadImg',
        //   key: 'proof',
        //   labelAttrs: {
        //     label: '凭证',
        //   },
        //   formAttrs: {
        //     copyImgUploade: true,
        //     uploadParam: { module: 'bill' },
        //     limitSize: 1024 * 10,
        //   },
        // },

        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '费用说明',
          },
          formAttrs: {
            type: 'textarea',
            clearable: true,
            disabled: false,
            placeholder: '',
          },
        },
        {
          type: 'uploadImg',
          key: 'file',
          labelAttrs: {
            label: '文件',
          },
          formAttrs: {
            copyImgUploade: true,
            module: 'product',
            multiple: true,
            listType: 'text',
            accept: 'application/pdf, image/*',
            verifyFileType: false,
          },
        },
        {
          type: 'slot',
          key: 'date',
          value: dayjs().format('YYYY-MM-DD'),
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  // 回款计划添加
  if (params.type === 'plan') {
    defaultConfig.form.formList.splice(
      0,
      0,
      {
        type: 'slot',
        key: 'id',
        labelAttrs: {
          label: '',
        },
        formAttrs: {
          class: 'w',
          placeholder: '请输入名称',
          clearable: true,
        },
      },
      {
        type: 'slot',
        key: 'audit_status',
        value: 1,
      }
    )
    defaultConfig.form.api.add = '/bill/add'
    defaultConfig.form.api.update = '/bill/add'
    defaultConfig.form.beforeReq = (data) => {
      const obj = { ...data }
      obj.bill_plan_id = obj.id
      console.log('obj :>> beforeReq', obj)
      return obj
    }
  } else if (type === 'bill_period') {
    // 账期
    defaultConfig.form.api.add = `/${MODULE}/add`
    defaultConfig.form.api.update = `/${MODULE}/add`
    defaultConfig.form.api.detail = ''
    defaultConfig.form.customTitle = true
    defaultConfig.form.formList.splice(
      0,
      2,
      {
        type: 'slot',
        key: 'customer_id',
        value: '',
        labelAttrs: {
          label: '客户名称',
        },
        formAttrs: {
          clearable: false,
        },
      },
      {
        type: 'slot',
        key: 'bill_sheet_id',
        value: '',
        labelAttrs: {
          label: '账期',
        },
        formAttrs: {
          clearable: false,
        },
      },
      {
        type: 'slot',
        key: 'pm',
        value: 1,
        labelAttrs: {
          label: '类型',
        },
        formAttrs: {
          clearable: false,
        },
      }
    )
    defaultConfig.form.beforeReq = (data, model) => {
      const obj = { ...data }
      obj.bill_sheet_id = model.id
      console.log('obj :>> beforeReq', obj)
      return obj
    }
  }
  console.log('defaultConfig :>> ', defaultConfig)
  return defaultConfig
}
/**
 * 员工离职弹窗
 * @param {*} MODULE
 * @param {*} type
 * @param {*} info
 * @param {*} from 来源
 * @returns
 */
export function staffDepartEditDialog(MODULE, type = '', info = {}, from = '') {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('from :>> ', from)

  const defaultConfig = {
    title: '员工离职交接',
    customTitle: true,
    dialog: {
      width: '500px',
      top: '20vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/dimission`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '80px',
      },
      formList: [
        {
          type: 'selectServer',
          key: 'transfer_id',
          value: '',
          labelAttrs: {
            label: '交接人',
          },
          formAttrs: {
            listApi: `/admin/index`,
            class: 'w',
            labelKey: 'realname',
            // searchKey: 'realname',
            valueType: 'number',
            placeholder: '请选择交接人',
            exParams: {
              status: 1,
            },
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  console.log('defaultConfig :>> ', defaultConfig)
  return defaultConfig
}

/**
 * 员工添加弹窗
 * @param {*} MODULE
 * @param {*} type
 * @param {*} info
 * @param {*} from 来源
 * @returns
 */
export function staffAddDialog(MODULE, type = '', info = {}, from = '') {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('from :>> ', from)

  const defaultConfig = {
    title: '成员',
    dialog: {
      width: '700px',
      appendToBody: true,
      destroyOnClose: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        update: `/${MODULE}/edit`,
        detail: `/${MODULE}/detail`,
      },
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '100px',
      },
      formList: [
        {
          type: 'slot',
          key: 'type',
          value: type || 1,
          labelAttrs: {
            label: '类型',
          },
          formAttrs: {
            type: 'text',
          },
        },
        {
          type: 'input',
          key: 'name',
          labelAttrs: {
            label: '昵称',
            rules: [
              {
                required: true,
                message: '请输入昵称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入昵称',
            clearable: true,
          },
        },
        {
          type: 'input',
          key: 'realname',
          labelAttrs: {
            label: '真实姓名',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入真实姓名',
            clearable: true,
          },
        },
        {
          type: 'input',
          key: 'tel',
          labelAttrs: {
            label: '手机号',
            rules: [
              {
                required: true,
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '手机号',
            tipsCls: 'lh1 fs12 mt5 cd',
            tips: '密码为手机号后六位',
            clearable: true,
          },
        },
        {
          type: 'input',
          key: 'email',
          labelAttrs: {
            label: '邮箱',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入邮箱',
            clearable: true,
          },
        },
        {
          type: 'selectServer',
          key: 'role_ids',
          labelAttrs: {
            label: '角色',
          },
          formAttrs: {
            listApi: `/role/index`,
            class: 'w',
            clearable: true,
            multiple: true,
            valueType: 'number',
            placeholder: '请选择角色',
          },
        },
        {
          type: 'cascaderServer',
          key: 'organization_ids',
          value: '',
          labelAttrs: {
            label: '部门',
          },
          formAttrs: {
            placeholder: '请选择部门',
            clearable: true,
            filterable: true,
            multiple: true,
            class: 'w',
            'show-all-levels': true,
            listApi: '/organization/tree',
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: false,
              multiple: true,
            },
          },
        },
        {
          type: 'richText',
          key: 'intro',
          value: '',
          labelAttrs: {
            label: '职责',
          },
          formAttrs: {
            class: 'w',
            style: {
              height: '300px',
            },
            excludeKeys: richTextExcludeKeys,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '确定',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  if (+type === 2) {
    defaultConfig.title = '供应商账号'
    defaultConfig.form.formList.unshift({
      type: info.id ? 'slot' : 'selectServer',
      key: 'supplier_id',
      value: info.id || '',
      labelAttrs: {
        label: '关联供应商',
        rules: [
          {
            required: true,
            message: '请选择供应商',
            trigger: 'blur',
          },
        ],
      },
      formAttrs: {
        listApi: `/supplier/index`,
        class: 'w',
        clearable: true,
        valueType: 'number',
        placeholder: '请选择供应商',
      },
    })
    defaultConfig.form.formList.splice(-2, 2)
  }
  console.log('defaultConfig :>> ', defaultConfig)
  return defaultConfig
}
/**
 * 发货单添加弹窗
 * @param {*} MODULE
 * @param {*} type
 * @param {*} info
 * @param {*} from 来源
 * @returns
 */
export function supplierDeliveryAddDialog(
  MODULE,
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('params :>> ', params)
  const layoutSpan = type === 'batch' ? 24 : 6
  const defaultValue =
    params?.type === 'orderlist'
      ? info?.delivery_date || dayjs().format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD')
  const defaultConfig = {
    title: '入库预报',
    dialog: {
      width: '500px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
        options: (data) => {
          if (type === 'add' && data.filedata) {
            return { contentType: 'multipart/form-data;' }
          } else {
            return null
          }
        },
      },
      beforeReq: async (data) => {
        console.log('beforeReq :>> ', data)
        console.log('beforeReq :>> store', store)
        if (type === 'batch') {
          data.detail_list = data.detail_list.map((item) => {
            const repository_id = F.isArray(item?.repository_id)
              ? item?.repository_id[item?.repository_id.length - 1]
              : item?.repository_id || ''
            return {
              product_id: item.product_id || item.id || '',
              num: data.num || 1,
              price: item.price,
              repository_id: repository_id,
              admin_remark: item.admin_remark || '',
              supplier_id: item.supplier_id || '',
            }
          })
          delete data.num
          delete data.product_num
        } else {
          data.detail_list = handleDetailList(false, true)
        }
        debugger
        return data
      },
      desHeader: {
        title: '基本信息',
        // border: true,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '100px',
      },
      formList: [
        {
          type: 'input',
          key: 'title',
          value:
            (info?.customer_info?.title || '') +
            t('入库预报') +
            dayjs().format('YYYYMMDD'),
          labelAttrs: {
            label: '名称',
            rules: [
              {
                required: true,
                message: '请输入名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入名称',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'datePicker',
          key: 'delivery_time',
          value: defaultValue,
          labelAttrs: {
            label: '发货时间',
            rules: [
              {
                required: true,
                message: '发货时间',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            'value-format': 'YYYY-MM-DD',
            placeholder: '请选择发货时间',
            clearable: true,
            'default-value': defaultValue,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        // {
        //   type: 'select',
        //   key: 'distribution_mode',
        //   value: objToArr(DELIVERY_PLAN_DISTRIBUTION_MODE)[0].value || '',
        //   labelAttrs: {
        //     label: '配送方式',
        //   },
        //   formAttrs: {
        //     placeholder: '请选择配送方式',
        //     class: 'w',
        //   },
        //   options: objToArr(DELIVERY_PLAN_DISTRIBUTION_MODE),
        //   layoutAttrs: {
        //     span: 6,
        //   },
        // },
        // {
        //   type: 'slot',
        //   key: 'delivery_date',
        //   name: 'deliveryDate',
        //   labelAttrs: {
        //     label: '交货时间',
        //   },
        //   formAttrs: {},
        //   layoutAttrs: {
        //     span: 6,
        //   },
        // },
        // {
        //   type: 'slot',
        //   key: 'customer_address_id',
        //   name: 'customerAddress',
        //   labelAttrs: {
        //     label: '收货地址',
        //   },
        //   formAttrs: {},
        //   layoutAttrs: {
        //     span: 6,
        //   },
        // },
        {
          type: 'slot',
          key: 'detail_list',
          name: 'productList',
          value: '',
          labelAttrs: {
            label: '产品列表',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入名称',
            clearable: true,
          },
        },
        {
          type: 'slot',
          key: 'filedata',
          value: '',
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  if (type === 'order') {
    defaultConfig.form = {
      api: {
        add: `/${MODULE}/generateDeliveryPlan`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/generateDeliveryPlan`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '110px',
      },
      formList: [
        {
          type: 'slot',
          key: 'id',
          value: info?.id,
          labelAttrs: {
            label: '销售订单',
          },
          formAttrs: {},
        },
        {
          type: 'datePicker',
          key: 'plan_date',
          value: defaultValue,
          labelAttrs: {
            label: '发货时间',
            rules: [
              {
                required: true,
                message: '发货时间',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            'value-format': 'YYYY-MM-DD',
            placeholder: '请选择发货时间',
            clearable: true,
            'default-value': defaultValue,
          },
        },
      ],
    }
  } else if (type === 'edit') {
    defaultConfig.form = {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '110px',
      },
      formList: [
        {
          type: 'slot',
          key: 'id',
          value: info?.id || '',
          labelAttrs: {
            label: '销售订单',
          },
          formAttrs: {},
        },
        {
          type: 'datePicker',
          key: 'plan_date',
          value: defaultValue,
          labelAttrs: {
            label: '发货时间',
            rules: [
              {
                required: true,
                message: '发货时间',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            'value-format': 'YYYY-MM-DD',
            placeholder: '请选择发货时间',
            clearable: true,
            'default-value': defaultValue,
          },
        },
      ],
    }
  } else if (type === 'batch') {
    // 全量添加
    defaultConfig.title = '批量生成入库预报'
    defaultConfig.form.api.add = '/supplier-delivery/allIn'
    defaultConfig.form.beforeReq = null
    defaultConfig.form.formList.splice(
      2,
      0,
      {
        type: 'inputNumber',
        key: 'num',
        value: 1,
        labelAttrs: {
          label: '各产品数量',
        },
        formAttrs: {
          class: 'w',
          placeholder: '请填写数量',
          clearable: true,
        },
      }
      // {
      //   type: 'input',
      //   key: 'product_num',
      //   value: 1,
      //   labelAttrs: {
      //     label: '产品数量',
      //   },
      //   formAttrs: {
      //     disabled: true,
      //     class: 'w',
      //     placeholder: '产品总数量',
      //     clearable: true,
      //   },
      // }
    )
    defaultConfig.form.beforeCreate = {
      url: `product/index?page=1&per_page=1200&page=1&nature=0&status=1`,
      callback: (res, model) => {
        model.detail_list = res.data
        model.product_num = res.data.length
      },
    }
  }
  console.log('defaultConfig :>> ', defaultConfig)
  return defaultConfig
}
/**
 * 发货计划添加弹窗
 * @param {*} MODULE
 * @param {*} type
 * @param {*} info
 * @param {*} from 来源
 * @returns
 */
export function deliveryPlanAddDialog(
  MODULE,
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('params :>> ', params)

  const defaultValue =
    params?.type === 'orderlist'
      ? info?.delivery_date || dayjs().format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD')
  const defaultConfig = {
    title: '发货计划',
    dialog: {
      width: '500px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      beforeReq: (data, model) => {
        console.log('model :>> ', model)
        console.log('beforeReq :>> ', data)
        console.log('beforeReq :>> store', store)
        data.detail_list = handleDetailList()
        return data
      },
      desHeader: {
        title: '基本信息',
        // border: true,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '110px',
      },
      formList: [
        {
          type: 'slot',
          key: 'type',
          value: 1, // 1: 发货计划 2: 商品代发
          labelAttrs: {},
          formAttrs: {},
        },
        {
          type: 'input',
          key: 'title',
          value:
            (info?.customer_info?.title || '') +
            t('发货单') +
            dayjs().format('YYYYMMDD'),
          labelAttrs: {
            label: '名称',
            rules: [
              {
                required: true,
                message: '请输入名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入名称',
            clearable: true,
          },
          layoutAttrs: {
            span: 8,
          },
        },
        {
          type: params?.type === 'orderlist' ? 'slot' : 'selectServer',
          key: 'sale_order_id',
          value: params?.type === 'orderlist' ? info?.id : '',
          labelAttrs: {
            label: '销售订单',
            rules: [
              {
                required: true,
                message: '请选择销售订单',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            listApi: `/sale-order/index`,
            class: 'w',
            searchKey: 'search',
            multiple: false,
            placeholder: '请选择销售订单',
            emitSelect: true,
          },
          onChange: (val, model) => {
            console.log('val :>> ', val)
            console.log('model :>> ', model)
            model.customer_id = val.customer_id
            model.customer_address_id = ''
          },
          layoutAttrs: {
            span: params?.type === 'orderlist' ? 0 : 8,
          },
        },
        {
          type: 'datePicker',
          key: 'plan_date',
          value: defaultValue,
          labelAttrs: {
            label: '发货时间',
            rules: [
              {
                required: true,
                message: '发货时间',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            'value-format': 'YYYY-MM-DD',
            placeholder: '请选择发货时间',
            clearable: true,
            'default-value': defaultValue,
          },
          layoutAttrs: {
            span: 8,
          },
        },
        {
          type: 'select',
          key: 'distribution_mode',
          value: objToArr(DELIVERY_PLAN_DISTRIBUTION_MODE)[0].value || '',
          labelAttrs: {
            label: '配送方式',
          },
          formAttrs: {
            placeholder: '请选择配送方式',
            class: 'w',
          },
          options: objToArr(DELIVERY_PLAN_DISTRIBUTION_MODE),
          layoutAttrs: {
            span: 8,
          },
        },
        {
          type: 'slot',
          key: 'delivery_date',
          name: 'deliveryDate',
          labelAttrs: {
            label: '交货时间',
          },
          formAttrs: {},
          layoutAttrs: {
            span: 8,
          },
        },
        {
          type: 'slot',
          key: 'customer_id',
          value: info?.customer_id || '',
          labelAttrs: {},
          formAttrs: {},
        },
        {
          type: 'slot',
          key: 'customer_address_id',
          name: 'customerAddress',
          labelAttrs: {
            label: '收货地址',
          },
          formAttrs: {},
          layoutAttrs: {
            span: 8,
          },
        },
        {
          type: 'slot',
          key: 'detail_list',
          name: 'productList',
          value: '',
          labelAttrs: {
            label: '产品列表',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入名称',
            clearable: true,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  if (type === 'order') {
    defaultConfig.form = {
      api: {
        add: `/${MODULE}/generateDeliveryPlan`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/generateDeliveryPlan`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '110px',
      },
      formList: [
        {
          type: 'slot',
          key: 'id',
          value: info?.id,
          labelAttrs: {
            label: '销售订单',
          },
          formAttrs: {},
        },
        {
          type: 'datePicker',
          key: 'plan_date',
          value: defaultValue,
          labelAttrs: {
            label: '发货时间',
            rules: [
              {
                required: true,
                message: '发货时间',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            'value-format': 'YYYY-MM-DD',
            placeholder: '请选择发货时间',
            clearable: true,
            'default-value': defaultValue,
          },
        },
      ],
    }
  } else if (type === 'edit') {
    defaultConfig.form = {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '110px',
      },
      formList: [
        {
          type: 'slot',
          key: 'id',
          value: info?.id || '',
          labelAttrs: {
            label: '销售订单',
          },
          formAttrs: {},
        },
        {
          type: 'datePicker',
          key: 'plan_date',
          value: defaultValue,
          labelAttrs: {
            label: '发货时间',
            rules: [
              {
                required: true,
                message: '发货时间',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            'value-format': 'YYYY-MM-DD',
            placeholder: '请选择发货时间',
            clearable: true,
            'default-value': defaultValue,
          },
        },
        {
          type: 'select',
          key: 'distribution_mode',
          value: '',
          labelAttrs: {
            label: '配送方式',
          },
          formAttrs: {
            placeholder: '请选择配送方式',
            class: 'w',
          },
          options: objToArr(DELIVERY_PLAN_DISTRIBUTION_MODE),
        },
        {
          type: 'slot',
          key: 'delivery_date',
          name: 'deliveryDate',
          labelAttrs: {
            label: '交货时间',
          },
          formAttrs: {},
        },
        {
          type: 'slot',
          key: 'customer_id',
          labelAttrs: {},
          formAttrs: {},
        },
        {
          type: 'slot',
          key: 'customer_address_id',
          name: 'customerAddress',
          labelAttrs: {
            label: '收货地址',
          },
          formAttrs: {},
        },
      ],
    }
  }
  console.log('defaultConfig :>> ', defaultConfig)
  return defaultConfig
}

export function deliveryDetailEditDialog(MODULE, type = '', info = {}) {
  console.log('type :>> ', type)
  console.log('info', info)
  const defaultConfig = {
    title: '备货图',
    dialog: {
      width: '800px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        // detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '90px',
      },
      formList: [
        {
          type: 'uploadImg',
          key: 'images',
          labelAttrs: {
            label: '图片',
          },
          formAttrs: {
            multiple: true,
            uploadParam: { module: MODULE },
            // copyImgUploade: true,
            limitSize: 1024 * 10,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  return defaultConfig
}
/**
 * 供货商产品出库添加弹窗
 * @param {*} MODULE
 * @param {*} type
 * @param {*} info
 * @param {*} from 来源
 * @returns
 */
export function stockOutAddDialog(MODULE, type = '', info = {}, params = {}) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('params :>> ', params)

  const defaultValue =
    params?.type === 'orderlist'
      ? info?.delivery_date || dayjs().format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD')

  const commonFormListSpan = type === 'edit' ? 12 : 6
  // 收件人信息
  const recipientsFormList = [
    {
      type: 'input',
      key: 'rec_name',
      value: '',
      labelAttrs: {
        label: '收货人姓名',
        rules: [
          {
            required: true,
            message: '请输入收货人姓名',
            trigger: 'blur',
          },
        ],
      },
      formAttrs: {
        type: 'text',
        placeholder: '请输入收货人姓名',
        clearable: true,
      },
      layoutAttrs: {
        span: commonFormListSpan,
      },
    },
    {
      type: 'input',
      key: 'rec_mobile',
      value: '',
      labelAttrs: {
        label: '收货人电话',
      },
      formAttrs: {
        type: 'text',
        placeholder: '请输入收货人电话',
        clearable: true,
      },
      layoutAttrs: {
        span: commonFormListSpan,
      },
    },
    {
      type: 'input',
      key: 'rec_email',
      value: '',
      labelAttrs: {
        label: '收货人邮箱',
      },
      formAttrs: {
        type: 'text',
        placeholder: '请输入收货人邮箱',
        clearable: true,
      },
      layoutAttrs: {
        span: commonFormListSpan,
      },
    },
    {
      type: 'input',
      key: 'rec_address',
      value: '',
      labelAttrs: {
        label: '收货人地址',
        rules: [
          {
            required: true,
            message: '请输入收货人地址',
            trigger: 'blur',
          },
        ],
      },
      formAttrs: {
        type: 'text',
        placeholder: '请输入收货人地址',
        clearable: true,
      },
      layoutAttrs: {
        span: commonFormListSpan,
      },
    },
    {
      type: 'input',
      key: 'rec_address2',
      value: '',
      labelAttrs: {
        label: '收货人地址2',
      },
      formAttrs: {
        type: 'text',
        placeholder: '请输入收货人地址2',
        clearable: true,
      },
      layoutAttrs: {
        span: commonFormListSpan,
      },
    },
    {
      type: 'input',
      key: 'rec_city',
      labelAttrs: {
        label: '城市',
        rules: [
          {
            required: true,
            message: '请输入城市',
            trigger: 'blur',
          },
        ],
      },
      formAttrs: {
        type: 'text',
        placeholder: '请输入城市',
        clearable: true,
      },
      layoutAttrs: {
        span: commonFormListSpan,
      },
    },
    {
      type: 'slot',
      key: 'rec_state',
      name: 'stateSlot',
      value: info?.rec_state || '',
      labelAttrs: {
        label: '州',
        rules: [
          {
            required: true,
            message: '请输入州',
            trigger: 'blur',
          },
        ],
      },
      formAttrs: {
        type: 'text',
        placeholder: '请输入州',
        clearable: true,
      },
      layoutAttrs: {
        span: commonFormListSpan,
      },
    },
    {
      type: 'selectServer',
      key: 'rec_country',
      labelAttrs: {
        label: '国家',
        rules: [
          {
            required: true,
            message: '请选择国家',
            trigger: 'blur',
          },
        ],
      },
      formAttrs: {
        listApi: `/config/shipsaving/country`,
        class: 'w',
        valueType: 'number',
        placeholder: '请选择国家',
        noPaging: true,
        avatarKey: 'logo',
        avatarCls: 'w50',
        defaultSelect: true,
        emitSelect: true,
        handleDataFun: (data) => {
          return data.map((item) => {
            return {
              ...item,
              logo: `https://flagpedia.net/data/flags/w580/${item?.id?.toLowerCase()}.webp`,
            }
          })
        },
      },
      onChange: (val, model) => {
        model.stateOptions = val?.children?.length ? val.children : null
      },
      layoutAttrs: {
        span: commonFormListSpan,
      },
    },
    {
      type: 'input',
      key: 'rec_zip',
      labelAttrs: {
        label: '邮编',
        rules: [
          {
            required: true,
            message: '请输入邮编',
            trigger: 'blur',
          },
        ],
      },
      formAttrs: {
        type: 'text',
        placeholder: '请输入邮编',
        clearable: true,
      },
      layoutAttrs: {
        span: commonFormListSpan,
      },
    },
  ]
  const commonFormList = [
    {
      type: 'input',
      key: 'code',
      value: '',
      labelAttrs: {
        label: '平台单号',
      },
      formAttrs: {
        type: 'text',
        placeholder: '请输入平台单号',
        clearable: true,
      },
      layoutAttrs: {
        span: commonFormListSpan,
      },
    },
    {
      type: 'datePicker',
      key: 'plan_date',
      value: info.plan_date || defaultValue,
      labelAttrs: {
        label: '发货时间',
        rules: [
          {
            required: true,
            message: '发货时间',
            trigger: 'blur',
          },
        ],
      },
      formAttrs: {
        type: 'text',
        'value-format': 'YYYY-MM-DD',
        placeholder: '请选择发货时间',
        clearable: true,
        'default-value': defaultValue,
      },
      layoutAttrs: {
        span: commonFormListSpan,
      },
    },
    {
      type: 'input',
      key: 'packing_remark',
      value: '',
      labelAttrs: {
        label: '包装要求',
      },
      formAttrs: {
        type: 'text',
        placeholder: '请输入包装要求',
        clearable: true,
      },
      layoutAttrs: {
        span: commonFormListSpan,
      },
    },
    {
      type: 'input',
      key: 'admin_remark',
      value: '',
      labelAttrs: {
        label: '备注',
      },
      formAttrs: {
        type: 'text',
        placeholder: '请输入备注',
        clearable: true,
      },
      layoutAttrs: {
        span: commonFormListSpan,
      },
    },
    {
      type: 'selectServer',
      key: 'send_address_id',
      value: '',
      labelAttrs: {
        label: '寄件人',
        rules: [
          {
            required: true,
            message: '请选择寄件人信息',
            trigger: 'blur',
          },
        ],
      },
      formAttrs: {
        listApi: `/address/index`,
        class: 'w',
        searchKey: 'search',
        multiple: false,
        defaultSelect: true,
        labelKey: 'recipients',
        exLabelKeyFun: (item) => {
          return `${item.recipients} ${item.zip}${item.street}-${item.city}-${item.state}-${item.country}`
        },
        exParams: {
          sort: 'is_default',
          order: 'desc',
        },
        placeholder: '请选择寄件人信息',
      },
      layoutAttrs: {
        span: commonFormListSpan,
      },
    },
    {
      type: 'select',
      key: 'signature',
      value: objToArr(EXPRESS_SIGNATURE, false)[0].value,
      labelAttrs: {
        label: '签收选项',
      },
      formAttrs: {
        placeholder: '请选择签收选项',
        class: 'w',
      },
      options: objToArr(EXPRESS_SIGNATURE, false),
      layoutAttrs: { span: commonFormListSpan },
    },
    {
      type: 'select',
      key: 'insurance_provider',
      value: objToArr(EXPRESS_INSURANCE_PROVIDER, false)[0].value,
      labelAttrs: {
        label: '运输保险',
      },
      formAttrs: {
        placeholder: '请选择运输保险',
        class: 'w',
      },
      options: objToArr(EXPRESS_INSURANCE_PROVIDER, false),
      layoutAttrs: { span: commonFormListSpan },
    },
    {
      type: 'slot',
      key: 'insurance_amount',
      name: 'insuranceAmountSlot',
      value: null,
      labelAttrs: {
        label: '货品价值',
      },
      formAttrs: {},
      layoutAttrs: { span: commonFormListSpan },
    },
  ]
  if (type === 'edit') {
    const SEND_ADDRESS_ID_INDEX = getFormListIndex(
      'send_address_id',
      commonFormList
    )
    commonFormList.splice(SEND_ADDRESS_ID_INDEX, 0, ...recipientsFormList)
  }

  const defaultConfig = {
    title: '商品代发',
    dialog: {
      width: '500px',
      top: '10vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      beforeReq: (data) => {
        console.log('beforeReq :>> ', data)
        console.log('beforeReq :>> store', store)
        data.detail_list = handleDetailList()
        return data
      },
      desHeader: {
        title: '基本信息',
        // border: true,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '110px',
      },
      formList: [
        {
          type: 'slot',
          key: 'type',
          value: 2, // 1: 发货计划 2: 商品代发
          labelAttrs: {},
          formAttrs: {},
          layoutAttrs: {},
        },
        {
          type: 'input',
          key: 'title',
          value:
            (info?.customer_info?.title || '') +
            t('商品代发单') +
            dayjs().format('YYYYMMDD'),
          labelAttrs: {
            label: '名称',
            rules: [
              {
                required: true,
                message: '请输入名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入名称',
            clearable: true,
          },
          layoutAttrs: {
            span: 6,
          },
        },
        ...commonFormList,
        {
          type: 'slot',
          key: 'detail_list',
          name: 'productList',
          value: '',
          labelAttrs: {
            label: '产品列表',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入名称',
            clearable: true,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  if (type === 'order') {
    defaultConfig.form = {
      api: {
        add: `/${MODULE}/generateDeliveryPlan`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/generateDeliveryPlan`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '110px',
      },
      formList: [
        {
          type: 'slot',
          key: 'id',
          value: info?.id,
          labelAttrs: {
            label: '销售订单',
          },
          formAttrs: {},
        },
        {
          type: 'datePicker',
          key: 'plan_date',
          value: defaultValue,
          labelAttrs: {
            label: '发货时间',
            rules: [
              {
                required: true,
                message: '发货时间',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            'value-format': 'YYYY-MM-DD',
            placeholder: '请选择发货时间',
            clearable: true,
            'default-value': defaultValue,
          },
        },
      ],
    }
  } else if (type === 'edit') {
    defaultConfig.dialog.width = '800px'
    defaultConfig.form = {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '110px',
      },
      formList: [
        {
          type: 'slot',
          key: 'id',
          value: info?.id || '',
          labelAttrs: {
            label: '销售订单',
          },
          formAttrs: {},
        },
        ...commonFormList,
      ],
    }
  } else if (type === 'add') {
    if (params.type === 'todayPlanlist') {
      const insuranceAmountIndex = getFormListIndex(
        'insurance_amount',
        defaultConfig.form.formList
      )
      // 仓库端添加
      defaultConfig.form.formList.splice(insuranceAmountIndex, 0, {
        type: 'slot',
        key: 'supplier_id',
        value: info.id, // 1: 发货计划 2: 商品代发
        labelAttrs: {},
        formAttrs: {},
        layoutAttrs: {},
      })
    }
    const insuranceAmountIndex = getFormListIndex(
      'insurance_amount',
      defaultConfig.form.formList
    )
    defaultConfig.form.formList.splice(
      insuranceAmountIndex,
      0,
      {
        type: 'divider',
        value: '收货人信息',
        labelAttrs: {
          labelWidth: '0px',
        },
        formAttrs: {
          contentPosition: 'center',
        },
      },
      ...recipientsFormList
    )
  }
  console.log('defaultConfig :>> ', defaultConfig)
  return defaultConfig
}

/**
 * 供货商产品退货添加弹窗
 * @param {*} MODULE
 * @param {*} type
 * @param {*} info
 * @param {*} from 来源
 * @returns
 */
export function salesReturnAddDialog(
  MODULE,
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('params :>> ', params)
  let title = type === 'salesClaim' ? t('快递索赔单') : t('商品退货单')
  if (type === 'stockAdmin') {
    title = t('仓库代退-商品退货单')
  }
  // const hasInfo = !!info?.id
  const defaultConfig = {
    title: title,
    dialog: {
      width: '500px',
      top: '10vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        // detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      beforeReq: (data) => {
        console.log('beforeReq :>> ', data)
        console.log('beforeReq :>> store', store)
        data.detail_list = handleDetailList()
        return data
      },
      beforeCreate: {
        setModelFun: (model) => {
          return {
            delivery_plan_id: model?.id || '',
            express_task_info: model?.express_task_info || {},
          }
        },
      },
      desHeader: {
        title: '基本信息',
        // border: true,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '80px',
      },
      formList: [
        // {
        //   type: hasInfo ? 'slot' : 'selectServer',
        //   key: 'delivery_plan_id',
        //   value: info?.id || '',
        //   labelAttrs: {
        //     label: '出库订单',
        //     rules: [
        //       {
        //         required: true,
        //         message: '请选择商品代发订单',
        //         trigger: 'blur',
        //       },
        //     ],
        //   },
        //   formAttrs: {
        //     listApi: `/delivery-plan/index`,
        //     searchKey: 'search',
        //     multiple: false,
        //     class: 'w',
        //     emitSelect: true,
        //     exParams: {
        //       type: 2,
        //       audit_status: 10 // 审核通过的
        //     },
        //     placeholder: '请选择关联商品代发订单',
        //   },
        //   layoutAttrs: {
        //     span: hasInfo ? 0 : 6,
        //   },
        //   onChange: (val, model) => {
        //     model.title = (val?.express_task_info?.rec_name || '') + title + dayjs().format('YYYYMMDD')
        //   }
        // },
        {
          type: 'input',
          key: 'tracking_num',
          labelAttrs: {
            label: '快递单号',
            rules: [
              {
                required: true,
                message: '请输入快递单号',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入快递单号',
            clearable: true,
          },
          layoutAttrs: {
            span: type === 'salesClaim' ? 6 : 5,
          },
        },
        {
          type: 'input',
          key: 'title',
          value:
            (info?.express_task_info?.rec_name || '') +
            title +
            dayjs().format('YYYYMMDD'),
          labelAttrs: {
            label: '名称',
            rules: [
              {
                required: true,
                message: '请输入名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入名称',
            clearable: true,
          },
          layoutAttrs: {
            span: type === 'salesClaim' ? 6 : 5,
          },
        },
        {
          type: 'radio',
          key: 'handle',
          value: 0,
          labelAttrs: {
            label: '处理方式',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请选择处理方式',
          },
          sufUnitFun: (model) => {
            if (model.handle === 2) {
              return t('将收取一定的费用')
            }
          },
          sufUnitCls: 'fs12 cd lh1 mt2',
          options: objToArrRadio(SALE_RETURN_HANDLE),
          layoutAttrs: {
            span: type === 'salesClaim' ? 6 : 8,
          },
        },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '备注',
          },
          formAttrs: {
            clearable: true,
            placeholder: '请输入备注信息',
          },
          layoutAttrs: {
            span: 6,
          },
        },
        {
          type: 'slot',
          key: 'detail_list',
          name: 'productList',
          value: '',
          labelAttrs: {
            label: '产品列表',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入名称',
            clearable: true,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  if (type === 'takePicture' || type === 'takePictureSeparate') {
    defaultConfig.title =
      type === 'takePictureSeparate' ? '退货拍照(拆开)' : '退货拍照(外观)'
    defaultConfig.customTitle = true
    defaultConfig.dialog.width = '800px'
    defaultConfig.form = {
      api: {
        add: `/${MODULE}/image`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/image`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '110px',
      },
      formList: [
        {
          type: 'uploadImg',
          key: type === 'takePictureSeparate' ? 'inner_images' : 'images',
          labelAttrs: {
            label: type === 'takePictureSeparate' ? '拆开照片' : '外观照片',
          },
          formAttrs: {
            multiple: true,
            uploadParam: { module: 'supplier-sale-return' },
            copyImgUploade: true,
            limitSize: 1024 * 10,
            tips: '提交后将会通知供货商',
            tipsCls: 'cd',
            afterBtnText: '从素材库选择',
          },
        },
      ],
    }
  } else if (type === 'handle') {
    defaultConfig.title = '选择处理方式'
    defaultConfig.customTitle = true
    defaultConfig.dialog.width = '500px'
    defaultConfig.form = {
      api: {
        add: `/${MODULE}/handle`,
        // detail: `/${MODULE}/detail`,
        update: `/${MODULE}/handle`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '110px',
      },
      beforeReq: async (data) => {
        data.handle = data.handles
        delete data.handles
        return data
      },
      formList: [
        {
          type: 'radio',
          key: 'handles',
          value: 1,
          labelAttrs: {
            label: '处理方式',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请选择处理方式',
          },
          sufUnitFun: (model) => {
            if (model.handles === 2 || model.handles === 0) {
              return t('将收取一定的费用')
            }
          },
          sufUnitCls: 'fs12 cd lh1 mt2',
          // options: objToArrRadio(SALE_RETURN_HANDLE).slice(1),
          optionsFun: (model) => {
            return objToArrRadio(SALE_RETURN_HANDLE).slice(
              model.handle === -1 ? 1 : 2
            )
          },
        },
      ],
    }
  } else if (type === 'expense') {
    defaultConfig.title = '处理费用'
    defaultConfig.customTitle = true
    defaultConfig.dialog.width = '500px'
    defaultConfig.form = {
      api: {
        add: `/${MODULE}/process`,
        // detail: `/${MODULE}/detail`,
        update: `/${MODULE}/process`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '90px',
      },
      beforeReq: async (data) => {
        data.handle = data.handles
        delete data.handles
        return data
      },
      formList: [
        {
          type: 'inputNumber',
          key: 'amount',
          value: null,
          labelAttrs: {
            label: '处理费用',
            rules: [
              {
                required: true,
                message: '请输入处理费用',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            class: 'wa',
            min: 0,
            placeholder: '请输入处理费用',
          },
          preUnit: '$',
        },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '备注',
          },
          formAttrs: {
            type: 'textarea',
            clearable: true,
            disabled: false,
            placeholder: '',
          },
        },
      ],
    }
  } else if (type === 'edit') {
    defaultConfig.dialog.width = '800px'
    defaultConfig.form = {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '110px',
      },
      formList: [
        {
          type: 'slot',
          key: 'id',
          value: info?.id || '',
          labelAttrs: {
            label: '销售订单',
          },
          formAttrs: {},
        },
      ],
    }
  } else if (type === 'salesClaim') {
    defaultConfig.title = '商品快递索赔'
    defaultConfig.dialog.width = '800px'
    defaultConfig.form.formList = [
      {
        type: 'input',
        key: 'tracking_num',
        labelAttrs: {
          label: '快递单号',
          rules: [
            {
              required: true,
              message: '请输入快递单号',
              trigger: 'blur',
            },
          ],
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入快递单号',
          clearable: true,
        },
        layoutAttrs: {
          span: 12,
        },
      },
      {
        type: 'inputNumber',
        key: 'worth',
        value: null,
        labelAttrs: {
          label: '货品价值',
          rules: [
            {
              required: true,
              message: '请输入货品价值',
              trigger: 'blur',
            },
          ],
        },
        formAttrs: {
          class: 'wa',
          min: 0,
          placeholder: '请输入货品价值',
        },
        preUnit: '$',
        layoutAttrs: {
          span: 12,
        },
      },
      {
        type: 'input',
        key: 'reason',
        value: '',
        labelAttrs: {
          label: '原因',
        },
        formAttrs: {
          type: 'textarea',
          clearable: true,
          disabled: false,
          placeholder: '请输入索赔原因',
        },
      },
      {
        type: 'uploadImg',
        key: 'order_screenshot',
        labelAttrs: {
          label: '订单截图',
        },
        formAttrs: {
          uploadParam: { module: 'supplier-sale-return' },
          copyImgUploade: true,
          limitSize: 1024 * 10,
        },
      },
      {
        type: 'uploadImg',
        key: 'proof',
        labelAttrs: {
          label: '证明图片',
        },
        formAttrs: {
          multiple: true,
          uploadParam: { module: 'supplier-sale-return' },
          copyImgUploade: true,
          limitSize: 1024 * 10,
        },
      },
    ]
  } else if (type === 'claimResult') {
    // 商品快递索赔结果
    defaultConfig.title = '索赔结果'
    defaultConfig.customTitle = true
    defaultConfig.dialog.width = '500px'
    defaultConfig.form = {
      api: {
        add: `/${MODULE}/process`,
        // detail: `/${MODULE}/detail`,
        update: `/${MODULE}/process`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '90px',
      },
      beforeReq: async (data) => {
        data.status = data.statuss
        delete data.statuss
        return data
      },
      formList: [
        {
          type: 'radio',
          key: 'statuss',
          value: 100,
          labelAttrs: {
            label: '结果',
          },
          formAttrs: {
            class: 'wa',
            placeholder: '请选择结果',
          },
          options: objToArrRadio(SUPPLIER_CLAIM_STATUS).slice(-2),
        },
        {
          type: 'inputNumber',
          key: 'amount',
          value: null,
          labelAttrs: {
            label: '费用',
            rules: [
              {
                required: true,
                message: '请输入费用',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            class: 'wa',
            min: 0,
            placeholder: '请输入费用',
          },
          preUnit: '$',
        },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '备注',
          },
          formAttrs: {
            type: 'textarea',
            clearable: true,
            disabled: false,
            placeholder: '',
          },
        },
      ],
    }
  } else if (type === 'stockAdmin') {
    // 仓库端 可直接退货
    defaultConfig.form.formList.splice(0, 0, {
      type: 'selectServer',
      key: 'supplier_id',
      labelAttrs: {
        label: '供应商',
        rules: [
          {
            required: true,
            message: '请选择供应商',
            trigger: 'blur',
          },
        ],
      },
      formAttrs: {
        clearable: false,
        filterable: true,
        listApi: `/supplier/index`,
        searchKey: 'title',
        class: 'w',
        valueType: 'number',
        placeholder: '请选择供应商',
        defaultSelect: true,
      },
      layoutAttrs: {
        span: 5,
      },
    })
  }
  console.log('defaultConfig :>> ', defaultConfig)
  return defaultConfig
}

/**
 * 发货计划三方代发添加弹窗
 * @param {*} MODULE
 * @param {*} type
 * @param {*} info
 * @param {*} params 来源
 * @returns
 */
export function deliveryPlanThirdSendDialog(
  MODULE,
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('params :>> ', params)

  const defaultConfig = {
    title: '三方发货',
    dialog: {
      width: '500px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        update: `/${MODULE}/freightForwarding        `,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '100px',
      },
      formList: [
        {
          type: 'input',
          key: 'express_num',
          value: '',
          labelAttrs: {
            label: '快递单号',
            rules: [
              {
                required: true,
                message: '请输入快递单号',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'textarea',
            row: 4,
            placeholder: '请输入快递单号 多个用英文逗号隔开',
            clearable: true,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  console.log('defaultConfig :>> ', defaultConfig)
  return defaultConfig
}

function getDetailNameAndWeight(info) {
  let productName = ''
  let productWeight = 0
  if (info.detail_list) {
    info.detail_list.map((item, index) => {
      if (index) {
        productName = `${productName} 等${info.detail_list.length}件产品`
      } else {
        productName = `${item?.product_info?.title || ''} x ${item.num} - ${
          item?.product_info?.code || ''
        }`
      }
      if (item.product_info?.box_weight) {
        productWeight += +item.product_info?.box_weight || 0
      }
    })
  }
  return {
    productName,
    productWeight,
  }
}
/**
 * 快递寄件添加弹窗
 * @param {*} MODULE
 * @param {*} type
 * @param {*} info
 * @param {*} from 来源
 * @param {*} callback 回调
 * @param {*} expressType 快递平台 kuaidi100：快递100  kuaibao：快宝
 * @returns
 */
export function expressDeliveryAddDialog(
  MODULE,
  type = '',
  info = {},
  params = {},
  callback = null,
  expressType = 'kuaidi100'
) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('params :>> ', params)
  const layoutSpan = 12
  const userInfo = store.getters['user/userInfo']
  let supportExpress = []
  if (expressType === 'kuaidi100') {
    supportExpress = objToArr(store.getters['sys/supportExpress'], false)
  } else if (expressType === 'kuaibao') {
    supportExpress = objToArr(store.getters['sys/supportExpressKb'], false)
  }
  let productWeight = ''
  let productName = ''
  if (info.id && type != 'addTracking') {
    const result = getDetailNameAndWeight(info)
    productWeight = result.productWeight
    productName = result.productName
  }
  const defaultConfig = {
    title: '快递寄件',
    customTitle: true,
    dialog: {
      width: '850px',
      top: '10vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        update: `/${MODULE}/edit`,
      },
      beforeCreate: {},
      desHeader: {
        title: '基本信息',
        // border: true,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '110px',
      },
      beforeReq: async (data) => {
        // 列表数据有服务商字段
        data.service = data.services
        delete data.services
        return data
      },
      formList: [
        {
          type: params?.type === 'deliveryPlan' ? 'slot' : 'selectServer',
          key: 'delivery_plan_id',
          value: params?.type === 'deliveryPlan' ? info?.id : '',
          labelAttrs: {
            label: '发货计划',
            rules: [
              {
                required: true,
                message: '请选择发货计划',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            listApi: `/delivery-plan/index`,
            class: 'w',
            refKey: 'rec_address_id',
            refSearchKey: 'customer_id',
            searchKey: 'search',
            multiple: false,
            emitSelect: true,
            placeholder: '请选择发货计划',
          },
          onChange: (val, model) => {
            console.log('val :>> ', val)
            console.log('model', model)
            model.rec_name = val?.customer_info?.title
            model.rec_mobile = val?.customer_info?.telephone
            model.rec_address = val?.customer_info?.address
          },
          searchValFun: (val) => {
            console.log('val searchValFun', val)
            return val.customer_id || 0
          },
        },
        // {
        //   type: 'selectServer',
        //   key: 'rec_address_id',
        //   cptKey: 'rec_address_id',
        //   value: '',
        //   labelAttrs: {
        //     label: '收件人',
        //   },
        //   formAttrs: {
        //     listApi: `/customer-address/index`,
        //     class: 'w',
        //     searchKey: 'search',
        //     multiple: false,
        //     emitSelect: true,
        //     showAdd: true,
        //     alwaysAdd: true,
        //     exLabelKeyFun: (info) => {
        //       return `${info.recipients}-${info.phone}-${info.address}`
        //     },
        //     exParams: {
        //       customer_id: info.customer_id || 0,
        //     },
        //     placeholder: '请选择收件人信息',
        //     tips: '销售>客户管理>详情>收货地址 中添加',
        //     tipsCls: 'cd fs12',
        //   },
        //   action: {
        //     type: 'editBox',
        //     editBoxName: 'addCoustomAddressBox',
        //   },
        //   onChange: (val, model, data) => {
        //     // debugger
        //     console.log('val :>> ', val);
        //     console.log('model', model)
        //     console.log('data', data)
        //     model.rec_name = val?.recipients
        //     model.rec_mobile = val?.phone
        //     model.rec_address = val?.address
        //   },
        //   layoutAttrs: {
        //     span: layoutSpan,
        //   },
        // },
        {
          type: 'divider',
          value: '收件人信息',
          labelAttrs: {
            labelWidth: '0px',
            style: {
              'margin-bottom': '10px',
            },
          },
          formAttrs: {
            class: 'c0',
            contentPosition: 'center',
          },
          layoutAttrs: {
            span: 24,
            // class: 'mt10'
          },
        },
        {
          type: 'input',
          key: 'rec_name',
          value: info.customer_info?.title || '',
          labelAttrs: {
            label: '收件人姓名',
            rules: [
              {
                required: true,
                message: '请输入收件人姓名',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入收件人姓名',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'input',
          key: 'rec_mobile',
          value: info.customer_info?.telephone || '',
          labelAttrs: {
            label: '收件人手机号',
            rules: [
              {
                required: true,
                message: '请输入收件人手机号',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入收件人手机号',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'input',
          key: 'rec_address',
          value: info.customer_info?.address || '',
          labelAttrs: {
            label: '收件人地址',
            rules: [
              {
                required: true,
                message: '请输入收件人地址',
                trigger: 'blur',
              },
            ],
            style: {
              'margin-bottom': '10px',
            },
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入收件人地址 省市区县街道',
            clearable: true,
          },
          layoutAttrs: {
            span: 24,
          },
        },
        {
          type: 'divider',
          value: '寄件人信息',
          labelAttrs: {
            labelWidth: '0px',
            style: {
              'margin-bottom': '10px',
            },
          },
          formAttrs: {
            class: 'c0',
            contentPosition: 'center',
          },
          layoutAttrs: {
            span: 24,
            // class: 'mt10'
          },
        },
        {
          type: 'selectServer',
          key: 'send_address_id',
          value: '',
          labelAttrs: {
            label: '寄件人',
            style: {
              'margin-bottom': '10px',
            },
          },
          formAttrs: {
            listApi: `/address/index`,
            class: 'w',
            searchKey: 'search',
            multiple: false,
            defaultSelect: true,
            emitSelect: true,
            showAdd: true,
            labelKey: 'recipient',
            exLabelKeyFun: (item) => {
              return `${item.recipients} ${item.phone}${item.zip}${
                item.street
              } ${item.city || ''} ${item.state || ''} ${item.country || ''}`
            },
            exParams: {
              sort: 'is_default',
              order: 'desc',
            },
            placeholder: '快捷选择寄件人信息',
            tips: '更多快捷寄件人信息请到[配置>公司信息>发货地址]中添加',
            tipsCls: 'g6 fs12 lh1 mt5',
          },
          action: {
            type: 'editBox',
            editBoxName: 'addAddressBox',
          },
          onChange: (val, model) => {
            // debugger
            console.log('val :>> ', val)
            console.log('model', model)
            if (val?.id) {
              model.send_name = val?.recipients
              model.send_mobile = val?.phone
              model.send_address = val?.street
            }
          },
          // layoutAttrs: {
          //   span: layoutSpan,
          // },
        },
        // 寄件人信息
        {
          type: 'input',
          key: 'send_name',
          value: userInfo.realname || '',
          labelAttrs: {
            label: '寄件人姓名',
            rules: [
              {
                required: true,
                message: '请输入寄件人姓名',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入寄件人姓名',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'input',
          key: 'send_mobile',
          value: userInfo.tel || '',
          labelAttrs: {
            label: '寄件人手机号',
            rules: [
              {
                required: true,
                message: '请输入寄件人手机号',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入寄件人手机号',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'input',
          key: 'send_address',
          value: userInfo.address || '',
          labelAttrs: {
            label: '寄件人地址',
            rules: [
              {
                required: true,
                message: '请输入寄件人地址',
                trigger: 'blur',
              },
            ],
            style: {
              'margin-bottom': '10px',
            },
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入收件人地址 省市区县街道',
            clearable: true,
          },
          layoutAttrs: {
            span: 24,
          },
        },
        {
          type: 'divider',
          value: '快递信息',
          labelAttrs: {
            labelWidth: '0px',
            style: {
              'margin-bottom': '10px',
            },
          },
          formAttrs: {
            class: 'c0',
            contentPosition: 'center',
          },
          layoutAttrs: {
            span: 24,
            // class: 'mt10'
          },
        },
        {
          type: 'select',
          key: 'express_com',
          value: info?.express_com || supportExpress?.[0]?.value || '',
          labelAttrs: {
            label: '快递公司',
            rules: [
              {
                required: true,
                message: '请选择快递公司',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            placeholder: '请选择快递公司',
            class: 'w',
          },
          options: supportExpress,
          layoutAttrs: {
            span: 24,
          },
        },
        {
          type: 'input',
          key: 'cargo',
          value: productName || '',
          labelAttrs: {
            label: '物品名称',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入物品名称',
            clearable: true,
          },
          layoutAttrs: {
            span: 24,
          },
        },
        {
          type: 'select',
          key: 'payment',
          value: 'MONTHLY',
          labelAttrs: {
            label: '支付方式',
          },
          formAttrs: {
            placeholder: '请选择支付方式',
            class: 'w',
          },
          options: objToArr(EXPRESS_PAY_TYPE, false),
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          // type: 'input',
          type: 'slot',
          key: 'weight',
          value: productWeight || '',
          labelAttrs: {
            label: '物品总重量(Kg)',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入物品总重量 无需单位',
            clearable: true,
          },
          layoutAttrs: {
            // span: 10,
          },
        },
        // {
        //   type: 'slot',
        //   key: 'csentPrice',
        //   name: 'csentPrice',
        //   value: '',
        //   labelAttrs: {
        //     labelWidth: '0',
        //     label: '运费预估',
        //   },
        //   formAttrs: {},
        //   layoutAttrs: {
        //     span: 2,
        //   },
        // },
        // {
        //   type: 'select',
        //   key: 'dayType',
        //   value: objToArr(EXPRESS_DATA, false)[0].value || '',
        //   labelAttrs: {
        //     label: '预约日期',
        //   },
        //   formAttrs: {
        //     placeholder: '请选择预约日期',
        //     class: 'w',
        //   },
        //   options: objToArr(EXPRESS_DATA, false),
        //   layoutAttrs: {
        //     span: layoutSpan,
        //   },
        // },
        // {
        //   type: 'timePicker',
        //   key: 'pickup_time',
        //   startKey: 'pickup_start_time',
        //   endKey: 'pickup_end_time',
        //   value: '',
        //   labelAttrs: {
        //     label: '预约时间',
        //   },
        //   formAttrs: {
        //     isRange: true,
        //     // arrowControl: true,
        //     format: 'hh:mm',
        //     rangeSeparator: "To",
        //     startPlaceholder: "起始时间",
        //     endPlaceholder: "截止时间",
        //   },
        //   layoutAttrs: {
        //     span: layoutSpan,
        //   },
        // },
        { type: 'slot', key: 'services', value: expressType },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '寄件',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  if (type === 'deliveryPlanDetail') {
    defaultConfig.form.beforeCreate.setModelFun = (result) => {
      if (result?.detail_list) {
        const { productWeight, productName } = getDetailNameAndWeight(result)
        result.weight = productWeight
        result.cargo = productName
        result.payment = 'MONTHLY'
      }
      return result
    }
    const waitTime = 5 * 1000
    ;(defaultConfig.form.api.update = `/${MODULE}/add`),
      (defaultConfig.form.api.addCallback = () => {
        const loading = gp.$baseLoading('', '下单中...')
        setTimeout(() => {
          loading.close()
        }, waitTime)
        callback?.()
      })
    defaultConfig.form.api.updateCallback = () => {
      const loading = gp.$baseLoading('', '下单中...')
      setTimeout(() => {
        loading.close()
      }, waitTime)
      callback?.()
    }
  } else if (type === 'addTracking') {
    defaultConfig.title = '添加快递单号'
    defaultConfig.dialog.width = '500px'
    defaultConfig.form.formList = [
      {
        type: 'input',
        key: 'task_id',
        value: '',
        labelAttrs: {
          label: '快递单号',
          rules: [
            {
              required: true,
              message: '请输入快递单号',
              trigger: 'blur',
            },
          ],
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入快递单号',
          clearable: true,
        },
        layoutAttrs: {},
      },
      {
        type: 'input',
        key: 'remark',
        value: '',
        labelAttrs: {
          label: '备注',
        },
        formAttrs: {
          type: 'textarea',
          clearable: true,
          disabled: false,
          placeholder: '',
        },
        layoutAttrs: {},
      },
      {
        type: 'slot',
        key: 'purchase_order_id',
        value: info.id || '',
      },
    ]
    defaultConfig.footer = [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '确定',
        attrs: {
          type: 'primary',
        },
      },
    ]
  }
  // 快宝
  if (expressType === 'kuaibao') {
    defaultConfig.form.formList.splice(
      12,
      0,
      {
        type: 'radio',
        key: 'printer',
        value: 1,
        labelAttrs: {
          label: '立即打印面单',
        },
        formAttrs: {
          placeholder: '请选择',
          class: 'w',
        },
        options: objToArrRadio(YES_NO),
        layoutAttrs: {
          span: 8,
        },
      },
      {
        type: 'selectServer',
        key: 'printer_id',
        labelAttrs: {
          label: '打印机',
        },
        formAttrs: {
          listApi: `/printer/index`,
          class: 'w',
          labelKey: 'title',
          searchKey: 'title',
          clearable: false,
          placeholder: '请选择打印机',
          defaultSelect: true,
          emitSelect: true,
          tips: '配置>硬件配置>打印机 中添加',
          tipsCls: 'g9 fs12',
          exLabelKeyFun: (item) => {
            return `${item.title}（${PRINTER_TYPE[item.type]}）`
          },
        },
        layoutAttrs: {
          span: 16,
        },
        condition: (model) => {
          return model.printer === 1
        },
      }
    )
    defaultConfig.form.formList.splice(16, 0, {
      type: 'selectServer',
      key: 'account_id',
      labelAttrs: {
        label: '快递配置',
      },
      formAttrs: {
        listApi: `/express-account/index`,
        class: 'w',
        labelKey: 'title',
        searchKey: 'title',
        clearable: false,
        defaultSelect: true,
        placeholder: '请选择快递配置',
        exParams: {
          service: 'kuaibao',
        },
      },
      layoutAttrs: {
        span: 12,
      },
      condition: (model) => {
        return model.payment === 'MONTHLY'
      },
    })
  }
  console.log('defaultConfig :>> ', defaultConfig)
  return defaultConfig
}
/**
 * 出入库单添加弹窗
 * @param {*} MODULE
 * @param {*} type
 * @param {*} info
 * @param {*} from 来源
 * @returns
 */
export function repositoryRecordAddDialog(
  MODULE,
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> repositoryRecordAddDialog', type)
  console.log('info :>> ', info)
  console.log('params :>> ', params)
  const defaultValue = dayjs().format('YYYY-MM-DD')
  const paramsType = params?.type || ''
  let pmDefaultValue = 0
  let hidePmSelect = false // 隐藏出入库选择
  if (paramsType === 'purchaselist') {
    // 采购
    pmDefaultValue = 1
  } else if (paramsType === 'inboundOrderList') {
    // 入库添加
    pmDefaultValue = 1
    hidePmSelect = true
  } else if (paramsType === 'outboundOrderList') {
    // 出库
    pmDefaultValue = 0
    hidePmSelect = true
  } else if (paramsType === 'entrustOrderList') {
    pmDefaultValue = 1
  }

  const defaultConfig = {
    title: '出入库',
    dialog: {
      width: '500px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
      fullscreen: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '80px',
        ...dynamicsFormAttrs,
      },
      beforeReq: (model) => {
        console.log('model :>> ', model)
        const obj = { ...model }
        if (paramsType === 'orderlist') {
          obj.sale_order_id = info?.id
          obj['type'] = +model.pm === 0 ? 10 : 5
        } else if (paramsType === 'purchaselist') {
          obj.purchase_order_id = info?.id
          obj['type'] = +model.pm === 1 ? 1 : 12
        } else {
          if (+model.pm === 0) {
            obj.customer_id = model.supplier_id
          } else if (+model.pm === 1) {
            obj.supplier_id = model.supplier_id
          }
          obj['type'] = +model.pm === 0 ? 19 : 9
        }
        obj.pm = model.pm
        obj.detail_list = handleDetailList(true)
        return obj
      },
      desHeader: {
        title: '基本信息',
        border: true,
      },
      formList: [
        {
          type: 'datePicker',
          key: 'date',
          value: defaultValue,
          labelAttrs: {
            label: '单据时间',
          },
          formAttrs: {
            type: 'text',
            'value-format': 'YYYY-MM-DD',
            placeholder: '请选择单据时间',
            clearable: true,
            'default-value': defaultValue,
          },
          layoutAttrs: {
            span: 6,
          },
        },
        // 客户/供应商
        {
          type: 'slot',
          key: 'supplier_id',
          name: 'supplierAndCustom',
          layoutAttrs: {
            span: 6,
          },
          condition: () => {
            return paramsType != 'entrustOrderList'
          },
        },
        {
          type: 'selectServer',
          key: 'entrust_id',
          value: info.id || '',
          labelAttrs: {
            label: '委外订单',
            rules: [
              { required: true, message: '请选择委外订单', trigger: 'change' },
            ],
          },
          formAttrs: {
            listApi: `/entrust/index`,
            class: 'w',
            labelKey: 'code',
            searchKey: 'search',
            multiple: false,
            modelValueName: info.id
              ? `${info.code} | ${info.supplier_info?.title} | ${info.product_info?.title}`
              : '',
            exLabelKeyFun: (item) => {
              return `${item.code} | ${item.supplier_info?.title} | ${item.product_info?.title}`
            },
            disabledFun: () => {
              if (paramsType === 'entrustOrderList' && info.id) {
                return true
              }
              return false
            },
            placeholder: '请选择委外订单',
          },
          layoutAttrs: {
            span: 6,
          },
          condition: () => {
            return paramsType === 'entrustOrderList'
          },
        },
        {
          type: 'input',
          key: 'packing_remark',
          value: '',
          labelAttrs: {
            label: '包装要求',
          },
          formAttrs: {
            clearable: true,
            disabled: false,
            placeholder: '',
          },
          layoutAttrs: {
            span: 6,
          },
        },
        {
          type: 'input',
          key: 'product_remark',
          value: '',
          labelAttrs: {
            label: '规格说明',
          },
          formAttrs: {
            clearable: true,
            disabled: false,
            placeholder: '',
          },
          layoutAttrs: {
            span: 6,
          },
        },
        {
          type: hidePmSelect ? 'slot' : 'radio',
          key: 'pm',
          value: pmDefaultValue,
          labelAttrs: {
            label: '入库类型',
          },
          formAttrs: {
            placeholder: '请选择出入库类型',
            clearable: true,
          },
          options: [
            { title: t('入库'), attrs: { label: 1 }, events: {} },
            { title: t('出库'), attrs: { label: 0 }, events: {} },
          ],
          formEvents: {},
          layoutAttrs: {
            span: hidePmSelect ? 0 : 6,
          },
        },
        {
          type: 'selectServer',
          key: 'label_ids',
          labelAttrs: {
            label: '码头',
          },
          formAttrs: {
            class: 'w',
            clearable: true,
            filterable: true,
            multiple: true,
            valueType: 'number',
            listApi: `/label/index`,
            searchKey: 'title',
            placeholder: '请选择码头',
            exParams: {
              type: ['repository_receipt'],
            },
            autoAdd: true,
            addApi: '/label/add',
            addParams: {
              type: 'repository_receipt',
            },
          },
          condition: (model) => {
            return model.pm === 0 || paramsType === 'outboundOrderList'
          },
          layoutAttrs: {
            span: 6,
          },
        },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '备注',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            placeholder: '',
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'slot',
          key: 'detail_list',
          name: 'productList',
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirmAdd',
        title: '保存并新增',
        attrs: {
          type: 'primary',
        },
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  console.log('defaultConfig :>> ', defaultConfig)
  return defaultConfig
}

/**
 * 箱单添加弹窗
 * @param {*} MODULE
 * @param {*} type
 * @param {*} info
 * @param {*} from 来源
 * @returns
 */
export function cartonsListAddDialog(
  MODULE,
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('params :>> ', params)

  const refObj = {
    supplierDeliveryList: 'supplier_delivery_id',
    outboundOrderList: 'repository_receipt_id',
  }
  const refKey = refObj[params.type] || 'delivery_plan_id'

  const layoutSpan = type === 'draw' ? 12 : 24
  const defaultConfig = {
    title: '箱单',
    dialog: {
      width: '500px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '100px',
      },
      formList: [
        {
          type: 'input',
          key: 'title',
          value:
            (info?.customer_info?.title || '') +
            '箱单' +
            dayjs().format('YYYYMMDD'),
          labelAttrs: {
            label: '名称',
            rules: [
              {
                required: true,
                message: '请输入名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入名称',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        // {
        //   type: 'inputNumber',
        //   key: 'cartons_num',
        //   value: 1,
        //   labelAttrs: {
        //     label: '箱数',
        //     rules: [
        //       {
        //         required: true,
        //         message: '请输入数量',
        //         trigger: 'blur',
        //       },
        //     ],
        //   },
        //   formAttrs: {
        //     class: 'wi',
        //     placeholder: '请输入预估箱数',
        //     tips: '装箱预估数量',
        //     tipsCls: 'lh1 pt5 g9',
        //   },
        // },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '备注',
          },
          formAttrs: {
            clearable: true,
            disabled: false,
            placeholder: '',
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'slot',
          key: 'detail_list',
          name: 'productList',
          value: '',
          labelAttrs: {
            label: '产品列表',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入名称',
            clearable: true,
          },
        },
        {
          type: 'slot',
          key: refKey,
          value: info?.id,
          labelAttrs: {
            label: '出库单',
            rules: [
              {
                required: true,
                message: '请选择出库单',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            listApi: `/epository-receipt/index`,
            class: 'w',
            searchKey: 'search',
            multiple: false,
            exParams: { pm: 0 },
            placeholder: '请选择出库单',
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  if (type === 'draw') {
    defaultConfig.form.beforeReq = (data) => {
      console.log('beforeReq :>> ', data)
      console.log('beforeReq :>> store', store)
      data.detail_list = handleDetailList(false, true)
      const cartonNumsObj = {}
      data.detail_list.forEach((item) => {
        item.packing_info?.content_list?.forEach((v) => {
          cartonNumsObj[v.carton_num] = v.cartons_num
        })
      })
      data.cartons_num = Object.keys(cartonNumsObj)?.length || 1
      return data
    }
  }
  console.log('defaultConfig :>> ', defaultConfig)
  return defaultConfig
}

/**
 * 正唛信息弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function eidtCartonsMarkDialog(MODULE = '', type = '', info) {
  console.log('type :>> ', type)
  console.log('info :>> eidtCartonsMarkDialog', info)
  let defaultConfig = {
    title: `箱单${type === 'front' ? '正' : '侧'}唛信息`,
    customTitle: true,
    dialog: {
      width: '800px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/edit`,
        update: `/${MODULE}/edit`,
      },
      beforeReq: (data) => {
        console.log('beforeReq :>> ', data)
        console.log('info :>> ', info)
        let result = {
          id: info.id,
        }
        if (type === 'front') {
          result.shipping_mark = {
            ...data,
          }
        } else if (type === 'side') {
          result.broadside_mark = {
            ...data,
          }
        }
        return result
      },
      formListKey: true, // 参数是formList的key
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '100px',
      },
      formList: [
        {
          type: 'input',
          key: 'customer_title',
          value: info?.shipping_mark?.customer_title || '',
          labelAttrs: {
            label: '客户名称',
          },
          formAttrs: {
            placeholder: '请输入客户名称',
            clearable: true,
          },
        },
        {
          type: 'input',
          key: 'customer_code',
          value: info?.shipping_mark?.customer_code || '',
          labelAttrs: {
            label: '客户编码',
          },
          formAttrs: {
            placeholder: '请输入客户编码',
            clearable: true,
          },
        },
        {
          type: 'input',
          key: 'address',
          value: info?.shipping_mark?.address || '',
          labelAttrs: {
            label: '目的地',
          },
          formAttrs: {
            placeholder: '请输入收货目的地',
            clearable: true,
          },
        },
        {
          type: 'input',
          key: 'cartons_mark',
          value: info?.shipping_mark?.cartons_mark || 'M',
          labelAttrs: {
            label: '箱号标识',
          },
          formAttrs: {
            placeholder: '如M、B',
            clearable: true,
          },
        },
        {
          type: 'input',
          key: 'producing_area',
          value: info?.shipping_mark?.producing_area || '',
          labelAttrs: {
            label: '产地',
          },
          formAttrs: {
            placeholder: '请输入产地',
            clearable: true,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  if (type === 'side') {
    defaultConfig.form.formList = [
      {
        type: 'input',
        key: 'order_code',
        value: '',
        labelAttrs: {
          label: '订单号',
        },
        formAttrs: {
          disabled: true,
          placeholder: '将自动填充',
        },
      },
      {
        type: 'input',
        key: 'num',
        value: '',
        labelAttrs: {
          label: '数量',
        },
        formAttrs: {
          disabled: true,
          placeholder: '将自动填充',
          clearable: true,
        },
      },
      {
        type: 'input',
        key: 'net_weight',
        value: '',
        labelAttrs: {
          label: '净重',
        },
        formAttrs: {
          disabled: true,
          placeholder: '将自动填充',
          clearable: true,
        },
      },
      {
        type: 'input',
        key: 'grossweight',
        value: '',
        labelAttrs: {
          label: '毛重',
        },
        formAttrs: {
          disabled: true,
          placeholder: '将自动填充',
          clearable: true,
        },
      },
      {
        type: 'input',
        key: 'volume',
        value: '',
        labelAttrs: {
          label: '体积',
        },
        formAttrs: {
          disabled: true,
          placeholder: '将自动填充',
          clearable: true,
        },
      },
    ]
    defaultConfig.footer = []
  }
  return defaultConfig
}

/**
 * 库存盘点添加弹窗
 * @param {*} MODULE
 * @param {*} type
 * @param {*} info
 * @param {*} from 来源
 * @returns
 */
export function repositoryInventoryAddDialog(
  MODULE,
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('params :>> ', params)

  const handleSearch = (val, model, params) => {
    console.log('val :>> ', val)
    console.log('model', model)
    console.log('params :>> ', params)
    const param = {
      repository_id: model.repository_id,
      'product-search': model.search,
    }
    getAllProductList(param).then((res) => {
      console.log('getProductList :>> ', res)
      store.commit(
        'sys/setAddProductDrawerSelect',
        res.map((item) => {
          return {
            ...item,
            current_num: item.num,
          }
        }) || []
      )
    })
  }
  const defaultValue =
    params?.type === 'orderlist'
      ? info?.delivery_date || dayjs().format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD')
  const defaultConfig = {
    title: '产品盘点',
    dialog: {
      width: '500px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      beforeReq: (data) => {
        console.log('beforeReq :>> ', data)
        console.log('beforeReq :>> store', store)
        data.detail_list = handleDetailList(true)
        return data
      },
      desHeader: {
        title: '基本信息',
        // border: true,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '110px',
      },
      formList: [
        {
          type: 'cascaderServer',
          key: 'repository_id',
          value: '',
          labelAttrs: {
            label: '仓库',
            rules: [
              {
                required: true,
                message: '请选择仓库',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            placeholder: '请选择仓库',
            clearable: true,
            filterable: true,
            multiple: false,
            showAllLevels: true,
            listApi: '/repository/tree',
            exParams: {
              sort: 'title',
              order: 'asc',
            },
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: true,
              multiple: false,
            },
          },
          formEvents: {},
          onChange: handleSearch,
          layoutAttrs: {
            span: 6,
          },
        },
        {
          type: 'input',
          key: 'search',
          value: '',
          labelAttrs: {
            label: '产品',
          },
          formAttrs: {
            placeholder: '请输入产品名',
            clearable: true,
          },
          onChange: handleSearch,
          layoutAttrs: {
            span: 6,
          },
        },
        {
          type: 'datePicker',
          key: 'date',
          value: defaultValue,
          labelAttrs: {
            label: '单据日期',
            rules: [
              {
                required: true,
                message: '请选择单据日期',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            'value-format': 'YYYY-MM-DD',
            placeholder: '请选择单据日期',
            clearable: true,
            'default-value': defaultValue,
          },
          layoutAttrs: {
            span: 6,
          },
        },
        {
          type: 'slot',
          key: 'detail_list',
          name: 'productList',
          value: '',
          labelAttrs: {
            label: '产品列表',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入名称',
            clearable: true,
          },
        },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '备注',
          },
          formAttrs: {
            type: 'textarea',
            clearable: true,
            disabled: false,
            placeholder: '',
          },
          layoutAttrs: {
            span: 12,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirmAdd',
        title: '保存并新增',
        attrs: {
          type: 'primary',
        },
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  console.log('defaultConfig :>> ', defaultConfig)
  return defaultConfig
}

/**
 * 产品调拨添加弹窗
 * @param {*} MODULE
 * @param {*} type
 * @param {*} info
 * @param {*} from 来源
 * @returns
 */
export function repositoryTransferAddDialog(
  MODULE,
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('params :>> ', params)
  const defaultValue =
    params?.type === 'orderlist'
      ? info?.create_time || dayjs().format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD')
  const defaultConfig = {
    title: '产品调拨',
    dialog: {
      width: '500px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      beforeReq: (data) => {
        data.detail_list = handleDetailList()
        return data
      },
      desHeader: {
        title: '基本信息',
        // border: true,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '80px',
        ...dynamicsFormAttrs,
      },
      formList: [
        {
          type: 'cascaderServer',
          key: 'out_repository_id',
          value: '',
          labelAttrs: {
            label: '调出仓库',
            rules: [
              {
                required: true,
                message: '请选择仓库',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            placeholder: '请选择仓库',
            clearable: true,
            filterable: true,
            multiple: false,
            showAllLevels: true,
            listApi: '/repository/tree',
            exParams: {
              sort: 'title',
              order: 'asc',
            },
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: true,
              multiple: false,
            },
          },
          layoutAttrs: {
            span: 6,
          },
        },
        {
          type: 'cascaderServer',
          key: 'in_repository_id',
          value: '',
          labelAttrs: {
            label: '调入仓库',
            rules: [
              {
                required: true,
                message: '请选择仓库',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            placeholder: '请选择仓库',
            clearable: true,
            filterable: true,
            multiple: false,
            showAllLevels: true,
            listApi: '/repository/tree',
            exParams: {
              sort: 'title',
              order: 'asc',
            },
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: true,
              multiple: false,
            },
          },
          layoutAttrs: {
            span: 6,
          },
        },
        {
          type: 'datePicker',
          key: 'date',
          value: defaultValue,
          labelAttrs: {
            label: '单据日期',
            rules: [
              {
                required: true,
                message: '请选择单据日期',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            'value-format': 'YYYY-MM-DD',
            placeholder: '请选择单据日期',
            clearable: true,
            'default-value': defaultValue,
          },
          layoutAttrs: {
            span: 6,
          },
        },
        {
          type: 'slot',
          key: 'detail_list',
          name: 'productList',
          value: '',
          labelAttrs: {
            label: '产品列表',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入名称',
            clearable: true,
          },
        },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '备注',
          },
          formAttrs: {
            type: 'textarea',
            clearable: true,
            disabled: false,
            placeholder: '',
          },
          layoutAttrs: {
            span: 12,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirmAdd',
        title: '保存并新增',
        attrs: {
          type: 'primary',
        },
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  console.log('defaultConfig :>> ', defaultConfig)
  return defaultConfig
}

/**
 * 产品调拨添加弹窗(三方)
 * @param {*} MODULE
 * @param {*} type
 * @param {*} info
 * @param {*} from 来源
 * @returns
 */
export function repositoryThirdTransferAddDialog(
  MODULE,
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('params :>> ', params)
  const defaultConfig = {
    title: '三方仓产品调拨',
    dialog: {
      width: '500px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      beforeReq: (data) => {
        data.detail_list = handleDetailList()
        return data
      },
      desHeader: {
        title: '基本信息',
        // border: true,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '80px',
        ...dynamicsFormAttrs,
      },
      formList: [
        // {
        //   type: 'cascaderServer',
        //   key: 'out_repository_id',
        //   value: '',
        //   labelAttrs: {
        //     label: '调出仓库',
        //     rules: [
        //       {
        //         required: true,
        //         message: '请选择仓库',
        //         trigger: 'blur',
        //       },
        //     ],
        //   },
        //   formAttrs: {
        //     placeholder: '请选择仓库',
        //     clearable: true,
        //     filterable: true,
        //     multiple: false,
        //     showAllLevels: true,
        //     listApi: '/repository/tree',
        //     exParams: {
        //       sort: 'title',
        //       order: 'asc',
        //     },
        //     props: {
        //       label: 'title',
        //       value: 'id',
        //       checkStrictly: true,
        //       multiple: false,
        //     },
        //   },
        //   layoutAttrs: {
        //     span: 6,
        //   },
        // },
        {
          type: 'selectServer',
          key: 'in_third_repository_id',
          value: '',
          labelAttrs: {
            label: '调入仓库',
            rules: [
              {
                required: true,
                message: '请选择仓库',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            placeholder: '请选择仓库',
            clearable: true,
            filterable: true,
            multiple: false,
            showAllLevels: true,
            listApi: '/third-repository/index',
            exParams: {
              sort: 'title',
              order: 'asc',
            },
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: true,
              multiple: false,
            },
          },
          layoutAttrs: {
            span: 6,
          },
        },
        {
          type: 'slot',
          key: 'detail_list',
          name: 'productList',
          value: '',
          labelAttrs: {
            label: '产品列表',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入名称',
            clearable: true,
          },
        },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '备注',
          },
          formAttrs: {
            type: 'textarea',
            clearable: true,
            disabled: false,
            placeholder: '',
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'slot',
          key: 'type',
          value: 1,
          labelAttrs: {
            label: '类型',
          },
          formAttrs: {},
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirmAdd',
        title: '保存并新增',
        attrs: {
          type: 'primary',
        },
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  console.log('defaultConfig :>> ', defaultConfig)
  return defaultConfig
}
/**
 * 产品组装拆卸添加弹窗
 * @param {*} MODULE
 * @param {*} type
 * @param {*} info
 * @param {*} from 来源
 * @returns
 */
export function productAssembleAddDialog(
  MODULE,
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('params :>> ', params)
  const defaultValue =
    params?.type === 'orderlist'
      ? info?.create_time || dayjs().format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD')
  const isAssemble = params?.from === 'assembleList' // 是否是产品组装
  const title = isAssemble ? t('组装') : t('拆卸')
  const defaultConfig = {
    title: title,
    dialog: {
      width: '500px',
      top: '25vh',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      beforeReq: (data) => {
        console.log('beforeReq :>> ', data)
        console.log('beforeReq :>> store', store)
        data.detail_list = handleDetailList()
        return data
      },
      desHeader: {
        title: '基本信息',
        // border: true,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '110px',
        ...dynamicsFormAttrs,
      },
      formList: [
        {
          type: 'slot',
          key: 'type',
          value: isAssemble ? 1 : 0,
          labelAttrs: {
            label: '类型',
          },
          formAttrs: {
            placeholder: '',
            clearable: true,
          },
          formEvents: {},
          layoutAttrs: {
            span: 0,
          },
        },
        {
          type: 'selectServer',
          key: 'product_id',
          labelAttrs: {
            label: title + t('产品名称'),
            rules: [
              {
                required: true,
                message: `${t('请输选择')}${title}${t('产品名称')}`,
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            clearable: true,
            filterable: true,
            listApi: `/product/index`,
            searchKey: 'title',
            placeholder: `${t('请输选择')}${title}${t('产品名称')}`,
          },
          layoutAttrs: {
            span: 5,
          },
        },
        {
          type: 'cascaderServer',
          key: 'repository_id',
          value: '',
          labelAttrs: {
            label: isAssemble ? '入库仓库' : '出库仓库',
            rules: [
              {
                required: true,
                message: '请选择仓库',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            placeholder: '请选择仓库',
            clearable: true,
            filterable: true,
            multiple: false,
            showAllLevels: true,
            listApi: '/repository/tree',
            exParams: {
              sort: 'title',
              order: 'asc',
            },
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: true,
              multiple: false,
            },
          },
          layoutAttrs: {
            span: 5,
          },
        },
        {
          type: 'inputNumber',
          key: 'num',
          value: 1,
          labelAttrs: {
            label: title + t('数量'),
            rules: [
              {
                required: true,
                message: '请输入数量',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            min: 1,
            placeholder: '请输入数量',
            clearable: true,
          },
          layoutAttrs: {
            span: 4,
          },
        },
        {
          type: 'inputNumber',
          key: 'amount',
          value: 1,
          labelAttrs: {
            label: title + t('费用'),
          },
          formAttrs: {
            min: 1,
            placeholder: `${t('请输入')}${title}${t('费用')}`,
            clearable: true,
          },
          layoutAttrs: {
            span: 4,
          },
        },
        {
          type: 'datePicker',
          key: 'date',
          value: defaultValue,
          labelAttrs: {
            label: '单据日期',
            rules: [
              {
                required: true,
                message: '请选择单据日期',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            'value-format': 'YYYY-MM-DD',
            placeholder: '请选择单据日期',
            clearable: true,
            'default-value': defaultValue,
          },
          layoutAttrs: {
            span: 4,
          },
        },
        {
          type: 'slot',
          key: 'detail_list',
          name: 'productList',
          value: '',
          labelAttrs: {
            label: '产品列表',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入名称',
            clearable: true,
          },
        },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '备注',
            labelWidth: 0,
          },
          formAttrs: {
            type: 'textarea',
            placeholder: '请输入备注',
            clearable: true,
            disabled: false,
          },
          layoutAttrs: {
            span: 23,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'saveTemplate',
        title: '存为模版',
        formValidate: true,
        attrs: {},
      },
      {
        action: 'confirmAdd',
        title: '保存并新增',
        attrs: {
          type: 'primary',
        },
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  if (!isAssemble) {
    // 选择拆卸的模版
    defaultConfig.form.formList.splice(1, 1, {
      type: 'selectServer',
      key: 'product_id',
      labelAttrs: {
        label: '拆卸产品',
        rules: [
          {
            required: true,
            message: '请选择拆卸产品',
            trigger: 'blur',
          },
        ],
      },
      formAttrs: {
        listApi: `/product/index`,
        class: 'w',
        valueType: 'number',
        multiple: false,
        placeholder: '请选择拆卸产品',
      },
      layoutAttrs: {
        span: 5,
      },
    })
  }

  console.log('defaultConfig :>> ', defaultConfig)
  return defaultConfig
}

/**
 * 轮播图新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function shopBannerAddDialog(MODULE = '', type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    title: '轮播图',
    dialog: {
      width: '700px',
      top: '8vh',
      appendToBody: true,
      destroyOnClose: true,
      closeOnClickModal: false,
      lockScroll: true,
      'lock-scroll': true,
      // fullscreen: true
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true, // 参数是formList的key
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '100px',
        ...dynamicsFormAttrs,
      },
      formList: [
        {
          type: 'input',
          key: 'label',
          value: '',
          labelAttrs: {
            label: '图片名称',
            rules: [
              {
                required: true,
                message: '请输入图片',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入图片',
            clearable: true,
          },
        },
        {
          type: 'input',
          key: 'link',
          value: '',
          labelAttrs: {
            label: '链接地址',
            rules: [
              {
                required: true,
                message: '请输入图片链接地址',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入图片链接地址',
            clearable: true,
          },
        },
        {
          type: 'select',
          key: 'type',
          value: 1,
          labelAttrs: {
            label: '类型',
          },
          formAttrs: {
            class: 'w',
            disabled: type === 'shopIndex',
            placeholder: '请选择类型',
          },
          options: objToArr(BANNER_TYPE),
        },
        {
          type: 'uploadImg',
          key: 'image',
          labelAttrs: {
            label: '图片',
          },
          formAttrs: {
            uploadParam: { module: 'carousel' },
            copyImgUploade: true,
            tips: '建议尺寸1920 * 520px',
            tipsCls: 'cd fs12 lh1 mt5',
            limitSize: 1024 * 10,
            afterBtnText: '从素材库选择',
          },
        },
        {
          type: 'inputNumber',
          key: 'rank',
          value: 1,
          labelAttrs: {
            label: '排序',
          },
          formAttrs: {
            placeholder: '请输入排序',
          },
        },
        {
          type: 'switch',
          key: 'status',
          value: 1,
          labelAttrs: {
            label: '使用状态',
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 文章新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function shopArticleAddDialog(MODULE = '', type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    title: '文章',
    dialog: {
      width: '1000px',
      top: '8vh',
      appendToBody: true,
      destroyOnClose: true,
      closeOnClickModal: false,
      lockScroll: true,
      'lock-scroll': true,
      // fullscreen: true
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true, // 参数是formList的key
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '80px',
        ...dynamicsFormAttrs,
      },
      formList: [
        {
          type: 'input',
          key: 'title',
          value: '',
          labelAttrs: {
            label: '文章标题',
            rules: [
              {
                required: true,
                message: '请输入文章标题',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入文章标题',
            clearable: true,
          },
        },
        {
          type: 'selectServer',
          key: 'type_id',
          value: '',
          labelAttrs: {
            label: '文章类型',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请选择文章类型',
            listApi: `/article-type/index`,
            valueType: 'number',
            multiple: false,
            // emitSelect: true,
            modelValueKey: 'type_info.title',
          },
          // options: objToArr(ARTICLE_TYPE),
        },
        // {
        //   type: 'uploadImg',
        //   key: 'image',
        //   labelAttrs: {
        //     label: '图片',
        //   },
        //   formAttrs: {
        //     uploadParam: { module: 'carousel' },
        //     copyImgUploade: true,
        //     tips: '建议尺寸1920 * 520px',
        //     tipsCls: 'cd fs12 lh1 mt5',
        //     limitSize: 1024 * 10,
        //     afterBtnText: '从素材库选择',
        //   },
        // },
        {
          type: 'richText',
          key: 'content',
          value: '',
          labelAttrs: {
            label: '文章内容',
            rules: [
              {
                required: true,
                message: '请输入文章内容',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            class: 'w',
            style: {
              height: '400px',
            },
            excludeKeys: [
              'blockquote',
              'group-more-style',
              'fontFamily',
              'lineHeight',
              'todo',
              'emotion',
              'uploadAttachment',
              'group-video',
              // 'insertTable',
              'codeBlock',
              'divider',
              'undo',
              'redo',
            ],
          },
        },
        {
          type: 'uploadVideo',
          key: 'video',
          labelAttrs: {
            label: '视频',
          },
          formAttrs: {
            multiple: true,
            uploadParam: { module: 'article' },
            afterBtnText: '从素材库选择',
            isCosSdk: true,
            uploadUrl: cosSdkConfig,
            // uploadUrl: uploadFileUrl,
            module: 'article',
          },
        },
        {
          type: 'inputNumber',
          key: 'rank',
          value: 0,
          labelAttrs: {
            label: '排序',
          },
          formAttrs: {
            placeholder: '请输入排序',
          },
        },
        {
          type: 'switch',
          key: 'status',
          value: 1,
          labelAttrs: {
            label: '使用状态',
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 海关交易详情接口
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function CustomsDetailDialog(MODULE = '', type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    title: '提单详情',
    customTitle: true,
    dialog: {
      width: '1000px',
      // top: '8vh',
      appendToBody: true,
      destroyOnClose: true,
      closeOnClickModal: false,
      lockScroll: true,
      'lock-scroll': true,
      // fullscreen: true
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/customs/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true, // 参数是formList的key
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '80px',
      },
      showColon: true,
      formList: [
        {
          type: 'divider',
          value: '基本信息',
          labelAttrs: {
            labelWidth: '0px',
          },
          formAttrs: {
            class: 'c0',
            // contentPosition: "center"
          },
          layoutAttrs: {
            span: 24,
            // class: 'mt10'
          },
        },
        {
          type: 'tips',
          key: 'time',
          value: '',
          labelAttrs: {
            label: '日期',
          },
          formatFun: (data) => {
            return dayjs(data.time).format('YYYY-MM-DD')
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'tips',
          value: '',
          key: 'supplier',
          labelAttrs: {
            label: '供应商',
          },
          formAttrs: {},
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'tips',
          value: '',
          key: 'purchaser',
          labelAttrs: {
            label: '采购商',
          },
          formAttrs: {},
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'divider',
          value: '产品信息',
          labelAttrs: {
            labelWidth: '0px',
          },
          formAttrs: {
            class: 'c0',
            // contentPosition: "center"
          },
          layoutAttrs: {
            span: 24,
            class: 'mt10',
          },
        },
        {
          type: 'tips',
          value: '',
          key: 'hs_code',
          labelAttrs: {
            label: 'HS编码',
          },
          formAttrs: {},
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'tips',
          value: '',
          key: 'amount',
          labelAttrs: {
            label: '总金额',
          },
          formAttrs: {
            class: 'cd',
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'tips',
          value: '',
          key: 'count',
          labelAttrs: {
            label: '数量',
          },
          formAttrs: {},
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'tips',
          value: '',
          key: 'weight',
          labelAttrs: {
            label: '重量',
          },
          formAttrs: {},
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'tips',
          value: '',
          key: 'product',
          labelAttrs: {
            label: '产品描述',
          },
          formAttrs: {},
          layoutAttrs: {
            span: 24,
          },
        },
        {
          type: 'divider',
          value: '货运信息',
          labelAttrs: {
            labelWidth: '0px',
          },
          formAttrs: {
            class: 'c0',
            // contentPosition: "center"
          },
          layoutAttrs: {
            span: 24,
            class: 'mt10',
          },
        },
        {
          type: 'tips',
          value: '',
          key: 'export_country',
          labelAttrs: {
            label: '原产国',
          },
          formAttrs: {
            class: 'ttc',
          },
          layoutAttrs: {
            span: 12,
          },
          formatFun: (data) => {
            return data?.export_country?.toLowerCase()
          },
        },
        {
          type: 'tips',
          value: '',
          key: 'import_country',
          labelAttrs: {
            label: '目的国',
          },
          formAttrs: {
            class: 'ttc',
          },
          layoutAttrs: {
            span: 12,
          },
          formatFun: (data) => {
            return data?.import_country?.toLowerCase()
          },
        },
        {
          type: 'tips',
          value: '',
          key: 'transport',
          labelAttrs: {
            label: '运输方式',
          },
          formAttrs: {
            class: 'ttc',
          },
          layoutAttrs: {
            span: 12,
          },
          formatFun: (data) => {
            return data?.transport?.toLowerCase()
          },
        },
        {
          type: 'tips',
          value: '',
          key: 'source',
          labelAttrs: {
            label: '数据来源',
          },
          formAttrs: {
            class: 'ttc',
          },
          layoutAttrs: {
            span: 12,
          },
          formatFun: (data) => {
            return data?.source?.toLowerCase()
          },
        },
      ],
    },
    // footer: [
    //   {
    //     action: 'cancel',
    //     title: '取消',
    //     attrs: {},
    //   },
    //   {
    //     action: 'confirm',
    //     title: '保存',
    //     attrs: {
    //       type: 'primary',
    //     },
    //   },
    // ],
  }

  return defaultConfig
}

/**
 * 联系人新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function contactAddDialog(MODULE = '', props = {}) {
  let defaultConfig = {
    title: '联系人',
    dialog: {
      width: '700px',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '80px',
      },
      formList: [
        {
          type: 'input',
          key: 'title',
          value: '',
          labelAttrs: {
            label: '姓名',
            rules: [
              {
                required: true,
                message: '请输入姓名',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入姓名',
            clearable: true,
          },
          layoutAttrs: {
            span: 24,
          },
        },
        {
          type: 'input',
          key: 'department',
          value: '',
          labelAttrs: {
            label: '部门',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            placeholder: '请输入部门',
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'input',
          key: 'position',
          value: '',
          labelAttrs: {
            label: '职务',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            placeholder: '请输入职务',
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'input',
          key: 'telephone',
          value: '',
          labelAttrs: {
            label: '手机号',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            placeholder: '请输入手机号',
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'input',
          key: 'mobile',
          value: '',
          labelAttrs: {
            label: '电话',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            placeholder: '请输入电话',
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'input',
          key: 'email',
          value: '',
          labelAttrs: {
            label: '邮箱',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            placeholder: '请输入邮箱',
          },
        },
        {
          type: 'uploadImg',
          key: 'photo',
          labelAttrs: {
            label: '头像',
          },
          formAttrs: {
            limitSize: 1024 * 10,
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'uploadImg',
          key: 'calling_card',
          labelAttrs: {
            label: '名片',
          },
          formAttrs: {
            limitSize: 1024 * 10,
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '备注',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            placeholder: '请输入备注',
          },
        },
        {
          type: 'hidden',
          key: 'ref_id',
          value: props?.info?.id,
        },
        {
          type: 'hidden',
          key: 'module',
          value: props.module || props.params.module,
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 关联网页新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function relationWebAddDialog(MODULE = '', props = {}) {
  let defaultConfig = {
    title: '关联网页',
    dialog: {
      width: '700px',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '80px',
      },
      formList: [
        {
          type: 'input',
          key: 'url',
          value: '',
          labelAttrs: {
            label: '网址',
            rules: [
              {
                required: true,
                message: '请输入网址',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入网址',
            clearable: true,
          },
          layoutAttrs: {
            span: 24,
          },
        },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '备注',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            placeholder: '请输入备注',
          },
        },
        {
          type: 'hidden',
          key: 'ref_id',
          value: props?.info?.id,
        },
        {
          type: 'hidden',
          key: 'module',
          value: props.module || props.params.module,
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 我的邮箱新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function personalEmailAddDialog(MODULE = '') {
  let defaultConfig = {
    title: '邮箱',
    dialog: {
      width: '500px',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '100px',
        ...dynamicsFormAttrs,
      },
      formList: [
        {
          type: 'input',
          key: 'name',
          value: '',
          labelAttrs: {
            label: '发信昵称',
            rules: [
              {
                required: true,
                message: '请输入发信昵称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            placeholder: '请输入发信昵称',
          },
        },
        {
          type: 'select',
          key: 'source',
          labelAttrs: {
            label: '邮箱服务商',
            rules: [
              {
                required: true,
                message: '请选择邮箱服务商',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            placeholder: '请选择邮箱服务商',
            clearable: true,
          },
          options: objToArr(EMAIL_LIST, false),
        },
        {
          type: 'input',
          key: 'email',
          value: '',
          labelAttrs: {
            label: '邮箱',
            rules: [
              {
                required: true,
                message: '请输入邮箱',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入邮箱',
            clearable: true,
          },
        },
        {
          type: 'input',
          key: 'password',
          value: '',
          labelAttrs: {
            label: '密码',
            rules: [
              {
                required: true,
                message: '请输入密码',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            placeholder: '请输入密码',
          },
        },
        {
          type: 'switch',
          key: 'is_default',
          value: 0,
          labelAttrs: {
            label: '默认发信邮箱',
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 金豆变更新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function companyBeanAddDialog(MODULE = '') {
  let defaultConfig = {
    title: '金豆变更',
    customTitle: true,
    dialog: {
      width: '500px',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/bean`,
        // detail: `/${MODULE}/detail`,
        update: `/${MODULE}/bean`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '100px',
      },
      formList: [
        {
          type: 'radio',
          key: 'pm',
          value: 1,
          labelAttrs: {
            label: '类型',
          },
          formAttrs: {},
          options: objToArrRadio(INCREASE_DECREASE),
        },
        {
          type: 'inputNumber',
          key: 'num',
          value: '',
          labelAttrs: {
            label: '数量',
            rules: [
              {
                required: true,
                message: '请输入数量',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            class: 'w',
            placeholder: '请输入数量',
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 字段管理新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function fieldAddDialog(MODULE = '', type = '') {
  let defaultConfig = {
    title: '字段',
    dialog: {
      width: '700px',
      appendToBody: true,
      destroyOnClose: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true, // 参数是formList的key
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '100px',
      },
      formList: [
        {
          type: 'input',
          key: 'label',
          value: '',
          labelAttrs: {
            label: '字段名称',
            rules: [
              {
                required: true,
                message: '请输入字段名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            disabled: !!type,
            placeholder: '请输入字段名称',
            clearable: true,
          },
        },
        {
          type: 'input',
          key: 'prop',
          value: '',
          labelAttrs: {
            label: '字段标识',
            rules: [
              {
                required: true,
                message: '请输入字段标识',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入字段标识',
            clearable: true,
          },
        },
        {
          type: 'cascaderServer',
          key: 'menu_id',
          value: '',
          labelAttrs: {
            label: '所属菜单',
            rules: [
              {
                required: true,
                message: '请选择父级菜单',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            placeholder: '请选择父级菜单',
            clearable: true,
            filterable: true,
            'show-all-levels': true,
            listApi: '/menu/tree',
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: false,
            },
          },
        },
        {
          type: 'inputNumber',
          key: 'width',
          value: 125,
          labelAttrs: {
            label: '列表宽度',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入列表宽度',
            clearable: true,
            max: 9999,
            style: 'width:220px',
          },
        },
        {
          type: 'inputNumber',
          key: 'rank',
          value: 0,
          labelAttrs: {
            label: '字段排序',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入字段排序',
            clearable: true,
            max: 9999,
            style: 'width:220px',
          },
        },
        {
          type: 'switch',
          key: 'is_fixed',
          value: 0,
          labelAttrs: {
            label: '是否固定',
          },
          formAttrs: {
            'inline-prompt': true,
            'active-text': '是',
            'inactive-text': '否',
          },
        },
        {
          type: 'switch',
          key: 'is_default_sort',
          value: 0,
          labelAttrs: {
            label: '默认排序',
          },
          formAttrs: {
            'inline-prompt': true,
            'active-text': '是',
            'inactive-text': '否',
          },
        },
        {
          type: 'radio',
          key: 'sort',
          value: '',
          labelAttrs: {
            label: '排序方式',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请选择类型',
          },
          options: objToArrRadio(FILED_ORDER, false),
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '确定',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  if (type === 'sort' || type === 'setSort') {
    defaultConfig.title = '设置默认排序字段'
    defaultConfig.customTitle = true
    defaultConfig.form.formList.splice(1, type === 'sort' ? 2 : 4)
  }
  if (type === '') {
    defaultConfig.form.formList.splice(2, 0, {
      type: 'input',
      key: 'field',
      value: '',
      labelAttrs: {
        label: '字段取值',
      },
      formAttrs: {
        type: 'text',
        placeholder: '请输入取值字段',
        clearable: true,
      },
    })
  }

  return defaultConfig
}

/**
 * 产品部件新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function vehiclePartAddDialog(MODULE = 'vehicle-part') {
  let defaultConfig = {
    title: '部件',
    dialog: {
      width: '500px',
      appendToBody: true,
      destroyOnClose: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true, // 参数是formList的key
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '80px',
      },
      formList: [
        {
          type: 'input',
          key: 'title',
          value: '',
          labelAttrs: {
            label: '名称',
            rules: [
              {
                required: true,
                message: '请输入名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入名称',
            clearable: true,
          },
        },
        {
          type: 'cascaderServer',
          key: 'pid',
          labelAttrs: {
            label: '父级',
          },
          formAttrs: {
            placeholder: '请选择父级',
            clearable: true,
            filterable: true,
            'show-all-levels': true,
            listApi: '/vehicle-part/tree',
            class: 'w',
            props: {
              label: 'title',
              value: 'id',
              checkStrictly: true,
            },
          },
        },
        {
          type: 'input',
          key: 'hs_code',
          value: '',
          labelAttrs: {
            label: 'HSCODE',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入HSCODE',
            clearable: true,
          },
        },
        {
          type: 'input',
          key: 'alias',
          value: '',
          labelAttrs: {
            label: '别名',
          },
          formAttrs: {
            type: 'textarea',
            row: 3,
            placeholder: '请输入别名,多个用英文逗号隔开',
            clearable: true,
          },
        },
        {
          type: 'inputNumber',
          key: 'rank',
          value: 0,
          labelAttrs: {
            label: '排序',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入操作排序',
            clearable: true,
            min: 0,
            style: 'width:220px',
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '确定',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 渠道新增弹窗
 * @param {*} MODULE
 * @param {*} type
 * @param {*} info
 * @param {*} params
 * @returns
 */
export function shopChannelAddDialog(
  MODULE,
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('params :>> ', params)
  const options =
    type === 'sale'
      ? PRODUCT_SALE_CHANNEL_SUB_TYPE
      : PRODUCT_PROMOTION_CHANNEL_SUB_TYPE
  let defaultConfig = {
    title: type === 'sale' ? '销售渠道' : '推广渠道',
    dialog: {
      width: '500px',
      appendToBody: true,
      destroyOnClose: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true, // 参数是formList的key
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '80px',
      },
      formList: [
        {
          type: 'slot',
          key: 'type',
          value: type === 'sale' ? 1 : 2,
          labelAttrs: {},
          formAttrs: {},
        },
        {
          type: 'select',
          key: 'sub_type',
          value: objToArr(options)[0].value,
          labelAttrs: {
            label: '渠道类型',
          },
          formAttrs: {
            placeholder: '请选择渠道类型',
            clearable: true,
            class: 'wi',
          },
          options: objToArr(options),
        },
        {
          type: 'input',
          key: 'title',
          value: '',
          labelAttrs: {
            label: '名称',
            rules: [
              {
                required: true,
                message: '请输入名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入店铺名称',
            clearable: true,
          },
        },
        {
          type: 'uploadImg',
          key: 'logo',
          labelAttrs: {
            label: 'logo',
          },
          formAttrs: {
            uploadParam: { module: 'product' },
            limitSize: 1024 * 10,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '确定',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 渠道分类新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function channelTypeAddDialog(MODULE = '', type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    title: '分类',
    dialog: {
      width: '500px',
      top: '15vh',
      appendToBody: false,
      destroyOnClose: true,
      'destroy-on-close': true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        update: `/${MODULE}/edit`,
        detail: `/${MODULE}/detail`,
      },
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '90px',
        ...dynamicsFormAttrs,
      },
      formList: [
        {
          type: 'input',
          key: 'title',
          value: '',
          labelAttrs: {
            label: '分类名称',
            rules: [
              {
                required: true,
                message: '请输入分类名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            class: 'w',
            placeholder: '请输入分类名称',
            clearable: true,
          },
        },
        {
          type: 'selectServer',
          key: 'pid',
          labelAttrs: {
            label: '所属分类',
          },
          formAttrs: {
            listApi: `/channel-type/list`,
            class: 'w',
            labelKey: 'title',
            searchKey: 'title',
            multiple: false,
            placeholder: '请选择所属分类',
            disabledFun: (row) => {
              return !!row.id
            },
          },
        },
        // {
        //   type: 'inputNumber',
        //   key: 'rank',
        //   value: 0,
        //   labelAttrs: {
        //     label: '排序',
        //   },
        //   formAttrs: {
        //     type: 'text',
        //     placeholder: '请输入排序',
        //     clearable: true,
        //     style: 'width:220px',
        //   },
        // },
        // {
        //   type: 'switch',
        //   key: 'status',
        //   value: 1,
        //   labelAttrs: {
        //     label: '使用状态',
        //   },
        // },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '确定',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 渠道新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function channelAddDialog(MODULE = 'channel', type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    title: '渠道',
    dialog: {
      width: '500px',
      top: '15vh',
      appendToBody: false,
      destroyOnClose: true,
      'destroy-on-close': true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        update: `/${MODULE}/edit`,
        detail: `/${MODULE}/detail`,
      },
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '90px',
        ...dynamicsFormAttrs,
      },
      formList: [
        {
          type: 'cascaderServer',
          key: 'channel_type_id',
          value: '',
          labelAttrs: {
            label: '渠道类型',
            rules: [
              {
                required: true,
                message: '请选择渠道类型',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            placeholder: '请选择渠道类型',
            clearable: true,
            filterable: true,
            class: 'w',
            listApi: '/channel-type/tree',
            props: {
              label: 'title',
              value: 'id',
            },
          },
        },
        {
          type: 'selectServer',
          key: 'third_shop_id',
          labelAttrs: {
            label: '关联店铺',
          },
          formAttrs: {
            listApi: `/third-shop/index`,
            class: 'w',
            labelKey: 'name',
            searchKey: 'name',
            valueType: 'number',
            placeholder: '请选择关联店铺',
            exLabelKeyFun: (item) => {
              return `${item.name}(${THIRD_SHOP_TYPE[item.type]})`
            },
          },
        },
        {
          type: 'input',
          key: 'title',
          value: '',
          labelAttrs: {
            label: '渠道名称',
            rules: [
              {
                required: true,
                message: '请输入渠道名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            class: 'w',
            placeholder: '请输入渠道名称',
            clearable: true,
          },
        },

        // {
        //   type: 'inputNumber',
        //   key: 'rank',
        //   value: 0,
        //   labelAttrs: {
        //     label: '排序',
        //   },
        //   formAttrs: {
        //     type: 'text',
        //     placeholder: '请输入排序',
        //     clearable: true,
        //     style: 'width:220px',
        //   },
        // },
        // {
        //   type: 'switch',
        //   key: 'status',
        //   value: 1,
        //   labelAttrs: {
        //     label: '使用状态',
        //   },
        // },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '确定',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  if (type === 'rivalChannel') {
    defaultConfig.form.formList.splice(1, 1)
    defaultConfig.form.formList.push({
      type: 'input',
      key: 'url',
      value: '',
      labelAttrs: {
        label: '链接地址',
      },
      formAttrs: {
        type: 'text',
        class: 'w',
        placeholder: '请输入链接地址',
        clearable: true,
      },
    })
  }
  return defaultConfig
}

/**
 * 拓展字段新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function extraFieldListAddDialog(MODULE = 'channel', type = '') {
  console.log('type :>> ', type)
  let defaultConfig = {
    title: '字段',
    dialog: {
      width: '600px',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '90px',
      },
      beforeReq: async (data, model) => {
        if (EXTEND_ATTR_TYPE[model.attr_type].option) {
          const optionsArr = model.org_options.split(/[(\r\n)\r\n]+/)
          const list = ['radio', 'checkbox']
          const result = optionsArr.map((v) => {
            const [key, val] = v.split('=')
            if (list.includes(model.attr_type)) {
              return {
                title: val,
                attrs: { label: key },
                events: {},
              }
            }
            return {
              id: key,
              value: key,
              label: val,
            }
          })
          data.options = JSON.stringify(result)
        }
        return data
      },
      formList: [
        {
          type: 'input',
          key: 'attr_title',
          value: '',
          labelAttrs: {
            label: '名称',
            rules: [
              {
                required: true,
                message: '请输入名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            placeholder: '请输入名称',
          },
        },
        // {
        //   type: 'input',
        //   key: 'attr_key',
        //   value: '',
        //   labelAttrs: {
        //     label: '键',
        //     rules: [
        //       {
        //         required: true,
        //         message:
        //           '只能是英文数字下划线的组合且以英文或者下划线开头',
        //         trigger: 'blur',
        //         validator: validateKey,
        //       },
        //     ],
        //   },
        //   formAttrs: {
        //     type: 'text',
        //     placeholder: '请输入键',
        //     clearable: true,
        //   },
        // },
        {
          type: 'select',
          key: 'attr_type',
          labelAttrs: {
            label: '类型',
            rules: [
              {
                required: true,
                message: '请选择类型',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            placeholder: '请选择类型',
            clearable: true,
          },
          options: objToArr(EXTRA_FIELD_TYPE, false),
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'switch',
          key: 'is_required',
          value: 0,
          labelAttrs: {
            label: '必填',
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'slot',
          key: 'org_options',
          name: 'optionsList',
        },
        {
          type: 'slot',
          key: 'options',
        },
        {
          type: 'input',
          key: 'default_value',
          value: '',
          labelAttrs: {
            label: '默认值',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            placeholder: '请输入默认值',
          },
        },
        {
          type: 'input',
          key: 'placeholder',
          value: '',
          labelAttrs: {
            label: '输入提示',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            placeholder: '请输入输入提示',
          },
        },
        {
          type: 'hidden',
          key: 'type',
          value: type,
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 快速调价新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function adjustPriceAddDialog(MODULE = 'channel', type = '', info = {}) {
  console.log('type :>> ', type)
  let defaultConfig = {
    title: '快速调价',
    customTitle: true,
    dialog: {
      width: '500px',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/quickPrice`,
        update: `/${MODULE}/quickPrice`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '170px',
      },
      beforeReq: async (data) => {
        const reqResult = await request({
          url: `/${MODULE}/quickPrice`,
          method: 'post',
          data: {
            ...data,
            confirm: 0,
          },
        }).then((res) => {
          return res
        })
        if (reqResult) {
          const tips = `当前修改影响${reqResult.num}条数据,总价${
            reqResult.amount > 0 ? '增加' : ''
          }${numberToFixed(reqResult.amount, 2)}, 是否继续修改?`
          let result = await openReconfirm(tips)
          return result ? data : true
        }
        return true
      },
      formList: [
        {
          type: 'slot',
          key: 'id',
          value: info.id || '',
        },
        {
          type: 'slot',
          key: 'confirm',
          value: 1,
        },
        {
          type: 'inputNumber',
          key: 'new',
          value: null,
          labelAttrs: {
            label: '新购产品按建议价格',
          },
          sufUnit: '倍调价',
          formAttrs: {
            placeholder: '请输入倍率',
            tipsCls: 'ml4',
          },
        },
        {
          type: 'inputNumber',
          key: 'old',
          value: null,
          labelAttrs: {
            label: '复购产品按历史底价',
          },
          sufUnit: '倍调价',
          formAttrs: {
            placeholder: '请输入倍率',
            tipsCls: 'ml4',
          },
        },
        {
          type: 'inputNumber',
          key: 'all',
          value: null,
          labelAttrs: {
            label: '所有产品按当前价格',
          },
          sufUnit: '倍调价',
          formAttrs: {
            placeholder: '请输入倍率',
            tipsCls: 'ml4',
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 打印机设备新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function printDeviceAddDialog(
  MODULE = 'channel',
  type = 'device',
  info
) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  const objTitle = {
    tpl: '打印模版',
    printer: '打印机',
    device: '硬件设备',
    buyPrinter: '购买打印机',
  }

  const deviceObj = {
    1: {
      title: '飞鹅云标签打印机',
      price: '500',
      img: 'https://cdn.erp.jinweitec.com/static/img/FEprinter.png',
    },
    2: {
      title: '佳博云标签打印机',
      price: '1250',
      img: 'https://cdn.erp.jinweitec.com/static/img/JBYprinter.png',
    },
  }
  let defaultConfig = {
    title: objTitle[type],
    dialog: {
      width: '500px',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '100px',
      },
      formList: [
        {
          type: 'input',
          key: 'title',
          value: '',
          labelAttrs: {
            label: '名称',
            rules: [
              {
                required: true,
                message: '请输入名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            placeholder: '请输入名称',
          },
        },
        {
          type: 'select',
          key: 'type',
          value: 1,
          labelAttrs: {
            label: '类型',
          },
          formAttrs: {
            class: 'w',
            placeholder: '请选择类型',
          },
          options: objToArr(PRINTER_TYPE),
        },
        {
          type: 'input',
          key: 'sn',
          value: '',
          labelAttrs: {
            label: 'SN编码',
            rules: [
              {
                required: true,
                message: '请输入SN编码',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            placeholder: '请输入SN编码',
          },
        },
        {
          type: 'input',
          key: 'secret',
          value: '',
          labelAttrs: {
            label: '密钥',
            rules: [
              {
                required: true,
                message: '请输入密钥',
                trigger: 'blur',
              },
            ],
          },
          condition: (model) => {
            return model.type === 1 || model.type === 6 || model.type === 7
          },
          formAttrs: {
            type: 'text',
            clearable: true,
            disabled: false,
            placeholder: '请输入密钥',
          },
        },
        {
          type: 'switch',
          key: 'is_default',
          value: 0,
          labelAttrs: {
            label: '是否默认',
          },
          formAttrs: {
            'inline-prompt': true,
            'active-text': '是',
            'inactive-text': '否',
          },
          layoutAttrs: {
            span: 8,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  if (type === 'tpl') {
    defaultConfig.dialog.width = '750px'
    defaultConfig.form.formList.splice(
      2,
      2,
      {
        type: 'input',
        key: 'l',
        value: '',
        labelAttrs: {
          label: '标签纸长(mm)',
        },
        formAttrs: {
          type: 'text',
          clearable: true,
          placeholder: '请输入标签纸长度',
        },
      },
      {
        type: 'input',
        key: 'w',
        value: '',
        labelAttrs: {
          label: '标签纸宽(mm)',
        },
        formAttrs: {
          type: 'text',
          clearable: true,
          placeholder: '请输入标签纸宽度',
        },
      },
      {
        type: 'input',
        key: 'content',
        value: '',
        labelAttrs: {
          label: '模本内容',
        },
        formAttrs: {
          type: 'textarea',
          clearable: true,
          autosize: { minRows: 6 },
          placeholder: '请输入模本内容',
        },
      },
      {
        type: 'uploadImg',
        key: 'thumbnail',
        labelAttrs: {
          label: '缩略图',
        },
        formAttrs: {
          uploadParam: { module: MODULE },
          limitSize: 1024 * 10,
        },
      }
    )
  } else if (type === 'buyPrinter') {
    defaultConfig.customTitle = true
    defaultConfig.form.api.add = `/${MODULE}/desired`
    defaultConfig.form.formList = [
      {
        type: 'select',
        key: 'type',
        value: 1,
        labelAttrs: {
          label: '类型',
        },
        formAttrs: {
          class: 'w',
          placeholder: '请选择类型',
        },
        options: objToArr(PRINTER_TYPE),
        onChange: (val, model) => {
          model.img = deviceObj[val].img
          model.price = deviceObj[val].price
        },
        sufUnitCls: 'lh1 mt5 cd fs12',
        sufUnitFun: (model) => {
          return `最大打印宽度${model.type === 1 ? '60' : '104'}mm`
        },
      },
      {
        type: 'uploadImg',
        key: 'img',
        value: 'https://cdn.erp.jinweitec.com/static/img/FEprinter.png',
        labelAttrs: {
          label: '图片',
        },
        formAttrs: {
          disabled: true,
          uploadParam: { module: MODULE },
          limitSize: 1024 * 10,
        },
      },
      {
        type: 'tips',
        key: 'amount',
        value: deviceObj[1].price,
        labelAttrs: {
          label: '价格',
        },
        formAttrs: {},
      },
    ]
    defaultConfig.footer = [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '购买',
        attrs: {
          type: 'primary',
        },
      },
    ]
  }
  return defaultConfig
}

/**
 * 选择打印设备新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @param {*} needNum // 是否需要打印数量
 * @param {*} usePrinter // 是否使用打印机
 * @returns
 */
export function choosePrintDeviceAddDialog(
  MODULE = 'channel',
  type = '',
  needNum = false,
  usePrinter = false
) {
  console.log('type :>> choosePrintDeviceAddDialog', type)
  let url = type || 'printLabel'
  if (type === 'batch' || type === 'singleJump' || type === 'batchStock') {
    url = 'printLabel'
  }
  if (MODULE === 'supplier-sale-return-detail') {
    // 退货单标签打印
    url = 'printLabel'
  }

  let addUrl = `/${MODULE}/${url}`
  // 图片打印
  if (MODULE === 'printImage') {
    url = 'printImage'
    addUrl = `/printer/printImage`
  }
  const userInfo = store.getters['user/userInfo']
  const uniqueIndex = `${MODULE}|${type}|${url}|${userInfo.company_id}`
  console.log('uniqueIndex', uniqueIndex)
  const localPrinterSetting = LS.get('localPrinterSetting') || {}
  let device_id = ''
  let template_id = ''
  if (localPrinterSetting[uniqueIndex]) {
    const [deviceId, templateId] = localPrinterSetting[uniqueIndex].split('-')
    device_id = +deviceId
    template_id = +templateId
  }
  let defaultConfig = {
    title: '打印标签',
    customTitle: true,
    dialog: {
      width: '500px',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: addUrl,
        update: addUrl,
        // updateCallback: (res) => {
        //   url = window.URL.createObjectURL(res)
        //   window.open(url)
        //   // download(url, '标签.pdf')
        // },
        options: (data) => {
          if (data.device_id === 0) {
            return {
              resType: 'blob',
              responseType: 'blob',
            }
          }
          return {}
        },
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '100px',
      },
      beforeCreate: {
        setModelFun: (model) => {
          if (localPrinterSetting[uniqueIndex]) {
            return { ...model, device_id: device_id, template_id: template_id }
          }
          return model
        },
      },
      beforeReq: (data, model, selectionRowIds) => {
        localPrinterSetting[
          uniqueIndex
        ] = `${data.device_id}-${data.template_id}`
        LS.set('localPrinterSetting', localPrinterSetting)
        if (usePrinter && data.device_id) {
          return data
        }
        let urls = ''
        if (
          type === 'batch' ||
          type === 'printProductLabel' ||
          (type === 'singleJump' && !data.device_id) ||
          (type === 'batchStock' && !data.device_id) ||
          !data.device_id
        ) {
          const params =
            type === 'batch'
              ? { ...data, ids: selectionRowIds }
              : { ...data, id: model.id, device_id: data.device_id || 0 }
          if (type === 'batchStock') {
            delete params.id
          }
          urls = objectToUrlParams(params)
          window.open(`${baseURL}/${MODULE}/${url}?${urls}`, '_blank')
          return {}
        }
        if (MODULE === 'printImage') {
          const url = model[type] || model.image
          if (url.includes(',')) {
            // 多图
            data.url = url
              .split(',')
              .map((item) => {
                return fillUrl(item, '', '', '', '')
              })
              .join(',')
          } else {
            data.url = fillUrl(url, '', '', '', '')
          }
        }
        return data
      },
      formList: [
        {
          type: 'selectServer',
          key: 'device_id',
          value: device_id,
          labelAttrs: {
            label: '设备',
            rules: [{ required: true, message: '请选择设备', trigger: 'blur' }],
          },
          formAttrs: {
            listApi: `/printer/index`,
            class: 'w',
            labelKey: 'title',
            searchKey: 'title',
            clearable: false,
            placeholder: '请选择设备',
            defaultSelect: true,
            emitSelect: true,
            refSearchKey: 'type', // 搜索数据的key
            refKey: 'template_id', // 关联数据key
            tips: '配置>硬件配置>打印机 中添加',
            tipsCls: 'g9 fs12 lh1 mt5',
            exParams: {
              type: type === 'batch' ? 99 : '',
            },
            exLabelKeyFun: (item) => {
              const text = `${item.title}（${PRINTER_TYPE[item.type]}）(${
                NORMAL_ONLINE_STATUS[item.status]
              })`
              return text
            },
          },
          searchValFun: (val) => {
            return val?.type ? val.type : ''
          },
        },
        {
          type: 'selectServer',
          key: 'template_id',
          value: template_id,
          cptKey: 'template_id',
          labelAttrs: {
            label: '打印模版',
          },
          formAttrs: {
            listApi: `/printer-template/index`,
            class: 'w',
            labelKey: 'title',
            searchKey: 'title',
            clearable: false,
            avatarKey: 'thumbnail',
            avatarCls: 'wh60',
            placeholder: '请选择打印模版',
            tips: '配置>硬件配置>打印模版 中添加',
            tipsCls: 'g9 fs12 lh1 mt5',
            exParams: {
              device_id: device_id || '',
            },
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '打印',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  if ((type === 'batch' || type === 'singleJump') && !needNum) {
    defaultConfig.form.formList.push({
      type: 'radio',
      key: 'mode',
      value: 'multiple',
      labelAttrs: {
        label: '打印类型',
      },
      formAttrs: {
        class: 'w',
      },
      options: objToArrRadio(PRINT_LABEL_TYPE, false),
    })
  }
  if (type === 'batchStock') {
    defaultConfig.form.formList.unshift({
      type: 'cascaderServer',
      key: 'pid',
      value: '',
      labelAttrs: {
        label: '父级仓库',
        rules: [
          {
            required: true,
            message: '请选择仓库',
            trigger: 'blur',
          },
        ],
      },
      formAttrs: {
        placeholder: '请选择仓库',
        clearable: true,
        filterable: true,
        multiple: false,
        showAllLevels: true,
        class: 'w',
        listApi: '/repository/tree',
        exParams: {
          sort: 'title',
          order: 'asc',
        },
        props: {
          label: 'title',
          value: 'id',
          checkStrictly: true,
          multiple: false,
        },
      },
      formEvents: {},
    })
  }
  if (needNum) {
    defaultConfig.form.formList.push({
      type: 'inputNumber',
      key: 'num',
      value: '',
      labelAttrs: {
        label: '数量',
      },
      formAttrs: {
        class: 'w',
        min: 1,
        placeholder: '请输入打印数量',
      },
    })
  }
  if (MODULE === 'printImage') {
    defaultConfig.title = '打印图片'
    defaultConfig.form.formList.pop()
  }
  return defaultConfig
}

/**
 * 公司发货地址新增弹窗
 * @param {*} type  // 类型判断
 * @param {*} params  // 参数
 * @param {*} info  // 信息
 * @returns
 */
export function addressAddDialog(
  MODULE = 'address',
  type = '',
  params = {},
  info = {}
) {
  console.log('type :>> ', type)
  console.log('params :>> ', params)
  console.log('info :>> ', info)
  const defaultConfig = {
    title: '发货地址',
    dialog: {
      width: '700px',
      top: '10vh',
      appendToBody: true,
      destroyOnClose: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        update: `/${MODULE}/edit`,
        bindModule: MODULE,
      },
      formListKey: true, // 参数是formList的key
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '100px',
        ...dynamicsFormAttrs,
      },
      formList: [
        {
          type: 'input',
          key: 'recipients',
          value: '',
          labelAttrs: {
            label: '寄件人',
            rules: [
              {
                required: true,
                message: '请输入寄件人',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入寄件人姓名',
            clearable: true,
          },
          layoutAttrs: {
            span: 24,
          },
        },
        {
          type: 'input',
          key: 'company',
          value: '',
          labelAttrs: {
            label: '公司名称',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入寄件人姓名',
            clearable: true,
          },
          layoutAttrs: {
            span: 24,
          },
        },
        {
          type: 'input',
          key: 'phone',
          value: '',
          labelAttrs: {
            label: '寄件人电话',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入寄件人电话',
            clearable: true,
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'input',
          key: 'email',
          value: '',
          labelAttrs: {
            label: '寄件人邮箱',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入寄件人邮箱',
            clearable: true,
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'input',
          key: 'street',
          labelAttrs: {
            label: '寄件地址1',
            rules: [
              {
                required: true,
                message: '请输入寄件地址1',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入寄件地址1',
            clearable: true,
          },
        },
        {
          type: 'input',
          key: 'street2',
          labelAttrs: {
            label: '寄件地址2',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入寄件地址2',
            clearable: true,
          },
        },
        {
          type: 'selectServer',
          key: 'country',
          labelAttrs: {
            label: '国家',
            rules: [
              {
                required: true,
                message: '请选择国家',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            listApi: `/config/shipsaving/country`,
            class: 'w',
            valueType: 'number',
            placeholder: '请选择国家',
            noPaging: true,
            avatarKey: 'logo',
            avatarCls: 'w50',
            emitSelect: true,
            defaultSelect: true,
            handleDataFun: (data) => {
              return data.map((item) => {
                return {
                  ...item,
                  logo: `https://flagpedia.net/data/flags/w580/${item?.id?.toLowerCase()}.webp`,
                }
              })
            },
          },
          onChange: (val, model) => {
            model.stateOptions = val?.children?.length ? val.children : null
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'slot',
          key: 'state',
          name: 'stateSlot',
          labelAttrs: {
            label: '州',
          },
          formAttrs: {},
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'input',
          key: 'city',
          labelAttrs: {
            label: '城市',
            rules: [
              {
                required: true,
                message: '请输入城市',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入城市',
            clearable: true,
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'input',
          key: 'zip',
          labelAttrs: {
            label: '邮编',
            rules: [
              {
                required: true,
                message: '请输入邮编',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入邮编',
            clearable: true,
          },
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'switch',
          key: 'is_default',
          value: 0,
          labelAttrs: {
            label: '默认地址',
          },
          formAttrs: {
            'inline-prompt': true,
            'active-text': '是',
            'inactive-text': '否',
          },
          layoutAttrs: {
            span: 12,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '确定',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  if (type === 'addCNAddress') {
    defaultConfig.form.formList = [
      {
        type: 'input',
        key: 'recipients',
        value: '',
        labelAttrs: {
          label: '寄件人',
          rules: [
            {
              required: true,
              message: '请输入寄件人',
              trigger: 'blur',
            },
          ],
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入寄件人姓名',
          clearable: true,
        },
        layoutAttrs: {
          span: 24,
        },
      },
      {
        type: 'input',
        key: 'phone',
        value: '',
        labelAttrs: {
          label: '寄件人电话',
          rules: [
            {
              required: true,
              message: '请输入寄件人电话',
              trigger: 'blur',
            },
          ],
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入寄件人电话',
          clearable: true,
        },
        layoutAttrs: {
          span: 12,
        },
      },
      {
        type: 'input',
        key: 'email',
        value: '',
        labelAttrs: {
          label: '寄件人邮箱',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入寄件人邮箱',
          clearable: true,
        },
        layoutAttrs: {
          span: 12,
        },
      },
      {
        type: 'input',
        key: 'street',
        labelAttrs: {
          label: '寄件地址',
          rules: [
            {
              required: true,
              message: '请输入寄件地址',
              trigger: 'blur',
            },
          ],
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入寄件地址',
          clearable: true,
        },
      },
      {
        type: 'input',
        key: 'zip',
        labelAttrs: {
          label: '邮编',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入邮编',
          clearable: true,
        },
        layoutAttrs: {
          span: 12,
        },
      },
      {
        type: 'switch',
        key: 'is_default',
        value: 0,
        labelAttrs: {
          label: '默认地址',
        },
        formAttrs: {
          'inline-prompt': true,
          'active-text': '是',
          'inactive-text': '否',
        },
        layoutAttrs: {
          span: 12,
        },
      },
    ]
  }
  return defaultConfig
}
/**
 * 快递费用变更新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @param {*} info // 详情
 * @param {*} params // 参数
 * @returns
 */
export function companyExpressChargeAddDialog(
  MODULE = 'supplier',
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('params :>> ', params)
  let defaultConfig = {
    title: '费用变更',
    customTitle: true,
    dialog: {
      width: '500px',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/balanceChange`,
        // detail: `/${MODULE}/detail`,
        update: `/${MODULE}/balanceChange`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '100px',
      },
      beforeReq: (data) => {
        data.pm = data.pm || 0
        return data
      },
      formList: [
        { type: 'slot', key: 'id', value: type ? info?.supplier_id : info.id },
        {
          type: 'radio',
          key: 'pm',
          value: type ? 0 : 1,
          labelAttrs: {
            label: '费用方式',
          },
          formAttrs: {},
          options: objToArrRadio(INCREASE_DECREASE),
        },
        {
          type: 'select',
          key: 'type',
          value: 2,
          labelAttrs: {
            label: '费用类型',
          },
          formAttrs: {
            placeholder: '请选择费用类型',
            class: 'w',
            filterable: true,
            clearable: true,
          },
          options: objToArr(SUPPLIER_BALANCE_TYPE),
        },
        {
          type: 'inputNumber',
          key: 'amount',
          value: null,
          labelAttrs: {
            label: '金额',
            rules: [
              {
                required: true,
                message: '请输入金额',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            class: 'wa',
            min: 0,
            placeholder: '请输入金额',
          },
          preUnit: '$',
        },
        {
          type: 'input',
          key: 'admin_remark',
          value: '',
          labelAttrs: {
            label: '备注',
          },
          formAttrs: {
            type: 'textarea',
            clearable: true,
            disabled: false,
            placeholder: '',
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  // 有关联模块
  if (type) {
    defaultConfig.form.formList.push(
      { type: 'slot', key: 'ref_id', value: info.id },
      { type: 'slot', key: 'ref_type', value: type }
    )
  }
  return defaultConfig
}

/**
 * 附件新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @param {*} info // 详情
 * @param {*} params // 参数
 * @returns
 */
export function fileListAddDialog(
  MODULE = 'file',
  type = '',
  info = {},
  params = {}
) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  console.log('params :>> ', params)
  const layoutSpan = type === 'rename' ? 24 : 12
  let defaultConfig = {
    title: '文件',
    dialog: {
      width: '700px',
      appendToBody: true,
      destroyOnClose: true,
    },
    form: {
      api: {
        detail: `/${MODULE}/detail`,
        add: `/${MODULE}/uploadFile`,
        update: `/${MODULE}/edit`,
      },
      beforeReq: (data, model) => {
        if (type === 'upload') {
          delete model.id
        }
        return data
      },
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '100px',
      },
      formList: [
        {
          type: 'input',
          key: 'title',
          labelAttrs: {
            label: '文件名称',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入文件名称',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'input',
          key: 'url',
          labelAttrs: {
            label: '来源链接',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入网页来源链接',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'input',
          key: 'keywords',
          labelAttrs: {
            label: '关键词',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入关键词',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'input',
          key: 'description',
          labelAttrs: {
            label: '描述',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入描述',
            clearable: true,
          },
          layoutAttrs: {
            span: layoutSpan,
          },
        },
        {
          type: 'slot',
          key: 'path',
          name: 'fileUpload',
          labelAttrs: {
            label: '附件',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
          },
          layoutAttrs: {
            span: 24,
          },
        },
        {
          type: 'slot',
          key: 'id',
          value: info.id,
          labelAttrs: {
            label: 'id',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
          },
        },
        {
          type: 'slot',
          key: 'module',
          value: params.module,
          labelAttrs: {
            label: 'module',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
          },
        },
        {
          type: 'slot',
          key: 'name',
          value: '',
          labelAttrs: {
            label: 'name',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
          },
        },
        {
          type: 'slot',
          key: 'size',
          value: '',
          labelAttrs: {
            label: 'size',
          },
          formAttrs: {
            type: 'text',
            clearable: true,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '确定',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  if (type === 'rename') {
    defaultConfig.form.formList.splice(1, 10)
  }

  return defaultConfig
}

/**
 * 更换产品
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @param {*} info // 详情
 * @param {*} params // 参数
 * @returns
 */
export function editBoxReplaceProduct(MODULE = '', type = '', info = {}) {
  console.log('type :>> ', type)
  console.log('info :>> editBoxReplaceProduct', info)
  let defaultConfig = {
    title: '更换产品',
    customTitle: true,
    dialog: {
      width: '500px',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: `/${MODULE}/edit`,
        // detail: `/${MODULE}/detail`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '100px',
      },
      formList: [
        { type: 'slot', key: 'id', value: info.id },
        {
          type: 'selectServer',
          key: 'product_id',
          value: '',
          labelAttrs: {
            label: '更换的产品',
            rules: [{ required: true, message: '请选择产品', trigger: 'blur' }],
            // required: true,
          },
          formAttrs: {
            class: 'w',
            clearable: false,
            filterable: true,
            listApi: `/product/index`,
            modelValueKey: 'product_info',
            searchKey: 'search',
            placeholder: '支持产品名称/OEM码/编码搜索',
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '保存',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * 菜单编辑
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @param {*} info // 详情
 * @param {*} params // 参数
 * @returns
 */
export function editBoxMenuAddDialog(MODULE = '', type = '', info = {}) {
  console.log('type :>> ', type)
  console.log('info :>> ', info)
  let defaultConfig = {
    title: '菜单',
    dialog: {
      width: '700px',
      appendToBody: true,
      destroyOnClose: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        update: `/${MODULE}/edit`,
      },
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '100px',
      },
      formList: [
        {
          type: 'input',
          key: 'title',
          value: '',
          labelAttrs: {
            label: '菜单名称',
            rules: [
              {
                required: true,
                message: '请输入菜单名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入菜单名称',
            clearable: true,
          },
        },
        {
          type: 'input',
          key: 'remark',
          value: '',
          labelAttrs: {
            label: '名称备注',
            rules: [
              {
                required: true,
                message: '请输入菜单名称备注',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入菜单名称备注',
            clearable: true,
          },
        },
        {
          type: 'cascaderServer',
          key: 'pid',
          value: 0,
          labelAttrs: {
            label: '父级菜单',
          },
          formAttrs: {
            placeholder: '请选择父级菜单',
            clearable: true,
            filterable: true,
            'show-all-levels': true,
            listApi: '/menu/tree',
            props: {
              label: 'remark',
              value: 'id',
              checkStrictly: true,
            },
          },
        },
        {
          type: 'input',
          key: 'url',
          value: '',
          labelAttrs: {
            label: '菜单地址',
            rules: [
              {
                required: true,
                message: '请输入菜单地址',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入菜单地址',
            clearable: true,
          },
        },
        {
          type: 'cascaderServer',
          key: 'rid',
          value: 0,
          labelAttrs: {
            label: '关联菜单',
          },
          formAttrs: {
            placeholder: '请选择关联菜单',
            clearable: true,
            filterable: true,
            'show-all-levels': true,
            listApi: '/menu/tree',
            props: {
              label: 'remark',
              value: 'id',
              checkStrictly: true,
            },
          },
        },
        {
          type: 'icon',
          key: 'icon',
          value: '',
          labelAttrs: {
            label: '菜单图标',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入菜单图标',
            clearable: true,
          },
        },
        {
          type: 'inputNumber',
          key: 'rank',
          value: 0,
          labelAttrs: {
            label: '菜单排序',
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入菜单排序',
            clearable: true,
            max: 9999,
            style: 'width:220px',
          },
        },
        {
          type: 'switch',
          key: 'show',
          value: 1,
          labelAttrs: {
            label: '是否显示PC',
          },
        },
        {
          type: 'switch',
          key: 'show_mobile',
          value: 1,
          labelAttrs: {
            label: '显示移动端',
          },
        },
        {
          type: 'switch',
          key: 'status',
          value: 1,
          labelAttrs: {
            label: '使用状态',
          },
        },
        // {
        //   type: 'switch',
        //   key: 'is_root',
        //   value: 1,
        //   labelAttrs: {
        //     label: 'ROOT',
        //   },
        // },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '确定',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  if (type) {
    defaultConfig.form.formList = [
      {
        type: 'input',
        key: 'title',
        value: '',
        labelAttrs: {
          label: '菜单名称',
          rules: [
            {
              required: true,
              message: '请输入菜单名称',
              trigger: 'blur',
            },
          ],
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入菜单名称',
          clearable: true,
        },
      },
      {
        type: 'cascaderServer',
        key: 'pid',
        value: 0,
        labelAttrs: {
          label: '父级菜单',
        },
        formAttrs: {
          placeholder: '请选择父级菜单',
          clearable: true,
          filterable: true,
          disabled: true,
          'show-all-levels': true,
          listApi: '/menu/tree',
          props: {
            label: 'remark',
            value: 'id',
            checkStrictly: true,
          },
        },
      },
      {
        type: 'icon',
        key: 'icon',
        value: '',
        labelAttrs: {
          label: '菜单图标',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入菜单图标',
          clearable: true,
        },
      },
      {
        type: 'inputNumber',
        key: 'rank',
        value: 0,
        labelAttrs: {
          label: '菜单排序',
        },
        formAttrs: {
          type: 'text',
          placeholder: '请输入菜单排序',
          clearable: true,
          max: 9999,
          style: 'width:220px',
        },
      },
      {
        type: 'switch',
        key: 'show',
        value: 1,
        labelAttrs: {
          label: '是否显示PC',
        },
      },
      {
        type: 'switch',
        key: 'show_mobile',
        value: 1,
        labelAttrs: {
          label: '显示移动端',
        },
      },
    ]
    defaultConfig.form.beforeReq = async (data) => {
      // 行业
      if (type === 'industry') {
        data.industry_id = info.id
      } else if (type === 'companys') {
        data.company_id = info.id
      }
      return data
    }
    defaultConfig.form.api.updateCallback = () => {
      // 重新获取菜单
      store.dispatch('routes/getUserMenuList')
      setTimeout(() => {
        // 重新获取菜单
        store.dispatch('routes/setRoutes', authentication)
      }, 2000)
    }
  }
  return defaultConfig
}
/**
 * 快递面单新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function expressSheetAddDialog(MODULE = '', type = '') {
  console.log('type', type)
  let defaultConfig = {
    title: '快递账号',
    dialog: {
      width: '700px',
      top: '20vh',
      appendToBody: true,
      destroyOnClose: true,
    },
    form: {
      api: {
        add: `/${MODULE}/add`,
        update: `/${MODULE}/edit`,
      },
      formListKey: true, // 参数是formList的key
      formAttrs: {
        labelPosition: 'left',
        labelWidth: '100px',
      },
      formList: [
        {
          type: 'input',
          key: 'title',
          value: '',
          labelAttrs: {
            label: '名称',
            rules: [
              {
                required: true,
                message: '请输入名称',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入名称',
            clearable: true,
          },
        },
        {
          type: 'input',
          key: 'token',
          value: '',
          labelAttrs: {
            label: 'Token',
            rules: [
              {
                required: true,
                message: '请输入token',
                trigger: 'blur',
              },
            ],
          },
          formAttrs: {
            type: 'text',
            placeholder: '请输入token',
            clearable: true,
          },
        },
        {
          type: 'inputNumber',
          key: 'increment_rate',
          value: '',
          labelAttrs: {
            label: '征收比例',
            tips: '快递费用征收比例',
          },
          formAttrs: {
            min: 0,
            max: 100,
            placeholder: '请输入比例',
            class: 'wi',
            precision: 2,
          },
          sufUnit: '%',
          layoutAttrs: {
            span: 12,
          },
        },
        {
          type: 'switch',
          key: 'is_default',
          value: 0,
          labelAttrs: {
            label: '是否默认',
          },
          formAttrs: {
            'inline-prompt': true,
            'active-text': '是',
            'inactive-text': '否',
          },
          layoutAttrs: {
            span: 12,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '确定',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }
  return defaultConfig
}

/**
 * 选择打印设备新增弹窗
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @param {*} needNum // 是否需要打印数量
 * @param {*} usePrinter // 是否使用打印机
 * @returns
 */
export function shippingImgAddDialog(MODULE = 'channel', type = '', opt = {}) {
  console.log('type :>> ', type)
  let addUrl = `/${MODULE}/edit`

  let defaultConfig = {
    title: '发货单据',
    customTitle: true,
    dialog: {
      width: '600px',
      appendToBody: true,
      destroyOnClose: true,
      lockScroll: true,
    },
    form: {
      api: {
        add: addUrl,
        update: addUrl,
      },
      formListKey: true,
      formAttrs: {
        labelPosition: 'right',
        labelWidth: '100px',
      },
      formList: [
        {
          type: 'uploadImg',
          key: 'label_url',
          labelAttrs: {
            label: '凭证文件',
          },
          formAttrs: {
            copyImgUploade: true,
            module: 'product',
            multiple: true,
            listType: 'text',
            accept: 'application/pdf, image/*',
            verifyFileType: false,
          },
        },
      ],
    },
    footer: [
      {
        action: 'cancel',
        title: '取消',
        attrs: {},
      },
      {
        action: 'confirm',
        title: '确定',
        attrs: {
          type: 'primary',
        },
      },
    ],
  }

  return defaultConfig
}
