/*
 * @Author: WGL
 * @Date: 2024-05-09 10:15:46
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-03-19 16:38:16
 * @Description: 业务配置相关
 */

// 产品列表路径
export const PRODUCT_PATH = '/product/productList'
// 客户列表路径
export const CUSTOMER_PATH = '/customer/list'
// 订单列表路径
export const SALEORDER_PATH = '/sale/saleOrder'
// 订单销售面板
export const SALEORDER_STATISTICS = '/sale/statistics'
// 报价单列表路径
export const QUOTATION_PATH = '/sale/quoteList'
// 采购订单列表路径
export const PURCHASE_PATH = '/purchase/purchaseOrder'
// 发货计划
export const DELIVERYPLAN_PATH = '/repository/deliveryPlan'
// 任务列表
export const TASK_PATH = '/task/list'
// 海关企业详情
export const MONITOR_PATH = '/monitor'
// AB客-海关客户详情
export const DECISIONAIDS_PATH = '/decision-aids'
// 独立站
export const SHOP_PATH = '/shop'
// 独立站产品
export const SHOP_PRODUCT_PATH = '/shop/product'
// 产品面板
export const PRODUCT_STATISTICS_PATH = '/product/statistics'
// 产品图册
export const PRODUCT_ATLAS_PATH = '/product/atlas'
// 产品图文
export const PRODUCT_GRAPHICS_PATH = '/product/graphics'
// 仓库列表
export const REPOSITORY_LIST_PATH = '/repository/repositoryList'
// 快递寄件
export const EXPRESS_LIST_PATH = '/repository/express'
// 渠道管理
export const CHANNEL_TYPE_PATH = '/marketing/channelType'
// 我方店铺
export const CHANNEL_MY_PATH = '/marketing/channel'
// 竞对店铺
export const CHANNEL_COMPETE_PATH = '/marketing/channelCompete'
// 回款明细
export const FINANCE_RECEIVABLES_PATH = '/finance/receivables'
// 公共操作
export const PUBLICE_OPERATE_PATH = '/public/operate'
