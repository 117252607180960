<!--
 * @Author: WGL 958897276@qq.com
 * @Date: 2025-02-12 09:08:42
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-03-05 17:56:20
 * @FilePath: /erp-admin/src/components/UserUpdateTime/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div :class="[styleCls, cls]" style="bottom: 0">
    {{ userName }} {{ updateTime }}{{ tips }}
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import { getTimeDiff } from '@/utils/index'
import dayjs from 'dayjs'
const props = defineProps({
  user: { type: Object, default: () => {} },
  time: { type: String, default: '' },
  tips: { type: String, default: '更新' },
  type: { type: String, default: '' }, // 数据类型
  from: { type: String, default: '' }, // 来源
  cls: { type: String, default: 'abs_lb fs11 bcf w' }, // 样式类名
})

const userName = computed(() => {
  return props.user?.username || props.user?.realname || ''
})

const updateTime = computed(() => {
  if (props.from === 'order' && !props.time) {
    return '暂无'
  }
  return getTimeDiff(props.time, props.type)
})

const styleCls = computed(() => {
  if (props.from === 'order') {
    if (!props.time) return 'cd'
    const isBefore = dayjs(props.time).isBefore(dayjs().subtract(90, 'day')) // 默认毫秒
    return isBefore ? 'cd' : 'g6'
  }
  return 'g6'
})
</script>
<script>
export default {
  name: 'UserUpdateTime',
}
</script>
<style lang="scss" scoped></style>
