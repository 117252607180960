<!--
 * @Author: WGL
 * @Date: 2024-05-13 09:47:55
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-03-10 17:02:50
 * @Description: 
-->
<template>
  <el-scrollbar class="stash-cascader-panel-item">
    <div v-if="showGridLayout">
      <div v-for="(row, i) in tableLayoutInfo.y" :key="i" class="fx">
        <div v-for="(col, j) in tableLayoutInfo.x" :key="j" class="ex">
          <!-- {{ i }}-{{ j }} -->
          <PanelItemGridNode
            :level="level"
            :nodes-pos="tableLayoutInfo.nodesPos"
            :pid="tableLayoutInfo.pid"
            :prop="prop"
            :x="j"
            :y="i"
          >
            <template #nodeItem="{ node, index, level }">
              <slot
                :index="index"
                :level="level"
                name="nodeItem"
                :node="node"
              ></slot>
            </template>
          </PanelItemGridNode>
        </div>
      </div>
    </div>
    <ul v-else>
      <li
        v-for="(node, index) in nodes"
        :key="index"
        @click="handleClick(node, index)"
      >
        <PanelItemNode
          :index="index"
          :level="level"
          :node="node"
          :prop="prop"
          :show-arrow="showArrow"
        >
          <template #nodeItem="{ node, index, level }">
            <slot
              :index="index"
              :level="level"
              name="nodeItem"
              :node="node"
            ></slot>
          </template>
        </PanelItemNode>
      </li>
    </ul>
    <template v-if="!nodes?.length">
      <slot :level="level" name="empty" :node="nodes">
        <el-empty description="暂无数据" />
      </slot>
    </template>
  </el-scrollbar>
</template>

<script setup>
import { defineProps, inject, computed } from 'vue'
import PanelItemNode from './PanelItemNode.vue'
import PanelItemGridNode from './PanelItemGridNode.vue'
const props = defineProps({
  nodes: { type: Array, default: () => [] },
  prop: { type: Object, default: () => {} },
  level: { type: Number, default: 1 },
  gridLevel: { type: Array, default: () => [] },
  showArrow: { type: Boolean, default: false },
})
const panel = inject('stashCascaderPanel')

// 是否是方格布局
const isGridLayout = computed(() => {
  return props?.gridLevel?.includes(props.level)
})
const showGridLayout = computed(() => {
  return tableLayoutInfo.value.x > 1 && tableLayoutInfo.value.y > 1
})
// 布局信息
const tableLayoutInfo = computed(() => {
  let x = 0
  let y = 0
  let nodesPos = {}
  if (isGridLayout.value) {
    props.nodes?.forEach((item, index) => {
      const pos = `${item.y}-${item.x}`
      nodesPos[pos] = { ...item, index }
      x = Math.max(x, item.x + 1)
      y = Math.max(y, item.y + 1)
    })
  }
  return { x, y, nodesPos, pid: props?.nodes[0]?.pid }
})

// console.log('tableLayoutInfo', tableLayoutInfo)
function handleClick(node, index) {
  panel.expandNode(node, props.level, index)
}
</script>

<style lang="scss">
$cascader-node-height: 45px;
$color-line: #e4e7ed;
.stash-cascader-panel-item {
  border-right: 1px solid #919293;
  height: 100%;
  &:last-child {
    border-right: none;
  }
  li {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 30px 0 15px;
    height: $cascader-node-height;
    line-height: $cascader-node-height;
    border-bottom: 1px solid $color-line;
    cursor: pointer;
    &:hover {
      background-color: #f5f7fa;
    }
    // &:last-child {
    //   border-bottom: none;
    // }
  }
  &-label {
    flex: 1;
  }
  &-grid {
    border-right: 1px solid $color-line;
    border-bottom: 1px solid $color-line;
    height: 45px;
    padding: 5px;
  }
  &-arrow {
    position: absolute;
    top: 0;
    right: 10px;
  }
}
</style>
