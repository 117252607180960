/*
 * @Author: WGL
 * @Date: 2023-05-31 14:48:24
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-04-02 11:06:56
 * @Description:
 */

// const MODULE_CUSTOMER = 'customer' // 客户模块
// const MODULE_BUSINESS = 'business-opportunity' // 商机模块
// const MODULE_PERFORMANCE_RULE = 'performance-rule' // 绩效规则模块
import {
  CURRENCY_UNIT,
  PRODUCT_UNIT,
  PRODUCT_SERVICE_CYCLE,
  BUSINESS_OPPORTUNITY_END_STAGE,
  BUSINESS_OPPORTUNITY_STAGE,
  AUDIT_STATUS,
  AUDIT_STATUS_COLOR,
  ORDER_DELIVERY_STATUS_COLOR,
  ORDER_PAY_STATUS,
  ORDER_PAY_STATUS_COLOR,
  ORDER_DELIVERY_STATUS,
  QUOTE_STATUS_COLOR,
  QUOTE_STATUS,
  ORDER_AUDIT_STATUS_COLOR,
  QUOTE_AUDIT_STATUS,
  ORDER_AUDIT_STATUS,
  PRODUCT_SOURCE_COLOR,
  PRODUCT_SOURCE,
  CONTAIN_TAX,
  YES_NO,
  DELIVERY_PLAN_DISTRIBUTION_MODE,
  EXPRESS_SIGNATURE,
  EXPRESS_INSURANCE_PROVIDER,
  SALE_RETURN_HANDLE,
  SUPPLIER_CLAIM_STATUS,
  SALE_ORDER_STATUS,
  NORMAL_STATUS_COLOR,
  NORMAL_STATUS,
  PROCESS_TYPE,
  PRODUCTION_PLAN_STATUS,
  PRODUCTION_PLAN_STATUS_COLOR,
  PRODUCTION_PLAN_TYPE_COLOR,
  PRODUCTION_PLAN_TYPE,
  PRODUCTION_PLAN_PRIORITY,
  DATE_FORMAT2,
  DATE_FORMAT3,
  PRODUCTION_RECEIPT_STATUS,
  PRODUCT_NATURE,
  PRODUCTION_RECEIPT_POSTPONE_STATUS,
  PRODUCTION_RECEIPT_OUTSOURCE_STATUS,
  PRODUCTION_RECEIPT_PROCESS_STATUS,
  NORMAL_STATUS_STEP_COLOR,
  PROCESS_RECEIPT_QC_STATUS,
  ENTRUST_STATUS,
  ENTRUST_TYPE,
  EXPRESS_DATA,
  CUSTOMER_TYPE,
  EQUIPMENT_STATUS,
  EQUIPMENT_STATUS_COLOR,
  CUSTOMER_PUBLIC,
  MHL_HONGKONG_DISTRICT_COLOR,
  MHL_DELIVERY_PLAN_STATUS,
  BILL_PLAN_STATUS,
  PAY_TYPE,
  CURRENCY,
  RECHECK_STATUS,
  EQUIPMENT_GROUP_STATUS,
  EQUIPMENT_GROUP_SAMPLE_STATUS,
  EQUIPMENT_GROUP_PRIORITY,
  THIRD_REPOSITORY_TRANSFER_TYPE,
  REPOSITORY_COMMON_STATUS,
  REPOSITORY_COMMON_STATUS_COLOR,
} from '@/config/variable.config'
import { objToArr, percentNum } from '@/utils/index'
import { t } from '@/utils/i18n'
import store from '@/store'
import dayjs from 'dayjs'
import {
  handleSupportCurrency,
  getFormListIndex,
  getProductStock,
} from '@/utils/business'
import { PRODUCT_PATH } from '@/config/business.config'

/**
 * 产品基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function productDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)
  let defaultConfig = {
    attrs: {
      // border: true,
      column: 4,
      title: '基本信息',
      direction: 'horizontal',
      labelWidth: '105px',
      // showColon: true
      // labelClassName: 'w120'
    },
    desType: 'info',
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    desColumns: [
      {
        label: '默认仓库',
        prop: 'default_repository_id',
        // span: 1,
      },
      {
        label: '是否KP',
        editType: 'tag',
        prop: 'is_pivotal',
        valFun: (row) => {
          return YES_NO[row.is_pivotal]
        },
        // span: 1,
      },
      {
        label: '起订量',
        prop: 'moq',
        valFun: (row) => {
          return row.moq + row.unit || ''
        },
      },
      {
        label: '部件备注',
        prop: 'admin_remark',
        // span: 1,
      },
      {
        label: '分类',
        prop: 'category_titles',
        // span: 1,
      },
      {
        label: '货源',
        editType: 'tag',
        tagTypeObj: PRODUCT_SOURCE_COLOR,
        prop: 'source',
        valFun: (row) => {
          return PRODUCT_SOURCE[row.source]
        },
        privacyId: 'source',
      },
      // {
      //   label: '车身部件',
      //   prop: 'vehicle_part_info.title',
      //   // span: 1,
      // },
      {
        label: '目标市场',
        prop: 'target_area',
        span: 12,
      },
      {
        label: '原产地',
        prop: 'source_area_id',
        editType: 'tag',
        valFun: (row) => {
          return row?.source_area_info?.title || '-'
        },
      },
      // {
      //   prop: 'box_l',
      //   label: '箱规-长(cm)',
      // },
      // {
      //   prop: 'box_w',
      //   label: '箱规-宽(cm)',
      // },
      // {
      //   prop: 'box_h',
      //   label: '箱规-高(cm)',
      // },
      // {
      //   prop: 'box_volume',
      //   label: '箱规-体积(m³)',
      // },
      // {
      //   label: '箱规-净重(kg)',
      //   prop: 'box_weight',
      //   // valFun: (row) => {
      //   //   return `${row.box_weight || '-'} kg/${row.box_rough_weight || '-'} kg`
      //   // },
      // },
      // {
      //   label: '箱规-毛重(kg)',
      //   prop: 'box_rough_weight',
      // },
      // {
      //   label: '装箱量',
      //   prop: 'box_quantity',
      // },
      // {
      //   label: '箱规',
      //   prop: 'box_l',
      //   valFun: (row) => {
      //     return `${row.box_l}L-${row.box_w}W-${row.box_h}H cm`
      //   },
      // },
      // {
      //   prop: 'l',
      //   label: '单个-长(cm)',
      // },
      // {
      //   prop: 'w',
      //   label: '单个-宽(cm)',
      // },
      // {
      //   prop: 'h',
      //   label: '单个-高(cm)',
      // },
      // {
      //   prop: 'volume',
      //   label: '单个-体积(m³)',
      // },
      // {
      //   prop: 'weight',
      //   label: '单个-净重(kg)',
      // },
      // {
      //   prop: 'rough_weight',
      //   label: '单个-毛重(kg)',
      // },
      // {
      //   label: '重量/毛重',
      //   prop: 'weight',
      //   valFun: (row) => {
      //     return `${row.weight || '-'} kg/${row.rough_weight || '-'} kg`
      //   },
      // },
      {
        label: '单位',
        prop: 'unit',
      },
      {
        label: '销量',
        prop: 'sales',
        privacyId: 'sales',
      },
      {
        label: '零售适配',
        prop: 'retail_suitable',
        editType: 'baseRate',
        attrs: {
          max: 3,
          disabled: true,
        },
      },
      {
        label: '产品评分',
        prop: 'score',
      },
      {
        label: '供应链评分',
        prop: 'supplier_score',
      },
      {
        label: '产品证书',
        prop: 'certificate',
        span: 12,
      },
      {
        label: '标签',
        prop: 'label_list',
        editType: 'tagList',
        span: 24,
      },
      {
        label: '描述',
        prop: 'description',
        span: 12,
      },
      {
        label: '内容',
        prop: 'content',
        span: 24,
      },
      {
        label: '创建人',
        prop: 'creator.name',
      },
      {
        label: '创建时间',
        prop: 'create_time',
      },
      {
        label: '最近更新',
        prop: 'update_time',
      },
    ],
  }
  // 头部展示
  if (type === 'header') {
    // 用户的列表字段
    const userFieldList = store.getters['sys/userFieldList']
    const userMenusAuthObj = store.getters['routes/menusObj']
    const productMenuId = userMenusAuthObj[PRODUCT_PATH]?.id
    const privacyArr = [
      {
        label: '建议售价',
        prop: 'price',
        valFun: (row) => {
          return `${CURRENCY_UNIT[row.currency] || ''} ${row.price}`
        },
        privacyId: 'advisePrice',
      },
      {
        label: '最低售价',
        prop: 'rt_price',
        valFun: (row) => {
          return `${CURRENCY_UNIT[row.currency] || ''} ${row.rt_price}`
        },
        privacyId: 'rt_price',
      },
      {
        label: '成本价',
        prop: 'cost',
        valFun: (row) => {
          return `${CURRENCY_UNIT[row.currency] || ''} ${row.cost}`
        },
        privacyId: 'cost',
      },
    ]
    const userProductField = userFieldList[productMenuId] || {}
    // defaultConfig.attrs.border = false
    defaultConfig.attrs.title = ''
    defaultConfig.attrs.labelWidth = ''
    defaultConfig.desColumns = [
      {
        label: '名称',
        prop: 'title',
      },
      {
        label: '编码',
        prop: 'code',
        privacyId: '',
      },
      {
        label: 'OEM编码',
        prop: 'oem_code',
        privacyId: '',
      },
      {
        label: '车身部件',
        prop: 'vehicle_part_info.title',
        span: 3,
      },
      {
        label: '库存',
        prop: 'vehicle_part_info.title',
        span: 3,
        valFun: (row) => {
          return getProductStock(row)
        },
      },
      // {
      //   label: '建议售价',
      //   prop: 'price',
      //   valFun: (row) => {
      //     return `${CURRENCY_UNIT[row.currency] || ''} ${row.price}`
      //   },
      //   privacyId: 'advisePrice'
      // },
      // {
      //   label: '最低售价',
      //   prop: 'rt_price',
      //   valFun: (row) => {
      //     return `${CURRENCY_UNIT[row.currency] || ''} ${row.rt_price}`
      //   },
      //   privacyId: 'rt_price'
      // },
      // {
      //   label: '成本价',
      //   prop: 'cost',
      //   valFun: (row) => {
      //     return `${CURRENCY_UNIT[row.currency] || ''} ${row.cost}`
      //   },
      //   privacyId: 'cost'
      // },
      {
        label: '价格策略',
        prop: 'pricePolicy',
        slotName: 'pricePolicy',
      },
    ]
    if (userProductField) {
      privacyArr.forEach((item) => {
        if (userProductField[item.privacyId]) {
          defaultConfig.desColumns.splice(-1, 0, item)
        }
      })
    }
  }

  // 普通商品
  if (type === 'common') {
    defaultConfig.desColumns = [
      {
        label: '名称',
        prop: 'title',
      },
      {
        label: '编码',
        prop: 'code',
      },
      {
        label: '推荐价格',
        prop: 'price',
        valFun: (row) => {
          return `${CURRENCY_UNIT[row.currency]} ${row.cost} ~ ${
            CURRENCY_UNIT[row.currency]
          } ${row.price}`
        },
      },
      {
        label: '单位',
        prop: 'unit',
        valFun: (row) => {
          return `${PRODUCT_UNIT[row.unit] || '-'}`
        },
      },
      {
        label: '实施服务',
        prop: 'unit',
        valFun: (row) => {
          return `${PRODUCT_SERVICE_CYCLE[row.service_cycle] || '-'}`
        },
      },
      {
        label: '分类',
        prop: 'category_titles',
        span: 12,
      },
      {
        label: '描述',
        prop: 'description',
        span: 24,
      },
      {
        label: '创建人',
        prop: 'creator.name',
      },
      {
        label: '创建时间',
        prop: 'create_time',
      },
      {
        label: '最近更新',
        prop: 'update_time',
      },
    ]
  }
  return defaultConfig
}
/**
 * 客户基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function customerDesTable(MODULE = '', type = '', info = {}) {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)
  let defaultConfig = {
    attrs: {
      border: false,
      column: 4,
      title: '基本信息',
      labelWidth: '95px',
      // direction: 'vertical',
    },
    desType: 'info',
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    desColumns: [
      // {
      //   label: '客户名称',
      //   prop: 'title',
      // },
      // {
      //   label: '客户编码',
      //   prop: 'code',
      // },
      { label: '头像', prop: 'avatar', isImgs: true, span: 6 },
      { label: '相册', prop: 'images', isImgs: true, span: 18 },
      {
        label: '标签',
        prop: 'label_list',
        editType: 'tagList',
        span: 24,
      },
      { label: '生日', prop: 'birthday' },
      { label: '宗教', prop: 'religion' },
      { label: '联系人', prop: 'contact' },
      // {
      //   label: '客户来源',
      //   prop: 'customer_source.title',
      // },
      {
        label: '客户类型',
        prop: 'is_public',
        editType: 'tag',
        valFun: (row) => {
          return CUSTOMER_PUBLIC[row.is_public]
        },
      },
      {
        label: '账期客户',
        prop: 'is_payment_period',
        editType: 'tag',
        valFun: (row) => {
          return YES_NO[row.is_payment_period]
        },
      },
      {
        label: '客户等级',
        prop: 'customer_level.title',
        valueClass: 'c0',
      },
      {
        label: '最后跟进时间',
        prop: 'last_follow_time',
      },
      {
        label: '微信',
        prop: 'wechat',
      },
      {
        label: 'Whats App',
        prop: 'whats_app',
      },
      {
        label: '手机号',
        prop: 'telephone',
      },
      {
        label: '邮箱',
        prop: 'email',
      },
      {
        label: '地区',
        prop: 'countrydata',
      },
      {
        label: '网址',
        prop: 'website',
        valueClass: 'c0',
      },
      {
        label: '感兴趣产品',
        prop: 'interest',
      },
      {
        label: '地址',
        prop: 'address',
        span: 12,
      },
      {
        label: '备注',
        prop: 'remark',
        span: 12,
      },
      {
        label: '创建人',
        prop: 'owner.name',
      },
      {
        label: '创建时间',
        prop: 'create_time',
      },
      {
        label: '最近更新',
        prop: 'update_time',
      },
    ],
  }
  if (type === 'header') {
    defaultConfig.attrs.title = ''
    defaultConfig.attrs.labelWidth = ''
    defaultConfig.desColumns = [
      {
        label: '客户名称',
        prop: 'title',
      },
      {
        label: '客户编码',
        prop: 'code',
      },
      {
        label: '客户行业',
        prop: 'customer_trade.title',
      },
      {
        label: '客户来源',
        prop: 'channel_info.title',
      },
    ]
  }
  if (type === 'baseInfo' && info?.type) {
    const wmsDes = [
      {
        label: '客户折扣',
        prop: 'discount_rate',
        valFun: (row) => {
          return `${row.discount_rate || 0}%`
        },
      },
      {
        label: '采购类型',
        prop: 'type',
        editType: 'tag',
        valFun: (row) => {
          return CUSTOMER_TYPE[row.type]
        },
      },
    ]
    defaultConfig.desColumns.splice(3, 0, ...wmsDes)
  }
  // 线索头部
  if (type === 'clueHeader') {
    defaultConfig.attrs.title = ''
    defaultConfig.attrs.labelWidth = ''
    defaultConfig.desColumns = [
      { label: '线索名称', prop: 'title' },
      { label: '线索来源', prop: 'customer_source.title', span: 3 },
      // { label: '线索编码', prop: 'code', },
      {
        label: '线索阶段',
        prop: 'stageLine',
        editType: 'tag',
        valFun: (row) => {
          return BUSINESS_OPPORTUNITY_STAGE[row.stage]
        },
        span: 15,
      },
    ]
  }
  // 线索信息
  if (MODULE === 'clueBaseInfo') {
    defaultConfig.desColumns.splice(2, 1, {
      label: '线索编码',
      prop: 'code',
    })
  }
  // 账单地址
  if (type === 'billingAddress') {
    defaultConfig.attrs.title = '账单地址'
    defaultConfig.desColumns = [
      { label: '地址1', prop: 'billing_address.address', span: 12 },
      { label: '地址2', prop: 'billing_address.address2', span: 12 },
      { label: '国家', prop: 'billing_address.country' },
      { label: '所在州', prop: 'billing_address.state' },
      { label: '城市', prop: 'billing_address.city' },
      { label: '邮编', prop: 'billing_address.zip' },
    ]
  }
  return defaultConfig
}

/**
 * 商机基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function businessOpportunityDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)
  let defaultConfig = {
    attrs: {
      border: true,
      column: 4,
      labelWidth: '95px',
      title: '商机详情',
    },
    desType: 'info',
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    desColumns: [
      // {
      //   label: '步骤',
      //   prop: 'quoteStep',
      //   slot: 'quoteStep',
      //   span: 10,
      // },
      {
        label: '商机名称',
        prop: 'title',
      },
      {
        label: '商机阶段',
        prop: 'stage',
        valFun: function (row) {
          return row.end_stage
            ? BUSINESS_OPPORTUNITY_END_STAGE[row.end_stage]
            : BUSINESS_OPPORTUNITY_STAGE[row.stage]
        },
        // editType: 'input',
      },
      {
        label: '最后跟进时间',
        prop: 'last_follow_time',
      },
      {
        label: '下次跟进时间',
        prop: 'next_follow_time',
      },
      {
        label: '预计成交金额',
        prop: 'amount',
        valueClass: 'c0',
        valFun: function (row) {
          return `${CURRENCY_UNIT[row.currency]} ${row.amount}`
        },
      },
      {
        label: '预计成交时间',
        prop: 'expected_completion_time',
      },
      {
        label: '备注',
        prop: 'remark',
        span: 24,
      },
      {
        label: '创建人',
        prop: 'owner.name',
      },
      {
        label: '创建时间',
        prop: 'create_time',
      },
      {
        label: '最近更新',
        prop: 'update_time',
      },
    ],
  }

  return defaultConfig
}

/**
 * 报价单基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function quoteDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)
  const { supportCurrency } = handleSupportCurrency()

  let defaultConfig = {
    attrs: {
      border: true,
      column: 4,
      title: '基本信息',
      labelWidth: '75px',
      // direction: 'vertical',
    },
    desType: 'info',
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    desColumns: [
      {
        label: '报价单名称',
        prop: 'title',
      },
      {
        label: '报价单编码',
        prop: 'code',
      },
      {
        label: '客户',
        prop: 'customer_info.title',
      },
      {
        label: '产品数量',
        prop: 'product_num',
        valueClass: 'c0 b',
      },
      {
        label: '订单金额',
        prop: 'amount',
        valFun: (row) => {
          return `${CURRENCY_UNIT[row.currency]}${row.amount}`
        },
        valueClass: 'cd b',
      },
      {
        label: '订单货币',
        prop: 'currency',
        valFun: (row) => {
          return supportCurrency[row.currency]
        },
      },
      {
        label: '审核状态',
        prop: 'audit_status',
        valFun: (row) => {
          return AUDIT_STATUS[row.audit_status]
        },
      },
      {
        label: '备注',
        prop: 'admin_remark',
      },
      {
        label: '创建人',
        prop: 'creator.name',
      },
      {
        label: '创建时间',
        prop: 'create_time',
      },
      {
        label: '最近更新',
        prop: 'update_time',
      },
    ],
  }
  if (type === 'edit') {
    defaultConfig = {
      attrs: {
        border: true,
        column: 10,
        title: '',
        direction: 'vertical',
      },
      titleSlotName: '',
      extraSlotName: '',
      headerActions: {
        list: [''],
      },
      desColumns: [
        {
          label: '步骤',
          prop: 'quoteStep',
          slot: 'quoteStep',
          span: 10,
        },
        {
          label: '名称',
          prop: 'title',
          editType: 'input',
          editTypeFun: (row) => {
            return row.status < 10 ? 'input' : ''
          },
        },
        {
          label: '编号',
          prop: 'code',
          // editType: 'input',
        },
        {
          label: '客户',
          // prop: 'customer_info.title',
          prop: 'drawCustomerInfo',
        },
        {
          label: '负责人',
          prop: 'owner.name',
        },
        {
          label: '币种',
          prop: 'currency',
          editType: 'select',
          editTypeFun: (row) => {
            return row.status < 10 ? 'select' : ''
          },
          attrs: {
            options: objToArr(supportCurrency, false, 'id', 'title'),
          },
          valFun: (row) => {
            return CURRENCY[row.currency]
          },
          // slot: 'currency',
        },
        {
          label: '金额',
          prop: 'amount',
          valFun: (row) => {
            return CURRENCY_UNIT[row.currency] + ' ' + row.amount
          },
        },
        {
          label: '创建时间',
          prop: 'create_time',
        },
        {
          label: '状态',
          prop: 'status',
          editType: 'tag',
          tagTypeObj: QUOTE_STATUS_COLOR,
          valFun: (row) => {
            return QUOTE_STATUS[row.status]
          },
        },
        {
          label: '审核状态',
          prop: 'audit_status',
          editType: 'tag',
          tagTypeObj: ORDER_AUDIT_STATUS_COLOR,
          valFun: (row) => {
            return QUOTE_AUDIT_STATUS[row.audit_status]
          },
        },
      ],
    }
  }
  defaultConfig.api = {
    update: `/${MODULE}/edit`,
  }
  return defaultConfig
}

/**
 * 订单基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function saleDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)
  const { supportCurrency } = handleSupportCurrency()

  let defaultConfig = {
    attrs: {
      border: true,
      column: 4,
      title: type ? '' : '基本信息',
      labelWidth: '',
      // direction: 'vertical',
    },
    desType: 'info',
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    api: {
      update: '/sale-order/edit',
    },
    desColumns: [
      {
        label: '订单名称',
        prop: 'title',
      },
      {
        label: '订单编码',
        prop: 'code',
      },
      {
        label: '客户',
        prop: 'customer_info.title',
      },
      {
        label: '订单来源',
        prop: 'channel_info.title',
      },
      {
        label: '产品数量',
        prop: 'product_num',
      },
      {
        label: '订单金额',
        prop: 'amount',
        valFun: (row) => {
          return `${CURRENCY_UNIT[row.currency]}${row.amount}`
        },
        valueClass: 'cd',
      },
      {
        label: '订单货币',
        prop: 'currency',
        valFun: (row) => {
          return supportCurrency[row.currency]
        },
      },
      {
        label: '负责人',
        prop: 'owner.name',
      },
      {
        label: '审核状态',
        prop: 'audit_status',
        editType: 'tag',
        tagTypeObj: AUDIT_STATUS_COLOR,
        valFun: (row) => {
          return AUDIT_STATUS[row.audit_status]
        },
      },
      {
        label: '审核人',
        prop: 'auditor.name',
      },
      {
        label: '审核备注',
        prop: 'audit_remark',
      },
      {
        label: '付款状态',
        prop: 'pay_status',
        editType: 'tag',
        tagTypeObj: ORDER_PAY_STATUS_COLOR,
        valFun: (row) => {
          return ORDER_PAY_STATUS[row.pay_status]
        },
      },
      {
        label: '复核状态',
        prop: 'recheck_status',
        editType: 'tag',
        tagTypeObj: AUDIT_STATUS_COLOR,
        valFun: (row) => {
          return RECHECK_STATUS[row.recheck_status]
        },
      },
      {
        label: '复核人',
        prop: 'rechecker.name',
      },
      {
        label: '复核备注',
        prop: 'recheck_remark',
      },

      {
        label: '发货状态',
        prop: 'delivery_status',
        editType: 'tag',
        tagTypeObj: ORDER_DELIVERY_STATUS_COLOR,
        valFun: (row) => {
          return ORDER_DELIVERY_STATUS[row.delivery_status]
        },
      },
      {
        label: '状态',
        prop: 'status',
        editType: 'tag',
        valFun: (row) => {
          return SALE_ORDER_STATUS[row.status]
        },
      },
      {
        label: '是否含税',
        prop: 'is_contain_tax',
        editType: 'tag',
        tagTypeObj: AUDIT_STATUS_COLOR,
        valFun: (row) => {
          return CONTAIN_TAX[row.is_contain_tax]
        },
      },
      {
        label: '税率',
        prop: 'tax_rate',
        valFun: (row) => {
          return `${row.tax_rate || 0}%`
        },
      },
      {
        label: '是否包邮',
        prop: 'is_freight',
        editType: 'tag',
        tagTypeObj: AUDIT_STATUS_COLOR,
        valFun: (row) => {
          return row.is_freight ? t('是') : t('否')
        },
      },
      {
        label: '邮费',
        prop: 'freight_amount',
        valFun: (row) => {
          return `${CURRENCY_UNIT[row.currency]}${row.freight_amount || 0}`
        },
      },
      {
        label: '发货日期',
        prop: 'delivery_date',
        editTypeFun: (row) => {
          // 有发货计划不能改日期
          return row.delivery_plan_num ? 'text' : 'datePicker'
        },
        valFun: (row) => {
          return row.delivery_date || null
        },
      },
      {
        label: '备注',
        prop: 'admin_remark',
        editType: 'input',
        span: 12,
      },
      {
        label: '包装要求',
        prop: 'packing_remark',
        editType: 'input',
        span: 12,
      },
      {
        label: '规格说明',
        prop: 'product_remark',
        editType: 'input',
        span: 12,
      },
      {
        label: '价格条款',
        prop: 'price_remark',
        editType: 'input',
        span: 12,
      },
      {
        label: '创建人',
        prop: 'creator.name',
      },
      {
        label: '创建时间',
        prop: 'create_time',
      },
      {
        label: '最近更新',
        prop: 'update_time',
      },
    ],
  }
  // 编辑
  if (type === 'edit') {
    defaultConfig.desType = ''
    defaultConfig.titleSlotName = 'desTitleSlotName'
    defaultConfig.attrs.column = 4
    defaultConfig.desColumns = [
      {
        label: '订单进度',
        prop: 'quoteStep',
        slot: 'quoteStep',
        span: 10,
      },
      {
        label: '备货进度',
        prop: 'stockupStep',
        slot: 'stockupStep',
        span: 10,
      },
      { label: '单号', prop: 'code' },
      { label: '名称', prop: 'title', editType: 'input' },
      {
        label: '币种',
        prop: 'currency',
        editType: 'select',
        editTypeFun: (row) => {
          return row.status < 10 ? 'select' : ''
        },
        attrs: {
          options: objToArr(supportCurrency, false, 'id', 'title'),
        },
        valFun: (row) => {
          return CURRENCY[row.currency]
        },
        // slot: 'currency',
      },
      { label: '负责人', prop: 'owner.name' },
      // {
      //   label: '最后编辑',
      //   prop: 'editor.name',
      // },
      {
        label: '审核状态',
        prop: 'audit_status',
        editType: 'tag',
        tagTypeObj: ORDER_AUDIT_STATUS_COLOR,
        valFun: (row) => {
          return ORDER_AUDIT_STATUS[row.audit_status]
        },
        // editType: 'select',
        // attrs: {
        //   options: objToArr(ORDER_AUDIT_STATUS, true, 'id', 'title'),
        // },
      },
      {
        label: '付款状态',
        prop: 'pay_status',
        editType: 'tag',
        tagTypeObj: ORDER_PAY_STATUS_COLOR,
        valFun: (row) => {
          return ORDER_PAY_STATUS[row.pay_status]
        },
        // editType: 'select',
        // attrs: {
        //   options: objToArr(ORDER_PAY_STATUS, true, 'id', 'title'),
        // },
      },
      {
        label: '发货状态',
        prop: 'delivery_status',
        editType: 'tag',
        tagTypeObj: ORDER_DELIVERY_STATUS_COLOR,
        valFun: (row) => {
          return ORDER_DELIVERY_STATUS[row.delivery_status]
        },
        // editType: 'select',
        attrs: {
          options: objToArr(ORDER_DELIVERY_STATUS, true, 'id', 'title'),
        },
      },
      { label: '客户', prop: 'customer_info.title' },
    ]
  }

  return defaultConfig
}

/**
 * 发货订单基本信息(家具)
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function furnitureSaleDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)

  let defaultConfig = {
    attrs: {
      border: true,
      column: 4,
      title: type ? '' : '基本信息',
      labelWidth: '',
      // direction: 'vertical',
    },
    desType: 'info',
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    api: {
      update: `/${MODULE}/edit`,
    },
    desColumns: [
      { label: '订单名称', prop: 'sale_order_info.title' },
      { label: '订单编码', prop: 'sale_order_info.code' },
      { label: '客户', prop: 'customer_info.title' },
      { label: '客户地址', prop: 'customer_info.address' },
      { label: '联系电话', prop: 'customer_info.telephone' },
      {
        label: '客户区域',
        prop: 'sale_order_info.district',
        editType: 'tag',
        tagTypeObj: MHL_HONGKONG_DISTRICT_COLOR,
        attrs: {
          effect: 'dark',
        },
      },
      {
        label: '产品类别',
        prop: 'productCategory',
        editType: 'tag',
        width: 150,
      },
      {
        label: '业务员',
        prop: 'creator.name',
      },
      {
        label: '发货状态',
        prop: 'delivery_status',
        editType: 'tag',
        valFun: (row) => {
          return MHL_DELIVERY_PLAN_STATUS[row.status]
        },
      },
      {
        label: '下单时间',
        prop: 'order_time',
        valFun: (row) => {
          return row?.sale_order_info?.order_time
            ? dayjs(row?.sale_order_info?.order_time).format('MM-DD HH:mm')
            : '-'
        },
      },
      {
        label: '订单备注',
        prop: 'sale_order_info.admin_remark',
        editType: 'input',
        span: 12,
      },

      {
        label: '后台备注',
        prop: 'admin_remark',
        editType: 'input',
        span: 12,
      },

      {
        label: '创建人',
        prop: 'creator.name',
      },
      {
        label: '创建时间',
        prop: 'create_time',
      },
      {
        label: '最近更新',
        prop: 'update_time',
      },
    ],
  }
  // 编辑
  if (type === 'edit') {
    defaultConfig.desType = ''
    defaultConfig.titleSlotName = 'desTitleSlotName'
    defaultConfig.attrs.column = 4
    defaultConfig.desColumns = [
      {
        label: '发货进度',
        prop: 'quoteStep',
        slot: 'quoteStep',
        span: 10,
      },
      { label: '单号', prop: 'sale_order_info.code' },
      { label: '名称', prop: 'sale_order_info.title' },
      {
        label: '计划时间',
        prop: 'plan_date_range',
        value: [],
        editType: 'datePickerRange',
        startKey: 'delivery_start_time',
        endKey: 'delivery_end_time',
        attrs: {
          class: 'w200',
          type: 'daterange',
          placeholder: '请选择开始日期',
          format: 'MM-DD',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          // clearable: true,
        },
      },
      {
        label: '送货日期',
        prop: 'delivery_date',
        editType: 'datePicker',
        attrs: {
          placeholder: '选择日期',
          format: 'MM-DD',
          // clearable: true,
        },
      },
      { label: '客户', prop: 'customer_info.title' },
      { label: '客户地址', prop: 'customer_info.address' },
      { label: '联系电话', prop: 'customer_info.telephone' },
      {
        label: '客户区域',
        prop: 'sale_order_info.district',
        editType: 'tag',
        tagTypeObj: MHL_HONGKONG_DISTRICT_COLOR,
        attrs: {
          effect: 'dark',
        },
      },
    ]
  }

  return defaultConfig
}
/**
 * 采购订单基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function purchaseDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)
  const { supportCurrency } = handleSupportCurrency()

  let defaultConfig = {
    attrs: {
      border: true,
      column: 3,
      title: '基本信息',
      // direction: 'vertical',
    },
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    api: {
      update: `/${MODULE}/edit`,
    },
    desColumns: [
      {
        label: '订单名称',
        prop: 'title',
      },
      {
        label: '订单编码',
        prop: 'code',
      },
      {
        label: '供应商',
        prop: 'supplier_info.title',
      },
      {
        label: '采购数量',
        prop: 'product_num',
      },
      {
        label: '订单金额',
        prop: 'amount',
        valFun: (row) => {
          return `${CURRENCY_UNIT[row.currency]}${row.amount}`
        },
      },
      {
        label: '订单货币',
        prop: 'currency',
        valFun: (row) => {
          return supportCurrency[row.currency]
        },
      },
      {
        label: '审核状态',
        prop: 'audit_status',
        editType: 'tag',
        tagTypeObj: AUDIT_STATUS_COLOR,
        valFun: (row) => {
          return AUDIT_STATUS[row.audit_status]
        },
      },
      {
        label: '付款状态',
        prop: 'pay_status',
        editType: 'tag',
        tagTypeObj: ORDER_PAY_STATUS_COLOR,
        valFun: (row) => {
          return ORDER_PAY_STATUS[row.pay_status]
        },
      },
      {
        label: '发货状态',
        prop: 'delivery_status',
        editType: 'tag',
        tagTypeObj: ORDER_DELIVERY_STATUS_COLOR,
        valFun: (row) => {
          return ORDER_DELIVERY_STATUS[row.delivery_status]
        },
      },
      {
        label: '发货日期',
        prop: 'delivery_date',
      },
      {
        label: '备注',
        prop: 'admin_remark',
        span: 2,
      },
      {
        label: '创建人',
        prop: 'creator.name',
      },
      {
        label: '创建时间',
        prop: 'create_time',
      },
      {
        label: '最近更新',
        prop: 'update_time',
      },
    ],
  }
  // 编辑
  if (type === 'edit') {
    defaultConfig.desType = ''
    defaultConfig.titleSlotName = 'desTitleSlotName'
    defaultConfig.attrs.column = 6
    defaultConfig.desColumns = [
      {
        label: '采购进度',
        prop: 'quoteStep',
        slot: 'quoteStep',
        span: 10,
      },
      {
        label: '单号',
        prop: 'code',
      },
      {
        label: '名称',
        prop: 'title',
        editType: 'input',
      },
      {
        label: '币种',
        prop: 'currency',
        editType: 'select',
        editTypeFun: (row) => {
          return row.status < 10 ? 'select' : ''
        },
        attrs: {
          options: objToArr(supportCurrency, false, 'id', 'title'),
        },
        valFun: (row) => {
          return CURRENCY[row.currency]
        },
        // slot: 'currency',
      },
      {
        label: '下单日期',
        prop: 'order_time',
        editType: 'datePicker',
        valFun: (row) => {
          return CURRENCY[row.currency]
        },
        // slot: 'currency',
      },
      {
        label: '业务员',
        prop: 'creator.name',
      },
      {
        label: '供应商',
        prop: 'supplier_info.title',
      },
    ]
  }
  return defaultConfig
}

/**
 * 供应商基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function supplierDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)
  let defaultConfig = {
    attrs: {
      border: true,
      column: 4,
      title: '基本信息',
      labelWidth: '100px',
      // direction: 'vertical',
    },
    desType: 'info',
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    desColumns: [
      {
        label: '是否账期',
        prop: 'is_payment_period',
        editType: 'tag',
        valFun: (row) => {
          return YES_NO[row.is_payment_period]
        },
      },
      {
        label: '地区',
        prop: 'area_info.title',
      },
      {
        label: '固定号码',
        prop: 'mob',
      },
      {
        label: '邮箱',
        prop: 'email',
      },
      {
        label: '微信',
        prop: 'wechat',
      },
      {
        label: 'QQ',
        prop: 'qq',
      },
      {
        label: '网址',
        prop: 'website',
        valueClass: 'c0',
      },
      {
        label: '详细地址',
        prop: 'address',
      },
      {
        label: '标签',
        prop: 'label_list',
        editType: 'tagList',
        span: 24,
      },
      {
        label: '创建人',
        prop: 'creator.name',
      },
      {
        label: '创建时间',
        prop: 'create_time',
      },
      {
        label: '最近更新',
        prop: 'update_time',
      },
    ],
  }
  if (type === 'header') {
    // defaultConfig.attrs.border = false
    defaultConfig.attrs.title = ''
    defaultConfig.attrs.labelWidth = ''
    defaultConfig.desColumns = [
      {
        label: '名称',
        prop: 'title',
      },
      {
        label: '编码',
        prop: 'code',
      },
      {
        label: '联系人',
        prop: 'contact',
      },
      {
        label: '手机号',
        prop: 'telephone',
      },
      {
        label: '产品质量',
        prop: 'quality_rating',
        editType: 'baseRate',
        attrs: {
          disabled: true,
        },
      },
      {
        label: '发货速度',
        prop: 'delivery_rating',
        editType: 'baseRate',
        attrs: {
          disabled: true,
        },
      },
      {
        label: '售后服务',
        prop: 'service_rating',
        editType: 'baseRate',
        attrs: {
          disabled: true,
        },
      },
      {
        label: '税率',
        prop: 'tax_rate',
        valFun: (row) => {
          return (row.tax_rate || '-') + '%'
        },
      },
    ]
  }
  return defaultConfig
}

/**
 * 企业基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function enterpriseDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)
  let defaultConfig = {
    attrs: {
      border: true,
      column: 4,
      title: '',
      labelClassName: 'w80',
    },
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    desColumns: [
      {
        label: '电话',
        prop: 'telephone',
        span: 1,
      },
      {
        label: '邮箱',
        prop: 'email',
        span: 1,
      },
      {
        label: '地址',
        prop: 'address',
        span: 2,
      },
      {
        label: '邮编',
        prop: 'postcode',
        span: 1,
      },
      {
        label: '传真',
        prop: 'fax',
        span: 1,
      },
      {
        label: '官网',
        prop: 'website',
        span: 2,
      },
      {
        label: '企业状态',
        prop: 'status',
        span: 1,
      },
      {
        label: '营收',
        prop: 'revenue',
        span: 1,
      },
      {
        label: '员工人数',
        prop: 'employee',
        span: 1,
      },
      {
        label: '公司类型',
        prop: 'entityType',
        span: 1,
      },
      {
        label: '所属国家',
        prop: 'country',
        span: 1,
      },
      {
        label: '所属城市',
        prop: 'city',
        span: 1,
      },
      {
        label: '简介',
        prop: 'overview',
        span: 4,
      },
      {
        label: '产品服务',
        prop: 'productService',
        span: 4,
      },
    ],
  }

  return defaultConfig
}

/**
 * 采购计划基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function deliveryPlanDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)
  let defaultConfig = {
    attrs: {
      border: true,
      column: 4,
      title: type ? '' : '基本信息',
      labelWidth: '',
      // direction: 'vertical',
    },
    desType: 'info',
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    api: {
      update: `/${MODULE}/edit`,
    },
    desColumns: [
      {
        label: '业务员',
        prop: 'creator.name',
      },
      {
        label: '最后编辑',
        prop: 'editor.name',
      },
      {
        label: '客户',
        prop: 'customer_info.title',
      },
      {
        label: '审核状态',
        prop: 'audit_status',
        editType: 'tag',
        tagTypeObj: ORDER_AUDIT_STATUS_COLOR,
        valFun: (row) => {
          return ORDER_AUDIT_STATUS[row.audit_status]
        },
      },
      {
        label: '发货状态',
        prop: 'delivery_status',
        editType: 'tag',
        tagTypeObj: ORDER_DELIVERY_STATUS_COLOR,
        valFun: (row) => {
          return ORDER_DELIVERY_STATUS[row.delivery_status]
        },
      },
      {
        label: '计划日期',
        prop: 'plan_date',
        // editType: 'datePicker',
      },
      {
        label: '关联订单号',
        prop: 'sale_order_info.code',
        // editType: 'datePicker',
      },
      {
        label: '订单支付状态',
        prop: 'sale_order_status',
        editType: 'tag',
        tagTypeObj: ORDER_PAY_STATUS_COLOR,
        valFun: (row) => {
          return ORDER_PAY_STATUS[row?.sale_order_info?.pay_status]
        },
      },
      {
        label: '配送方式',
        prop: 'distribution_mode',
        editType: 'tag',
        valFun: (row) => {
          return DELIVERY_PLAN_DISTRIBUTION_MODE[row.distribution_mode] || '-'
        },
        // editType: 'datePicker',
      },
      { label: '收件人', prop: 'recipients' },
      { label: '收件电话', prop: 'phone' },
      { label: '收件地址', prop: 'address' },
      { label: '包装要求', prop: 'sale_order_info.packing_remark', span: 12 },
      {
        label: '规格说明',
        prop: 'sale_order_info.product_remark',
        // editType: 'input',
        span: 12,
      },
      { label: '快递单号', prop: 'express_num', span: 12 },
      { label: '发货单据', prop: 'label_url', isImgs: true, span: 12 },
      {
        label: '创建人',
        prop: 'creator.name',
      },
      {
        label: '创建时间',
        prop: 'create_time',
      },
      {
        label: '最近更新',
        prop: 'update_time',
      },
    ],
  }
  // 编辑
  if (type === 'edit') {
    defaultConfig.desType = ''
    defaultConfig.titleSlotName = 'desTitleSlotName'
    defaultConfig.attrs.column = 4
    defaultConfig.desColumns = [
      {
        label: '发货进度',
        prop: 'quoteStep',
        slot: 'quoteStep',
        span: 10,
      },
      {
        label: '单号',
        prop: 'code',
      },
      {
        label: '名称',
        prop: 'title',
      },
      {
        label: '业务员',
        prop: 'creator.name',
      },
      {
        label: '客户',
        prop: 'customer_info.title',
      },
      {
        label: '审核状态',
        prop: 'audit_status',
        editType: 'tag',
        tagTypeObj: ORDER_AUDIT_STATUS_COLOR,
        valFun: (row) => {
          return ORDER_AUDIT_STATUS[row.audit_status]
        },
      },
      {
        label: '发货状态',
        prop: 'delivery_status',
        editType: 'tag',
        tagTypeObj: ORDER_DELIVERY_STATUS_COLOR,
        valFun: (row) => {
          return ORDER_DELIVERY_STATUS[row.delivery_status]
        },
      },
      {
        label: '计划日期',
        prop: 'plan_date',
        // editType: 'datePicker',
      },
      {
        label: '关联订单号',
        prop: 'sale_order_info.code',
        // editType: 'datePicker',
      },
      {
        label: '订单支付状态',
        prop: 'sale_order_status',
        editType: 'tag',
        tagTypeObj: ORDER_PAY_STATUS_COLOR,
        valFun: (row) => {
          return ORDER_PAY_STATUS[row?.sale_order_info?.pay_status]
        },
      },

      {
        label: '包装要求',
        prop: 'sale_order_info.packing_remark',
        // editType: 'input',
        span: 2,
      },
      {
        label: '规格说明',
        prop: 'sale_order_info.product_remark',
        // editType: 'input',
        span: 2,
      },
    ]
  }

  return defaultConfig
}

/**
 * 发货单基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function supplierDeliveryDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)
  let defaultConfig = {
    attrs: {
      border: true,
      column: 4,
      title: type ? '' : '基本信息',
      labelWidth: '',
      // direction: 'vertical',
    },
    desType: 'info',
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    api: {
      update: `/${MODULE}/edit`,
    },
    desColumns: [
      {
        label: '业务员',
        prop: 'creator.name',
      },
      {
        label: '最后编辑',
        prop: 'editor.name',
      },
      {
        label: '审核状态',
        prop: 'audit_status',
        editType: 'tag',
        tagTypeObj: ORDER_AUDIT_STATUS_COLOR,
        valFun: (row) => {
          return ORDER_AUDIT_STATUS[row.audit_status]
        },
      },
      {
        label: '发货状态',
        prop: 'delivery_status',
        editType: 'tag',
        tagTypeObj: ORDER_DELIVERY_STATUS_COLOR,
        valFun: (row) => {
          return ORDER_DELIVERY_STATUS[row.delivery_status]
        },
      },
      {
        label: '计划日期',
        prop: 'plan_date',
        // editType: 'datePicker',
      },
      {
        label: '配送方式',
        prop: 'distribution_mode',
        editType: 'tag',
        valFun: (row) => {
          return DELIVERY_PLAN_DISTRIBUTION_MODE[row.distribution_mode] || '-'
        },
        // editType: 'datePicker',
      },
      { label: '收件人', prop: 'recipients' },
      { label: '收件电话', prop: 'phone' },
      { label: '收件地址', prop: 'address', span: 24 },
      {
        label: '创建人',
        prop: 'creator.name',
      },
      {
        label: '创建时间',
        prop: 'create_time',
      },
      {
        label: '最近更新',
        prop: 'update_time',
      },
    ],
  }
  // 编辑
  if (type === 'edit') {
    defaultConfig.titleSlotName = 'desTitleSlotName'
    defaultConfig.attrs.column = 4
    defaultConfig.desColumns = [
      // {
      //   label: '发货进度',
      //   prop: 'quoteStep',
      //   slot: 'quoteStep',
      //   span: 10,
      // },
      {
        label: '单号',
        prop: 'code',
      },
      {
        label: '名称',
        prop: 'title',
      },
      {
        label: '审核状态',
        prop: 'audit_status',
        editType: 'tag',
        tagTypeObj: ORDER_AUDIT_STATUS_COLOR,
        valFun: (row) => {
          return ORDER_AUDIT_STATUS[row.audit_status]
        },
      },
      {
        label: '发货状态',
        prop: 'delivery_status',
        editType: 'tag',
        tagTypeObj: ORDER_DELIVERY_STATUS_COLOR,
        valFun: (row) => {
          return ORDER_DELIVERY_STATUS[row.delivery_status]
        },
      },
    ]
  }

  return defaultConfig
}
/**
 * 产品出库基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function stockOutDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)
  const supportExpress = store.getters['sys/supportExpress']
  const supportExpressKb = store.getters['sys/supportExpressKb']
  const isSelectExpress = type === 'selectExpress'
  const recAndSendInfo = [
    {
      label: '',
      prop: 'rec_name',
      labelWidth: '0px',
      editType: 'divider',
      valFun: () => {
        return t('收货人信息')
      },
      span: 24,
      attrs: {
        'content-position': 'center',
      },
    },
    {
      label: '姓名',
      prop: 'rec_name',
      editTypeFun: () => {
        return isSelectExpress ? 'input' : ''
      },
    },
    {
      label: '电话',
      prop: 'rec_mobile',
      editTypeFun: () => {
        return isSelectExpress ? 'input' : ''
      },
    },
    {
      label: '邮箱',
      prop: 'rec_email',
      editTypeFun: () => {
        return isSelectExpress ? 'input' : ''
      },
    },
    {
      label: '地址1',
      prop: 'rec_address',
      editTypeFun: () => {
        return isSelectExpress ? 'input' : ''
      },
    },
    {
      label: '地址2',
      prop: 'rec_address2',
      editTypeFun: () => {
        return isSelectExpress ? 'input' : ''
      },
    },
    {
      label: '国家',
      prop: 'rec_country',
      editTypeFun: () => {
        return isSelectExpress ? 'selectServer' : ''
      },
      attrs: {
        listApi: `/config/shipsaving/country`,
        class: 'w',
        placeholder: '请选择国家',
        noPaging: true,
      },
    },
    {
      label: '所在州',
      prop: 'rec_state',
      editTypeFun: () => {
        return isSelectExpress ? 'selectServer' : ''
      },
      attrs: {
        listApi: `/config/shipsaving/country?id=US`,
        class: 'w',
        placeholder: '请选择州',
        noPaging: true,
      },
    },
    {
      label: '城市',
      prop: 'rec_city',
      editTypeFun: () => {
        return isSelectExpress ? 'input' : ''
      },
    },
    {
      label: '邮编',
      prop: 'rec_zip',
      editTypeFun: () => {
        return isSelectExpress ? 'input' : ''
      },
    },
    {
      label: '运输保险',
      prop: 'insurance_provider',
      editTypeFun: () => {
        return isSelectExpress ? 'select' : ''
      },
      attrs: {
        placeholder: '请选择运输保险',
        class: 'w',
        options: objToArr(EXPRESS_INSURANCE_PROVIDER, false, 'id', 'title'),
      },
      valFun: (row) => {
        return EXPRESS_INSURANCE_PROVIDER[row.insurance_provider]
      },
    },
    {
      label: '货品价值',
      prop: 'insurance_amount',
      editTypeFun: () => {
        return isSelectExpress ? 'inputNumber' : ''
      },
    },
    {
      label: '签收选项',
      prop: 'signature',
      editTypeFun: () => {
        return isSelectExpress ? 'select' : ''
      },
      attrs: {
        placeholder: '请选择签收选项',
        class: 'w',
        options: objToArr(EXPRESS_SIGNATURE, false, 'id', 'title'),
      },
      valFun: (row) => {
        return EXPRESS_SIGNATURE[row.signature]
      },
    },
    {
      label: '',
      prop: '',
      labelWidth: '0px',
      editType: 'divider',
      valFun: () => {
        return t('寄件人信息')
      },
      span: 24,
      attrs: {
        'content-position': 'center',
      },
    },
    { label: '姓名', prop: 'send_name' },
    { label: '公司', prop: 'send_company' },
    { label: '电话', prop: 'send_mobile' },
    { label: '邮箱', prop: 'send_email' },
    { label: '地址1', prop: 'send_address' },
    { label: '地址2', prop: 'send_address2' },
    { label: '国家', prop: 'send_country' },
    { label: '所在州', prop: 'send_state' },
    { label: '城市', prop: 'send_city' },
    { label: '邮编', prop: 'send_zip' },
  ]
  let baseInfo = [
    {
      label: '',
      prop: '',
      labelWidth: '0px',
      editType: 'divider',
      valFun: () => {
        return t('基本信息')
      },
      span: 24,
      attrs: {
        'content-position': 'center',
      },
    },
    {
      label: '审核状态',
      prop: 'audit_status',
      editType: 'tag',
      tagTypeObj: ORDER_AUDIT_STATUS_COLOR,
      valFun: (row) => {
        return ORDER_AUDIT_STATUS[row.audit_status]
      },
    },
    {
      label: '发货状态',
      prop: 'delivery_status',
      editType: 'tag',
      tagTypeObj: ORDER_DELIVERY_STATUS_COLOR,
      valFun: (row) => {
        return ORDER_DELIVERY_STATUS[row.delivery_status]
      },
    },
    { label: '计划日期', prop: 'plan_date' },
    { label: '商品名称', prop: 'cargo' },
    {
      label: '商品体积',
      prop: 'w',
      valFun: (row) => {
        if (row.l && row.w && row.h) {
          return `${row.l || '-'} * ${row.w || '-'} * ${row.h || '-'}`
        }
        return '-'
      },
    },
    {
      label: '商品重量',
      prop: 'deliveryCosts',
      valFun: (row) => {
        return `${row.weight || '-'}${
          row?.delivery_plan_info?.type === 2 ? 'lb' : 'kg'
        }`
      },
    },
    { label: '商品货值', prop: 'insurance_amount' },
    { label: '快递费用', prop: 'price', class: 'cd b' },
    { label: '包装要求', prop: 'sale_order_info.packing_remark', span: 12 },
    { label: '业务员', prop: 'creator.name' },
    { label: '最后编辑', prop: 'editor.name' },
    { label: '创建人', prop: 'creator.name' },
    { label: '创建时间', prop: 'create_time' },
    { label: '最近更新', prop: 'update_time' },
  ]
  let defaultConfig = {
    attrs: {
      border: true,
      column: 4,
      title: type ? '' : '',
      labelWidth: '',
      // direction: 'vertical',
    },
    desType: 'info',
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    api: {
      update: `/${MODULE}/edit`,
    },
    desColumns: [...baseInfo, ...recAndSendInfo],
  }
  // 编辑
  if (type === 'edit') {
    defaultConfig.desType = ''
    defaultConfig.titleSlotName = 'desTitleSlotName'
    defaultConfig.attrs.column = 4
    defaultConfig.desColumns = [
      {
        label: '发货进度',
        prop: 'quoteStep',
        slot: 'quoteStep',
        span: 10,
      },
      { label: '单号', prop: 'code' },
      { label: '名称', prop: 'title' },
      { label: '客户', prop: 'rec_name' },
      { label: '业务员', prop: 'creator.name' },
      {
        label: '审核状态',
        prop: 'audit_status',
        editType: 'tag',
        tagTypeObj: ORDER_AUDIT_STATUS_COLOR,
        valFun: (row) => {
          return ORDER_AUDIT_STATUS[row.audit_status]
        },
      },
      {
        label: '发货状态',
        prop: 'delivery_status',
        editType: 'tag',
        tagTypeObj: ORDER_DELIVERY_STATUS_COLOR,
        valFun: (row) => {
          return ORDER_DELIVERY_STATUS[row.delivery_status]
        },
      },
      {
        label: '计划日期',
        prop: 'plan_date',
        // editType: 'datePicker',
      },
      {
        label: '包装要求',
        prop: 'sale_order_info.packing_remark',
        // editType: 'input',
        span: 2,
      },
    ]
  } else if (isSelectExpress) {
    const send_nameIndex = getFormListIndex('send_name', recAndSendInfo, 'prop')
    recAndSendInfo.splice(send_nameIndex, 10, {
      editType: 'selectServer',
      prop: 'send_address_id',
      label: '寄件人',
      attrs: {
        listApi: `/address/index`,
        class: 'w',
        searchKey: 'search',
        multiple: false,
        defaultSelect: true,
        emitSelect: true,
        labelKey: 'recipients',
        exLabelKeyFun: (item) => {
          return `${item.recipients} ${item.zip}${item.street}-${item.city}-${item.state}-${item.country}`
        },
        exParams: {
          sort: 'is_default',
          order: 'desc',
        },
        placeholder: '请选择寄件人信息',
      },
      // onChange: (val, model, row) => {
      //   console.log('val', val)
      //   model.send_name = val.recipients
      //   model.send_company = val.company
      //   model.send_mobile = val.phone
      //   model.send_email = val.email
      //   model.send_address = val.street
      //   model.send_address2 = val.street2 || ''
      //   model.send_country = val.country || ''
      //   model.send_state = val.state || ''
      //   model.send_city = val.city || ''
      //   model.send_zip = val.zip || ''
      // },
      span: 24,
    })
    // 选择快递
    defaultConfig.desColumns = [
      ...recAndSendInfo,
      {
        label: '',
        prop: '',
        labelWidth: '0px',
        editType: 'divider',
        valFun: () => {
          return t('包裹信息')
        },
        span: 24,
        attrs: {
          'content-position': 'center',
        },
      },
      {
        label: '长(in)',
        prop: 'l',
        editType: 'inputNumber',
        labelWidth: '50px',
        span: 4,
        attrs: {
          step: 0.1,
          precision: 2,
        },
      },
      {
        label: '宽(in)',
        prop: 'w',
        editType: 'inputNumber',
        labelWidth: '50px',
        span: 4,
        attrs: {
          step: 0.1,
          precision: 2,
        },
      },
      {
        label: '高(in)',
        prop: 'h',
        editType: 'inputNumber',
        labelWidth: '50px',
        span: 4,
        attrs: {
          step: 0.1,
          precision: 2,
        },
      },
      {
        label: '重量(lb)',
        prop: 'weight',
        editType: 'inputNumber',
        labelWidth: '50px',
        span: 4,
        attrs: {
          step: 0.1,
          precision: 2,
        },
      },
      // { label: '寄件日期', prop: 'plan_date', editType: 'datePicker', labelWidth: '60px', span: 4 },
      {
        label: '计算费用',
        prop: 'checkShipping',
        span: 4,
        attrs: {
          step: 1,
          precision: 0,
        },
      },
    ]
  } else if (type === 'expressDatail') {
    // 快递详情
    baseInfo = [
      {
        label: '',
        prop: '',
        labelWidth: '0px',
        editType: 'divider',
        valFun: () => {
          return t('基本信息')
        },
        span: 24,
        attrs: {
          'content-position': 'center',
        },
      },

      { label: '关联订单', prop: 'delivery_plan_info.sale_order_info.code' },
      { label: '发货计划', prop: 'delivery_plan_info.code' },
      { label: '商品名称', prop: 'cargo', span: 12 },
      {
        label: '商品体积',
        prop: 'w',
        valFun: (row) => {
          if (row.l && row.w && row.h) {
            return `${row.l || '-'} * ${row.w || '-'} * ${row.h || '-'}`
          }
          return '-'
        },
      },
      {
        label: '商品重量',
        prop: 'deliveryCosts',
        valFun: (row) => {
          return `${row.weight || '-'}${
            row?.delivery_plan_info?.type === 2 ? 'lb' : 'kg'
          }`
        },
      },
      { label: '商品货值', prop: 'insurance_amount' },
      {
        label: '快递公司',
        prop: 'express_com',
        valFun: (row) => {
          if (row?.delivery_plan_info?.type === 1) {
            if (row.service === 'kuaidi100') {
              return supportExpress[row.express_com] || '-'
            } else if (row.service === 'kuaibao') {
              return supportExpressKb[row.express_com] || '-'
            }
          }
          return row?.express_com || '-'
        },
      },
      { label: '快递单号', prop: 'task_id' },
      {
        label: '快递面签',
        prop: 'label_url',
        valueClass: 'w100 dl ex0',
        isImg: true,
        width: 100,
      },
      { label: '快递费用', prop: 'price', class: 'cd b' },
      { label: '包装要求', prop: 'sale_order_info.packing_remark', span: 12 },
      {
        label: '预约日期',
        prop: 'dayType',
        width: 120,
        valFun: (row) => {
          return row.delivery_plan_info?.type === 2
            ? row.plan_date || '-'
            : EXPRESS_DATA[row.dayType] || '-'
        },
      },
      {
        label: '预约时间',
        prop: 'pickup_start_time',
        width: 200,
        valFun: (row) => {
          return (
            (row.pickup_start_time || '-') + '-' + (row.pickup_end_time || '-')
          )
        },
      },
      { label: '创建人', prop: 'creator.name' },
      { label: '创建时间', prop: 'create_time' },
      { label: '最近更新', prop: 'update_time' },
    ]
    defaultConfig.desColumns = [...baseInfo, ...recAndSendInfo]
  }
  return defaultConfig
}

/**
 * 产品退货基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function salesReturnDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)
  const recAndSendInfo = [
    {
      label: '',
      prop: 'rec_name',
      labelWidth: '0px',
      editType: 'divider',
      valFun: () => {
        return t('收货人信息')
      },
      span: 24,
      attrs: {
        'content-position': 'center',
      },
    },
    { label: '姓名', prop: 'express_task_info.rec_name' },
    { label: '电话', prop: 'express_task_info.rec_mobile' },
    { label: '邮箱', prop: 'express_task_info.rec_email' },
    { label: '地址1', prop: 'express_task_info.rec_address' },
    { label: '地址2', prop: 'express_task_info.rec_address2' },
    { label: '国家', prop: 'express_task_info.rec_country' },
    { label: '所在州', prop: 'express_task_info.rec_state' },
    { label: '城市', prop: 'express_task_info.rec_city' },
    { label: '邮编', prop: 'express_task_info.rec_zip' },
    {
      label: '运输保险',
      prop: 'express_task_info.insurance_provider',
      valFun: (row) => {
        return EXPRESS_INSURANCE_PROVIDER[
          row?.express_task_info?.insurance_provider
        ]
      },
    },
    {
      label: '货品价值',
      prop: 'express_task_info.insurance_amount',
    },
    {
      label: '签收选项',
      prop: 'signature',
      valFun: (row) => {
        return EXPRESS_SIGNATURE[row.express_task_info?.signature]
      },
    },
    {
      label: '',
      prop: '',
      labelWidth: '0px',
      editType: 'divider',
      valFun: () => {
        return t('寄件人信息')
      },
      span: 24,
      attrs: {
        'content-position': 'center',
      },
    },
    { label: '姓名', prop: 'express_task_info.send_name' },
    { label: '公司', prop: 'express_task_info.send_company' },
    { label: '电话', prop: 'express_task_info.send_mobile' },
    { label: '邮箱', prop: 'express_task_info.send_email' },
    { label: '地址1', prop: 'express_task_info.send_address' },
    { label: '地址2', prop: 'express_task_info.send_address2' },
    { label: '国家', prop: 'express_task_info.send_country' },
    { label: '所在州', prop: 'express_task_info.send_state' },
    { label: '城市', prop: 'express_task_info.send_city' },
    { label: '邮编', prop: 'express_task_info.send_zip' },
  ]

  let defaultConfig = {
    attrs: {
      border: true,
      column: 4,
      title: type ? '' : '',
      labelWidth: '',
      // direction: 'vertical',
    },
    desType: 'info',
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    api: {
      update: `/${MODULE}/edit`,
    },
    desColumns: [
      {
        label: '',
        prop: '',
        labelWidth: '0px',
        editType: 'divider',
        valFun: () => {
          return t('退货照片')
        },
        span: 24,
        attrs: {
          'content-position': 'center',
        },
      },
      { label: '外观照片', prop: 'images', isImgs: true, span: 12 },
      { label: '拆开照片', prop: 'inner_images', isImgs: true, span: 12 },
      {
        label: '',
        prop: '',
        labelWidth: '0px',
        editType: 'divider',
        valFun: () => {
          return t('基本信息')
        },
        span: 24,
        attrs: {
          'content-position': 'center',
        },
      },
      { label: '单号', prop: 'code' },
      { label: '名称', prop: 'title' },
      {
        label: '审核状态',
        prop: 'audit_status',
        editType: 'tag',
        tagTypeObj: ORDER_AUDIT_STATUS_COLOR,
        valFun: (row) => {
          return ORDER_AUDIT_STATUS[row.audit_status]
        },
      },
      {
        label: '处理方式',
        prop: 'handle',
        editType: 'tag',
        tagTypeObj: ORDER_DELIVERY_STATUS_COLOR,
        valFun: (row) => {
          return SALE_RETURN_HANDLE[row.handle] || '-'
        },
      },
      { label: '快递单号', prop: 'tracking_num', span: 12 },
      { label: '备注', prop: 'admin_remark', span: 12 },
      { label: '最后编辑', prop: 'editor.name' },
      { label: '创建人', prop: 'creator.name' },
      { label: '创建时间', prop: 'create_time' },
      { label: '最近更新', prop: 'update_time' },
      ...recAndSendInfo,
    ],
  }
  // 编辑
  if (type === 'edit') {
    defaultConfig.desType = ''
    defaultConfig.titleSlotName = 'desTitleSlotName'
    defaultConfig.attrs.column = 5
    defaultConfig.desColumns = [
      {
        label: '退货进度',
        prop: 'quoteStep',
        slot: 'quoteStep',
        span: 10,
      },
      { label: '单号', prop: 'code' },
      { label: '名称', prop: 'title' },
      { label: '快递单号', prop: 'tracking_num' },
      // { label: '业务员', prop: 'creator.name', },
      {
        label: '审核状态',
        prop: 'audit_status',
        editType: 'tag',
        tagTypeObj: ORDER_AUDIT_STATUS_COLOR,
        valFun: (row) => {
          return ORDER_AUDIT_STATUS[row.audit_status]
        },
      },
      {
        label: '处理方式',
        prop: 'handle',
        editType: 'tag',
        tagTypeObj: ORDER_DELIVERY_STATUS_COLOR,
        valFun: (row) => {
          return SALE_RETURN_HANDLE[row.handle] || '-'
        },
      },
    ]
  }
  if (type === 'salesClaim') {
    defaultConfig.desColumns = [
      {
        label: '',
        prop: '',
        labelWidth: '0px',
        editType: 'divider',
        valFun: () => {
          return t('基本信息')
        },
        span: 24,
        attrs: {
          'content-position': 'center',
        },
      },
      { label: '单号', prop: 'code' },
      // { label: '名称', prop: 'title', },
      {
        label: '审核状态',
        prop: 'audit_status',
        editType: 'tag',
        tagTypeObj: ORDER_AUDIT_STATUS_COLOR,
        valFun: (row) => {
          return ORDER_AUDIT_STATUS[row.audit_status]
        },
      },
      {
        label: '索赔结果',
        prop: 'status',
        editType: 'tag',
        tagTypeObj: ORDER_DELIVERY_STATUS_COLOR,
        valFun: (row) => {
          return SUPPLIER_CLAIM_STATUS[row.status] || '-'
        },
      },
      { label: '快递单号', prop: 'tracking_num' },
      {
        label: '商品货值',
        prop: 'worth',
        valFun: (row) => {
          return row.worth ? `$${row.worth}` : '-'
        },
      },
      { label: '原因', prop: 'reason', span: 18 },
      { label: '订单截图', prop: 'order_screenshot', isImgs: true },
      { label: '证明图片', prop: 'proof', isImgs: true, span: 18 },
      { label: '最后编辑', prop: 'editor.name' },
      { label: '创建人', prop: 'creator.name' },
      { label: '创建时间', prop: 'create_time' },
      { label: '最近更新', prop: 'update_time' },
    ]
  }
  return defaultConfig
}

/**
 * 仓储体积基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function storageVolumeDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)
  let defaultConfig = {
    attrs: {
      border: true,
      column: 6,
      title: '基本信息',
      labelWidth: '75px',
      // direction: 'vertical',
    },
    desType: 'info',
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    desColumns: [
      { label: '日期', prop: 'date' },
      {
        label: '重量',
        prop: 'weight',
        valFun: (row) => {
          return row.weight + 'lb'
        },
      },
      {
        label: '体积',
        prop: 'volume',
        valFun: (row) => {
          return row.volume + 'm³'
        },
      },
      {
        class: 'cd b',
        label: '费用',
        prop: 'amount',
        valFun: (row) => {
          return '$' + row.amount
        },
      },
      { label: '供应商', prop: 'supplier_info.title' },
    ],
  }
  defaultConfig.api = {
    update: `/${MODULE}/edit`,
  }
  return defaultConfig
}

/**
 * 回款计划基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function billPlanDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)
  let defaultConfig = {
    attrs: {
      border: true,
      column: 4,
      direction: 'horizontal',
    },
    desType: 'info',
    desColumns: [
      {
        label: '单号',
        prop: 'code',
      },
      {
        label: '客户',
        prop: 'customer_info.title',
      },
      {
        label: '销售订单',
        prop: 'sale_order_info.code',
      },
      {
        label: '订单金额',
        prop: 'sale_order_info.amount',
      },
      { label: '计划金额', prop: 'amount', class: 'b cd' },
      {
        label: '计划时间',
        prop: 'plan_date',
      },
      {
        label: '回款方式',
        prop: 'pay_type',
        valFun: (row) => {
          return PAY_TYPE[row.pay_type]
        },
      },
      { label: '回款渠道', prop: 'bill_channel_info.title' },
      {
        label: '回款状态',
        prop: 'status',
        editType: 'tag',
        tagTypeObj: ORDER_DELIVERY_STATUS_COLOR,
        valFun: (row) => {
          return BILL_PLAN_STATUS[row.status] || '-'
        },
      },
      { label: '业务员', prop: 'creator.name' },
      { label: '最后编辑', prop: 'editor.name' },
      {
        label: '备注',
        prop: 'remark',
        // editType: 'input',
        valFun: (row) => {
          return row.remark || row.admin_remark || '-'
        },
      },
    ],
  }

  return defaultConfig
}

/**
 * BOM基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function bomDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)

  let defaultConfig = {
    attrs: {
      border: true,
      column: 4,
      title: '基本信息',
      labelWidth: '75px',
      // direction: 'vertical',
    },
    desType: 'info',
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    desColumns: [
      { label: '名称', prop: 'title' },
      { label: '编码', prop: 'code' },
      { label: '适用产品', prop: 'product_info.title', span: 12 },
      {
        label: '版本号',
        editType: 'tag',
        prop: 'version',
        valFun: (row) => {
          return `V${row.version}`
        },
      },
      {
        label: '状态',
        editType: 'tag',
        prop: 'status',
        tagTypeObj: NORMAL_STATUS_COLOR,
        valFun: (row) => {
          return NORMAL_STATUS[row.status]
        },
      },
      { label: '备注', prop: 'admin_remark', span: 12 },
      { label: '创建人', prop: 'creator.name' },
      { label: '创建时间', prop: 'create_time' },
      { label: '最近更新', prop: 'update_time' },
    ],
  }
  if (type === 'header') {
    defaultConfig.attrs.title = ''
    defaultConfig.attrs.labelWidth = ''
    defaultConfig.desColumns = [
      { label: '名称', prop: 'title', span: 4 },
      { label: '编码', prop: 'code', span: 4 },
      { label: '适用产品', prop: 'product_info.title', span: 10 },
      {
        label: '版本号',
        editType: 'tag',
        prop: 'version',
        valFun: (row) => {
          return `V${row.version}`
        },
        span: 3,
      },
      {
        label: '状态',
        editType: 'tag',
        prop: 'status',
        tagTypeObj: NORMAL_STATUS_COLOR,
        valFun: (row) => {
          return NORMAL_STATUS[row.status]
        },
        span: 3,
      },
    ]
  }
  defaultConfig.api = {
    update: `/${MODULE}/edit`,
  }
  return defaultConfig
}
/**
 * 工序基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function processDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)

  let defaultConfig = {
    attrs: {
      border: true,
      column: 4,
      title: '基本信息',
      labelWidth: '75px',
      // direction: 'vertical',
    },
    desType: 'info',
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    desColumns: [
      { label: '名称', prop: 'title' },
      { label: '编码', prop: 'code' },
      {
        label: '类型',
        prop: 'type',
        editType: 'tag',
        tagTypeObj: NORMAL_STATUS_COLOR,
        valFun: (row) => {
          return PROCESS_TYPE[row.type]
        },
      },
      { label: '负责人', prop: 'mainer.name' },
      { label: '单位', prop: 'unit' },
      { label: '单价', prop: 'price', preUnit: '￥', valueClass: 'fxnwp' },
      {
        label: '加工时长',
        prop: 'spend',
        valFun: (row) => {
          return row.spend + 's'
        },
      },
      {
        label: '状态',
        editType: 'tag',
        prop: 'status',
        tagTypeObj: NORMAL_STATUS_COLOR,
        valFun: (row) => {
          return NORMAL_STATUS[row.status]
        },
      },
      { label: '描述', prop: 'description', span: 12 },
      { label: '备注', prop: 'admin_remark', span: 12 },
      { label: '创建人', prop: 'creator.name' },
      { label: '创建时间', prop: 'create_time' },
      { label: '最近更新', prop: 'update_time' },
    ],
  }
  if (type === 'header') {
    defaultConfig.attrs.title = ''
    defaultConfig.attrs.labelWidth = ''
    defaultConfig.desColumns = defaultConfig.desColumns.slice(0, 4)
  }
  defaultConfig.api = {
    update: `/${MODULE}/edit`,
  }
  return defaultConfig
}
/**
 * 工艺基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function technologyDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)

  let defaultConfig = {
    attrs: {
      border: true,
      column: 4,
      title: '基本信息',
      labelWidth: '75px',
      // direction: 'vertical',
    },
    desType: 'info',
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    desColumns: [
      { label: '名称', prop: 'title' },
      { label: '适用产品', prop: 'product_info', span: 12 },
      {
        label: '状态',
        editType: 'tag',
        prop: 'status',
        tagTypeObj: NORMAL_STATUS_COLOR,
        valFun: (row) => {
          return NORMAL_STATUS[row.status]
        },
      },
      { label: '编码', prop: 'code' },
      { label: '备注', prop: 'admin_remark', span: 12 },
      { label: '创建人', prop: 'creator.name' },
      { label: '创建时间', prop: 'create_time' },
      { label: '最近更新', prop: 'update_time' },
    ],
  }
  if (type === 'header') {
    defaultConfig.attrs.title = ''
    defaultConfig.attrs.labelWidth = ''
    defaultConfig.desColumns = defaultConfig.desColumns.slice(0, 3)
  }
  defaultConfig.api = {
    update: `/${MODULE}/edit`,
  }
  return defaultConfig
}
/**
 * 生产计划基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function productionPlanDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)

  let defaultConfig = {
    attrs: {
      border: true,
      column: 4,
      title: '基本信息',
      labelWidth: '75px',
      // direction: 'vertical',
    },
    desType: 'info',
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    desColumns: [
      { label: '计划单号', prop: 'code' },
      { label: '名称', prop: 'title' },
      {
        label: '状态',
        editType: 'tag',
        prop: 'status',
        tagTypeObj: PRODUCTION_PLAN_STATUS_COLOR,
        valFun: (row) => {
          return PRODUCTION_PLAN_STATUS[row.status]
        },
      },
      {
        label: '生产类型',
        editType: 'tag',
        prop: 'type',
        tagTypeObj: PRODUCTION_PLAN_TYPE_COLOR,
        valFun: (row) => {
          return PRODUCTION_PLAN_TYPE[row.type]
        },
      },
      {
        label: '优先级',
        editType: 'tag',
        prop: 'priority',
        tagTypeObj: NORMAL_STATUS_COLOR,
        valFun: (row) => {
          return PRODUCTION_PLAN_PRIORITY[row.priority]
        },
      },
      { label: '计划生产数量', prop: 'plan_num', valueClass: 'b c0 fs16' },
      {
        label: '交货日期',
        prop: 'delivery_time',
        dateTimeFormat: DATE_FORMAT3,
      },
      {
        label: '计划开工日期',
        prop: 'plan_start_time',
        dateTimeFormat: DATE_FORMAT3,
      },
      {
        label: '计划完工日期',
        prop: 'plan_finish_time',
        dateTimeFormat: DATE_FORMAT3,
      },
      { label: '生产产品', prop: 'product_info.title' },
      { label: 'BOM', prop: 'bom_info.title' },
      { label: '关联订单', prop: 'sale_order_info.title' },
      { label: '生产批次号', prop: 'batch_no' },
      { label: '备注', prop: 'admin_remark', span: 12 },
      {
        label: '审核状态',
        prop: 'audit_status',
        editType: 'tag',
        tagTypeObj: AUDIT_STATUS_COLOR,
        valFun: (row) => {
          return AUDIT_STATUS[row.audit_status]
        },
      },
      { label: '审核时间', prop: 'audit_time' },
      { label: '审核人', prop: 'auditor.name' },
      { label: '创建人', prop: 'creator.name' },
      { label: '创建时间', prop: 'create_time' },
      { label: '最近更新', prop: 'update_time' },
    ],
  }
  if (type === 'header') {
    defaultConfig.attrs.title = ''
    defaultConfig.attrs.labelWidth = ''
    defaultConfig.desColumns = defaultConfig.desColumns.slice(1, 9)
  }
  defaultConfig.api = {
    update: `/${MODULE}/edit`,
  }
  return defaultConfig
}

/**
 * 生产工单基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function productionOrderDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)

  let defaultConfig = {
    attrs: {
      border: true,
      column: 4,
      title: '基本信息',
      labelWidth: '75px',
      // direction: 'vertical',
    },
    desType: 'info',
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    desColumns: [
      { label: '生产单号', prop: 'code' },
      {
        label: '生产状态',
        editType: 'tag',
        prop: 'product_info.title',
        tagTypeObj: PRODUCTION_PLAN_STATUS_COLOR,
        valFun: (row) => {
          return PRODUCTION_RECEIPT_STATUS[row.status]
        },
      },
      {
        label: '生产产品',
        editType: 'tag',
        prop: 'product_info.title',
        valFun: (row) => {
          return `${row?.product_info?.title} | ${
            PRODUCT_NATURE[row?.product_info?.nature]
          }`
        },
      },
      {
        label: '延期情况',
        editType: 'tag',
        prop: 'postpone_status',
        tagTypeObj: PRODUCTION_PLAN_TYPE_COLOR,
        valFun: (row) => {
          return PRODUCTION_RECEIPT_POSTPONE_STATUS[row.postpone_status]
        },
      },
      { label: '计划生产数量', prop: 'plan_num', valueClass: 'b c0 fs16' },
      {
        label: '计划开工日期',
        prop: 'plan_start_time',
        dateTimeFormat: DATE_FORMAT3,
      },
      {
        label: '计划完工日期',
        prop: 'plan_finish_time',
        dateTimeFormat: DATE_FORMAT3,
      },
      { label: '生产计划', prop: 'production_plan_info.title' },
      {
        label: '委外状态',
        prop: 'outsource_status',
        editType: 'tag',
        tagTypeObj: NORMAL_STATUS_COLOR,
        valFun: (row) => {
          return PRODUCTION_RECEIPT_OUTSOURCE_STATUS[row.outsource_status]
        },
      },
      {
        label: '入库日期',
        prop: 'stock_in_time',
        dateTimeFormat: DATE_FORMAT3,
      },
      { label: '排产人', prop: 'mainer.name' },
      {
        label: '生产完成率',
        prop: 'finish_num',
        valFun: (row) => {
          return `${(row.finish_num / (row.plan_num || 1)) * 100}%`
        },
      },
      { label: '已完工数量', prop: 'finish_num' },
      { label: '待入库数量', prop: 'wait_stock_num' },
      { label: '已入库数量', prop: 'stock_in_num' },
      { label: '工序计划数量', prop: 'process_plan_num' },
      { label: '工序完工数量', prop: 'process_finish_num' },
      { label: '报废数', prop: 'scrap_num' },
      { label: '返工数', prop: 'rework_num' },
      { label: '总生产成本', prop: 'cost' },
      { label: '加工成本', prop: 'process_cost' },
      { label: '物料成本', prop: 'material_cost' },
      { label: '工艺', prop: 'technology_info.title' },
      { label: '备注', prop: 'admin_remark', span: 12 },
      { label: '创建人', prop: 'creator.name' },
      { label: '创建时间', prop: 'create_time' },
      { label: '最近更新', prop: 'update_time' },
    ],
  }
  if (type === 'header') {
    defaultConfig.attrs.title = ''
    defaultConfig.attrs.labelWidth = ''
    defaultConfig.desColumns = defaultConfig.desColumns.slice(1, 9)
  }
  defaultConfig.api = {
    update: `/${MODULE}/edit`,
  }
  return defaultConfig
}

/**
 * 委外单基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function entrustOrderDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)

  let defaultConfig = {
    attrs: {
      border: true,
      column: 4,
      title: '基本信息',
      labelWidth: '75px',
      // direction: 'vertical',
    },
    desType: 'info',
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    desColumns: [
      { label: '委外编号', prop: 'code' },
      {
        label: '委外状态',
        editType: 'tag',
        prop: 'product_info.title',
        tagTypeObj: PRODUCTION_PLAN_STATUS_COLOR,
        valFun: (row) => {
          return ENTRUST_STATUS[row.status]
        },
      },
      { label: '委外供应商', prop: 'supplier_info.title' },
      {
        label: '委外类型',
        editType: 'tag',
        prop: 'product_info.title',
        tagTypeObj: NORMAL_STATUS_COLOR,
        valFun: (row) => {
          return ENTRUST_TYPE[row.type]
        },
      },
      { label: '生产计划', prop: 'production_plan_info.title' },
      { label: '生产工单', prop: 'production_receipt_info.code' },

      {
        label: '委外产品',
        editType: 'tag',
        prop: 'product_info.title',
        valFun: (row) => {
          return `${row?.product_info?.title} | ${
            PRODUCT_NATURE[row?.product_info?.nature]
          }`
        },
      },
      { label: '委外数量', prop: 'num', valueClass: 'b c0 fs16' },
      { label: '委外单价', prop: 'price' },
      { label: '负责人', prop: 'mainer.name' },
      {
        label: '委外日期',
        prop: 'plan_start_time',
        dateTimeFormat: DATE_FORMAT3,
      },
      {
        label: '计划完工日期',
        prop: 'plan_finish_time',
        dateTimeFormat: DATE_FORMAT3,
      },
      {
        label: '入库日期',
        prop: 'stock_in_time',
        dateTimeFormat: DATE_FORMAT3,
      },
      { label: '委外总额', prop: 'amount', class: 'cr b' },
      {
        label: '委外应付总额',
        prop: 'amount',
        class: 'cy b',
        valFun: (row) => {
          return +row.amount - +row.real_amount
        },
      },
      { label: '委外已付总额', prop: 'real_amount', class: 'cs b' },
      { label: '待入库数量', prop: 'wait_stock_num' },
      { label: '已入库数量', prop: 'stock_in_num' },
      { label: '已退货数量', prop: 'rework_num' },
      {
        label: '质检不合格数量',
        prop: 'good_num',
        valFun: (row) => {
          if (row.good_num) {
            return row.num - row.good_num
          }
          return '-'
        },
      },
      {
        label: '质检合格率',
        prop: 'good_num',
        valFun: (row) => {
          if (row.good_num) {
            return `${percentNum('good_num', 'num', row)}%`
          }
          return '-'
        },
      },
      { label: '创建人', prop: 'creator.name' },
      { label: '创建时间', prop: 'create_time' },
      { label: '最近更新', prop: 'update_time' },
    ],
  }
  if (type === 'header') {
    defaultConfig.attrs.title = ''
    defaultConfig.attrs.labelWidth = ''
    defaultConfig.desColumns = defaultConfig.desColumns.slice(1, 9)
  }
  defaultConfig.api = {
    update: `/${MODULE}/edit`,
  }
  return defaultConfig
}

/**
 * 生产工序基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function productionProcessDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)

  let defaultConfig = {
    attrs: {
      border: true,
      column: 4,
      title: '基本信息',
      labelWidth: '100px',
      // direction: 'vertical',
    },
    desType: 'info',
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    desColumns: [
      { label: '工序编号', prop: 'code' },
      {
        label: '工序状态',
        editType: 'tag',
        prop: 'status',
        tagTypeObj: NORMAL_STATUS_STEP_COLOR,
        valFun: (row) => {
          return PRODUCTION_RECEIPT_PROCESS_STATUS[row.status]
        },
      },
      {
        label: '生产产品',
        editType: 'tag',
        prop: 'product_info.title',
        valFun: (row) => {
          return `${row?.product_info?.title} | ${
            PRODUCT_NATURE[row?.product_info?.nature]
          }`
        },
      },
      { label: '执行工序', prop: 'process_info.title' },
      { label: '工艺路线', prop: 'technology_info.title' },
      { label: '工序执行人', prop: 'mainer.name' },
      { label: '工序计划生产数', prop: 'plan_num', class: 'b c0 fs16' },
      {
        label: '计划开工日期',
        prop: 'plan_start_time',
        dateTimeFormat: DATE_FORMAT3,
      },
      {
        label: '计划完工日期',
        prop: 'plan_finish_time',
        dateTimeFormat: DATE_FORMAT3,
      },
      {
        label: '实际完工日期',
        prop: 'finish_time',
        dateTimeFormat: DATE_FORMAT3,
      },
      {
        label: '待完工数量',
        prop: 'finish_num',
        valFun: (row) => {
          return `${row.plan_num - row.finish_num}`
        },
      },
      { label: '完工良品数', prop: 'good_num' },
      {
        label: '完工良品率',
        prop: 'good_num',
        valFun: (row) => {
          return `${(row.good_num / (row.finish_num || 1)) * 100}%`
        },
      },
      {
        label: '工序完成率',
        prop: 'finish_num',
        valFun: (row) => {
          return `${(row.finish_num / row.plan_num) * 100}%`
        },
      },
      { label: '返工数量', prop: 'rework_num' },
      {
        label: '返工率',
        prop: 'rework_num',
        valFun: (row) => {
          return `${(row.rework_num / (row.finish_num || 1)) * 100}%`
        },
      },
      { label: '报废数量', prop: 'scrap_num' },
      {
        label: '报废率',
        prop: 'scrap_num',
        valFun: (row) => {
          return `${(row.scrap_num / (row.finish_num || 1)) * 100}%`
        },
      },
      { label: '创建人', prop: 'creator.name' },
      { label: '创建时间', prop: 'create_time' },
      { label: '最近更新', prop: 'update_time' },
    ],
  }
  if (type === 'header') {
    defaultConfig.attrs.title = ''
    defaultConfig.attrs.labelWidth = ''
    defaultConfig.desColumns = defaultConfig.desColumns.slice(1, 9)
  }
  defaultConfig.api = {
    update: `/${MODULE}/edit`,
  }
  return defaultConfig
}

/**
 * 报工单基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function workTicketDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)

  let defaultConfig = {
    attrs: {
      border: true,
      column: 4,
      title: '基本信息',
      labelWidth: '100px',
      // direction: 'vertical',
    },
    desType: 'info',
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    desColumns: [
      { label: '报工单编号', prop: 'code' },
      {
        label: '生产工单',
        editType: 'tag',
        prop: 'production_receipt_info.product_info.title',
        valFun: (row) => {
          return `${row?.production_receipt_info?.product_info?.title} - ${row?.production_receipt_info?.code}`
        },
      },
      { label: '生产数量', prop: 'finish_num', class: 'b c0 fs16' },
      {
        label: '生产工序',
        prop: 'production_receipt_process_info.process_info.title',
      },
      { label: '生产人员', prop: 'mainer.name' },
      { label: '报工日期', prop: 'finish_time', dateTimeFormat: DATE_FORMAT3 },
      {
        label: '质检状态',
        prop: 'qc_status',
        editType: 'tag',
        tagTypeObj: NORMAL_STATUS_STEP_COLOR,
        valFun: (row) => {
          return PROCESS_RECEIPT_QC_STATUS[row.qc_status]
        },
      },
      { label: '返工数量', prop: 'rework_num' },
      { label: '报废数量', prop: 'scrap_num' },
      { label: '良品数量', prop: 'good_num' },
      {
        label: '报废率',
        prop: 'scrap_num',
        valFun: (row) => {
          return `${(row.scrap_num / (row.finish_num || 1)) * 100}%`
        },
      },
      {
        label: '返工率',
        prop: 'rework_num',
        valFun: (row) => {
          return `${(row.rework_num / (row.finish_num || 1)) * 100}%`
        },
      },
      { label: '生产工资', prop: 'salary' },
      {
        label: '工序单价',
        prop: 'production_receipt_process_info.process_info.price',
      },
      {
        label: '问题描述',
        prop: 'qc_receipt_detail_info.description',
        span: 12,
      },
      {
        label: '问题图片',
        prop: 'qc_receipt_detail_info.pic',
        isImgs: true,
        span: 12,
      },
      { label: '备注', prop: 'admin_remark', span: 12 },
      { label: '创建人', prop: 'creator.name' },
      { label: '创建时间', prop: 'create_time' },
      { label: '最近更新', prop: 'update_time' },
    ],
  }
  if (type === 'header') {
    defaultConfig.attrs.title = ''
    defaultConfig.attrs.labelWidth = ''
    defaultConfig.desColumns = defaultConfig.desColumns.slice(1, 9)
  }
  defaultConfig.api = {
    update: `/${MODULE}/edit`,
  }
  return defaultConfig
}

/**
 * 领料基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function materielPickingDesTable(MODULE = '', type = '', info = {}) {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)
  console.log('info :>> materielPickingDesTable', info)

  let defaultConfig = {
    attrs: {
      border: true,
      column: 4,
      title: '基本信息',
      labelWidth: '100px',
      // direction: 'vertical',
    },
    desType: 'info',
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    desColumns: [
      { label: '物料申请单号', prop: 'code' },
      {
        label: '审批状态',
        prop: 'status',
        editType: 'tag',
        tagTypeObj: NORMAL_STATUS_STEP_COLOR,
        valFun: (row) => {
          return AUDIT_STATUS[row.status]
        },
      },
      { label: '申请人', prop: 'mainer.name' },
      { label: '出库单', prop: 'repository_receipt_info.title' },
      { label: '申请时间', prop: 'create_time' },
      { label: '创建人', prop: 'creator.name' },
      { label: '创建时间', prop: 'create_time' },
      { label: '最近更新', prop: 'update_time' },
    ],
  }
  if (info.target === 1) {
    defaultConfig.desColumns.splice(2, 0, {
      label: '生产工单',
      prop: 'production_receipt_info.product_info.title',
    })
  } else if (info.target === 2) {
    defaultConfig.desColumns.splice(2, 0, {
      label: '委外工单',
      prop: 'entrust_info.code',
    })
  }
  if (type === 'header') {
    defaultConfig.attrs.title = ''
    defaultConfig.attrs.labelWidth = ''
    defaultConfig.desColumns = defaultConfig.desColumns.slice(0, 4)
  }
  defaultConfig.api = {
    update: `/${MODULE}/edit`,
  }
  return defaultConfig
}

/**
 * 模具基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function equipmentDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)

  let defaultConfig = {
    attrs: {
      border: true,
      column: 4,
      title: '基本信息',
      labelWidth: '75px',
      // direction: 'vertical',
    },
    desType: 'info',
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    desColumns: [
      { label: '模具名称', prop: 'title' },
      { label: '模具编码', prop: 'sn' },
      // {
      //   label: '外观尺寸',
      //   prop: 'size',
      //   valFun: (row) => {
      //     return `${row.l}*${row.w}*${row.h}`
      //   },
      // },
      { label: '模具类型', prop: 'equipment_type_info.title' },
      // { label: '模具用途', prop: 'use_purpose', },
      { label: '管理负责人', prop: 'mainer.name' },
      { label: '开发方', prop: 'developer_equipment_supplier_ids', span: 12 },
      { label: '维护方', prop: 'maintenance_equipment_supplier_ids', span: 12 },
      { label: '加工方', prop: 'process_equipment_supplier_ids', span: 12 },
      // { label: '耗材', prop: 'material_ids', span: 12 },
      { label: '耗材', prop: 'materials', span: 12 },
      { label: '当前位置', prop: 'position', span: 12 },
      {
        label: '状态',
        prop: 'status',
        editType: 'tag',
        tagTypeObj: EQUIPMENT_STATUS_COLOR,
        valFun: (row) => {
          return EQUIPMENT_STATUS[row.status]
        },
      },
      { label: '投产日期', prop: 'commission_date' },
      {
        label: '模具成本',
        prop: 'price',
        class: 'cd',
        valFun: (row) => {
          return `${CURRENCY_UNIT[row.currency]}${row.price}`
        },
      },
      { label: '生产日期', prop: 'production_date' },
      { label: '成本描述', prop: 'price_remark', span: 12 },
      { label: '产权描述', prop: 'equity_remark', span: 12 },
      { label: '创建人', prop: 'creator.name' },
      { label: '创建时间', prop: 'create_time' },
      { label: '最近更新', prop: 'update_time' },
      { label: '模具图集', prop: 'images', isImgs: true, span: 24 },
      { label: '模具描述', prop: 'use_purpose', span: 24 },
    ],
  }
  if (type === 'header') {
    defaultConfig.attrs.title = ''
    defaultConfig.attrs.labelWidth = ''
    defaultConfig.desColumns = defaultConfig.desColumns.slice(0, 4)
  }
  defaultConfig.api = {
    update: `/${MODULE}/edit`,
  }
  return defaultConfig
}
/**
 * 模具组基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function equipmentGroupDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)

  let defaultConfig = {
    attrs: {
      border: true,
      column: 4,
      title: '基本信息',
      labelWidth: '75px',
      // direction: 'vertical',
    },
    desType: 'info',
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    api: {
      update: `/${MODULE}/edit`,
    },
    desColumns: [
      { label: '名称', prop: 'title', span: 4 },
      {
        label: '研发成本',
        prop: 'price',
        class: 'cd b',
        valFun: (row) => {
          return `${CURRENCY_UNIT[row.currency]}${row.price}`
        },
        span: 4,
      },
      {
        label: '生产成本',
        prop: 'production_price',
        class: 'cd b',
        valFun: (row) => {
          return `${CURRENCY_UNIT[row.currency]}${row?.production_price || '-'}`
        },
        span: 4,
      },
      {
        label: '预计售价',
        prop: 'sell_price',
        class: 'cd b',
        valFun: (row) => {
          return `${CURRENCY_UNIT[row.currency]}${row.sell_price}`
        },
        span: 4,
      },
      { label: '回本数量', prop: 'win_num', class: 'cb b', span: 4 },
      {
        label: '状态',
        prop: 'status',
        editType: 'tag',
        valFun: (row) => {
          return EQUIPMENT_GROUP_STATUS[row.status]
        },
        span: 4,
      },
      { label: '负责人', prop: 'mainer.name', span: 4 },
      {
        label: '样品状态',
        prop: 'sample_status',
        editType: 'tag',
        valFun: (row) => {
          return EQUIPMENT_GROUP_SAMPLE_STATUS[row.sample_status]
        },
        span: 4,
      },
      {
        label: '优先级',
        prop: 'priority',
        editType: 'tag',
        valFun: (row) => {
          return EQUIPMENT_GROUP_PRIORITY[row.priority]
        },
        span: 4,
      },
      {
        label: '启动时间',
        prop: 'start_time',
        span: 4,
        dateTimeFormat: DATE_FORMAT2,
      },
      {
        label: '预计完结',
        prop: 'predict_finish_time',
        span: 4,
        dateTimeFormat: DATE_FORMAT2,
      },
      {
        label: '实际完结',
        prop: 'end_time',
        span: 4,
        dateTimeFormat: DATE_FORMAT2,
      },
      { label: '封面图', prop: 'image', span: 4, isImg: true },
      { label: '图集', prop: 'slider_image', span: 20, isImgs: true },
      { label: '视频', prop: 'video', span: 24, isVideo: true },
      { label: '项目描述', prop: 'content', span: 24, editType: 'html' },
      { label: '项目成本', prop: 'price_remark', span: 24, editType: 'html' },
      {
        label: '产品成本',
        prop: 'product_price_remark',
        span: 24,
        editType: 'html',
      },
      { label: '项目复盘', prop: 'review', span: 24, editType: 'html' },
      { label: '创建人', prop: 'creator.name' },
      { label: '创建时间', prop: 'create_time' },
      { label: '最近更新', prop: 'update_time' },
    ],
  }
  if (type === 'header') {
    defaultConfig.attrs.title = ''
    defaultConfig.attrs.labelWidth = ''
    defaultConfig.desColumns = defaultConfig.desColumns.slice(0, 6)
  }

  return defaultConfig
}
/**
 * 模具供应商基本信息
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function equipmentSupplierDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)

  let defaultConfig = {
    attrs: {
      border: true,
      column: 4,
      title: '基本信息',
      labelWidth: '75px',
      // direction: 'vertical',
    },
    desType: 'info',
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    api: {
      update: `/${MODULE}/edit`,
    },
    desColumns: [
      { label: '名称', prop: 'title', span: 8 },
      {
        label: '类型',
        prop: 'category_info.title',
        editType: 'tag',
        span: 4,
      },
      {
        label: '标签',
        prop: 'label_list',
        editType: 'tagList',
        span: 12,
      },
      { label: '头像', prop: 'image', span: 4, isImg: true },
      { label: '图集', prop: 'slider_image', span: 16, isImgs: true },
      { label: '视频', prop: 'video', span: 4, isVideo: true },
      { label: '描述', prop: 'content', span: 24, editType: 'html' },
      { label: '创建人', prop: 'creator.name' },
      { label: '创建时间', prop: 'create_time' },
      { label: '最近更新', prop: 'update_time' },
    ],
  }
  if (type === 'header') {
    defaultConfig.attrs.title = ''
    defaultConfig.attrs.labelWidth = ''
    defaultConfig.desColumns = defaultConfig.desColumns.slice(0, 6)
  }

  return defaultConfig
}
/**
 *
 * @param {*} MODULE 模块
 * @param {*} type // 类型判断
 * @returns
 */
export function thirdRepositoryTransferDesTable(MODULE = '', type = '') {
  console.log('MODULE :>> ', MODULE)
  console.log('type :>> ', type)

  let defaultConfig = {
    attrs: {
      border: true,
      column: 4,
      title: '基本信息',
      labelWidth: '75px',
      // direction: 'vertical',
    },
    desType: 'info',
    titleSlotName: '',
    extraSlotName: '',
    headerActions: {
      list: [''],
    },
    api: {
      update: `/${MODULE}/edit`,
    },
    desColumns: [
      { label: '编码', prop: 'code', span: 4 },
      {
        label: '类型',
        prop: 'type',
        editType: 'tag',
        tagTypeObj: NORMAL_STATUS_COLOR,
        valFun: (row) => {
          return THIRD_REPOSITORY_TRANSFER_TYPE[row.type]
        },
        span: 3,
      },
      {
        label: '调入仓库',
        prop: 'in_third_repository_info.title',
        class: 'b',
        minWidth: 150,
        valFun: (row) => {
          return `${
            row?.in_third_repository_info?.full_title ||
            row?.in_third_repository_info?.title ||
            '-'
          }`
        },
        span: 4,
      },
      {
        label: '审核状态',
        prop: 'audit_status',
        editType: 'tag',
        tagTypeObj: AUDIT_STATUS_COLOR,
        valFun: (row) => {
          return AUDIT_STATUS[row.audit_status]
        },
        span: 3,
      },
      {
        label: '状态',
        prop: 'status',
        editType: 'tag',
        tagTypeObj: REPOSITORY_COMMON_STATUS_COLOR,
        valFun: (row) => {
          return REPOSITORY_COMMON_STATUS[row.status]
        },
        span: 3,
      },
      { label: '创建人', prop: 'creator.name' },
      { label: '创建时间', prop: 'create_time' },
      { label: '最近更新', prop: 'update_time' },
    ],
  }
  if (type === 'header') {
    defaultConfig.attrs.title = ''
    defaultConfig.attrs.labelWidth = ''
    defaultConfig.desColumns = defaultConfig.desColumns.slice(0, 6)
  }

  return defaultConfig
}
