<template>
  <jwt-form
    ref="formRef"
    v-model="appModel"
    class="app-form-container"
    :config="appConfig"
    @addSuccess="handleAddSuccess"
    @editSuccess="handleEditSuccess"
    @get-detail-success="handleGetDetailSuccess"
    @reset="handleReset"
    @submit="handleSubmit"
  >
    <template v-for="k in Object.keys(slots)" #[k]="{ model }" :key="k">
      <slot :model="model" :name="k"></slot>
    </template>
  </jwt-form>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  onBeforeMount,
  reactive,
  toRefs,
  useSlots,
} from 'vue'
import { merge } from 'lodash-es'
import request from '@/utils/request'
import { fillUrl } from '@/common/js/utils'
import { uploadUrl, uploadRichTextUrl } from '@/config/index'
import { translateTitle } from '@/utils/i18n'

export default defineComponent({
  name: 'BaseForm',
  components: {},
  props: {
    modelValue: {
      require: true,
      type: [Object, Function],
      default: () => {},
    },
    config: {
      require: true,
      type: [Object, Function],
      default: () => {},
    },
    responseKey: { type: String, default: 'relative_path' }, // 图片上传返回地址key
  },
  emits: [
    'submit',
    'reset',
    'update:modelValue',
    'add-success',
    'edit-success',
    'get-detail-success',
  ],
  setup(props, { emit }) {
    //异步调用时不能够生成响应式属性，可设置计算属性将变化后的值直接赋值给变量
    let appConfig = computed(() => {
      return merge({}, props.config)
    })
    const slots = useSlots()
    const appModel = computed({
      get: () => {
        return props.modelValue
      },
      set: (value) => {
        console.log(`value`, value)
        emit('update:modelValue', value)
      },
    })

    const state = reactive({})

    const formRef = ref(null)

    // 添加请求成功
    const handleAddSuccess = (data) => {
      emit('add-success', data)
    }

    // 编辑请求成功
    const handleEditSuccess = (data) => {
      emit('edit-success', data)
    }
    // 获取详情
    const handleGetDetailSuccess = (data) => {
      emit('get-detail-success', data)
    }

    const handleReset = () => {}

    const handleSubmit = (data) => {
      emit('submit', data)
    }

    const formSubmit = (type = '', params = {}) => {
      formRef.value?.formSubmit(type, params)
    }

    onBeforeMount(() => {})

    return {
      slots,
      formRef,
      appModel,
      appConfig,
      request,
      uploadUrl,
      uploadRichTextUrl,
      ...toRefs(state),
      fillUrl,
      handleAddSuccess,
      handleEditSuccess,
      handleGetDetailSuccess,
      translateTitle,
      handleReset,
      handleSubmit,
      formSubmit,
    }
  },
})
</script>

<style lang="scss" scoped></style>

<style>
/* .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .el-col-dl {
    display: inline-block !important;
    flex: none;
  } */
</style>
