<!--
 * @Author: WGL
 * @Date: 2023-06-19 09:06:21
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-03-07 16:54:02
 * @Description: 
-->
<template>
  <el-popover
    v-if="listData.length"
    :persistent="false"
    placement="bottom"
    trigger="hover"
    :width="240"
  >
    <div>
      <p class="tc fs14">{{ t('正在上传的文件') }}</p>
      <ul>
        <li v-for="(item, index) in listData" :key="index">
          <div class="omit">{{ item.fileName }}</div>
          <el-progress :percentage="item.percentage" />
        </li>
      </ul>
    </div>
    <template #reference>
      <el-badge :value="listData.length">
        <vab-icon icon="upload-fill" />
      </el-badge>
    </template>
  </el-popover>
  <el-tooltip
    v-else
    :content="t('导入历史')"
    effect="dark"
    :persistent="false"
    placement="top"
  >
    <vab-icon icon="upload-fill" @click="handleDownloadList" />
  </el-tooltip>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  watchEffect,
  onUnmounted,
} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import request, { handleErrCode } from '@/utils/request'
import { needDownloadCode } from '@/config'
import { t } from '@/utils/i18n'
export default defineComponent({
  name: 'VabUploadloadFile',
  setup() {
    const store = useStore()
    const router = useRouter()
    let timer = null
    // 正在导入的文件队列
    const asynImportFileList = computed(
      () => store.getters['sys/asynImportFileList']
    )
    const asynImportFileObj = computed(() => {
      const obj = {}
      asynImportFileList.value.forEach((item) => {
        obj[item.id] = item
      })
      return obj
    })

    const state = reactive({
      listData: [],
    })

    // 获取下载的任务
    const handleGetDownloadFile = () => {
      request({
        url: `/import-task/index`,
        // url: `/index/inspection`,
        method: 'get',
        params: {
          sort: 'id',
          order: 'desc',
          // 'status|<': 10,
          per_page: 100,
        },
      }).then((res) => {
        const result = res?.data?.filter((item) => {
          item.percentage = (
            (item.curr * 100) /
            (item.total || Infinity)
          ).toFixed(2)
          item.fileName = item?.title || ''
          if (asynImportFileObj.value[item.id]) {
            if (item.status === 10) {
              store.dispatch('sys/deleteAsynImportFileList', item.id)
            } else if (item.status === -1) {
              store.dispatch('sys/deleteAsynImportFileList', item.id)
              handleErrCode(needDownloadCode, item.reason, {
                data: {
                  http_path: item.callback_file_path,
                },
              })
            } else {
              return true
            }
          }
        })
        if (result.length) {
          timer && clearTimeout(timer)
          timer = setTimeout(() => {
            handleGetDownloadFile()
          }, 1000 * 3)
        }
        state.listData = result
      })
    }

    watchEffect(() => {
      if (asynImportFileList.value?.length) {
        handleGetDownloadFile()
      }
    })

    const handleDownloadList = () => {
      router.push({
        path: '/logs/import',
      })
    }

    onMounted(() => {
      // handleGetDownloadFile()
    })

    onUnmounted(() => {
      if (timer) {
        clearTimeout(timer)
      }
    })
    return {
      ...toRefs(state),
      t,
      handleGetDownloadFile,
      handleDownloadList,
    }
  },
})
</script>
