<!--
 * @Author: WGL
 * @Date: 2024-05-13 09:43:40
 * @LastEditors: WGL 958897276@qq.com
 * @LastEditTime: 2025-03-11 13:58:55
 * @Description: 
-->
<template>
  <div class="stash-cascader-panel-wrapper">
    <template v-for="(panel, i) in panelList" :key="i">
      <slot :level="i + 1" name="panelItm" :panel="panel">
        <CascaderPanelItem
          :grid-level="gridLevel"
          :level="i + 1"
          :nodes="panel"
          :prop="prop"
          :show-arrow="showArrow"
        >
          <template #nodeItem="{ node, index, level }">
            <slot
              :index="index"
              :level="level"
              name="nodeItem"
              :node="node"
            ></slot>
          </template>
          <template #empty="{ node, index, level }">
            <slot
              :index="index"
              :level="level"
              name="empty"
              :node="node"
            ></slot>
          </template>
        </CascaderPanelItem>
      </slot>
    </template>
  </div>
</template>
<!-- 仓库级联组件 -->
<script setup>
import {
  defineProps,
  ref,
  watch,
  reactive,
  provide,
  computed,
  defineEmits,
  nextTick,
  defineExpose,
} from 'vue'
import CascaderPanelItem from './components/CascaderPanelItem'
import { cloneDeep } from 'lodash-es'

// import { useCascaderPanel } from './useCascaderPane.ts'

const props = defineProps({
  options: { type: Array, default: () => [] },
  prop: {
    type: Object,
    default: () => {
      return {
        label: 'title',
        value: 'id',
        children: 'children',
      }
    },
  },
  gridLevel: { type: Array, default: () => [] },
  initData: { type: Boolean, default: true },
  showArrow: { type: Boolean, default: false },
  lazy: { type: Boolean, default: false }, // 是否懒加载
  load: { type: Function, default: () => {} }, // 懒加载方法
})
const emit = defineEmits(['expand'])
const panelList = ref([])
const checkedNodes = ref([])
const expandingNode = ref(null)
// const {  } = useCascaderPanel(props)
const initStore = () => {
  if (!props.initData) {
    return
  }
  panelList.value = [cloneDeep(props.options)]
}

const expandNode = async (node, level, index) => {
  const newMenus = panelList.value.slice(0, level)
  // debugger
  // if (level === 1) {
  //   newCheckedNodes = [node]
  // } else {
  // }
  checkedNodes.value.splice(level - 1, 1, node)
  // if (newCheckedNodes) {
  // }
  let newExpandingNode = node
  if (props.lazy) {
    const loadData = await props.load(node, level, index)
    newMenus.push(loadData)
  } else {
    if (node.children?.length) {
      newMenus.push(node.children)
    } else {
      newMenus.push([])
    }
  }
  if (expandingNode?.value?.id !== newExpandingNode?.id) {
    nextTick(() => {
      emit('expand', {
        showCol: newMenus.length,
        node,
        checkedNodes: checkedNodes.value,
        index,
        panelList: newMenus,
      })
    })
  }
  expandingNode.value = node
  panelList.value = newMenus
}
const checkedNodesIds = computed(() => {
  return checkedNodes.value?.map((i) => i[props.prop?.value])
})

const setExpandNode = (list, node) => {
  expandingNode.value = node
  panelList.value = list
}
watch(() => props.options, initStore, {
  deep: true,
  immediate: true,
})
provide(
  'stashCascaderPanel',
  reactive({
    checkedNodes,
    checkedNodesIds,
    expandNode,
  })
)

defineExpose({
  expandNode,
  setExpandNode,
})
</script>
<script>
export default {
  name: 'StashCascaderPanel',
}
</script>

<style scoped lang="scss">
.stash-cascader-panel-wrapper {
  display: flex;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
}
</style>
